export const normalizeArray = ({ entity, key, array }) => {
    const normalized = {
        [entity]: {},
        allIds: []
    };

    array.forEach(item => {
        const id = item[key];
        normalized[entity][id] = item;
        normalized.allIds.push(id);
    });

    return normalized;

}