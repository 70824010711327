import React from "react";
import classNames from "classnames";
import moment from "moment";

import "./user-status.scss";
import { formatTime } from "../../utils/timeLegend";

const UserStatus = ({ childern }) => {

    return childern
};

const getClassname = (status) => {
    return classNames({
        "in-call": status.loggedUserStatus === 4,
        "online": status.loggedUserStatus === 1,
        "away": status.loggedUserStatus !== null && status.loggedUserStatus === 2 && status.pause && status.pause.isProductive,
        "absent": status.loggedUserStatus !== null && status.loggedUserStatus === 2 && status.pause && !status.pause.isProductive,
        "offline": status.loggedUserStatus === 3
    })
}
UserStatus.Marker = ({status}) => {
    return <span className={`user-status-marker-ui ${getClassname(status)}`}></span>
}

UserStatus.Label = ({status}) => {
    const statusLabel = status.loggedUserStatus !== null &&
                        status.loggedUserStatus === 4
                        ? "Em ligação"
                        : status.loggedUserStatus === 1
                        ? "Online"
                        :   status.loggedUserStatus !== null && status.loggedUserStatus === 2 && status.pause && status.pause.isProductive ||
                            status.loggedUserStatus !== null && status.loggedUserStatus === 2 && status.pause && !status.pause.isProductive
                        ? <><b>{status.pause && formatTime(moment().diff(moment.utc(status.pause.startedAt).utcOffset("-03:00").locale('pt-br'), 'minutes'))}</b> {status.pause && status.pause?.pauseName}</>
                        : "Offline"
    return <span className={`user-status-label-ui ${getClassname(status)}`}>{statusLabel}</span>
}


export default UserStatus;