export const setWindowHierarchy = (window) => {
    return dispatch => {
        dispatch({
            type: "WINDOW_HIERARCHY",
            window
        })
    }
}

export const toggleShowNotifications = () => {
    return dispatch => {
        dispatch({
            type: "TOGGLE_SHOW_NOTIFICATIONS",
        })
    }
}