import React, { MouseEvent } from 'react';
import './toggle.scss';

interface ToggleProps {
  label?: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  className?: string;
  color?: string;
  disabled?: boolean;
}

function Toggle({
  label,
  value,
  onChange,
  name,
  id,
  className,
  color,
  disabled,
}: ToggleProps) {
  function changeToggle() {
    onChange({
      target: {
        name: name,
        value: !value,
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  }

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    if (!disabled) {
      changeToggle();
    }
  }

  return (
    <div
      id={id}
      className={`rc-toggle-ui ${className ? className : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={handleClick}
    >
      <div
        className={`rc-toggle-ui-button ${value ? 'on' : 'off'} ${
          color ? `toggle-${color}` : ''
        }`}
      >
        <div className="rc-toggle-ui-bullet"></div>
      </div>
      {label && <span>{label}</span>}
    </div>
  );
}

export default Toggle;
