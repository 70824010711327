export const STORE_RELEASE_NOTES_VERSIONS = 'STORE_RELEASE_NOTES_VERSIONS';
export const SET_CURRENT_RELEASE_VERSION = 'SET_CURRENT_RELEASE_VERSION';
export const TOGGLE_RELEASE_NOTES_MODALS = 'TOGGLE_RELEASE_NOTES_MODALS';
export const DISMISS_RELEASE_VERSION = 'DISMISS_RELEASE_VERSION';
export const UPDATE_RELEASE_NOTE_USER_STATUS = 'UPDATE_RELEASE_NOTE_USER_STATUS';
export const SHOW_RELEASE_NOTE = 'SHOW_RELEASE_NOTE';
export const STORE_RELEASE_NOTE_PAGES = 'STORE_RELEASE_NOTE_PAGES';
export const RELEASE_NOTES_PAGE_NAVIGATION = 'RELEASE_NOTES_PAGE_NAVIGATION';
export const CLOSE_WELCOME_MODAL = 'CLOSE_WELCOME_MODAL';
export const CLOSE_PRESENTATION_MODAL = 'CLOSE_PRESENTATION_MODAL';
export const RELEASE_NOTE_CHANGE_PRESENTATION = 'RELEASE_NOTE_CHANGE_PRESENTATION'
