import { call, put, takeEvery, select, delay } from 'redux-saga/effects';

import { USER_DATA, SAGA_ERROR } from '../../../helpers/constants';
import { START_INTERNAL_CHATS } from '../../../helpers/internalChatConstants';
import { NOTIFY_UPDATE_LOGGED_USER_STATUS } from '../../../helpers/constants';
import { 
  CORPORATE_NOTIFY_USER_TYPING_MESSAGE, 
  CORPORATE_NOTIFY_MESSAGE, 
  CORPORATE_NOTIFY_MESSAGE_DELETE,
  CORPORATE_NOTIFY_FINISHED_CONVERSATION,
  REMOVE_CONTACT_FROM_LIST,
  CORPORATE_NOTIFY_MESSAGE_WAS_READ
} from '../../../helpers/internalChatConstants';

import {
  updateUserIsTyping,
  addNewMessageToContact,
  updateExistinContact,
  craetaNewContact,
  updateActiveChatUserStatus,
  updateContactStatus,
  deleteMessageFromContact,
  removeContactFromList,
  changeMessageStatus
} from '../utils/internalChatFunctions/socketNotificationsFunc';

import {
  requestReadMessageIfActiveChat
} from '../utils/internalChatFunctions/requestReadMessagesFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getContacts = state => state.internalChat.contacts;
const getUserStatus = state => state.internalChat.userStatus;
const getActiveChat = state => state.internalChat.activeChat;


function* receiveIsTypingNotification(action) {
  const contacts = yield select(getContacts);
  yield put(updateUserIsTyping(contacts, action.payload));
}

function* receiveCorporateMessageNotification(action) {

  const contacts = yield select(getContacts);
  const userStatus = yield select(getUserStatus);
  const activeChat = yield select(getActiveChat);
  const updateContact = yield call(updateExistinContact, contacts, action.payload);
  if(activeChat && activeChat.user && activeChat.user.id === action.payload.conversationId) yield call(requestReadMessageIfActiveChat, action.payload);
  if (updateContact) {
    const updatedContactList = yield select(getContacts);
    yield put(addNewMessageToContact(updatedContactList, activeChat.user, action.payload));
  } else {
    yield put(craetaNewContact(contacts, userStatus, action.payload));
  }

}

function* changeUserStatusNotification(action) {

  const contacts = yield select(getContacts);
  const activeChat = yield select(getActiveChat);

  try {

    // if (activeChat?.user) yield put(updateActiveChatUserStatus(activeChat, action.payload));

    yield put(updateContactStatus(action.payload));

  } catch (e) {
    yield put({
      type: SAGA_ERROR,
      origin: errorOrigin.saga,
      errorType: errorTypes.error,
      response: e.message
    });
  }

}

function* deleteMessageByNotification(action) {
  const contacts = yield select(getContacts);

  try {
    yield put(deleteMessageFromContact(contacts, action.payload));
  } catch (e) {
    yield put({
      type: SAGA_ERROR,
      origin: errorOrigin.saga,
      errorType: errorTypes.error,
      response: e.message
    });
  }

}

function* removeConversationByNotification(action) {
  const contacts = yield select(getContacts);

  try {
    yield put(removeContactFromList(contacts, action.payload));
  } catch (e) {
    yield put({
      type: SAGA_ERROR,
      origin: errorOrigin.saga,
      errorType: errorTypes.error,
      response: e.message
    });
  }
}

function* changeMessageStatusByNotification(action) {
  const contacts = yield select(getContacts);

  try {
    yield put(changeMessageStatus(contacts, action.payload));
  } catch (e) {
    yield put({
      type: SAGA_ERROR,
      origin: errorOrigin.saga,
      errorType: errorTypes.error,
      response: e.message
    });
  }
}

export default function* socketNotificationsSaga() {
  yield takeEvery(CORPORATE_NOTIFY_USER_TYPING_MESSAGE, receiveIsTypingNotification);
  yield takeEvery(CORPORATE_NOTIFY_MESSAGE, receiveCorporateMessageNotification);
  yield takeEvery(NOTIFY_UPDATE_LOGGED_USER_STATUS, changeUserStatusNotification);
  yield takeEvery(CORPORATE_NOTIFY_MESSAGE_DELETE, deleteMessageByNotification);
  yield takeEvery(CORPORATE_NOTIFY_FINISHED_CONVERSATION, removeConversationByNotification);
  yield takeEvery(CORPORATE_NOTIFY_MESSAGE_WAS_READ, changeMessageStatusByNotification);
}