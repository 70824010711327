import React from 'react';
import { Heading } from '../../../typography';
import { calendarDate } from '../../../../utils/dateUtility';
import Avatar from '../../../avatar/Avatar';
import UserStatus from "../../../userStatus/UserStatus";
import { useSelector } from 'react-redux';
import './contact-details.scss';

export const ContactDetails = ({ user, position }) => {
    
    const contacts = useSelector(state => state.internalChat.contacts);
    const userStatus = useSelector(state => state.internalChat.userStatus.byUserId)[user?.userId] || null;

    const getUserData = React.useMemo(() => {
        if(user?.userId){
            return contacts.find(contact => contact.userId === user.userId) || false;
        } else {
            return null
        }
    }, [contacts, user?.userId]);

    
    return (<div
        style={{ top: position.y - 5 }}
        className={`contact-details-ui ${user && (position.y > 0 || position.x > 0) ? "show" : ""}`}>
        <Avatar
            name={user?.name}
            size="lg"
            style={{ backgroundColor: user?.color?.light, color: user?.color?.dark }}
            status={userStatus ? <UserStatus.Marker status={userStatus} /> : null} />
        <div className="contact-details-info">
            <div className="contact-details-info-header">
                <Heading type="h6" fw={600} color="primary">{user?.name}</Heading><span>{calendarDate(user?.dateLastMessage)}</span>
            </div>
            <div className="contact-details-info-last-message">
                {user?.lastMessage}
            </div>
        </div>
    </div>)
};

export default React.memo(ContactDetails);