import { UserManager, processSilentRenew } from 'oidc-client';
import { storeUserError, storeUser } from '../../redux/actions/authentication/authActions'
import { IDENTITY_CONFIG, METADATA_OIDC } from "../../utils/auth-constants"
import api from '../../services/apps/settings/users'
import status from '../../utils/usersStatus'

const userManager = new UserManager(IDENTITY_CONFIG)

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser()
    if (!user) { return store.dispatch(storeUserError()) }
      store.dispatch(storeUser(user))
      console.log(user)
  } catch (e) {
    console.error(`User not found: ${e}`)
    store.dispatch(storeUserError())
  }
}

export function signinRedirect() {
  return userManager.signinRedirect()
}

export function signinRedirectCallback() {
  /*api.userLogon()
    .then(res => {
      alert(JSON.stringify(res))
    })
    .catch(err => { alert(JSON.stringify(err)) })*/
  return userManager.signinRedirectCallback()
}

export function signinPopUp() {
  return userManager.signinPopup().then(res => {
    console.log(res)
  });
}

export function silentRefresh() {
  //processSilentRenew()
  userManager.signinSilentCallback()
  .catch((err) => {
      console.log(err);
  });
}


export async function signoutRedirect(origin) {
  let user = await userManager.getUser()
    const logout = status.logout(origin).then( res => {
      localStorage.removeItem('pauseId')
      localStorage.removeItem('userInfo')
      return userManager.signoutRedirect({ 'id_token_hint': user.id_token });
    }).catch(err => {
      console.log(err)
    })
    
    //


}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  console.log('redirect_callback')
  localStorage.removeItem('userInfo')
  
  return userManager.signoutRedirectCallback()
  
  
}

export default userManager