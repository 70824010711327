const calcTime = (time) => {
  let timelabel = 0
  //SE MENOR DE ZERO
  if(Math.floor(time) === 0 && (time - Math.floor(time)) > 0){
    return (`${Math.floor(time * 60)}s`)
  } 

  if(Math.floor(time) === 0 && (time - Math.floor(time)) === 0){
    return 0
  }

  //se ENTRE 1 E 60
  if(time > 1 && time < 60){
    return (`${Math.floor(time)}m ${ Math.floor((time - Math.floor(time)) * 60)}s`)
  }

  //SE MAIOR DE 60
  if(time > 60){
    if((time / 60) > 24){
      return (`${Math.floor((time / 60) / 24)}d ${ Math.floor((time - (Math.floor((time / 60) / 24))*24*60)/60)}h`)
    } else {
      return (`${Math.floor((time / 60))}h ${Math.floor(((time / 60) - Math.floor((time / 60))) * 60)}m`)
    }
    
    return timelabel
  }
}

const durationTime = (time) => {
  if(time && time !== Infinity){
    const timeLabel = new Date((time*60) * 1000).toISOString().substr(11, 8).split(':');
    //let duration = `${timeLabel[0]}:${timeLabel[1]}:${timeLabel[2]}`
    let duration = ``
    if(Number(timeLabel[0]) > 0){
      duration = duration + timeLabel[0] + ':'
    }
    if(Number(timeLabel[1]) > 0){
      duration = duration + timeLabel[1]
    } else {
      duration = duration + '00'
    }
    if(Number(timeLabel[2]) > 0){
      duration = duration + ":" + timeLabel[2]
    }
    return duration
  }
}

const secondsToTime = (seconds) => {
  
  if(seconds > 0 && seconds < 60){
    return `00:${seconds < 10 ? `0${seconds}` : seconds}`
  } else if(seconds >=60 && seconds <= 3599) {
    const min = Math.floor(seconds / 60)
    const sec = parseInt(seconds) - (parseInt(min*60))
    return `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
  }  else if(seconds > 3599) {
    const hr = Math.floor(seconds / 3600)
    let sec = parseInt(seconds) - (parseInt(hr*3600))
    const min = Math.floor(sec / 60)
    sec = parseInt(sec) - parseInt(min * 60)
    return `${hr < 10 ? `0${hr}` : hr}:${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`

  } else {
    return `00:00`
  }
}

const formatHoursMinutes = (minutes) => {
  
  if(minutes > 0 && minutes < 60){
    return `0:${minutes < 10 ? `0${minutes}` : minutes}`
  } else if(minutes >=60 && minutes <= 3599) {
    const min = Math.floor(minutes / 60)
    const sec = parseInt(minutes) - (parseInt(min*60))
    return `${min < 10 ? `${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
  }  else if(minutes > 3599) {
    const hr = Math.floor(minutes / 60)
    let min = parseInt(minutes) - (parseInt(hr*60))
    return `${hr}:${min < 10 ? `0${min}` : min}`

  } else {
    return minutes
  }
}

const secondsToTime2 = (seconds) => {
  const roundedSeconds = Math.round(seconds)
  let hours = Math.floor(roundedSeconds / 3600);
  let minutes = Math.floor((roundedSeconds % 3600) / 60);
  let secondsLeft = roundedSeconds % 60;

  let resultado = hours > 0 && minutes > 0 && secondsLeft > 0 ? 
                      `${hours} h, ${minutes} m e ${secondsLeft} s`:
                    hours > 0 && minutes > 0 && secondsLeft === 0 ?
                      `${hours} h e ${minutes} m`:
                    hours > 0 && minutes === 0 && secondsLeft > 0 ?
                      `${hours} h e ${secondsLeft} s`:
                    hours > 0 && minutes === 0 && secondsLeft === 0 ?
                      `${hours} h`:
                    hours === 0 && minutes > 0 && secondsLeft > 0 ?
                      `${minutes} m e ${secondsLeft} s`:
                    hours === 0 && minutes > 0 && secondsLeft === 0 ?
                      `${minutes} m`:
                    hours === 0 && minutes === 0 && secondsLeft > 0 ?
                      `${secondsLeft} s`:
                      ` - `;

  return resultado;
}

const formatTime = (time) => {
  if (time && !isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes =
      minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(time % 60);
    const formatSeconds =
      seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  }
  return '00:00';
};




export {
  calcTime,
  durationTime,
  secondsToTime,
  formatHoursMinutes,
  secondsToTime2,
  formatTime
}