import React from 'react'
import {ReactComponent as X} from './x.svg'
import "./close-button.scss"

const CloseButton = ({ onClick }) => {
  return (
      <div className={`rc-close-button-ui`}  onClick={onClick}>
        <X />
      </div>
  )
}
export default CloseButton