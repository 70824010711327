import React, { useState, useCallback, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useIntl } from "react-intl";
import moment from "moment";
import { useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';

import { ReactComponent as EmojiIcon } from "../../../../../assets/img/svg/lol.svg";
import { ReactComponent as SentIcon } from "../../../../../assets/img/svg/sent.svg";

import CommandBar from "../../../../commandBar/CommandBar";
import { Small } from "../../../../typography";
import Button from "../../../../button/Button";
import EmojiPicker from "../../../../emojisPicker/EmojisPicker";

import {
    REMOVE_CONTACT_FROM_LIST,
    SEND_INTERNAL_CHAT_MESSAGE
} from "../../../../../helpers/internalChatConstants";

import useChatDispatchActions from '../../../../../customHooks/useChatDispatchActions';
import usePrevious from '../../../../../customHooks/usePrevious';

import { uuidv4 } from "../../../../../utils/uuidGenerator";
import { getLocalTime } from '../../../../../utils/dateUtility';

import { conversationsService } from "../../../../../newServices/corporateChat";

import "./footer.scss";

const Footer = ({
    isFinished,
}) => {
    const intl = useIntl();
    const { activeChat } = useSelector(state => state.internalChat);
    const dispatchChatActions = useChatDispatchActions()

    /** For performance reasions, it was necessary
     * to create variables to control the typing status
     */
    let typingTimer = null;
    let isTyping = false;
    const typingInterval = 500;
    /** --------------------------------------------- */

    /** This variable are being used just for show or hide the field.
     *  Using ref, the field is not being updated instantaneously
     */
    const [messageText, setMessageText] = useState({});
    /** ----------------------------------------------------------- */

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const inputTextRef = useRef(null);

    const prevActiveChat = usePrevious(activeChat);

    /** To avoid setState in each typing, we are useing debouncing function.
     *  When the app updates the state, the component is re-rendered, causing a lag
     */
    const debouncedInputChange = useCallback(
        _debounce((value) => {
            setMessageText({
                ...messageText,
                [activeChat.user.id]: value
            });
        }, 300),
        [activeChat]
    );

    /** Make an API Call when user start typping */
    const startTyping = () => {
        if (!isTyping) {
            requestTypingStatus();
            isTyping = true;
        }
    };

    /** Make an API Call when user stop typping */
    const stopTyping = () => {
        if (isTyping) {
            requestTypingStatus();
            isTyping = false;
        }
    };


    const onInputChange = useCallback((e) => {
        const { value } = e.target;
        debouncedInputChange(value);

        clearTimeout(typingTimer);
        startTyping();

        typingTimer = setTimeout(() => {
            stopTyping();
        }, typingInterval);


    }, [activeChat]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage()
        } else if (event.key === 'Enter' && event.shiftKey) {
            event.preventDefault();
            const textarea = event.target;
            const start = textarea.selectionStart;
            const end = textarea.selectionEnd;
            const value = textarea.value;
            const newValue = value.substring(0, start) + '\n' + value.substring(end);
            textarea.value = newValue;
            textarea.setSelectionRange(start + 1, start + 1);
        }
    }, [inputTextRef])

    const handleGetEmoji = (emoji) => {

        const inputElement = inputTextRef.current;
        const currentValue = inputElement?.value;
        const caretPosition = inputElement.selectionStart;

        const newValue = currentValue.slice(0, caretPosition) + emoji + currentValue.slice(caretPosition);

        inputElement.value = newValue;
        inputElement.selectionStart = caretPosition + emoji.length;
        inputElement.selectionEnd = caretPosition + emoji.length;
        inputElement.focus();

        setMessageText({
            ...messageText,
            [activeChat.user.id]: newValue
        });
    }

    const requestTypingStatus = useCallback(() => {
        conversationsService
            .putConversationsTypingConversationId({
                conversationId: activeChat?.user?.id,
                isTyping: !isTyping
            })
    }, [isTyping, activeChat])


    const handleSendMessage = () => {
        if (!inputTextRef?.current?.value || inputTextRef?.current?.value?.trim().length === 0) return
        dispatchChatActions(SEND_INTERNAL_CHAT_MESSAGE, {
            createdAt: getLocalTime(moment()),
            messageText: inputTextRef.current.value,
            externalReference: uuidv4(),
        })

        /** Clean up the input value */
        inputTextRef.current.value = ""

       /** Clean up the text stored in messageText state */
        debouncedInputChange("")

        clearTimeout(typingTimer);
        setShowEmojiPicker(false)
    }

    useEffect(() => {
        if (prevActiveChat?.user?.id !== activeChat?.user?.id) {
            const userMessageText = messageText && messageText[activeChat?.user?.id] ? messageText[activeChat?.user?.id] : ""
            setMessageText({
                ...messageText,
                [activeChat?.user?.id]: userMessageText
            });
            if(inputTextRef?.current) inputTextRef.current.value = userMessageText
        }
    }, [prevActiveChat, activeChat])


    return (<>
        <div className={`test-emoji ${showEmojiPicker ? 'show' : ''}`}>
            <div className="test-emoji-container">
                <EmojiPicker
                    handleEmoji={handleGetEmoji}
                    show={showEmojiPicker} />
            </div>
        </div>
        <div
            className="chat-window-footer-ui">
            {isFinished ?
                <>
                    <Small color="gray">{intl.formatMessage({ id: "Esse chat foi finalizado" })}</Small>
                    <Button.Danger size="xs" onClick={() => dispatchChatActions(REMOVE_CONTACT_FROM_LIST)}>{intl.formatMessage({ id: "Remover da lista" })}</Button.Danger>
                </>
                :
                <>
                    <CommandBar>
                        <CommandBar.Group>
                            <CommandBar.Item
                                color="default"
                                transparentBackground
                                active={showEmojiPicker}
                                badge={null}
                                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                description={null}>
                                <EmojiIcon width={25} height={25} />
                            </CommandBar.Item>
                        </CommandBar.Group>
                    </CommandBar>
                    <TextareaAutosize
                        ref={inputTextRef}
                        className="chat-messaging-input mr-50 ml-50"
                        placeholder={intl.formatMessage({ id: "Escreva uma mensagem" })}
                        id="send_message_input"
                        style={{ resize: "none", height: 30 }}
                        rows={1}
                        maxRows={5}
                        onChange={onInputChange}
                        onKeyDown={handleKeyPress}
                    />
                    {messageText[activeChat?.user?.id]?.trim()?.length > 0 && (
                        <CommandBar className="chat-messaging-buttons">
                            <CommandBar.Group>
                                <CommandBar.Item
                                    color="default"
                                    transparentBackground
                                    // active={actions.showEmojisKeyboard}
                                    badge={null}
                                    onClick={handleSendMessage}
                                    description={null}>
                                    <SentIcon width={25} height={25} />
                                </CommandBar.Item>
                            </CommandBar.Group>
                        </CommandBar>
                    )}
                </>
            }
        </div>
    </>
    );
}

export default Footer;