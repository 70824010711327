import React from "react";
import { ReactComponent as HomeIcon } from "../../../assets/img/svg/home.svg";
import { ReactComponent as BellIcon } from "../../../assets/img/svg/bell.svg";
import { ReactComponent as VersionsIcon } from "../../../assets/img/svg/versions.svg";
import logo from "../../../assets/img/logo/logo-omnyve.svg";
import symbol from "../../../assets/img/logo/symbol-omnyve.svg";

import NavbarRight from "../navbar/NavbarRight";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Spinner } from "reactstrap";
import { ChevronLeft, Search } from 'react-feather';
import "./header.scss"

import CommandBar from '../../../newComponents/commandBar/CommandBar';
import SearchBar from "../../../newComponents/searchbar/Searchbar";
import Avatar from "../../../newComponents/avatar/Avatar";

const NewHeader = () => {
    const history = useHistory();
    const { userData } = useSelector((state: any) => state.auth);

    const handleGoToHome = (e: any) => {
        e.preventDefault();
        console.log("Go to home")
        history.push("/home");
    }

    return (
        <div className="application-header-ui">
            <a 
                onClick={handleGoToHome}
                href="#" 
                className="application-header-left ml-50">
                <img src={logo} alt="" width="120" />
            </a>
            <div className="application-header-center">
                {/* <SearchBar /> */}
            </div>
            <div className="application-header-right">
                {/* <CommandBar className="mr-1">
                    <CommandBar.Group>
                        <CommandBar.Item
                            onClick={() => alert("Home")}
                            description={null}>
                            <Search width={15} height={15} />
                        </CommandBar.Item>
                        <CommandBar.Item
                            onClick={() => alert("Home")}
                            description={null}>
                            <VersionsIcon width={15} height={15} />
                        </CommandBar.Item>
                        <CommandBar.Item
                            onClick={() => alert("Home")}
                            description={null}>
                            <BellIcon width={15} height={15} />
                        </CommandBar.Item>
                    </CommandBar.Group>
                </CommandBar>
                <div className="cursor-pointer">
                    <Avatar
                        name={userData?.name}
                        color="primary"
                        size="md" />
                </div> */}
                <NavbarRight />
            </div>
        </div>
    )
}

export default NewHeader;