import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ReactComponent as Play } from '../assets/play.svg';
import { ReactComponent as Pause } from '../assets/pause.svg';
const TogglePlay = ({
  audioRef,
  progressBarRef,
  currentTrack,
  setTimeProgress
}) => {

  const [isPlaying, setIsPlaying] = useState(false);

  // useEffect(() => {

  //   function animated(){
  //     const value = this.currentTime;
  //     const currentTime = Math.floor(value);
  //     setTimeProgress(currentTime);
  //     progressBarRef.current.value = currentTime;
  //     progressBarRef.current.style.setProperty(
  //       '--range-progress',
  //       `${(currentTime / currentTrack.duration) * 100}%`
  //     );
  
  //   }

  //   if(isPlaying) {
  //     audioRef.current.addEventListener('timeupdate', animated);
  //   } else {
  //     audioRef.current.removeEventListener('timeupdate', animated);
  //   }
  // }, [isPlaying])

  
  const togglePlayPause = useCallback(() => {
    audioRef.current.addEventListener('play', function (ev) {
      console.log("audio started playing");
    });

    audioRef.current.addEventListener('pause', function (ev) {
      console.log("audio paused. Do you want to change track");
    });

    let paused = audioRef.current.paused;
    let playled = !paused;

    if (!playled) {
      const { currentTime, playbackRate } = audioRef.current;
      console.log(currentTime, playbackRate)
      audioRef.current.load();
      audioRef.current.currentTime = currentTime;
      audioRef.current.playbackRate = playbackRate;
      audioRef.current.play();
      setIsPlaying(true);

      audioRef.current.addEventListener('ended', function (ev) {
        audioRef.current.currentTime = 0
        progressBarRef.current.value = 0
        audioRef.current.pause();
        setIsPlaying(false);
      });

    } else {
      setIsPlaying(false);
      audioRef.current.pause();
    }

  }, [audioRef, progressBarRef, setTimeProgress, isPlaying])

  return (
    <div>
      <div className={`toggle-play ${isPlaying ? 'active' : ''}`} onClick={togglePlayPause}>
        {isPlaying ? <Pause width={9} /> : <Play width={10} />}
      </div>
    </div>
  )
}

export default TogglePlay;