import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchGroupedUsers } from "../redux/actions/users/users";


const withGroupedUserList = (WrappedComponent) => {
    class WithGroupedUserList extends Component {
        constructor(props) {
            super(props);
            this.state = {
                searchTerm: '',
                onlyOnlineUsers: false
            };
        }

        obtainUsersList = (params) => {
            this.props.fetchGroupedUsers(params);
        };

        updateSearchTerm = (term) => {
            this.setState({ searchTerm: term });
        };

        showOnlyOnlineUsers = (value) => {
            this.setState({ onlyOnlineUsers: value });
        }

        userData = () => {
            const { groups } = this.props.groupedUsers
            return groups.map( u => {
                    return {
                    groupId: u.groupId,
                    groupName: u.groupName,
                    users: u.users.filter(item => {
                        const itemData = `${item?.name?.toUpperCase()} ${item?.groupName?.toUpperCase()} ${item?.email?.toUpperCase()}`;
                        const textData = this.state.searchTerm.toUpperCase();
                        if(this.state.onlyOnlineUsers){
                            return itemData.indexOf(textData) > -1 && item.loggedUserStatus !== 3;
                        }
                        return itemData.indexOf(textData) > -1;
                    })
                    }
              })
        }

        render() {
            const { isLoading } = this.props.groupedUsers;
            return (
                <WrappedComponent
                    users={this.userData()}
                    obtainUsersList={this.obtainUsersList}
                    showOnlyOnlineUsers={this.showOnlyOnlineUsers}
                    isLoading={isLoading}
                    updateSearchTerm={this.updateSearchTerm}
                    {...this.props}
                />
            );
        }
    }

    const mapStateToProps = (state) => {
        console.log(state)
        // Mapeie o estado do Redux para as props do componente
        return {
          // Exemplo: mapeie a prop "users" para o estado "users" no Redux
          groupedUsers: state.users.groupedUsers,
        };
      };
    
      const mapDispatchToProps = (dispatch) => {
        // Mapeie as ações do Redux para as props do componente
        return {
          // Exemplo: mapeie a ação "fetchUsers" para a prop "fetchUsers"
          fetchGroupedUsers: (params) => dispatch(fetchGroupedUsers(params)),
        };
      };
    
      // Use a função "connect" para conectar o componente ao Redux
      return connect(mapStateToProps, mapDispatchToProps)(WithGroupedUserList);
    
};

export default withGroupedUserList;