import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/customers`;

const customersServices = {
	getCustomers: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(url, { params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getCustomersActiveMode: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/active-mode`, { params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getCustomersAgentAll: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/agent/all`, { params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getCustomersId: (id) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/${id}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getCustomerCustomerTelephone: (telephone) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/customertelephone/${telephone}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	postCustomer: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(url, params)
				.then((res) => {
					resolve(res.data);
					console.log(res.data);
				})
				.catch((error) => {
					reject(error);
					console.log(error);
				});
		});
	},

	putCustomer: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	patchCustomersStatus: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.patch(`${url}/status`, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getCustomersFilter: (params) => {
		const { ActiveRows, InactiveRows, Filter } = params;
		return new Promise((resolve, reject) => {
			return client()
				.get(
					`${url}?ActiveRows=${ActiveRows}&InactiveRows=${InactiveRows}${
						Filter ? `&Filter=${Filter}` : ''
					}`,
				)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	postCustomersImport: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/import`, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	postCustomersExport: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/export`, params, { responseType: 'blob' })
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		});
	},

	putCustomersExtraInfoReset: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/extra-info-reset`, params)
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		});
	},

	getCustomersImportedProtocols: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/imported-protocols`, { params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	postCustomersHandleAgentsProtocols: () => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/handle-agents-protocols`)
				.then((res) => resolve(res))
				.catch((error) => reject(error));
		});
	},
};

export default customersServices;
