import { useEffect, useRef, useState } from 'react';

const useResizableWindow = ({ windowSize, minWindowSize, resizeHandles, windowRef }) => {
    const initialMouseX = useRef(0);
    const initialMouseY = useRef(0);
    const [windowWidth, setWindowWidth] = useState(windowSize?.width || minWindowSize.width);
    const [windowHeight, setWindowHeight] = useState(windowSize?.height || minWindowSize.height);

    const initialWindowAdjustment = () => {
        const { width, height, left, top } = windowRef.current.getBoundingClientRect();
        const { innerWidth, innerHeight } = window;
        const distandeFromRight = innerWidth - left - width;
        const overflowX = distandeFromRight + width + 20 > innerWidth;
        const overflowY = top + height + 20 > innerHeight;
        console.log(left)
        setWindowWidth((prevWidth) => {
            if (overflowX) return innerWidth - distandeFromRight - 20;
            return prevWidth >= minWindowSize.width ? prevWidth : minWindowSize.width;
        });

        setWindowHeight((prevHeight) => {
            if (overflowY) return innerHeight - top - 20;
            return prevHeight >= minWindowSize.height ? prevHeight : minWindowSize.height;
        });
    };
    
    useEffect(() => {
        let handler;

        const handleMouseMove = (event) => {
            const currentMouseX = event.clientX;
            const currentMouseY = event.clientY;
            const deltaX = initialMouseX.current - currentMouseX;
            const deltaY = currentMouseY - initialMouseY.current;
            
            const { width, top } = windowRef.current.getBoundingClientRect();
            const { innerWidth, innerHeight } = window;
            const distandeFromRight = innerWidth - windowRef.current.offsetLeft - width;

            const animateResize = () => {
                if (handler === 'width') {
                    // Atualiza a largura da janela com base no movimento horizontal do mouse
                    setWindowWidth((prevWidth) => {
                        const newWidth = prevWidth + deltaX;
                        
                        const overflowX = distandeFromRight + newWidth + 20 > innerWidth;
                        if (newWidth < minWindowSize.width) return prevWidth;
                        if(overflowX) return innerWidth - distandeFromRight - 20;
                        return newWidth;
                    });
                } else if (handler === 'height') {
                    // Atualiza a altura da janela com base no movimento vertical do mouse
                    setWindowHeight((prevHeight) => {
                        const newHeight = prevHeight + deltaY;
                        const overflowY = top + newHeight + 20 > innerHeight;

                        // Verifica se a nova altura está dentro dos limites mínimos e máximos
                        if (newHeight < minWindowSize.height) return prevHeight;
                        if(overflowY) return innerHeight - top - 20;

                        return newHeight;
                    });
                }
            };

            const requestAnimation = () => {
                // Solicita uma animação de quadro para o navegador
                requestAnimationFrame(animateResize);
            };

            // Atualiza as coordenadas do mouse inicial para a posição atual
            initialMouseX.current = currentMouseX;
            initialMouseY.current = currentMouseY;

            // Chama a função de solicitação de animação para atualizar o redimensionamento da janela
            requestAnimation();
        };

        const handleMouseUp = () => {
            document.body.style.userSelect = '';
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            handler = '';
        };

        const handleMouseDown = (event) => {
            if (event.target?.classList.contains('left-handler')) {
                handler = 'width';
            } else if (event.target?.classList.contains('bottom-handler')) {
                handler = 'height';
            }

            initialMouseX.current = event.clientX;
            initialMouseY.current = event.clientY;
            document.body.style.userSelect = 'none';
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        };

        const leftHandler = resizeHandles.left.current;
        leftHandler.addEventListener('mousedown', handleMouseDown);

        const bottomHandler = resizeHandles.bottom.current;
        bottomHandler.addEventListener('mousedown', handleMouseDown);

        return () => {
            leftHandler.removeEventListener('mousedown', handleMouseDown);
            bottomHandler.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    const onPositionChangeHandler = (event, ui) => {
        setTimeout(initialWindowAdjustment, 100);
    };


    return { windowWidth, windowHeight, onPositionChangeHandler };
};

export default useResizableWindow;