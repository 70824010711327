import React, { useState, ReactNode, FC, ReactElement } from "react";
import { Heading } from "../typography";
import { Check, Chevron } from "../pureHtmlIcons";
import Loading from '../loading/Loading';
import "./list.scss";

interface ListProps {
    children: ReactNode;
    className?: string;
    isLoadingMore?: boolean;
    collapse?: boolean;
}

interface ListItemProps {
    avatar?: ReactNode;
    title: string;
    subtitle?: any;
    className?: string;
    color?: string;
    onClick?: () => void;
    active?: boolean;
    align?: "left" | "right";
    rightSideComponent?: ReactNode;
    truncateTitle?: number;
    truncateSubtitle?: number;
}

interface ListGroupProps {
    children: ReactNode;
    id?: number | string;
    className?: string;
}

interface ListTitleProps {
    children: ReactNode;
    title?: string;
    onClick?: (args: { index: number, id: any }) => void | null;
}

const List: FC<ListProps> & {
    Group: FC<ListGroupProps>;
    Title: FC<ListTitleProps>;
    Item: FC<ListItemProps>;
} = ({
    children,
    className,
    isLoadingMore = false,
    collapse = false,
}) => {
    const [collapsedGroups, setCollapsedGroups] = useState<number[]>([]);

    const toggleHideGroup = (idx: number) => {
        setCollapsedGroups(prevState => {
            const newState = [...prevState];
            if (newState.includes(idx)) {
                const index = newState.indexOf(idx);
                newState.splice(index, 1);
            } else {
                newState.push(idx);
            }
            return newState;
        });
    };

    const handleClickTitle = (index: number, id: any, onClick?: (args: { index: number, id: any }) => void | null) => {

        console.log(index, id)
        console.log(collapse)
        if (onClick) {
            onClick({ index, id });
        }

        if (collapse) {
            toggleHideGroup(index);
        }
    };

    const group = React.Children.map(children, (child, index) => {
        if (React.isValidElement(child) && child.type === List.Group) {
            const titleElements: ReactElement<ListTitleProps>[] = [];
            const itemElements: ReactElement<ListItemProps>[] = [];

            React.Children.forEach(child.props.children, (childElement) => {
                if (React.isValidElement(childElement)) {
                    if (childElement.type === List.Title) {
                        titleElements.push(React.cloneElement(childElement) as ReactElement<ListTitleProps>);
                    } else if (childElement.type === List.Item) {
                        itemElements.push(React.cloneElement(childElement) as ReactElement<ListItemProps>);
                    }
                }
            });

            const id = child.props.id || null;
            const title = titleElements[0];
            const onClick = title?.props.onClick;
            const collapsed = collapse && collapsedGroups.includes(index) || false;

            return (
                <div className="list-group">
                    {titleElements.length > 0 && (
                        <div
                            className={`list-title ${onClick || collapse ? 'clickable' : ''}`}
                            onClick={() => collapse ? handleClickTitle(index, id, onClick) : {}}>
                            <Heading type="h6" className="m-1" fw={600}>
                                {title}
                            </Heading>
                            {collapse && (
                                <div className="list-title-icon">
                                    <Chevron direction={collapsed ? "down" : "up"} />
                                </div>
                            )}
                        </div>
                    )}
                    <div className={`list-group-items ${!collapsed ? 'show' : ''}`}>
                        {itemElements}
                    </div>
                </div>
            );
        }

        return null;
    });

    return (
        <div className={`list-ui ${className || ''}`}>
            <div>{group}</div>
            {isLoadingMore && (
                <div className="list-ui-loading">
                    <Loading />
                </div>
            )}
        </div>
    );
};

List.Group = ({ children }) => children;

List.Title = ({ children }) => children;

List.Item = ({
    avatar,
    title,
    subtitle,
    className,
    color,
    onClick,
    active = false,
    align = "left",
    rightSideComponent,
    truncateTitle = 999,
    truncateSubtitle = 999,
}) => {
    return (
        <div
            onClick={onClick ? onClick : () => {}}
            className={`list-item-ui ${className || ''} ${color ? `ui-${color}` : ''} ${active ? 'active' : ''} align-${align}`}>
            <div className="list-item-content">
                <div className="list-item-content-contact">
                    {avatar}
                    <div className="list-item-contact-description">
                        <div className="texts">
                        <h6 style={{ WebkitLineClamp: truncateSubtitle}}>{title}</h6>
                        <small style={{ WebkitLineClamp: truncateSubtitle}}>{subtitle}</small>
                        </div>
                    </div>
                </div>
            </div>
            {rightSideComponent && <div className="list-item-right-side">{rightSideComponent}</div>}
        </div>
    );
};

List.displayName = "List";

export default List;
