
import React from "react"
import { Form } from "reactstrap"
import { Search, X } from "react-feather"
import { TextInput } from "../forms"
import Button from "../button/Button"
import "./searchbar.scss"

const status = [
    { value: null, label: "Selecione uma opção" },
    { value: false, label: "Desativar" },
    { value: true, label: "Ativar" }
]

class SearchBar extends React.Component {
    typingTimer = null;
    state = {
        value: '',
        searchArea: false
    }
    formSubmit = e => {
        e.preventDefault();
        const { value } = this.state;
        this.props.handleTermFilter(value)
        // send to server with e.g. `window.fetch`

    }

    handleChange = (e) => {
        const searchTerm = e.target.value
        this.setState({
            value: searchTerm
        }, () => {
            const val = searchTerm;
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                if (searchTerm.trim().length > 0 || searchTerm === '') {
                    this.props.handleTermFilter(searchTerm)
                }
            }, 500);
        })
        /**/

        //this.setState({value: e.target.value}, () => this.props.handleTermFilter(this.state.value))
    }
    handleClearInput = () => {
        this.setState({
            value: ''
        }, () => this.props.handleTermFilter(''))
    }
    enterPressed = (e) => {
        console.log(e.key)
    }

    componentDidMount = (prevProps) => {
        if(this.props?.initialValue !== this.state.value){
            this.setState({value: this.props.initialValue})
        }
    }

    render() {
        const { testid, id } = this.props
        return (
            <Form className={`search-bar-form d-flex ${this.props.className}`} onSubmit={this.formSubmit}>
                <TextInput
                    id="login"
                    className="flex-grow-1"
                    testid={testid ? testid : id ? id : 'searchbar'}
                    //className={`mb-1 ${username.trim().length === 0 && errors.find( f => f.field === 'login') ? 'is-invalid' : ''}`} 
                    autoComplete="off"
                    name="search"
                    placeholder={this.props.placeholder ? this.props.placeholder : ''}
                    value={this.state.value}
                    //errorMessage={username.trim().length === 0 && errors.find( f => f.field === 'login') ? errors.find( f => f.field === 'login').message : null}
                    //fieldDescription={null}]
                    onEnterPressed={(e) => {
                        if (this.state.value.trim().length > 0 && this.props.onEnterPressed) {
                            this.props.onEnterPressed(this.state.value)
                        }
                    }}
                    handleClearInput={this.handleClearInput}
                    placeholderStyle='hide'
                    fieldIcon={{
                        icon: <Search size={15} />,
                        position: 'left'
                    }}
                    clear
                    onChange={e => {
                        if (this.props.dynamic) {
                            this.handleChange(e)
                        } else {
                            this.setState({ value: e.target.value })
                            if (e.target.value.length === 0) {
                                this.props.handleTermFilter('')
                                // this.formSubmit(e)
                                // this.setState({value: ''}, () => this.handleChange(e))
                            }
                        }
                    }} />
                {this.props.searchButton && this.state.value.trim().length > 0 &&
                    <Button.Primary
                        testid={testid ? `${testid}-button` : id ? `${id}-button` : 'searchbar-button'}
                        onClick={this.formSubmit}
                        dark
                        className="ml-50">
                        Buscar
                    </Button.Primary>
                }
            </Form>
        )
    }

}

export default SearchBar