import {
    SAGA_ERROR,
} from '../../../helpers/constants';

import { IReleaseVersion } from '../../../interfaces/releaseNotes/releaseVersions.interface';
import { IReleaseVersionPage }  from '../../../interfaces/releaseNotes/releaseVersionPages.interface';
import {
    TOGGLE_RELEASE_NOTES_MODALS,
    STORE_RELEASE_NOTES_VERSIONS,
    SET_CURRENT_RELEASE_VERSION,
    STORE_RELEASE_NOTE_PAGES,
    RELEASE_NOTES_PAGE_NAVIGATION
} from '../../../helpers/releaseNotesConstants';

interface IReleaseState {
    topic: number;
    modals: {
        welcome: boolean;
        presentation: boolean;
    };
    currentRelease: IReleaseVersion | null; // Replace `any` with the actual type of `currentRelease`
    showVersionList: boolean;
    releaseVersions: IReleaseVersion[]; // Replace `any` with the actual type of `releaseVersions`
    releaseVersionsPages: {
        loading: boolean;
        pages: IReleaseVersionPage[]; // Replace `any` with the actual type of `pages`
    };
    error?: any; // Replace `any` with the actual error type
}

const initialState: IReleaseState = {
    topic: 0,
    modals: {
        welcome: false,
        presentation: false
    },
    currentRelease: null,
    showVersionList: false,
    releaseVersions: [],
    releaseVersionsPages: {
        loading: false,
        pages: []
    }
};

export default function releaseReducer(state: IReleaseState = initialState, action: any): IReleaseState {
    switch (action.type) {
        case TOGGLE_RELEASE_NOTES_MODALS: {
            return { ...state, modals: action.data };
        }
        case STORE_RELEASE_NOTES_VERSIONS: {
            return { ...state, releaseVersions: action.payload };
        }
        case SET_CURRENT_RELEASE_VERSION: {
            return { ...state, currentRelease: action.payload };
        }
        case STORE_RELEASE_NOTE_PAGES: {
            return { ...state, releaseVersionsPages: action.payload };
        }
        case RELEASE_NOTES_PAGE_NAVIGATION: {
            return { ...state, topic: action.data };
        }
        case SAGA_ERROR: {
            return { ...state, error: action.response };
        }
        default:
            return state;
    }
}