import React, { useState, useEffect } from "react";
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import withGroupedUserList from '../../../../../hoc/withUserList';

import Avatar from '../../../../avatar/Avatar';
import ContactList from '../../../../contactList/ContactList';
import Divider from '../../../../divider/Divider';
import List from '../../../../list/List';
import SearchBar from '../../../../searchbar/Searchbar';
import UserStatus from '../../../../userStatus/UserStatus';

import { Check, XCircle } from '../../../../pureHtmlIcons';
import { Toggle } from '../../../../forms';
import BeautyScrollbar from "../../../../beautyScrollbar/BeautyScrollbar";


const UserList = ({
    users,
    obtainUsersList,
    isLoading,
    updateSearchTerm,
    showOnlyOnlineUsers,
    show,
    toggleSelectUser,
    selectedUsers = [],
    handleRemoveUser
}) => {

    const intl = useIntl();
    const { userData } = useSelector(state => state.auth);

    const [searchTerm, setSearchTerm] = useState('')
    const [showOnlineUsers, setShowOnlineUsers] = useState(false)
    const [showSelectedList, setShowSelectedList] = useState(false);

    const handleSearechTerm = term => {
        setSearchTerm(term)
        updateSearchTerm(term)
    }

    const toggleOnlineUsers = (e) => {
        const { name, value } = e.target;
        setShowOnlineUsers(value)
        showOnlyOnlineUsers(value)
    }

    const toggleShowSelectedList = () => {
        setShowSelectedList(!showSelectedList)
    }

    useEffect(() => {
        if (show) {
            obtainUsersList({ excludeIds: [userData.id], corporate: true });
            setShowOnlineUsers(false);
            showOnlyOnlineUsers(false)
        }
    }, [show])

    const userListArray = React.useMemo(() => {
        return <List collapse>{users.map((group) => {
          if (group.users.length === 0) return null;
          return (
            <List.Group id={group.groupId} key={group.groupId} className="mb-50">
              <List.Title>
                {group.groupName}
              </List.Title>
              {group.users.map((user, index) => {
                const active =
                  typeof selectedUsers.find(
                    (c) => c.id === user.id && c.groupId === user.groupId
                  ) !== "undefined";
                return (
                  <List.Item
                    active={false}
                    key={`user_${group.groupId}_${user.id}`}
                    onClick={() => toggleSelectUser(user)}
                    avatar={
                      <Avatar
                        name={user.name}
                        size="lg"
                        color="primary"
                        status={<UserStatus.Marker status={user} />}
                      />
                    }
                    title={user.name}
                    subtitle={user.email}
                    rightSideComponent={active && <Check />}
                  />
                );
              })}
            </List.Group>
          );
        })}</List>;
      }, [users, selectedUsers, toggleSelectUser]);

    return (
        <ContactList>
            <ContactList.Header>
                <Divider />
                <div className="p-50 d-flex flex-column">
                    <SearchBar
                        initialValue={searchTerm}
                        placeholder="Buscar"
                        handleTermFilter={handleSearechTerm}
                        onEnterPressed={() => { }}
                        searchButton={false}
                        dynamic={true} />
                    <Toggle
                        name="showOnlineUsers"
                        className="mt-50"
                        label="apenas usuários online"
                        value={showOnlineUsers}
                        onChange={toggleOnlineUsers}
                        color="success" />
                </div>
                <Divider />
            </ContactList.Header>
            <ContactList.Content loading={isLoading}>
                <BeautyScrollbar>
                    {userListArray}
                </BeautyScrollbar>
            </ContactList.Content>
            {selectedUsers.length > 0 && (
                <ContactList.Footer
                    collapse
                    onClick={toggleShowSelectedList}
                    title={
                        selectedUsers.length === 1 ?
                            intl.formatMessage({ id: "1 usuário selecionado" }) :
                            intl.formatMessage({ id: "{count} usuários selecionados" }, { count: selectedUsers.length })
                    }>
                    <List>
                        <List.Group className="mb-50">
                            {selectedUsers.map((user, index) => {
                                return (
                                    <List.Item
                                        active={false}
                                        key={`selected_user_${user.id}_${index}`}

                                        avatar={
                                            <Avatar
                                                name={user.name}
                                                size="lg"
                                                color="primary"
                                                status={<UserStatus.Marker status={user} />} />
                                        }
                                        title={user.name}
                                        subtitle={user.email}
                                        rightSideComponent={
                                            <XCircle onClick={() => handleRemoveUser(user)} />
                                        }
                                    />
                                )
                            }
                            )}
                        </List.Group>
                    </List>
                </ContactList.Footer>)}
        </ContactList>
    )
}

export default withGroupedUserList(UserList);