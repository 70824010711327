import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  KeyboardEvent,
} from "react";

import TextInput from "../input/TextInput";
import { Chevron } from "../../pureHtmlIcons";
import ScrollBars from "../../scrollBar/ScrollBar";
import Clearer from "./Clearer";
import RenderOption from "./RenderOption";
import { Spinner } from "reactstrap";
import "./select-box.scss";
import { injectIntl } from "react-intl";

// types
import { OptionType, TextInputMethods, SelectboxProps } from "../interfaces";

const Selectbox: React.FC<SelectboxProps> = ({
  id,
  placeholder = "Selecione",
  className,
  name,
  value,
  multiSelect = false,
  errorMessage,
  fieldDescription,
  fieldIcon,
  options = [],
  onChange = () => {},
  onSearch,
  onPagination,
  loading,
  onCleanInput,
  disabled = false,
  size,
  intl,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<TextInputMethods | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [positions, setPositions] = useState({
    width: null as number | null,
    height: null as number | null,
    x: null as number | null,
    y: null as number | null,
    top: 0,
  });

  const handleShowDropdown = useCallback(() => {
    const windowHeight = window.innerHeight - 60;

    const getPositions = selectRef.current?.getBoundingClientRect();
    const dropdownMetrics = dropdownRef.current?.getBoundingClientRect();

    if (!getPositions || !dropdownMetrics) return;

    const { top, height } = dropdownMetrics;
    const orientation =
      getPositions.top + height > windowHeight + 10 ? "top" : "bottom";

    setPositions(() => ({
      width: getPositions.width,
      height: getPositions.height,
      x: getPositions.x,
      y: getPositions.y,
      top:
        orientation === "bottom"
          ? getPositions.top - top + getPositions.height + 5
          : getPositions.top -
            top +
            getPositions.height -
            height -
            getPositions.height -
            10,
    }));

    setShowDropdown((prev) => !prev);
  }, []);

  const clickOutsideDropdown = useCallback(
    (e: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target as Node)
      ) {
        // Clicked outside of the component
        if (showDropdown) {
          handleShowDropdown();
        }
        // setSearch("");
      }
    },
    [showDropdown, handleShowDropdown]
  );

  useEffect(() => {
    document.addEventListener("click", clickOutsideDropdown);
    return () => {
      document.removeEventListener("click", clickOutsideDropdown);
    };
  }, [clickOutsideDropdown]);

  const handleClearInput = useCallback(
    (optionIsArray: boolean) => {
      if (optionIsArray) {
        onChange({ name, options: [] });
      } else {
        onChange({ name, option: "" });
      }
      setSearch("");
      searchRef.current?.setFocus();
    },
    [name, onChange]
  );

  const handleClearSearch = useCallback(() => {
    setSearch("");
    handleShowDropdown();
    searchRef.current?.setFocus();
  }, [handleShowDropdown]);

  const handleHideInactiveFieldDropDown = useCallback(() => {
    const activeElement = document.activeElement;

    if (!activeElement) return;

    handleShowDropdown();
  }, [handleShowDropdown]);

  const handleOnKeyPress = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      // Check if it's a multi-select dropdown and exit early if it is.
      if (multiSelect) return;

      const currentIndex = options.findIndex(
        (item) => item.value === value?.value
      );
      let newIndex = -1;

      switch (e.keyCode) {
        case 40: // Down arrow key
          newIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1;
          break;
        case 38: // Up arrow key
          newIndex = currentIndex === 0 ? options.length - 1 : currentIndex - 1;
          break;
        case 13: // Enter key
          setShowDropdown(false);
          return;
        case 9: // Tab key
          handleHideInactiveFieldDropDown();
          return;
        default:
          console.log("Unrecognized key code:", e.keyCode);
          return;
      }

      if (newIndex !== -1) {
        onChange({ name, option: options[newIndex] });
      }
    },
    [
      handleHideInactiveFieldDropDown,
      multiSelect,
      name,
      onChange,
      options,
      value,
    ]
  );

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (onSearch) {
        onSearch(search || "");
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [onSearch, search]);

  useEffect(() => {
    if (!showDropdown) {
      setPositions({
        width: null,
        height: null,
        x: null,
        y: null,
        top: 0,
      });
    }
  }, [showDropdown]);

  // useEffect(() => {
  //   if (!!search && !showDropdown) {
  //     handleShowDropdown();
  //   }
  // }, [search, handleShowDropdown, showDropdown]);

  const isArray = Array.isArray(value);

  const dropdownOptions = useMemo(() => {
    if (search && search.trim().length > 0) {
      return options
        .flatMap((optionOrGroup) => {
          if (optionOrGroup.options) {
            return {
              ...optionOrGroup,
              options: optionOrGroup.options.filter(
                (option: OptionType) =>
                  (option?.searchString &&
                    option.searchString
                      .toLowerCase()
                      .includes(search.toLowerCase())) ||
                  option.label.toLowerCase().includes(search.toLowerCase())
              ),
            };
          }

          if (optionOrGroup.searchString) {
            if (
              optionOrGroup.searchString
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return optionOrGroup;
            }
          } else if (
            optionOrGroup?.label?.toLowerCase().includes(search.toLowerCase())
          ) {
            return optionOrGroup;
          }
          return [];
        })
        .filter(
          (optionOrGroup) =>
            Array.isArray(optionOrGroup) ||
            (optionOrGroup.options ? optionOrGroup.options.length > 0 : true)
        );
    }

    return options;
  }, [options, search]);

  return (
    <div
      ref={wrapperRef}
      className={`ui-select-box ${className ? className : ""}`}
      data-testid="selectbox"
    >
      <div
        ref={selectRef}
        className="ui-select-box__container"
        onFocus={handleShowDropdown}
        data-testid="selectbox-container"
      >
        <TextInput
          id={`select-input-field-${id}`}
          className="ui-select-box__input"
          ref={searchRef}
          testid="selectbox-input"
          value={search}
          placeholderTop={(isArray ? !!value.length : !!value) || !!search}
          placeholder={placeholder}
          errorMessage={errorMessage}
          fieldDescription={fieldDescription}
          fieldIcon={fieldIcon}
          disabled={disabled}
          autoComplete="off"
          clear
          handleClearInput={handleClearSearch}
          minLength={undefined}
          maxLength={undefined}
          onChange={(e) => onSearch && setSearch(e.target.value)}
          onKeyDown={handleOnKeyPress}
          size={size}
        />

        {((isArray && value.length > 0) || (!isArray && value)) && !search && (
          <div
            className={`ui-select-box__selected-item ${
              disabled ? "disabled" : ""
            }`}
            id={`selected-item-${id}`}
            onClick={() => searchRef.current?.setFocus()}
          >
            <div className={`selected-item-info ${size}`}>
              <div
                className={`selected-item-name truncate ${
                  disabled ? "disabled" : ""
                }`}
                data-testid="selected-items"
              >
                {isArray ? value.map((m) => m.label).join(", ") : value.label}
              </div>
            </div>

            {Clearer(handleClearInput, isArray, size, disabled)}
          </div>
        )}

        <div
          id={`input-field-chevron-${id}`}
          className={`ui-select-box__chevron ${
            disabled ? "disabled" : ""
          } ${size}`}
          data-testid="selectbox-chevron"
          onClick={() => searchRef.current?.setFocus()}
        >
          <Chevron direction="down" />
        </div>
      </div>

      <div
        id={`dropdown-${id}`}
        ref={dropdownRef}
        data-testid="selectbox-dropdown"
        className={`ui-select-box-dropdown ${showDropdown ? "show" : ""}`}
        style={{
          width: `${selectRef.current?.offsetWidth}px`,
          top: positions.top,
        }}
      >
        <ScrollBars
          maxHeight="240px"
          onReachVerticalEnd={() => !loading && onPagination && onPagination()}
          loading={loading}
        >
          <div className="ui-select-box-dropdown__container">
            {dropdownOptions.length > 0 ? (
              dropdownOptions.map((option, index) => {
                return (
                  <div key={index} className="ui-select-box-dropdown__item">
                    {Object.keys(option).includes("title") ? (
                      <div className="ui-select-box-dropdown__title">
                        {option.title}
                      </div>
                    ) : (
                      <RenderOption
                        option={option}
                        optionList={options}
                        index={index}
                        multiSelect={multiSelect}
                        onChange={onChange}
                        id={id}
                        value={value}
                        name={name}
                        handleShowDropdown={handleShowDropdown}
                        setSearch={setSearch}
                      />
                    )}
                  </div>
                );
              })
            ) : !loading ? (
              <div className="ui-select-box-empty">
                <p>
                  {intl.formatMessage({
                    id: "Não há opções a serem selecionadas",
                  })}
                  .
                </p>
              </div>
            ) : null}

            {loading && (
              <div className="loading-content">
                <Spinner />
              </div>
            )}
          </div>
        </ScrollBars>
      </div>
    </div>
  );
};

export default injectIntl(Selectbox);
