import mock from "../mock"
let data = {
    channels: [
        {
            id: 1,
            name: "Facebook CT",
            welcomeMessage: "Seja bem vindo(a). Como podemos ajudá-lo(a)?",
            type: "facebook",
            title: "Oficial CT Voicer",
            date: new Date(),
            chatbot: true,
            status: true,
            belongs: true
        },
        {
            id: 2,
            name: "WhatsApp CT Comercial",
            welcomeMessage: null,
            type: "whatsapp",
            title: "4121039300",
            date: new Date(),
            chatbot: true,
            status: true,
            belongs: false
        },
        {
            id: 3,
            name: "WhatsApp CT Suporte",
            welcomeMessage: null,
            type: "whatsapp",
            title: "4121039301",
            date: new Date(),
            chatbot: true,
            status: true,
            belongs: false
        }
      ],
}

// GET : Calendar Events
mock.onGet("/api/apps/group-rules/channels").reply(() => {
  return [200, data.channels]
})
// POST : Add new user
mock.onPut("/api/apps/group-rules/channels").reply(request => {

  let { id } = JSON.parse(request.data)

  const change = data.channels.find(
    c => c.id === id
  ).belongs = !data.channels.find(c => c.id === id).belongs
  console.log(change)
  
  return [201, data.channels]
})

mock.onPost("/api/apps/group-rules/channels").reply(request => {
  const channelId = JSON.parse(request.data).channelId
  data.channels.find(c => c.id === channelId).belongs !== true
    ? (data.channels.find(c => c.id === channelId).belongs = true)
    : (data.channels.find(c => c.id === channelId).belongs = false)
  return [200]
})

