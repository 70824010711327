import { client } from '../../utils/axios-utils'

const api =  {
    fetchNotifications: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/notification/available-websocket-notifications-from-user/`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    readNotification: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/notification/websocket-notification-as-read/`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    readNotifications: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/notification/websocket-notifications-as-read/`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    deleteNotification: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/notification/websocket-notification-as-deleted/`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    deleteNotifications: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/notification/websocket-notifications-as-deleted/`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
    
}

export default  api;    