import { INTERNAL_CHAT_CONTACTS,  } from '../../../../helpers/internalChatConstants';
import { messagesService } from "../../../../newServices/corporateChat";

export const isDeletingMessage = (contacts, message) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            return {
                ...contact,
                messages: contact.messages.map(msg => {
                    if (msg.id === message.id) {
                        return {
                            ...msg,
                            isDeleting: !msg.isDeleting
                        }
                    }
                    return msg;
                })
            }})
    }
}

export const requestDeleteInternalChatMessage = (messageId) => {
    return messagesService.deleteMessages(messageId)
            .then(res => res)
            .catch(err => err)
}

export const removeMessageFromContact = (contacts, message) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            return {
                ...contact,
                messages: contact.messages.filter(msg => msg.id !== message.id)
            }})
    }
}