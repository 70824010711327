import React, { useEffect, useState, useCallback } from "react";
import "../../../assets/scss/core/menu/_navbar-right.scss";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../../assets/img/svg/home.svg";
import { ReactComponent as BellIcon } from "../../../assets/img/svg/bell.svg";
import versionIcon from "../../../assets/img/svg/versions.svg";
// import { ReactComponent as VersionsIcon } from "../../../assets/img/svg/versions.svg";
import { Badge } from "reactstrap";
import MainSocket from "../../../MainSocket";
import api from "../../../services/notifications/notifications";
import moment from "moment-timezone";
import { ReactComponent as EmptyNotification } from "../../../assets/img/svg/empty-notification.svg";
import MonitoringNotification from "./components/MonitoringNotification";
import ReportNotification from "./components/ReportNotification";
import TransferNotification from "./components/TransferNotification";
import SurveyNotification from "./components/SurveyNotification";
import VersionNotification from "./components/VersionNotification";
import groupBy from "../../../utils/groupBy";
import classListToArray from "../../../utils/classListToArray";
import sound from "../../../assets/sounds/notification-notification.mp3";
import usePrevious from "../../../customHooks/usePrevious";
import { useSelector, useDispatch } from "react-redux";
import PendingInteractionNotification from "./components/PendingInteractionNotification";
import useChatDispatchActions from '../../../customHooks/useChatDispatchActions';
import { internalChatActions } from "../../../redux/actions/internalChat/internalChat";
import CommandBar from '../../../newComponents/commandBar/CommandBar';
import { ChevronLeft, Search } from 'react-feather';
import Avatar from "../../../newComponents/avatar/Avatar";
import NavbarUser from "../navbar/NavbarUser"

import { 
  SHOW_RELEASE_NOTE
} from  '../../../helpers/releaseNotesConstants';

let notification;
let oldTitle = "Omnyve";
let interval = null;
let isOldTitle = true;

function changeTitle(title) {
  document.title = isOldTitle ? oldTitle : title;
  isOldTitle = !isOldTitle;
}

const messageAlert = new Audio(sound);
const defualtBrowserNotification = {
  count: 0,
  message: "",
  redirectPath: null,
  state: null,
  id: null,
};

function NavbarRight() {
  const history = useHistory();
  const location = useLocation();
  const { socket } = useSelector((state) => state.socket);
  const { releaseNotes } = useSelector((state) => state);

  const { userData } = useSelector((state) => state.auth);
  const dispatch = useDispatch()

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems, updateMenuItems] = useState([]);

  const showReleaseNote = () => {
    console.log(releaseNotes.releaseVersions)
    const { releaseVersions } = releaseNotes
    console.log(releaseVersions)
    const lastVersion = releaseVersions.length > 0 ? releaseVersions[0] : null
    console.log(lastVersion)
    // dispatchActions(SHOW_RELEASE_NOTE, lastVersion)
    dispatch(internalChatActions(SHOW_RELEASE_NOTE, lastVersion))
  }

  const [notifications, setNotifications] = useState([]);
  const [browserNotification, changeBrowserNotification] = useState(
    defualtBrowserNotification
  );
  const prevBrowserNotification = usePrevious(browserNotification);

  useEffect(() => {
    // updateMenuItems([
    //   {
    //     icon: <HomeIcon width={18} height={18} />,
    //     id: "home",
    //     name: "Home",
    //     onClick: () => history.push("/home"),
    //     badge: null,
    //   },
    //   {
    //     icon: <BellIcon width={18} height={18} />,
    //     id: "notifications",
    //     name: "Notificações",
    //     onClick: () => toggleOpenMenu(),
    //     badge: null,
    //   },
    //   {
    //     icon: <VersionsIcon width={18} height={18} />,
    //     id: "Versions",
    //     name: "Versões",
    //     onClick: () => showReleaseNote(),
    //     badge: null,
    //   },
    // ])
  }, [releaseNotes])

  useEffect(() => {
    if (socket.socketEvent) {
      const event = socket.socketEvent.event;
      const message = socket.socketEvent.message;
      const visibility = socket.socketEvent.visibility;
      if (event === "NotifyFromNotificationHub") {
        const { NotificationId, NotificationServiceType, Text, TotalItems } =
          message;
        const newNotification = {
          createdAt: moment().format(),
          id: NotificationId,
          notificationServiceType: NotificationServiceType,
          readAt: null,
          text: Text,
          totalItems: TotalItems,
        };
        console.log(newNotification);
        setNotifications((notify) => {
          return {
            ...notify,
            /** Caso não haja nenhuma notificação, cria um objeto novo, senão, adiciona ao objeto existente */
            [NotificationServiceType]:
              notify[NotificationServiceType] &&
              notify[NotificationServiceType].length > 0
                ? [newNotification, ...notify[NotificationServiceType]]
                : [newNotification],
          };
        });
        if (!visibility) {
          /** Apenas uma prevenção para não entregar a mensagem mais de uma vez */
          if (
            prevBrowserNotification &&
            prevBrowserNotification.id === NotificationId
          )
            return;
          changeBrowserNotification((prev) => {
            const newCount = parseInt(prev.count) + 1;
            return {
              ...prev,
              id: NotificationId,
              count: newCount,
              message:
                newCount === 1
                  ? `1 nova notificação`
                  : `${newCount} novas notificações`,
              state: {
                redirect:
                  NotificationServiceType === 1
                    ? "/protocol-monitoring"
                    : "/reports",
                id: NotificationId,
              },
            };
          });
        }
      }
    }
  }, [socket.socketEvent]);

  /** Mapeia os eventos de cliques para fechar a lista de notificações ao clicar fora da área de notificações.
   *  Nesse caso, caso a lista esteja aberta, a fecha.
   */
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      const clickedElement = classListToArray(
        e.target.closest("div").classList
      );
      const classNames = [
        "notification-icon",
        "notification-item",
        "notification-description",
        "notification-items",
        "navbar-right-ui",
        "notification-delete",
        "notification-group-header",
        "notification-clear-all",
      ];
      const clkInside =
        typeof clickedElement.find((f) => classNames.includes(f)) !==
        "undefined";
      if (!clkInside) {
        setIsMenuOpen(false);
      }
    };
    if (isMenuOpen) handleMarkAllAsRead();
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  /** Ao carregar a página, busca as notificações do usuário */
  useEffect(() => {
    api
      .fetchNotifications()
      .then((res) => {
        const notificationsGroup = groupBy(
          res.data.notificationItems,
          "notificationServiceType"
        );
        setNotifications(notificationsGroup);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /** Atualiza o state quando alguma propriedade no objeto de notificações muda,
   * como, por exemplo, apagar uma notificação ou marcar todas como lidas.
   */
  useEffect(() => {
    const countNotifications = Object.keys(notifications)
      .map((key, index) => {
        return notifications[key].filter((f) => !f.readAt).length;
      })
      .filter((f) => f > 0).length;

    cleanNotifications(location, notifications);

    if (!isMenuOpen)
      updateMenuItems((menus) =>
        menus.map((item) => {
          if (item.id === "notifications") {
            item.badge = countNotifications;
          }
          return item;
        })
      );
    else handleMarkAllAsRead();
  }, [notifications, location]);

  useEffect(() => {
    cleanNotifications(location, notifications);
  }, []);

  useEffect(() => {
    if (browserNotification && browserNotification.count > 0) {
      const options = {
        body: browserNotification.message,
        icon: "/images/symbol-omnyve-500x500.jpeg",
        dir: "ltr",
        tag:
          browserNotification &&
          browserNotification.state &&
          browserNotification.state.id
            ? browserNotification.state.id
            : null,
        data: browserNotification.state,
        silent: false,
        sound: messageAlert,
        requireInteraction: true,
      };
      notification = new Notification("Omnyve", options);
      messageAlert.play();
      document.title = browserNotification.message;

      clearInterval(interval);
      interval = setInterval(
        () => changeTitle(browserNotification.message),
        500
      );
      notification.onshow = (e) => {
        console.log(e);
      };
      const data = notification.data;
      notification.onclick = (e) => {
        e.preventDefault();
        window.focus();
        notification.close();
        document.title = oldTitle;
        changeBrowserNotification(defualtBrowserNotification);
        if (data && data.redirect) {
          history.push(`/${data.redirect}`);
        }
        changeBrowserNotification(defualtBrowserNotification);
        clearInterval(interval);
      };
    }
  }, [browserNotification]);

  /** Ao carregar a página ou mudar de rota, verifica se a pagina atual possui notificações
   *  e as remove.
   */
  function cleanNotifications(location, notifications) {
    if (location.pathname === "/reports") {
      //Não entrega a notificação de relatórios e envia um delete para o back
      const reportNotifications =
        notifications && notifications[2] ? notifications[2] : null;
      if (reportNotifications && reportNotifications.length > 0) {
        notificationRedirection(2, reportNotifications, null);
      }
    }

    if (location.pathname === "/protocol-monitoring") {
      //Não entrega a notificação de problemas no atendimento e envia um delete para o back
      const protocolNotifications =
        notifications && notifications[1] ? notifications[1] : null;
      if (protocolNotifications && protocolNotifications.length > 0) {
        notificationRedirection(1, protocolNotifications, null);
      }
    }
  }

  /** Alternância de abertura da lista de notificações */
  function toggleOpenMenu() {
    setIsMenuOpen((active) => {
      return !active;
    });
    /** Ao abrir a lista de notificações, marca todas as notificações como lidas */
    handleMarkAllAsRead();
  }

  /** Método para marcar todas as notificações como lidas ao abrir o menu */
  function handleMarkAllAsRead() {
    const updateIds = Object.keys(notifications)
      .map((key, index) => {
        return notifications[key]
          .filter((f) => !f.readAt)
          .map((m) => {
            return { notificationId: m.id };
          });
      })
      .flat();

    if (updateIds.length === 0)
      updateMenuItems((menus) =>
        menus.map((item) => {
          if (item.id === "notifications" && item.badge > 0) {
            item.badge = 0;
          }
          return item;
        })
      );
    else
      api
        .readNotifications(updateIds)
        .then((res) => {
          handleClearCount();
        })
        .catch((err) => {
          console.log(err);
        });
  }

  /** Função para limpar o contador de mensagens (badge) */
  function handleClearCount() {
    setNotifications((notify) => {
      let changed = {};
      Object.keys(notify).forEach((key, index) => {
        changed[key] = notify[key].map((item) => {
          item.readAt = new Date();
          return item;
        });
      });
      return changed;
    });
  }

  /** Ao clicar em uma notificação, removê-la da lista e encaminhar o usuário para a respectiva página */
  function notificationRedirection(type, array, redirect = null) {
    const params = array.map((m) => {
      return { notificationId: m.id };
    });
    api
      .deleteNotifications(params)
      .then((res) => {
        delete notifications[`${type}`];
        setNotifications({ ...notifications });
        if (redirect) history.push(`/${redirect}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleDeleteNotification(type, id) {
    api
      .deleteNotifications([{ notificationId: id }])
      .then((res) => {
        let notif = notifications;
        notif[`${type}`] = notif[`${type}`].filter((f) => f.id !== id);
        if (notif[`${type}`].length === 0) {
          delete notifications[`${type}`];
        }
        setNotifications({ ...notif });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /** O método abaixo, mapeia o click em uma notificação e,
   *  caso seja clicado no botão de apagar [ x ], exclui a notificação,
   *  senão, redireciona o usuário para a respectiva página.
   */
  function handleClickNotification(event, type, array) {
    const action = classListToArray(event.target.closest("div").classList)[0];
    if (action === "notification-delete") {
      notificationRedirection(type, array, null); //Apaga mas não redireciona
    } else if (
      [
        "notification-item",
        "notification-icon",
        "notification-description",
      ].includes(action)
    ) {
      const redirect =
        type === 1 ? "protocol-monitoring" : type === 2 ? "reports" : "surveys";
      notificationRedirection(type, array, redirect);
    }
  }

  function visibilityChange(visibility) {
    if (visibility) {
      document.title = oldTitle;
      changeBrowserNotification(defualtBrowserNotification);
      clearInterval(interval);
    }
  }

  const countNotifications = Object.keys(notifications)
  .map((key, index) => {
    return notifications[key].filter((f) => !f.readAt).length;
  })
  .filter((f) => f > 0).length;


  return (
    <MainSocket visibilityChange={visibilityChange}>
      <div className="navbar-right-ui">
      <CommandBar className="mr-1">
            <CommandBar.Group>
                {/* <CommandBar.Item
                    onClick={() => alert("Home")}
                    description={null}>
                    <Search width={15} height={15} />
                </CommandBar.Item> */}
                <CommandBar.Item
                    onClick={() => showReleaseNote()}
                    description={null}>
                    <img src={versionIcon} width={15} height={15} />
                </CommandBar.Item>
                <CommandBar.Item
                    badge={countNotifications > 0 ? countNotifications : null}
                    onClick={() => toggleOpenMenu()}
                    description={null}>
                    <BellIcon width={15} height={15} />
                </CommandBar.Item>
            </CommandBar.Group>
        </CommandBar>
        <NavbarUser />
        {/* <ul className="nav-menu">
          {menuItems.map((item, i) => {
            return (
              <li key={`menu-item-${i}`} onClick={item.onClick}>
                {item.icon}
                {item.badge > 0 && (
                  <Badge color="danger" className="badge-sm badge-up" pill>
                    {item.badge}
                  </Badge>
                )}
              </li>
            );
          })}
        </ul> */}

        <div className={`notifications ${isMenuOpen ? "show" : ""}`}>
          {Object.keys(notifications).length > 0 ? (
            <div className="notification-items">
              {notifications && notifications["1"] ? (
                <MonitoringNotification
                  data={notifications["1"]}
                  type={1}
                  handleClickNotification={handleClickNotification}
                />
              ) : null}
              {notifications && notifications["2"] ? (
                <ReportNotification
                  data={notifications["2"]}
                  type={2}
                  handleClickNotification={handleClickNotification}
                />
              ) : null}
              {notifications && notifications["3"] ? (
                <TransferNotification
                  data={notifications["3"]}
                  type={3}
                  handleClearNotifications={notificationRedirection}
                  handleDeleteNotification={handleDeleteNotification}
                />
              ) : null}
              {notifications && notifications["4"] ? (
                <SurveyNotification
                  data={notifications["4"]}
                  type={4}
                  handleClickNotification={handleClickNotification}
                />
              ) : null}

              {notifications && notifications["5"] ? (
                <PendingInteractionNotification
                  data={notifications["5"]}
                  type={5}
                  handleClickNotification={handleClickNotification}
                />
              ) : null}
              {notifications && notifications["6"] && (
                <VersionNotification
                  data={notifications["6"]}
                  type={6}
                  handleClickNotification={handleClickNotification}
                />
              )}
            </div>
          ) : (
            <div className="notification-items">
              <div className="notification-item">
                <div className="notification-item-empty">
                  <div className="d-flex justify-content-center">
                    <EmptyNotification width={180} height={150} />
                  </div>
                  <h3>Não há notificações</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </MainSocket>
  );
}

export default NavbarRight;
