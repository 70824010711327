const initialState = {
  campaigns: [],
  broadcasts: []
}

const broadcasts = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_BROADCASTS_CAMPAIGNS": {
      return { ...state, campaigns: [...state.campaigns, ...action.data] }
    }
    case "SELECT_BROADCASTS_CAMPAIGN":
      return { ...state, campaigns: state.campaigns.map( m => {
        let campaign = m
        if(m.id === action.id){
          campaign.selected = campaign.selected ? false : true
        } else {
          campaign.selected = false
        }
        return campaign
      }) }
    case "UPDATE_BROADCASTS_CAMPAIGN":
      const index = state.campaigns.findIndex( f => f.id === action.data.id)
      let campaigns = [...state.campaigns]
      let campaign = { ...campaigns[index] };
      campaign.selected = true
      campaigns[index] = action.data;

      return { ...state, campaigns: campaigns }
    case "ADD_BROADCAST_CAMPAIGN":
      console.log(action)
      const newCampaignArray = [action.data, ...state.campaigns.map( m => {
        let campaign = m
        campaign.selected = false
        return campaign
      })]
      console.log(newCampaignArray)

      return { ...state, campaigns: newCampaignArray}
    case "DELETE_BROADCASTS_CAMPAIGN":
      return { ...state, campaigns: state.campaigns.filter( f => f.id !== action.data.id) }
    case "FETCH_BROADCASTS":
      return { ...state, broadcasts: [...state.broadcasts, ...action.data] }
    case "SELECT_BROADCAST":
      console.log(action)
      return { ...state, broadcasts: state.broadcasts.map( m => {
        let broadcast = m
        if(m.id === action.id){
          broadcast.selected = broadcast.selected ? false : true
        } else {
          broadcast.selected = false
        }
        return broadcast
      }) }
    case "CREATE_BROADCAST":
      return { ...state }
    case "UPDATE_BROADCAST": 
      const idx = state.broadcasts.findIndex( f => f.id === action.data.id)
      let broadcasts = [...state.broadcasts]
      let broadcast = { ...broadcasts[idx] };
      broadcast.selected = true
      broadcasts[idx] = action.data;
      console.log(broadcasts)
        return { ...state, broadcasts: broadcasts }
    case "BROADCAST_CONTACT":

      let newContact = action.data
      newContact.new = true
      const selectedIdx = state.broadcasts.findIndex( f => f.selected)
      let changeBroadcasts = [...state.broadcasts]
      let changeBroadcast = { ...changeBroadcasts[selectedIdx] };

      const existingContact = changeBroadcast.contacts.find( f => f.id === newContact.id)
      //VERIFICA SE O CONTATO JÁ EXISTE E REMOVE DA LISTA
      if(existingContact){
        //SE FOR UM CONTATO NOVO, REMOVE DA LISTA
        console.log(existingContact)
        if(existingContact.new){
          changeBroadcast.contacts = changeBroadcast.contacts.filter( f => f.id !== newContact.id)
        }
      } else {
        changeBroadcast.contacts = [newContact, ...changeBroadcast.contacts]
      }
      
      changeBroadcasts[selectedIdx] = changeBroadcast;
      console.log(changeBroadcast)

      return { ...state, broadcasts: changeBroadcasts }

    case "REMOVE_BROADCAST_CONTACT":

      const removeIdx = state.broadcasts.findIndex( f => f.selected)
      let removeBroadcasts = [...state.broadcasts]
      let removeBroadcast = { ...removeBroadcasts[removeIdx] };
      const removeContact = removeBroadcast.contacts.find( f => f.id === action.data.id)
      //VERIFICA SE O CONTATO JÁ EXISTE E REMOVE DA LISTA
      if(removeContact.new){
        //SE FOR UM CONTATO NOVO, REMOVE DA LISTA
        removeBroadcast.contacts = removeBroadcast.contacts.filter( f => f.id !== action.data.id)
        
      } else {
        removeBroadcast.contacts = removeBroadcast.contacts.map( m => {
          let contact = m
          if(contact.id === action.data.id){
            contact.removed = !contact.removed
          }
          return contact
        })
      }
      
      removeBroadcasts[removeIdx] = removeBroadcast;

      return { ...state, broadcasts: removeBroadcasts }
    case "UNSELECT_CAMPAIGN": 

      return { ...state, campaigns: state.campaigns.map( m => {
        let campaign = m
        m.selected = false
        return campaign
      }) }

    default:
      return { ...state }
  }
}


export default broadcasts
