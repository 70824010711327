import React from 'react';
import './message-footer.scss';

export const MessageFooter = React.memo(({children}) => {
    return (
        <div className="chat-message-footer-ui">
            {children}
        </div>
    );
})

