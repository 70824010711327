import { useState, useEffect } from 'react';

const useWindowIsResizing = () => {
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    const handleResizeStart = () => {
      setIsResizing(true);
    };

    const handleResizeEnd = () => {
      setIsResizing(false);
    };

    window.addEventListener('resize', handleResizeStart);
    window.addEventListener('resize', handleResizeEnd);

    return () => {
      window.removeEventListener('resize', handleResizeStart);
      window.removeEventListener('resize', handleResizeEnd);
    };
  }, []);

  return isResizing;
};

export default useWindowIsResizing;