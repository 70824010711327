import React from 'react'
import { ArrowDown } from 'react-feather';
import "./go-to-bottom.scss"

function GoToBottom({onClick}) {
return (
      <div className="go-to-bottom-ui" onClick={onClick}>
          <ArrowDown size={15} />
      </div>
  )
}
export default React.memo(GoToBottom);
