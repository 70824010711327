import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR, STORE_USER } from '../../../helpers/constants';
import { INotification } from '../../../interfaces/global/notifications.interface';

import {
    UPDATE_SOCKET_EVENT,
    TOGGLE_SHOW_NOTIFICATIONS,
    DELETE_NOTIFICATION
} from '../../../helpers/constants';

import {
    formatNotifyFromNotificationHub,
    formatNotifyTransferAdd,
    fetchUseNotifications,
    listOfUserNotifications,
    tryToMarkAllNotificationAsRead,
    deleteUseNotification,
    fetchNotificationFromLocalStorage
} from '../utils/notifications/notificationsFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getNotifications = (state: any) => state.notifications;
const getSystemConfigs = (state: any) => state.systemConfig;

function* addNotificationFromNotificationHub( action: any ) {
    try {
        const { notifications }  = yield select(getNotifications);
        const { showNotifications } = yield select(getSystemConfigs);

        if(action?.socketEvent?.event === "NotifyFromNotificationHub"){
            const notification: INotification = yield call(formatNotifyFromNotificationHub, action?.socketEvent?.message);

            yield put(listOfUserNotifications([...notifications, notification]));
            
        } else if(action?.socketEvent?.event === "NotifyTransferAdd"){

            const notification: INotification = yield call(formatNotifyTransferAdd, action?.socketEvent?.message, showNotifications);
            
            yield put(listOfUserNotifications([...notifications, notification]));
        }

        if(showNotifications){
            yield call(markAsNotificationAsRead)
        }
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* fetchUserNotificationsFromApi( action: any){
    try {
        const { data, response } = yield call(fetchUseNotifications);
        if (data) {
            yield put(listOfUserNotifications(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    
    } 
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* fetchUserNotificationsFromLocalStorage(){
    try {
        const { data } = yield call(fetchNotificationFromLocalStorage);
        
        if (data) {
            yield put(listOfUserNotifications(data));
        } 
    
    } 
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* markAsNotificationAsRead(){
    try {
        const { notifications } = yield select(getNotifications);
        const { showNotifications } = yield select(getSystemConfigs);
        const {data, response} =  yield call(tryToMarkAllNotificationAsRead, notifications, showNotifications)
        if (data) {
            yield put(listOfUserNotifications(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    
    } 
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* handleDeleteNotification(action: any){
    console.log(action)
    try {
        const { notifications } = yield select(getNotifications);
        const {data, response} =  yield call(deleteUseNotification, notifications, action.data)
        if (data) {
            yield put(listOfUserNotifications(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    } 
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
}
}

export default function* notificationsSaga() {
    yield takeEvery(STORE_USER, fetchUserNotificationsFromApi);
    // yield takeEvery(STORE_USER, fetchUserNotificationsFromLocalStorage);
    yield takeEvery(UPDATE_SOCKET_EVENT, addNotificationFromNotificationHub);
    yield takeEvery(TOGGLE_SHOW_NOTIFICATIONS, markAsNotificationAsRead);
    yield takeEvery(DELETE_NOTIFICATION, handleDeleteNotification);
}
