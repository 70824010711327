import React from "react";
import Accordion from "../accordion/Accordion";
import Button from "../button/Button";
import "./error-boundary.scss";

// We're going to use the ErrorBoundary's state to keep track
// of whether an error has happened. We start without an error,
// so these are initially null.

const initial_state = {
  error: null,
  info: null,
  hidden: true,
  showErrorDetails: false,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // establish the initial state
    this.state = initial_state;

    // Bind any event handlers
    this.reset = this.reset.bind(this);
  }

  // When an error occurs in one of the child components,
  // the Error Boundary's `componentDidCatch` will be invoked
  // with information about the error.
  //
  // We'll put this information on the state,
  // and in the `render()` method we'll read it back
  // and act accordingly.
  componentDidCatch(error, info) {
    this.setState({ error: error, info: info, hidden: false });

    // Let's also forward the error to a third-party service.
    // Below, we're sending it to Sentry:
    // Raven.captureException(error, { extra: info });
  }

  // We also want to allow the user to recover from an error,
  // so we add a button in the fallback UI that resets the
  // error boundary to its initial state (no error).
  //
  // This causes it to try to re-render the child components
  // and hope they don't break again in the process.
  reset() {
    this.setState(initial_state);
  }

  hideError = () => {
    this.setState({ hidden: true });
  };

  toggleErrorDetails = () => {
    this.setState({ showErrorDetails: !this.state.showErrorDetails });
  };

  render() {
    // read the error information from the state
    let { error, info, hidden, showErrorDetails } = this.state;

    // if we have an error, let's render the fallback UI...
    if (error) {
      return (
        <div className={`error-boundary-ui ${hidden ? "hidden" : ""}`}>
          <h3>Something went wrong</h3>
          <h4>{error.toString()}</h4>
          <Accordion>
            <Accordion.Group>
              <Accordion.Item
                color="gray"
                opened={showErrorDetails}
                toggleAccordion={this.toggleErrorDetails}
                title="Error details"
              >
                <pre>{info.componentStack}</pre>
              </Accordion.Item>
            </Accordion.Group>
          </Accordion>
          <div className="error-boundary-buttons">
            <Button.Danger onClick={this.hideError}>Hide error</Button.Danger>
            <Button.Danger dark onClick={this.reset}>
              Try again
            </Button.Danger>
          </div>
        </div>
      );
    }

    // ...otherwise, render the child components.

    return this.props.children;
  }
}

export default ErrorBoundary;
