// export const TOAST_ERROR_TIME = 6000;
// export const TOAST_SUCCESS_TIME = 2000;
// // export const domain = 'http://ec2-204-236-250-38.compute-1.amazonaws.com:3002';
export const domain = 'http://157.245.235.171';
export const apiUrl = `api/v1`;
export const LOGGEDUSER = 'user'
export const LOGGED_CUSTOMER = 'user'
export const TOKEN_CUSTOMER = 'token'
export const TOKEN = 'token'
export const REFRESHTOKEN_CUSTOMER = 'refresh-token'
export const COMPANY = 'company'

export const SAGA_ERROR = 'SAGA_ERROR';
export const NOTIFY_UPDATE_LOGGED_USER_STATUS = 'NOTIFY_UPDATE_LOGGED_USER_STATUS';
export const USER_DATA = 'USER_DATA';
export const STORE_USER = 'STORE_USER';
export const FETCH_GROUPED_USERS = 'FETCH_GROUPED_USERS';
export const GROUPED_USERS = 'GROUPED_USERS';
export const STORE_NOTIFICATIONS = 'STORE_NOTIFICATIONS';
export const UPDATE_SOCKET_EVENT = 'UPDATE_SOCKET_EVENT';
export const TOGGLE_SHOW_NOTIFICATIONS = 'TOGGLE_SHOW_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
