import React, {
  ForwardedRef,
  useRef,
  useState,
  useImperativeHandle,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import "../inputs.scss";

// types
import { TextInputProps, TextInputMethods } from "../interfaces";

const TextInput = React.forwardRef<TextInputMethods, TextInputProps>(
  (
    {
      id,
      type,
      className,
      autoComplete,
      name,
      placeholder,
      value,
      placeholderTop = !!value,
      onChange,
      errorMessage,
      warningMessage,
      fieldDescription,
      fieldIcon,
      placeholderStyle,
      clear,
      handleClearInput,
      hint,
      minLength,
      maxLength,
      dataCy,
      disabled,
      size,
      onClick,
      onKeyDown,
      onKeyUp,
      testid,
    }: TextInputProps,
    ref: ForwardedRef<TextInputMethods>
  ) => {
    const [state, setState] = useState<number>(0);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const setFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
        setState((state) => state + 1);
      }
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      setFocus,
      inputRef,
    }));

    function toggleShowPassword() {
      setShowPassword((prevState) => !prevState);
    }

    return (
      <div
        className={`input-form-group ${className ? className : ""} ${
          errorMessage && errorMessage.error
            ? `is-invalid ${errorMessage.color ? errorMessage.color : ""}`
            : ""
        } ${disabled ? "input-disabled" : ""}`}
      >
        <div
          className={`input-form ${
            fieldIcon ? `has-icon ${fieldIcon.position}` : ""
          }`}
        >
          <input
            data-cy={dataCy ? dataCy : undefined}
            data-testid={testid ? testid : id ? id : undefined}
            className={size ? String(size) : ""}
            maxLength={maxLength ? maxLength : undefined}
            minLength={minLength ? minLength : undefined}
            ref={inputRef}
            type={
              type === "password" && showPassword
                ? "text"
                : type
                ? type
                : "text"
            }
            id={id}
            autoComplete={autoComplete === "off" ? "new-password" : ""}
            name={name}
            value={value ? value : ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange ? onChange(e) : {}
            }
            onKeyUp={(e: KeyboardEvent<HTMLInputElement>) =>
              onKeyUp ? onKeyUp(e) : {}
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
              onKeyDown ? onKeyDown(e) : {}
            }
            onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
              onClick ? onClick(e) : {}
            }
            disabled={disabled}
          />
          {fieldIcon && (
            <div className={`input-field-icon ${fieldIcon.position}`}>
              {fieldIcon.icon}
            </div>
          )}
          <label
            className={`${placeholderStyle === "hide" ? "hide" : "animated "} ${
              placeholderTop ? "placeholder-top" : ""
            } truncate ${size ? String(size) : ""}`}
            data-cy={id}
            htmlFor={id}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          >
            {placeholder}
          </label>
          <div className="input-actions">
            {clear && value && value.trim().length > 0 && (
              <div
                className={`input-form-clear ${hint ? "has-hint" : ""}`}
                onClick={handleClearInput}
              >
                +
              </div>
            )}
            {hint && (
              <span className="input-form-hint">
                <div className="input-hint-icon">i</div>
                <div className="input-hint-text">{hint}</div>
              </span>
            )}
            {type === "password" && (
              <span
                className={`show-hide-characters ${
                  showPassword ? "active" : ""
                }`}
                onClick={toggleShowPassword}
              >
                <div className="gg-eye" />
              </span>
            )}
          </div>
          <div className="input-border" />
        </div>
        {(maxLength && maxLength > 0) || errorMessage || fieldDescription ? (
          <div className="fields-legends">
            <div className="fields-legends-descriptions">
              {errorMessage && errorMessage.error ? (
                <small className={errorMessage.color || ""}>
                  {errorMessage.error}
                </small>
              ) : fieldDescription ? (
                <small>{fieldDescription}</small>
              ) : null}
              {minLength &&
              minLength > 0 &&
              value &&
              value.trim().length < minLength ? (
                <small>{`Mínimo ${minLength} caracteres`}</small>
              ) : maxLength && maxLength > 0 ? (
                <small>{`Máximo ${maxLength} caracteres`}</small>
              ) : null}
            </div>
            {maxLength && maxLength > 0 && (
              <div className="fields-legends-counter">
                {value?.length}/{maxLength}
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
);

export default TextInput;
