import { client } from "../../utils/axios-utils";

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/qualifierqualificationanswers`;

const qualifierQualificationAnswersService = {
  getQualifierQualificationAnswersByServiceProtocolId: (serviceProtocolId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/by-serviceprotocolid/${serviceProtocolId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  getQualifierQualificationAnswersByUserId: (userId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/by-userid/${userId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  getQualifierQualificationAnswersByTransferId: (transferId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/by-transferid/${transferId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  getQualifierQualificationAnswersByQualifierId: (qualifierId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/by-qualifierid/${qualifierId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  postQualifierQualificationAnswers: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(`${url}`, { ...params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
};

export default qualifierQualificationAnswersService;
