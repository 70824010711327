import mock from "../mock"
let data = {
    messages: [
        {
            id: 1,
            slug: "/obrigado",
            message: "Agradecemos o seu contato e permanecemos à disposição caso tenha alguma nova questão.",
            exit: true,
            status: true
        },
        {
            id: 2,
            slug: "/documentos",
            message: "Para abrir a sua solicitação, precisaremos do número do seu CPF, Endereco completo com o CEP e o número do telefone.",
            exit: false,
            status: true
        },
        {
            id: 3,
            slug: "/endereço",
            message: "Qual é o seu endereço completo com o CEP?",
            exit: false,
            status: true
        },
        {
            id: 4,
            slug: "/telefone",
            message: "Qual é o número do seu telefone com o DDD?",
            exit: false,
            status: true
        },
        {
            id: 5,
            slug: "/email",
            message: "Qual é o seu endereço de email?",
            exit: false,
            status: true
        }
      ],
}

// GET : Calendar Events
mock.onGet("/api/apps/group-rules/ready-messages").reply(() => {
  return [200, data.messages]
})
// POST : Add new user
mock.onPatch("/api/apps/group-rules/ready-messages").reply(request => {

  let { id } = JSON.parse(request.data)

  const change = data.messages.find(
    c => c.id === id
  ).status = !data.messages.find(c => c.id === id).status
  console.log(change)
  
  return [201, data.messages]
})

mock.onPost("/api/apps/group-rules/ready-messages").reply(request => {
  
  let { params } = JSON.parse(request.data)
  const uid = Math.floor(Math.random() * 99999)

  params.id = uid
  params.slug = '/'+params.slug
  data.messages.unshift(params)
  return [200, data.messages]
})

mock.onPut("/api/apps/group-rules/ready-messages").reply(request => {
  let { params } = JSON.parse(request.data)

    let message = data.messages.find(m => m.id === params.id)
    message.slug = params.slug
    message.message = params.message
    message.exit = params.exit
        
  return [200, data.messages]
})

