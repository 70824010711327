import { call, put, takeEvery, select, delay } from 'redux-saga/effects';

import { SAGA_ERROR } from '../../../helpers/constants';
import { FETCH_GROUPED_USERS, NOTIFY_UPDATE_LOGGED_USER_STATUS } from '../../../helpers/constants';

import {
    requestGetGroupedAgents,
    storeGroupedUsers,
    updateGroupedUserStatus,
    toggleLoadingGroupedUsers
  } from '../utils/users/groupedUsersFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';
const getGroupedUsers = state => state.users.groupedUsers;

function* handleFetchGroupedUsers(action) {
    const groupedUsers = yield select(getGroupedUsers);
    yield put(toggleLoadingGroupedUsers(groupedUsers));
    try {
        const { data, response } = yield call(requestGetGroupedAgents, action.payload);
        if(data){
            yield put(storeGroupedUsers(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    } catch (e) {
        yield put(toggleLoadingGroupedUsers(groupedUsers));
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }
}

function* changeUserStatusByNotification(action) {
    const groupedUsers = yield select(getGroupedUsers);
    try {
        yield put(updateGroupedUserStatus(groupedUsers, action.payload));
    } catch(e){
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }
}

export default function* groupedUsersSaga() {
    yield takeEvery(FETCH_GROUPED_USERS, handleFetchGroupedUsers);
    yield takeEvery(NOTIFY_UPDATE_LOGGED_USER_STATUS, changeUserStatusByNotification);
}