import { useState, useEffect } from 'react';

const SmartRendering = ({children, componentWillRender}) => {
    const [componentMustRender, setComponentMustRender] = useState(false)
    
    useEffect(() => {
        if(componentWillRender && !componentMustRender) {
            setComponentMustRender(true)
        }
    }, [componentWillRender, componentMustRender])

    return componentMustRender ? children : null
};

export default SmartRendering;