const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

const onlyUniqueV2 = (array, field) => {
    const removeDuplicate = array.reduce((acc, current) => {
      const x = acc.find(item => item[field] === current[field]);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return removeDuplicate
  }

  export { onlyUnique, onlyUniqueV2 }