import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

const useChatDispatchActions = () => {
  const dispatch = useDispatch();

  const dispatchAction = useCallback(
    (action, data) => {
      dispatch({ type: action, data: data });
    },
    [dispatch]
  );

  return dispatchAction;
};

export default useChatDispatchActions;