import moment from 'moment';
import { getGmt0 } from '../../../../utils/dateUtility';
import { INotification } from '../../../../interfaces/global/notifications.interface';
import { notificationService } from "../../../../newServices/main";

import {
    STORE_NOTIFICATIONS
} from '../../../../helpers/constants';


interface INotifyFromNotificationHub {
    NotificationId: number,
    Text: string,
    NotificationServiceType: number,
    CustomerId: number | null,
    ServiceProtocolId: number | null
}

interface INotifyTransferAdd {
    CustomerId: number,
    ServiceProtocolId: number,
    TransferServiceDetail: {
        UserFromName: string,
        UserToName: string,
        UserMonitoringName: string,
    }
}

interface IGetNotification {
    id: number,
    text: string,
    totalItems: number,
    notificationServiceType: number,
    createdAt: string,
    readAt: string | null,
    serviceProtocolId: number | null,
    customerId: number | null
}

export const formatNotifyFromNotificationHub = (
    notification: INotifyFromNotificationHub): INotification => {
        
    const { NotificationId, Text, NotificationServiceType, CustomerId, ServiceProtocolId } = notification
    return {
        ident: NotificationId,
        title: NotificationServiceType === 5 ? "Conversa sem interação" : "Nova versão",
        tag: false,
        description: Text,
        color: null,
        tagClassName: null,
        isActive: false,
        controls: null,
        date: moment(getGmt0(moment())).format('YYYY-MM-DD HH:mm:ss'),
        type: NotificationServiceType,
        readAt: null,
        serviceProtocolId: ServiceProtocolId,
        customerId: CustomerId
    }
}

export const formatNotifyTransferAdd = (
notification: any,
showNotifications: boolean): INotification => {

    const { 
        CustomerId, 
        ServiceProtocolId, 
        TransferServiceDetail: { 
            UserFromName, 
            UserToName, 
            UserMonitoringName 
        } 
    }: INotifyTransferAdd = notification

    const notificationObj = {
        ident: `${CustomerId}_${ServiceProtocolId}`,
        title: `Transferência recebida`,
        description: `Transferência ${UserFromName
                ? `de ${UserFromName}`
                : ""
            } para ${UserToName} ${UserMonitoringName
                ? ` feita por ${UserMonitoringName}`
                : ""
            } em ${moment().format("DD/MM/YYYY HH:mm")}`,
        date: moment(getGmt0(moment())).format('YYYY-MM-DD HH:mm:ss'),
        data: notification,
        type: 'trasfer',
        readAt: showNotifications ? moment(getGmt0(moment())).format('YYYY-MM-DD HH:mm:ss') : null,
        serviceProtocolId: ServiceProtocolId,
        customerId: CustomerId
    }

    /** Provisory storing */
    const storageNotifications = JSON.parse(localStorage.getItem('notifications') || '[]')
    const objStorageNotifications = [...storageNotifications, notificationObj]
    localStorage.setItem('notifications', JSON.stringify(objStorageNotifications))
    /** Provisory storing */
    
    
    return notificationObj
}

export const fetchUseNotifications = () => {

    const params = {
        PageNumber: 1,
        PageSize: 12
    }

    return notificationService
            .getNotificationAvailableWebsosocketNotificationFromUser(params)
            .then((res) => {
                return {
                    data: res.data.notificationItems.map((notification: IGetNotification): INotification => {
                        const { id, text, createdAt, notificationServiceType, readAt, serviceProtocolId, customerId } = notification
                        return {
                            ...notification,
                            ident: id,
                            title: notificationServiceType === 5 ? "Conversa sem interação" : "Nova versão",
                            tag: false,
                            description: text,
                            color: null,
                            tagClassName: null,
                            isActive: false,
                            controls: null,
                            date: createdAt,
                            type: notificationServiceType,
                            readAt: readAt,
                            serviceProtocolId: serviceProtocolId,
                            customerId: customerId
                        }
                    })
                }
            })
            .catch((err) => err)
}

export function fetchNotificationFromLocalStorage() {
    const storageNotifications = JSON.parse(localStorage.getItem('notifications') || '[]')
    return {
        data: storageNotifications
    }
}

export const tryToMarkAllNotificationAsRead = (notifications: INotification[], show:  boolean) => {

    const unreadNotifications = notifications.filter((notification: INotification) => notification.type !== 'trasfer' && !notification.readAt)
    
    if (unreadNotifications.length === 0) return {
        data: notifications
    }

    return notificationService
            .putNotificationWebsocketNotificationsAsRead(
                unreadNotifications.map((notification: INotification) => {
                    return {
                        notificationId: notification.ident,
                    }
                })
            ).then(() => {
                return {
                    data: notifications.map((notification: INotification) => {
                        return {
                            ...notification,
                            readAt: show ? moment(getGmt0(moment())).format('YYYY-MM-DD HH:mm:ss') : null
                        }
                    })
                }
            })
            .catch(err => err)

}

export const deleteUseNotification = (notifications: INotification[], notification: INotification) => {
    console.log(notifications, notification)
    if(notification.type === 'trasfer') {
        return {
            data: notifications.filter((item: INotification) => item.ident !== notification.ident)
        }
    } else {
        return notificationService
        .putNotificationWebsocketNotificationAsDeleted({
            notificationId: notification.ident,
        }).then(() => {
            return {
                data: notifications.filter((item: INotification) => item.ident !== notification.ident)
            }
        })
        .catch(err => err)
    }
}

export const listOfUserNotifications = (data: INotification[]) => {
    return {
        type: STORE_NOTIFICATIONS,
        payload: data
    }
};