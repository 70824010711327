import { releaseVersionUserStatesService } from '../../../../newServices/main';
import { IReleaseVersion } from '../../../../interfaces/releaseNotes/releaseVersions.interface';

export const createOrUpdateReleaseNoteStates = (currentRelease: IReleaseVersion, action: string ) => {

    const params = {
        releaseVersionId: currentRelease.id,
        isSeeLater: action === 'remindme' ? true : false,
        isSkipped: action === 'dismiss' ? true : false,
        id: currentRelease?.userVersionData?.userId || null
    }

    const createOrUpdate = params?.id ?
      releaseVersionUserStatesService.putReleaseVersionsUserStates :
      releaseVersionUserStatesService.postReleaseVersionsUserStates;

    return createOrUpdate(params)
      .then((res) => res)
      .catch((err) => err)
}