const initialState = {
    outMessages: [],
}
    
    const groupOutMesageReducer = (state = initialState, action) => {
      switch (action.type) {
        case "FETCH_GROUP_OUT_MESSAGES":
          let outMessages = [...state.outMessages]
          outMessages = action.outMessages
          return { ...state, outMessages }
        case "PUT_GROUP_OUT_MESSAGE":
            return { ...state, outMessages: action.outMessages }
      default:
          return state
      }
    }
    
    export default groupOutMesageReducer
    