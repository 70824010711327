import React, { useEffect, useState, useRef } from "react";

import moment from "moment";
import { MoreVertical } from "react-feather";
import { useSelector } from "react-redux";

import CommandBar from "../../../../commandBar/CommandBar";
import ContactList from '../../../../contactList/ContactList';
import { ContextMenu, ContextMenuButton } from '../../../../contextMenu';
import EmojisPicker from "../../../../emojisPicker/EmojisPicker";
import BeautyScrollbar from "../../../../beautyScrollbar/BeautyScrollbar";
import { TextInput } from "../../../../forms";

import {
    Message,
    MessageSpacing,
    MessageText,
    MessageFooter
} from '../../../../messages/components';

import { ReactComponent as EmojiIcon } from "../../../../../assets/img/svg/lol.svg";
import { ReactComponent as SentIcon } from "../../../../../assets/img/svg/sent.svg";

import { getLocalTime } from "../../../../../utils/dateUtility";

import './message.scss';
import { uuidv4 } from "../../../../../utils/uuidGenerator";

const MessageComponent = ({ messages, setMessages }) => {

    const [message, setMessage] = useState({
        id: null,
        text: ''
    });
    const [scrollFunctionFromChild, setScrollFunctionFromChild] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [messageMenu, showMessageMenu] = useState(null);
    const scrollRef = useRef();

    const handleInputChange = (event) => {
        const { value } = event.target;
        setMessage({
            ...message,
            text: value
        });
    }

    const { userData } = useSelector(state => state.auth);

    const handleAddMessage = () => {
        const newMessage = {
            id: messages.length + 1,
            createdAt: new Date(),
            userSender: {
                id: userData.id,
                name: userData.name
            },
            externalReference: uuidv4(),
            isSent: true,
            messageText: message.text
        }
        if(message.id) {
            const index = messages.findIndex(item => item.id === message.id);
            setMessages( prevState => {
                return prevState.map( (item, i) => {
                    if(i === index) {
                        item.messageText = message.text
                    }
                    return item
                })   
            });
        } else {
            setMessages([...messages, newMessage]);
        }

        
        setMessage({
            id: null,
            text: ''
        });

    }

    const handleScrollDown = (el) => {
        setScrollFunctionFromChild(el)
    }

    const handleShowMenu = (e, item) => {
        showMessageMenu({
            ...e,
            item: item
        })
    }

    const handleCloseMenu = () => {
        showMessageMenu(null)
    }

    const handleDeleteMessage = (item) => {
        setMessages(prevState => {
            return prevState.filter(message => message.id !== item.id)
        })
    }

    const handleEditMessage = (item) => {
        setMessage({
            id: item.id,
            text: item.messageText
        })
    }

    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prevShowEmojiPicker) => !prevShowEmojiPicker);
    };

    useEffect(() => {
        if (scrollRef) {
            scrollRef.current.scrollToEnd({ behavior: "smooth" })
            setShowEmojiPicker(false);
        }
    }, [messages, scrollRef])
      
    return (
        <>
        <ContactList>
            <ContactList.Content
                className="message-content"
                // style={}
                scrollDown={(el) => handleScrollDown(el)}
                loading={false}>
                <BeautyScrollbar
                    // disableScrollX={disableScrollX}
                    ref={scrollRef}
                    className="message-content"
                    style={{ maxHeight: '300px' }}>
                    {messages.map((message, index) => {
                        const prevMessage = messages[index - 1] ?? null;
                        const nextMessage = messages[index + 1] ?? null;
                        return (
                            <React.Fragment key={index}>
                                <Message
                                    item={message}
                                    prevMessage={prevMessage}
                                    nextMessage={nextMessage}
                                    isChatbot={false}
                                    isSent={true}
                                    color="success"
                                    // handleShowMenu={handleShowMenu}
                                    >
                                    <ContextMenuButton
                                        className="message-options-button"
                                        handleShowMenu={(e) => handleShowMenu(e, message)}
                                    >
                                        <MoreVertical size={15} />
                                    </ContextMenuButton>
                                    <MessageText>{message.messageText}</MessageText>
                                    <MessageFooter>
                                        <span>{moment(getLocalTime(message?.createdAt)).format('HH:mm')}</span>
                                    </MessageFooter>
                                </Message>
                                {index + 1 < messages.length && <MessageSpacing nextMessage={nextMessage} currMessage={message} />}
                            </React.Fragment>
                        )
                    })}
                </BeautyScrollbar>
                <div className={`message-emoji-picker ${showEmojiPicker ? 'show' : ''}`}>
                    <EmojisPicker
                        handleEmoji={(emoji) => setMessage({ ...message, text: message.text + emoji })}
                        show={showEmojiPicker} />
                </div>

            </ContactList.Content>

            <ContactList.Footer
                collapse={false}
                title={false}>
                <div className="p-50 d-flex w-100">
                    <CommandBar className="chat-messaging-buttons">
                        <CommandBar.Group>
                            <CommandBar.Item
                                color="default"
                                transparentBackground
                                active={showEmojiPicker}
                                badge={null}
                                onClick={toggleEmojiPicker}
                                description={null}>
                                <EmojiIcon width={25} height={25} />
                            </CommandBar.Item>
                        </CommandBar.Group>
                    </CommandBar>

                    <TextInput
                        placeholderStyle='hide'
                        value={message.text}
                        onEnterPressed={handleAddMessage}
                        onChange={handleInputChange}
                        className="flex-grow-1 mr-50 ml-50" placeholder="Escreva uma mensagem" />
                    {message.text.length > 0 && (
                        <CommandBar className="chat-messaging-buttons">
                            <CommandBar.Group>
                                <CommandBar.Item
                                    color="default"
                                    transparentBackground
                                    // active={actions.showEmojisKeyboard}
                                    badge={null}
                                    onClick={handleAddMessage}
                                    description={null}>
                                    <SentIcon width={25} height={25} />
                                </CommandBar.Item>
                            </CommandBar.Group>
                        </CommandBar>
                    )}
                </div>
            </ContactList.Footer>
        </ContactList>
        <ContextMenu {...messageMenu} handleCloseMenu={handleCloseMenu}>
            <ContextMenu.Item onClick={() => handleEditMessage(messageMenu.item)}>
                Editar
            </ContextMenu.Item>
            <ContextMenu.Item onClick={() => handleDeleteMessage(messageMenu.item)}>
                Apagar
            </ContextMenu.Item>
        </ContextMenu>
        </>
    )
}

export default React.memo(MessageComponent);