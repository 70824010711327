import { client } from "../../utils/axios-utils";

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/servicechannel`;

const serviceChannelService = {
  getServiceChannel: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(url, { params })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannelId: (id) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  postServiceChannel: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(url, params)
        .then((res) => {
          resolve(res.data);
          console.log(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },

  putServiceChannel: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(url, params)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  patchServiceChannelStatus: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .patch(`${url}/status`, params)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getFacebookPages: (userID, accessToken) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(
          `https://graph.facebook.com/${userID}/accounts?fields=name,access_token&access_token=${accessToken}`
        )
        .then((res) => {
          resolve(res.data);
          console.log(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },

  getServiceChannelActiveMode: (params) => {
    console.log(JSON.stringify(params));
    const formated = params
      ? Object.entries(params).map((key, index) => {
          const value = key[1];
          return typeof value === "object"
            ? value.map((t) => `${key[0]}=${t}`).join("&")
            : `${key[0]}=${value}`;
        })
      : [];

    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/active-mode?${formated.join("&")}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  patchServiceChannelPhoneSync: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .patch(`${url}/phone-sync`, params)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannelIsWhatsPhoneNumberOnline: (id) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}is-whats-phone-number-online/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  postServiceChannelRestartWhatsPhoneNumber: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(`${url}/restart-whats-phone-number/`, { ...params })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannelIsWhatsPhoneNumberExists: (serviceChannelId, customerId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(
          `${url}/is-whats-phone-number-exists/${serviceChannelId}/${customerId}`
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannelFacebook: (facebookAppId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/facebook/${facebookAppId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannelGupshup: (serviceChannelId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/gupshup/${serviceChannelId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannel360dialogTemplates: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/360dialog/templates`, { params })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannel360dialogTemplatesByServiceChannelId: (serviceChannelId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(
          `${url}/360dialog/templates/by-servicechannelid?serviceChannelId=${serviceChannelId}`
        )
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  getServiceChannelNumberLicenses: () => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/number-licenses`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  putServiceChannelNumberLicences: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(`${url}/number-licenses`, params)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  getServiceChannelChatWebConfiguration: (id) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/chatweb-configuration/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  putServiceChannelChatWebConfiguration: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(`${url}/chatweb-configuration`, params)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  getServiceChannelChatUserFormsPagination: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/chatweb-user-forms-pagination`, {params})
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
};

export default serviceChannelService;
