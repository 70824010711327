import React from "react";
import moment from "moment";
import "./message-spacing.scss";

const spacing = (nextMessage, currMessage) => {

    /** As regras para espaçamento são as seguintes:
     *  1. Se a mensagem anterior for do mesmo usuário e no mesmo minuto 4px
     *  2. Se a mensagem anterior for do mesmo usuário e em minutos diferentes 8px
     *  3. O espço para separar mensagens entre enviadas e recebidas é de 16px
     */

    const nextIsSent = nextMessage?.userSender ? true : false;
    const currIsSent = currMessage.userSender ? true : false;
    const spacer = currIsSent !== nextIsSent ? 'spacer-3' : nextMessage ? moment(nextMessage.createdAt).isSame(currMessage.createdAt, 'minute') ? 'spacer-1' : 'spacer-2' : 0;
    return spacer;
}

export const MessageSpacing = React.memo(({nextMessage, currMessage}) => {

    const spacer = spacing(nextMessage, currMessage);
    return (
        <div className={`message-spacing ${spacer}`}/>
    );
});

