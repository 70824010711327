import React from 'react';
import { ReactComponent as ReportsIcon } from "../../../../assets/img/svg/reports-icon.svg"
import { X } from "react-feather";
import moment from 'moment-timezone';

function ReportNotification({ data, type, handleClickNotification }) {
    const array = data
    const items = array.reduce((accum, item) => {
      return accum + item.totalItems
    }, 0)
    const lastDatetime = array.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))[0]
    return <div className="notification-item cursor-pointer" onClick={e => handleClickNotification(e, 2, array)}>
      <div className="notification-icon">
        <ReportsIcon />
      </div>
      <div className="notification-description">
        <h2>Relatórios disponíveis para download</h2>
        <p>{items === 1 ? <><b>1 relatório</b> está disponível para donwload.</> : <><b>{items} relatórios</b> estão disponíveis para download.</>}</p>
        <small>{moment.utc(lastDatetime.createdAt).local().fromNow()}</small>
      </div>
      <div className="notification-delete">
        <X />
      </div>
    </div>

}

export default ReportNotification