import { client } from '../../utils/axios-utils'

/*if (!domain) {
    throw new Error('Server url not present. Contact the adminstrator!')
}*/
const shiftsService =  {
    
    getShifts: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/shifts`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    postShift: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/shifts`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putShift: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/shifts`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    patchShiftStatus: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .patch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/shifts/status`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
}

export default  shiftsService;