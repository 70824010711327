
import axios from 'axios'
import moment from 'moment'
const fs = require('browserify-fs');

const date = moment().format('YYYY-MM-DD')
const mkpath = `../../${date}`

export const createOrUpdateFile = async (name, data) => {
    const jsonContent = [data]

    // //apenas experimental (remoção de arquivo)
    // fs.unlink(`${mkpath}/${name}.json`, function( res){
    //     fs.rmdir(mkpath, function(res){
    //     })    
    // })

    //Verifica se a pasta existe
    fs.exists(`${mkpath}/`, function(res){
        if(res){
            // console.log('pasta já existe')
        } else {
            // Caso não exista, cria uma pasta nova
            fs.mkdir(mkpath, () => {
                // console.log('pasta criada com sucesso')
            })
        }
    })
    
    //Verifica se o arquivo existe
    fs.readFile(`${mkpath}/${name}.json`, 'utf-8', function(err, res) {
        if(err){
            //Caso não exista, cria um arquivo novo
            console.log('Cria arquivo')
            fs.writeFile(`${mkpath}/${name}.json`, JSON.stringify(jsonContent), "utf8", function() {
                // console.log('arquivo criado com sucesso')
            });
        } else {
            // console.log(res)
            // console.log(`${mkpath}/${name}.json`)
            //Caso exista, atualiza o arquivo existente
            // console.log('Atualiza arquivo')
            fs.readFile(`${mkpath}/${name}.json`, 'utf-8', function(err, res) {
                const updatedData = [...JSON.parse(res), ...jsonContent]
                fs.writeFile(`${mkpath}/${name}.json`, JSON.stringify(updatedData), "utf8", function() {
                    // console.log('arquivo atualizado com sucesso')
                });
            });
        }
    });
}

export const userLoginLog = async (name, data) => {
    createOrUpdateFile(name,data)
}

export const userSocketLog = async (name, data) => {
    createOrUpdateFile(name,data)
}

export const getLocalData = async (name) => {
    const data = fs.readFile(`${mkpath}/${name}.json`, 'utf-8', async (err, res) => {
        // console.log(res)
        return await res
    });
    // console.log(await data)
}

export const userMessageLog = async (name, data) => {
    // console.log(data)
    const saveData = {
        customerId: data.CustomerId,
        userId: data?.userSender?.userId,
        userName: data?.userSender?.userName,
        date: data.Received,
        id: data.id,
        protocol: data.protocol,
        serviceProtocolId: data.serviceProtocolId,
        originGroupId: data.originGroupId,
        messageStatusType: data.messageStatusType,
        isSent: data.isSent,
        ServiceChannelType: data.ServiceChannelType
    }

    // fs.unlink(`${mkpath}/${name}.json`, function( res){
    //     fs.rmdir(mkpath, function(res){
    //     })    
    // })

    // console.log(saveData)

    fs.readFile(`${mkpath}/${name}.json`, 'utf-8', function(err, res) {
        if(err){
            // console.log('arquivo não existe')
            createOrUpdateFile(name,saveData)
        } else {
            const oldData = JSON.parse(res)
            const protocolExistis = oldData.find( f => f.serviceProtocolId === data.serviceProtocolId)
            if(typeof protocolExistis === 'undefined'){
                // console.log('adiciona novo protocolo')
                createOrUpdateFile(name,saveData)
            } else {
                // console.log('protocolo já presente')
            }
            // console.log(oldData)
            // console.log('arquivo existe')
        }
    });
    // console.log(saveData)
    
    //createOrUpdateFile(name,data)
}

export const updateLocalData = async (name, data) => {
    const oldData = await axios.get(`/${date}/${name}.json`)
    .then(res => { 
        const jsonContent = [...res.data, ...data]
        console.log(jsonContent)
        fs.writeFile(`./public/${date}/${name}.json`, JSON.stringify(jsonContent), "utf8", function (error) {
            if (error) {
              console.log("An error occured while saving build date and time to meta.json");
              return console.log(error);
            }
            console.log("Latest build date and time updated in meta.json file");
        });
    })
    .catch(err => console.log(err))
}



export const cleanLocalData = async (name) => {
        const jsonContent = []
        // console.log(jsonContent)
        fs.writeFile(`./public/${date}/${name}.json`, JSON.stringify(jsonContent), "utf8", function (error) {
            if (error) {
              console.log("An error occured while saving build date and time to meta.json");
              return error;
            }
            console.log("Latest build date and time updated in meta.json file");
        });
}