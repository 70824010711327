import { client } from '../../utils/axios-utils'

/*if (!domain) {
    throw new Error('Server url not present. Contact the adminstrator!')
}*/
const surveybotevaluationsService =  {
    getSurveybotevaluations: (params) => {
        console.log(params)
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/surveybotevaluations`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getSurveybotevaluationsDescriptions: (surveyEvaluationId) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/surveybotevaluations/descriptions/${surveyEvaluationId}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getSurveybotevaluationsAttachment: (attachmentId) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/surveybotevaluations/${attachmentId}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
}
export default  surveybotevaluationsService;