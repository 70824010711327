import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/releaseVersions`;

const releaseVersionsService =  {
    getReleaseVersionsByPagination: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${url}/by-pagination`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    postReleaseVersions: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putReleaseVersions: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    deleteReleaseVersions: (id) => {
        return new Promise((resolve, reject) => {
            return  client()
            .delete(`${url}/${id}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putReleaseVersionsPublish: (releaseVersionId) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${url}/publish/${releaseVersionId}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putReleaseVersionsUnpublish: (releaseVersionId) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${url}/unpublish/${releaseVersionId}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
}
export default  releaseVersionsService;