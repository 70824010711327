export const calculateElementPosition = (params) => {
    /** Essa função tem como objetivo calcular a altura e a largura do menu
     *  assim como a posição do botão que o chamou para posicioná-lo corretamente
     *  na tela do usuário.
    */
    const { containerWidth, containerHeight, x, y } = params;
    /** Calcula a distancia entre o container e elementos a direita */
    const overflowX = x + containerWidth > window.innerWidth;
    const overflowY = y + containerHeight > window.innerHeight;

    const menuPosition = {
        top:  overflowY ? y - containerHeight : y,
        left: overflowX ? x - containerWidth : x,
        alignment: {
            x: overflowX ? "left" : "right",
            y: overflowY ? "bottom" : "top"
        },
        window: {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    return menuPosition
}
