import mock from "../mock"
let data = {
    worktimes: [
        {
          id: 1,
          day: 0,
          start: '07:00',
          end: '11:00',
          status: true
        },
        {
            id: 2,
            day: 1,
            start: '07:00',
            end: '13:00',
            status: true
        },
        {
            id: 3,
            day: 2,
            start: '07:00',
            end: '13:00',
            status: true
        },
        {
            id: 4,
            day: 3,
            start: '07:00',
            end: '13:00',
            status: true
        },
        {
            id: 5,
            day: 4,
            start: '07:00',
            end: '13:00',
            status: true
        },
        {
            id: 6,
            day: 5,
            start: '07:00',
            end: '13:00',
            status: true
        },
        {
            id: 7,
            day: 6,
            start: '07:00',
            end: '13:00',
            status: true
        },

        {
          id: 8,
          day: 0,
          start: '14:00',
          end: '20:00',
          status: true
        },
        {
            id: 9,
            day: 1,
            start: '14:30',
            end: '20:00',
              status: true
        },
        {
            id: 10,
            day: 2,
            start: '13:00',
            end: '20:00',
              status: true
        },
        {
            id: 11,
            day: 3,
            start: '14:30',
            end: '20:00',
              status: true
        },
        {
            id: 12,
            day: 4,
            start: '14:30',
            end: '20:00',
            status: true
        },
        {
            id: 13,
            day: 5,
            start: '14:30',
            end: '20:00',
            status: true
        },
        {
            id: 14,
            day: 6,
            start: '14:30',
            end: '23:59',
            status: true
        },
    ]
}

// GET : Calendar Events
mock.onGet("/api/apps/group-rules/work-times").reply(() => {
  return [200, data.worktimes]
})
// POST : Add new user
mock.onPatch("/api/apps/group-rules/work-times").reply(request => {

  let { id } = JSON.parse(request.data)

  let index = data.worktimes.findIndex(item => item.id === id)
  data.worktimes.splice(index, 1)
  return [200]
})


mock.onPut("/api/apps/group-rules/work-times").reply(request => {
  let { params } = JSON.parse(request.data)
    let worktime = data.worktimes.find(m => m.id === params.id)
    worktime.start = params.start
    worktime.end = params.end
    console.log(data.worktimes)
  return [200, data.worktimes]
})

