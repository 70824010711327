const initialState = {
    clients: [],
    filteredClients: [],
  }
  
  const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_CLIENTS":
        let clients = [...state.clients]
        clients = action.clients
        return { ...state, clients }

      case "CLIENT_UPDATE_STATUS":
        state.clients.find(
          c => c.id === action.id
        ).status = !state.clients.find(c => c.id === action.id).status
        return { ...state }
        
      case "UPDATE_CLIENTS":
        let clientUpdate = state.clients.find(c => c.id === action.activeClient.id)
        clientUpdate.name = action.activeClient.name
        clientUpdate.status = action.activeClient.status
        return { ...state }


    case "SEARCH_CLIENTS":
      let filteredClients = state.clients.filter(val => {
         if (action.query) {
          return (
            (val.name.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.clientCode !== null && val.clientCode.toString().toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.email.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.agent && val.agent.name && val.agent.name.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.agent && val.agent.department && val.agent.department.toLowerCase().includes(action.query.term.toLowerCase())
            ) &&
            (action.query.actives && val.status === true || 
            action.query.inactives && val.status === false)
          )
        } else {
          return state.clients
        }
      })
      return { ...state, filteredClients }
    case "ADD_CLIENT":
      return { ...state }
      default:
        return state
    }
  }
  
  export default clientsReducer
  