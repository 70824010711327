import { INTERNAL_CHAT_CONTACTS,  } from '../../../../helpers/internalChatConstants';
import { conversationsService } from '../../../../newServices/corporateChat';
import { generateDarkColorFromString } from '../../../../utils/generateColorBasedOnString';
import { normalizeArray } from '../../../../utils/normalizeArray';


export const getListOfInternalChatContacts = (data) => {
    // const initialStorageUsers = localStorage.getItem('internalChatUsers') ? JSON.parse(localStorage.getItem('internalChatUsers')) : []
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: data.map(user => {
          return {
            ...user,
            color: generateDarkColorFromString(user.email),
            totalUnread: user.totalUnread || 0,
            messages: []
          }
        }),
        userStatus: normalizeArray({
            entity: 'byUserId',
            key: 'userId',
            array: data.map( user => {
                return {
                  userId: user.userId,
                  lastMessageStatusType: user.lastMessageStatusType,
                  loggedUserStatus: user.loggedUserStatus,
                  pause: user.pause
                } 
            })
        })
    };
};

export const getInternalContactList = () => {
    return conversationsService
        .getConversationsLoggeduser()
        .then(res => res)
        .catch(err => err);
}