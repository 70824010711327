import { usersService } from '../../../../newServices/main';
import { onlyUnique } from '../../../../utils/onlyUnique';

export const toggleLoadingGroupedUsers = (groupedUsers) => {
    return {
        type: 'GROUPED_USERS',
        data: {
            ...groupedUsers,
            isLoading: !groupedUsers.isLoading
        }
    }
}

export const requestGetGroupedAgents = (params) => {

    const decideRequest = params?.corporate ?   usersService.getAgentsCorporateAll : 
                                                usersService.getAgentsGroupAll

    return decideRequest()
        .then( res => {
            const result = res.data
            const groupsIds = result.map(m => m.groupId).filter(onlyUnique)
            const excludeIds = params?.excludeIds || null;
            
            const groups = groupsIds.map(m => {
                return {
                    groupId: m,
                    groupName: result.find(f => f.groupId === m).groupName,
                    users: result.filter(f => {
                        if(excludeIds && excludeIds.length > 0){
                            return f.groupId === m && !excludeIds.includes(f.id)
                        }
                        return f.groupId === m
                    }).sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                    })
                }
            }).sort((a, b) => {
                if (a.groupName < b.groupName) { return -1; }
                if (a.groupName > b.groupName) { return 1; }
                return 0;
            })

            return {
                data: {
                    groups, 
                    isLoading: false,
                    requestWasMade: true
                }
            }
        })
        .catch(err => err)
}

export const storeGroupedUsers = (data) => {
    return {
        type: 'GROUPED_USERS',
        data
    }
}

export const updateGroupedUserStatus = (groupedUsers, statusNotification) => {
    console.log(groupedUsers)
    console.log(statusNotification)
    return {
        type: 'GROUPED_USERS',
        data: {
            ...groupedUsers,
            groups: groupedUsers.groups.map(m => {
                return {
                    ...m,
                    users: m.users.map(u => {
                        if(u.id === statusNotification.userId){
                            return {
                                ...u,
                                loggedUserStatus: statusNotification.loggedUserStatus,
                                pause: statusNotification.pause
                            }
                        }
                        return u
                    })
                }
            })
        }
    }
}