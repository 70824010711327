import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/releaseversionpages`;

const releaseVersionPagesService =  {
    postReleaseVersionPages: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putReleaseVersionPages: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getReleaseVersionPages: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(url, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    deleteReleaseVersionPages: (id) => {
        return new Promise((resolve, reject) => {
            return  client()
            .delete(`${url}/${id}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },

}
export default  releaseVersionPagesService;