import React from 'react';
import './messages-text.scss';
import { customTagReplacer } from '../../../utils/customTagReplacer';
import { linkify } from '../../../utils/linkify';

export const MessageText = React.memo(({children}) => {
    return <div className="chat-messages-item-text-ui">
            <div className="chat-messages-item-text" dangerouslySetInnerHTML={{
                __html: customTagReplacer(linkify(children.trim()))
             }} />
    </div>
});