import React from 'react';
import moment from 'moment';
import { getLocalTime } from "../../../utils/dateUtility";

function useSortedContacts(contacts) {
  return React.useMemo(() => {
    return [...contacts].sort(
        (a, b) => moment(getLocalTime(b.dateLastMessage)) - moment(getLocalTime(a.dateLastMessage))
      );;
  }, [contacts]);
}

export default useSortedContacts;