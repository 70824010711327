import React from 'react';
import { X } from "react-feather";
import moment from 'moment-timezone';

function SurveyNotification({ data, type, handleClickNotification }) {
    const array = data
    const items = array.reduce((accum, item) => {
      return accum + item.totalItems
    }, 0)
    const lastDatetime = array.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))[0]
    return <div className="notification-item cursor-pointer" onClick={e => handleClickNotification(e, type, array)}>
      <div className="notification-icon">
        <img src={require('../../../../assets/img/svg/surveys-notification-icon.svg')} width={45} height={45} alt="New Version" />
      </div>
      <div className="notification-description">
        <h2>Pesquisa de satisfação</h2>
        <p>{items === 1 ? <><b>1 cliente</b> respondeu a pesquisa de satisfação.</> : <><b>{items} clientes</b> responderam a pesquisa de satisfação.</>}</p>
        <small>{moment.utc(lastDatetime.createdAt).local().fromNow()}</small>
      </div>
      <div className="notification-delete">
        <X />
      </div>
    </div>

}

export default SurveyNotification