import React, { useEffect, useState, useRef, useCallback } from "react";

import { Plus } from "react-feather";
import { useSelector } from "react-redux";

import Avatar from "../avatar/Avatar";
import Badge from "../badge/Badge";
import BeautyScrollbar from "../beautyScrollbar/BeautyScrollbar";
import UserStatus from "../userStatus/UserStatus";
import ErrorBoundary from "../errorBoundary/ErrorBondary";

import useChatDispatchActions from '../../customHooks/useChatDispatchActions';
import useWindowIsResizing from "../../customHooks/useWindowIsResizing";
import useTabVisibility from "../../customHooks/useTabVisibility";
import usePrevious from "../../customHooks/usePrevious";
import useAlternateBrowserTitle from "../../customHooks/useAlternateBrowserTitle";


import { TERMINATE_INTERNAL_CHAT, ACTIVE_INTERNAL_CHAT } from "../../helpers/internalChatConstants";

import {ChatWindow, ContactDetails, StartNewInternalChatModal, DeleteModalConfirmation } from "./components"
import useSortedMessages from "./utils/useSortedMessages";
import useSortedContacts from "./utils/useSortedContacts";

import "./internal-chat.scss";
import SmartRendering from "../smartRendering/SmartRendering";
import TypingIndicator from "./components/typingIndicator/TypingIndicator";

import sound from "../../assets/sounds/internal-chat-new-message.wav"

let notificationSound = new Audio(sound);
let notification;

const defaultDocumentTitle = "Omnyve"

const InternalChat = () => {

  const contactRefs = useRef([]);

  const dispatchChatActions = useChatDispatchActions()
  const isResizing = useWindowIsResizing();
  const isTabVisible = useTabVisibility();

  const { contacts, activeChat } = useSelector(state => state.internalChat);
  const userStatus = useSelector(state => state.internalChat.userStatus.byUserId)|| null;

  const [newMessagesReceived, setNewMessagesReceived] = useState(0)
  const { handleResetTitle, handleChangeTitle } = useAlternateBrowserTitle(defaultDocumentTitle);

  const [modals, setShowModals] = useState({
    startNewInternalChat: false,
    confirmTerminateChat: false
  });

  const [scrolling, setScrolling] = useState(false);

  const [contactDetails, setContactDetails] = useState({
    user: null,
    position: {
      x: 0,
      y: 0
    }
  });

  const sortedContacts = useSortedContacts(contacts);
  const messages = useSortedMessages(contacts, activeChat);
  const previousMessages = usePrevious(messages);

  const unreadMessages = sortedContacts.reduce((acc, curr) => acc + curr.totalUnread, 0)
  const prevUnreadMessages = usePrevious(unreadMessages)

  const assignRef = (index, element) => {
    contactRefs.current[index] = element;
  };

  const toggleShowMessageModal = React.useCallback(() => {
    setShowModals(prevState => {
      return {
        ...prevState,
        startNewInternalChat: !prevState.startNewInternalChat
      }
    });
  }, [])

  const toggleShowConfirmTerminateChat = useCallback(() => {
    if (activeChat.isLoading) return;
    setShowModals(prevState => {
      return {
        ...prevState,
        confirmTerminateChat: !prevState.confirmTerminateChat
      }
    });
  }, [])

  const handleOpenChat = (index, user) => {
    /** É necessário enviar a posição do botão
     *  para que a janela de chat seja aberta
     *  no local correto
     */

    const element = contactRefs.current[index];
    const rect = element.getBoundingClientRect();

    dispatchChatActions(ACTIVE_INTERNAL_CHAT, {
      user: user.id === activeChat.user?.id ? null : user,
      position: {
        x: rect.left,
        y: rect.top,
        height: rect.height,
      }
    })
  }

  const handleMouseEnter = useCallback((index, user) => {
    const element = contactRefs.current[index];
    const rect = element.getBoundingClientRect();

    setContactDetails({
      user: user,
      position: {
        y: rect.top,
      }
    })
  }, [])

  const handleMouseLeave = (index) => {
    setContactDetails({
      user: null,
      position: {
        x: 0,
        y: 0,
        height: 0
      }
    })
  }


  const handleTerminateUserChat = useCallback(() => {
    setShowModals(prevState => {
      return {
        ...prevState,
        confirmTerminateChat: !prevState.confirmTerminateChat
      }
    });
  }, [])

  const handleConfirmTerminateUserChat = useCallback(() => {
    dispatchChatActions(TERMINATE_INTERNAL_CHAT)
  }, [])

  const handleCloseChatWindow = useCallback(() => {
    dispatchChatActions(ACTIVE_INTERNAL_CHAT, {
      user: null,
      position: {
        x: 0,
        y: 0
      }
    })
  }, [])


  const browserRequestNotification = (data) => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      browserNotification(data)
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          browserNotification(data)
          // …
        }
      });
    }
  }

  const browserNotification = (data) => {
    const options = {
      body: `Nova mensagem de chat interno`,
      icon: "/images/symbol-omnyve-500x500.jpeg",
      image: "/images/symbol-omnyve-500x500.jpeg",
      title: `Seus colegas estão tentando falar com você`,
      dir: "ltr",
      tag: data,
      data: data,
      silent: false,
      sound: notificationSound,
      requireInteraction: true
    };

    notification = new Notification("Omnyve", options);

    notification.onclick = (e) => {
      e.preventDefault();
      window.focus();
      notification.close();
    }

    
  }

  useEffect(() => {
    setScrolling(false);
    if (activeChat.user && (scrolling || isResizing)) {
      dispatchChatActions(ACTIVE_INTERNAL_CHAT, {
        user: null,
        position: {
          x: 0,
          y: 0
        }
      })
    }
  }, [activeChat.user, scrolling, isResizing])

  useEffect(() => {
    if (!activeChat.user && modals.confirmTerminateChat) {
      setShowModals({
        ...modals,
        confirmTerminateChat: false
      })
    }
  }, [activeChat.user, modals.confirmTerminateChat]);

  /** Quando a janela do chat estiver fechada OU
   *  A janela do chat estiver aberta && 
   *  o usuário estiver em outra aba ou com o navegador fechado
   *  e receber uma nova mensagem, o título da página será alterado
   *  e o usuário receberá uma notificação sonora
   */
  useEffect(() => {
    if (!isTabVisible &&  ((unreadMessages > prevUnreadMessages) && unreadMessages > 0) ||
                          (messages?.length > previousMessages?.length)) {
      setNewMessagesReceived(prevState => prevState + 1)
    }

    if (isTabVisible) {
      setNewMessagesReceived(0)
      handleResetTitle()
    }

  }, [isTabVisible, prevUnreadMessages, unreadMessages, messages, previousMessages])


  useEffect(() => {
    if (newMessagesReceived > 0) {
      browserRequestNotification(newMessagesReceived)
      handleChangeTitle(`${newMessagesReceived} recebida(s) no chat interno`)
      notificationSound.play();
    }
  }, [newMessagesReceived])

  return (
    <>
      <div 
        className="internal-chat-ui">
        <div className="internal-chat-header">
          Interno
        </div>
        <div className="internal-chat-body">
          <div className="internal-chat-body-content">
            <BeautyScrollbar
              onScroll={e => setScrolling(e)}
              className="internal-chat-contacts">
              {sortedContacts
                .map((user, index) => {
                  const { dark, light } = user.color;
                  return (
                    <div key={index}
                      ref={element => assignRef(index, element)}
                      onMouseEnter={e => handleMouseEnter(index, user)}
                      onMouseLeave={e => handleMouseLeave(index, user)}
                      onClick={e => handleOpenChat(index, user)}
                      className={`internal-chat-body-contact ${activeChat?.user?.id === user.id ? 'selected' : ''}`}>
                      <Avatar
                        name={user.name}
                        size="lg"
                        style={{ backgroundColor: light, color: dark }}
                        status={<UserStatus.Marker status={userStatus && userStatus[user.userId] ? userStatus[user.userId] : null} />} 
                        />
                      {user.isTyping ?
                        <div className="internal-chat-is-typing">
                          <TypingIndicator />
                        </div> :
                        <Badge count={user?.totalUnread} max={99} />
                      }
                    </div>)
                })}
            </BeautyScrollbar>
          </div>
        </div>
        <div className="internal-chat-footer">
          <button className="internal-chat-start-new" onClick={toggleShowMessageModal}>
            <Plus size={18} />
          </button>
        </div>
      </div>
      <ErrorBoundary
        render={(error, info, onReset) => (
          <div className="internal-chat-error"></div>
        )}>
        <SmartRendering
          componentWillRender={modals.startNewInternalChat}
        >
          <StartNewInternalChatModal
            show={modals.startNewInternalChat}
            toggle={toggleShowMessageModal}
          />
        </SmartRendering>
      </ErrorBoundary>
      <ErrorBoundary>
        <SmartRendering
          componentWillRender={contactDetails?.user}
        >
          <ContactDetails
            user={activeChat?.user?.id === contactDetails?.user?.id ? null : contactDetails.user}
            position={contactDetails.position} />
        </SmartRendering>
      </ErrorBoundary>
      <ErrorBoundary>
        <SmartRendering
          componentWillRender={activeChat?.user}
        >
          <ChatWindow
            showChatWindow={activeChat}
            user={activeChat.user}
            position={activeChat.position}
            terminateUserChat={handleTerminateUserChat}
            closeChatWindow={handleCloseChatWindow}
          />
        </SmartRendering>
      </ErrorBoundary>
      <ErrorBoundary>
        <SmartRendering
          componentWillRender={activeChat?.user}
        >
          <DeleteModalConfirmation
            show={modals.confirmTerminateChat}
            toggle={toggleShowConfirmTerminateChat}
            handleConfirm={handleConfirmTerminateUserChat}
            activeChat={activeChat}
          />
        </SmartRendering>
      </ErrorBoundary>
    </>
  )
};

export default InternalChat;