import mock from "./mock"
import "./apps/dataView"
import "./tables/aggrid"
import "./autoComplete/autoComplete"
import "./navbar/navbarSearch"
import "./navbar/navbarBookmarkSearch"
import "./auth/authentication"
import "./apps/email"
import "./apps/chat"
import "./apps/todo"
import "./apps/calendar"
import "./apps/settingsUsers"
import "./apps/settingsChannels"
import "./apps/settingsClients"
import "./apps/groupRulesChannels"
import "./apps/groupReadyMessages"
import "./apps/groupChatScripts"
import "./apps/groupWorkTimes"
import "./apps/groupTimeLimits"
import "./apps/groupMessageOutOfTime"
import "./apps/groupRouting"
import "./apps/userList"
mock.onAny().passThrough()
