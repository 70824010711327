export function camelCaseToPascalCase(obj) {
    if (Array.isArray(obj)) {
      return obj.map(camelCaseToPascalCase);
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        acc[newKey] = camelCaseToPascalCase(obj[key]);
        return acc;
      }, {});
    } else {
      return obj;
    }
  }