import React, { ReactNode, MouseEvent } from "react";
import classNames from "classnames";
import { Spinner, Badge } from "reactstrap";

import "./command-bar.scss";

interface CommandBarProps {
  children: ReactNode;
  className?: string;
  vertical?: boolean;
  options?: {
    descriptionPosition?: string;
    badgePosition?: string;
  };
  size?: string;
}

interface CommandBarGroupProps {
  children: ReactNode;
}

const CommandBar: React.FC<CommandBarProps> & {
  Group: React.FC<CommandBarGroupProps>;
  Item: React.FC<CommandBarItemProps>;
} = ({ children, className, vertical, options, size = "default" }) => {
  return (
    <div
      className={`command-bar-ui ${className ? className : ""} 
      ${size} 
      ${options?.descriptionPosition ? `description-${options.descriptionPosition}` : ""} 
      ${options?.badgePosition ? `badge-${options.badgePosition}` : ""} 
      ${vertical ? "vertical" : ""}`}
    >
      {children}
    </div>
  );
};

interface CommandBarItemProps {
  children: ReactNode;
  active?: boolean;
  color?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  dark?: boolean;
  description?: string | null;
  disabled?: boolean;
  loading?: boolean;
  badge?: string;
  transparentBackground?: boolean;
}

CommandBar.Item = ({
  children,
  active,
  color,
  onClick,
  dark,
  description,
  disabled,
  loading,
  badge,
  transparentBackground = false,
}) => {
  return (
    <div
      className={`command-bar-item 
      ${active ? 'active' : ''}
      ${disabled ? "disabled" : ""} 
      ${transparentBackground ? "transparent-background" : ""} 
      ${active ? `ui-${color || "default"}` : ""} ${dark ? "ui-dark" : ""}`}
      onClick={disabled ? () => {} : onClick}
    >
      {loading ? <Spinner size="sm" /> : children}
      {description && <span className="command-bar-item-description">{description}</span>}
      {badge && <Badge color="danger" pill>{badge}</Badge>}
    </div>
  );
};

CommandBar.Group = ({ children }) => {
  return <div className="command-bar-group">{children}</div>;
};

export default CommandBar;
