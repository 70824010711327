import { call, put, takeEvery, select, delay } from 'redux-saga/effects';

import { SAGA_ERROR } from '../../../helpers/constants';
import { SEND_INTERNAL_CHAT_MESSAGE } from '../../../helpers/internalChatConstants';
import { internalChatMessageObject } from '../utils/internalChatMessageObject';

import {
    addTemporaryMessage,
    removeMessageFromList,
    sendMessageRequest,
    updateMessageStatus
} from '../utils/internalChatFunctions/sendInternalChatMessageFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getContacts = state => state.internalChat.contacts;
const getActiveChat = state => state.internalChat.activeChat;

function* sendInternalChatMessage(action) {
    
    const contacts = yield select(getContacts);
    const activeChat = yield select(getActiveChat);

    const message = internalChatMessageObject(activeChat.user, action.data);
    console.log(message)
    try {
    /** Add a temporary message */
    yield put(addTemporaryMessage(contacts, message));

    /** Remove the message of the list messaging state object */
    yield put(removeMessageFromList());

    /** Make a POST resquest to store de message in the database */
    const { data, response } = yield call(sendMessageRequest, message, activeChat);

    if (data) {
        /** Update message based on the POST response */
        const updatedContacts = yield select(getContacts);
        yield put(updateMessageStatus(updatedContacts, data));
    } else {
        const updatedContacts = yield select(getContacts);
        let errorMessage = message
        errorMessage.messageStatus = 3

        yield put(updateMessageStatus(updatedContacts, errorMessage));

        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.backend,
            errorType: errorTypes.error,
            response: response
        });
    }


    } catch (e) {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });

    }
}

export default function* sendInternalChatMessageSaga() {
    yield takeEvery(SEND_INTERNAL_CHAT_MESSAGE, sendInternalChatMessage);
}