import React, { useState } from 'react';
import { Minus, MoreVertical, X } from "react-feather";
import { useSelector } from 'react-redux';
import { useIntl } from "react-intl";


import Avatar from "../../../../avatar/Avatar";
import { ContextMenu, ContextMenuButton } from '../../../../contextMenu';
import { Heading } from "../../../../typography";
import UserStatus from "../../../../userStatus/UserStatus";

import TypingIndicator from "../../typingIndicator/TypingIndicator";

import {
    REMOVE_CONTACT_FROM_LIST,
} from "../../../../../helpers/internalChatConstants";

import useChatDispatchActions from '../../../../../customHooks/useChatDispatchActions';

import "./header.scss";
import CommandBar from '../../../../commandBar/CommandBar';

const Header = ({
    user,
    isFinished,
    terminateUserChat,
    closeChatWindow
}) => {

    const intl = useIntl();

    const activeChat = useSelector(state => state.internalChat.activeChat);
    const contacts = useSelector(state => state.internalChat.contacts);
    const userStatus = useSelector(state => state.internalChat.userStatus.byUserId)[user?.userId] || null;
    const dispatchChatActions = useChatDispatchActions()

    const [windowMenu, showWindowMenu] = useState(null);

    const handleShowMenu = (e, item, type) => {
        showWindowMenu({
            ...e,
            item: item
        })
    }

    const isTyping = React.useMemo(() => {
        return contacts.find(contact => contact.id === activeChat?.user?.id)?.isTyping || false;
    }, [contacts, activeChat]);

    const handleCloseMenu = () => {
        showWindowMenu(null)
    }

    return (
        <>
            <div className="chat-window-header-ui">
                <div className="chat-window-header-user">
                    <Avatar
                        name={user?.name}
                        size="lg"
                        style={{ backgroundColor: user?.color?.light, color: user?.color?.dark }}
                        status={userStatus && <UserStatus.Marker status={userStatus} />}
                    />
                    <div className="chat-window-header-user-info">
                        <Heading type="h6" fw={600} color="primary">{user?.name}</Heading>
                        {isTyping ?
                            <span className="chat-window-header-is-typing"> {intl.formatMessage({ id: "digitando" })} <TypingIndicator /></span> :
                            <span>{userStatus && <UserStatus.Label status={userStatus} />}</span>
                        }
                    </div>
                </div>
                <CommandBar size="sm" className="message-options-button">
                    <CommandBar.Group>
                        <CommandBar.Item
                            color="warning"
                            description="Minimizar"
                            active={true}
                            badge={null}
                            onClick={closeChatWindow}>
                            <Minus size={12} />
                        </CommandBar.Item>
                        <CommandBar.Item
                            color="danger"
                            description="Finalizar"
                            active={true}
                            badge={null}
                            onClick={isFinished ?
                                () => dispatchChatActions(REMOVE_CONTACT_FROM_LIST) :
                                terminateUserChat}>
                            <X size={12} />
                        </CommandBar.Item>
                    </CommandBar.Group>
                </CommandBar>
            </div>
        </>
    )
}

export default Header