import React, { FC, HTMLProps } from "react";
import "./overlay.scss";

interface OverlayProps extends HTMLProps<HTMLDivElement> {
  show: boolean;
  zIndex?: number;
  isFixed?: boolean;
}

const Overlay: FC<OverlayProps> = ({
  show,
  onClick,
  zIndex,
  isFixed = false,
  ...rest
}) => {
  return (
    <div
      data-testid="overlay"
      className={`overlay-ui ${show ? "show" : ""} ${isFixed ? "fixed" : ""}`}
      onClick={onClick}
      style={zIndex ? { zIndex: zIndex } : undefined}
      {...rest}
    />
  );
};

export default Overlay;
