import React, { useEffect, useRef, useState, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";

import { calculateElementPosition } from "../../../../utils/calculateElementPosition";

import {
    UPDATE_INTERNAL_CHAT_MESSAGING_INFO,
} from "../../../../helpers/internalChatConstants";

import useChatDispatchActions from '../../../../customHooks/useChatDispatchActions';
import useResizableWindow from "../../../../customHooks/useResizableWindow";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Body from "./components/Body";

import { setWindowHierarchy } from "../../../../redux/actions/systemConfig/systemConfig";

import "./chat-window.scss";

const minWindowSize = {
    width: 330,
    height: 400
}

const ChatWindow = ({ position, user, closeChatWindow, terminateUserChat }) => {

    const chatWindowRef = useRef(null);

    const resizeHandles = {
        left: useRef(),
        bottom: useRef()
    }

    const dispatchChatActions = useChatDispatchActions()
    const dispatch = useDispatch();


    const contacts = useSelector(state => state.internalChat.contacts);
    const activeChat= useSelector(state => state.internalChat.activeChat);
    const windowHierarchy = useSelector(state => state.systemConfig.windowHierarchy);
    const activeChatUserId = activeChat?.user?.userId || null;

    const [windowPosition, setWindowPosition] = useState({
        x: 0,
        y: 0,
        alignment: null
    })

    const { windowWidth, windowHeight, onPositionChangeHandler } = useResizableWindow({
        windowSize: localStorage.getItem("internalChatWindowSize") ? JSON.parse(localStorage.getItem("internalChatWindowSize")) : null,
        minWindowSize,
        resizeHandles,
        windowRef: chatWindowRef,
    });

    const [messageText, setMessageText] = useState("");

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);


    const isFinished = React.useMemo(() => {
        return contacts.find(contact => contact.id === activeChat?.user?.id)?.isFinished || false;
    }, [contacts, activeChat]);

    const handlePriorizeWindow = useCallback(() => {
        if(windowHierarchy !== 'internal-chat') dispatch(setWindowHierarchy('internal-chat'));
    }, [windowHierarchy])


    useEffect(() => {
        // if (activeChat) {
        //     if(activeChat?.user?.id){
        //         dispatch(setWindowHierarchy('internal-chat'))
        //     } else {
        //         dispatch(setWindowHierarchy(null))
        //     }
        // } else {
        //     setWindowPosition({
        //         x: 0,
        //         y: 0,
        //         alignment: null
        //     })
        // }
        console.log(activeChatUserId)
        if(activeChatUserId){
            dispatch(setWindowHierarchy('internal-chat'))
        } else {
            dispatch(setWindowHierarchy(null))
            setWindowPosition({
                x: 0,
                y: 0,
                alignment: null
            })
        }
    }, [activeChatUserId])

    const handleWindoPosition = (width, height) => {
        return calculateElementPosition({
            containerWidth: width,
            containerHeight: height,
            x: position.x,
            y: position.y
        });

    }

    useEffect(() => {
        if (activeChat) {
            const delayDebounceFn = setTimeout(() => {
                dispatchChatActions(UPDATE_INTERNAL_CHAT_MESSAGING_INFO, messageText)
            }, 150)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [messageText])

    useEffect(() => {
        const { width, height } = chatWindowRef.current.getBoundingClientRect();
        const { top, left, alignment } = handleWindoPosition(width, height);

        setWindowPosition({
            x: left - 15,
            y: alignment.y === "top" ? top - 7 : top + position.height + 7,
            alignment: alignment.y
        });
        onPositionChangeHandler()
    }, [position]);

    useEffect(() => {
        const { top, left, alignment } = handleWindoPosition(windowWidth, windowHeight);

        setWindowPosition({
            x: left - 15,
            y: alignment.y === "top" ? top - 7 : top + position.height + 7,
            alignment: alignment.y
        });

        localStorage.setItem("internalChatWindowSize", JSON.stringify({
            width: windowWidth,
            height: windowHeight
        }))

    }, [windowWidth, windowHeight])

    return (
        <div
            ref={chatWindowRef}
            onClick={handlePriorizeWindow}
            className={`chat-window-ui ${user && (windowPosition.y > 0 || windowPosition.x > 0) ? "show" : ""} ${windowPosition.alignment || ""}`}
            style={{
                top: /*windowPosition.y*/ 25,
                left: windowPosition.x,
                width: windowWidth,
                height: windowHeight,
            }}>

            <div ref={resizeHandles.left} className="left-handler" />
            <div ref={resizeHandles.bottom} className="bottom-handler" />
            <Header 
                user={user} 
                isFinished={isFinished} 
                terminateUserChat={terminateUserChat} 
                closeChatWindow={closeChatWindow} />
            
            <Body
                setMessageText={setMessageText}
                setShowEmojiPicker={setShowEmojiPicker}
                showEmojiPicker={showEmojiPicker} />

            <Footer
            isFinished={isFinished}
            showEmojiPicker={showEmojiPicker}
            setShowEmojiPicker={setShowEmojiPicker}
            user={user}
            messageText={messageText}
            setMessageText={setMessageText}
            />
        </div>
    );
}

export default React.memo(ChatWindow);