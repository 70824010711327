import React from "react";
import { X } from "react-feather";
import classnames from "classnames";
import BodyCard from "../bodyCard/BodyCard";
import "./modal.scss";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
  }

  handleOpenModal = () => {
    this.props.toggle();
  };

  componentDidMount = () => {};

  componentWillUnmount() {
    window.removeEventListener("keydown", this.pressedKeyboardKey);
  }

  componentDidUpdate = (prevProps) => {
    const prevShow = prevProps.show;
    const currShow = this.props.show;
    if (prevShow !== currShow) {
      if (currShow) window.addEventListener("keydown", this.pressedKeyboardKey);
      else window.removeEventListener("keydown", this.pressedKeyboardKey);
    }
  };

  pressedKeyboardKey = (key) => {
    if (key?.code?.toLowerCase() === "escape" || key?.keyCode === 27) {
      this.props.toggle();
    }

    if ((key?.code?.toLowerCase() === "enter" || key?.keyCode === 13) && this?.props?.onPressEnter) {
      this.props.onPressEnter();
    }
  };

  render() {
    const {
      show,
      position,
      size,
      className,
      style,
      color="default",
      id,
      removeShadowOnClick,
      hideCloseButton,
      overflowVisible,
      testid = "modal-window-ui",
    } = this.props;
    return (
      <div
        className={`modal-window-container ${show ? "show" : ""}  ${position} ${
          overflowVisible ? "overflow-visible" : ""
        }`}
        style={style ? style : {}}
        data-testid={testid}
      >
        <div
          className={`modal-window-overlay ${show ? "show" : ""}`}
          onClick={!removeShadowOnClick ? this.handleOpenModal : null}
        />
        <div
          className={classnames(
            `modal-window ${color} ${show ? "show" : ""} ${
              className ? className : ""
            }`,
            {
              top: position === "top",
              center: position === "center",
              bottom: position === "bottom",
            },
            {
              "window-mn": size === "mn",
              "window-xs": size === "xs",
              "window-sm": size === "sm",
              "window-md": size === "md",
              "window-lg": size === "lg",
              "window-xl": size === "xl",
              "window-mx": size === "mx",
            }
          )}
          id={id ?? "modal-window"}
        >
          <BodyCard>{this.props.children}</BodyCard>
          {!hideCloseButton && (
            <div className="ct-close" onClick={this.handleOpenModal}>
              <X />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Modal.Modal = undefined;

Modal.Header = ({ children }) => {
  return <BodyCard.Header>{children}</BodyCard.Header>;
};

Modal.Content = ({ children, className = "", disableScroll = false }) => {
  return disableScroll ? (
    <div className={`modal-without-scroll ${className}`}>{children}</div>
  ) : (
    <BodyCard.Content className={className}>{children}</BodyCard.Content>
  );
};

Modal.Footer = ({ children }) => {
  return <BodyCard.Footer>{children}</BodyCard.Footer>;
};

export default Modal;
