import moment from "moment-timezone";
import { userSocketLog } from "../../../utils/saveLocalData";

const initialState = {
  chatsContacts: [],
  statistics: [],
  userProfile: [],
  messages: [],
  colleaguesStatus: null,
  isLoadingChats: true,
  currentPage: null,
  nextPage: null,
  totalPages: 0,
};

const newChats = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case "NEW_CREATE_CHAT_CONTACT": {
      console.log(action);
      return {
        ...state,
        chatsContacts: [
          ...state.chatsContacts,
          {
            ...action.contact,
            totalUnread: action.increase ? +1 : action.contact.totalUnread,
            opened: false,
          },
        ],
      };
    }
    case "CLOSE_PROTOCOL_BY_USER": {
      console.log(action)
      return {
        ...state,
        chatsContacts: state.chatsContacts.map((m) => {
          if (
            m.customerId === action.contact.CustomerId &&
            m.serviceProtocolId === action.contact.ServiceProtocolId
          ) {
            m.isChatWebClosedByUser = action.contact.IsClosed;
          }
          return m;
        }),
      };
      // const requestCloseProtocol = state.chatsContacts.filter((contact) => {
      //   if(contact.customerId !== action.contact.CustomerId && contact.serviceProtocolId !== action.contact.ServiceProtocolId) {
      //     console.warn(contact)
      //     contact.isChatWebClosedByUser = action.contact.IsClosed;
      //   }
      //   return contact
      // });
      // return { ...state, chatsContacts: requestCloseProtocol };
    }
    case "NEW_UPDATE_CHAT_CONTACT": {
      return {
        ...state,
        chatsContacts: state.chatsContacts.map((m) => {
          if (
            m.customerId === action.contact.customerId &&
            m.serviceProtocolId === action.contact.serviceProtocolId
          ) {
            m.profileImage = action.contact.profileImage;
            m.originGroup = action.contact.originGroup;
            m.originGroupId = action.contact.originGroupId;
            m.customerName = action.contact.customerName;
            m.lastAttachment = action.contact.lastAttachment;
            m.protocol = action.contact.protocol;
            m.lastMessage = action.contact.lastMessage;
            m.totalUnread = action.increase ? m.totalUnread + 1 : m.totalUnread;
            m.dateLastMessage = action.contact.dateLastMessage;
            m.serviceChannelId = action.contact.serviceChannelId;
            m.serviceProtocolId = action.contact.serviceProtocolId;
            m.lastMessageId = m.opened ? action.contact.lastMessageId : 0;
            m.transferServiceDetail = m.transferServiceDetail
              ? m.transferServiceDetail
              : action.contact.transferServiceDetail;
            m.hasButtonByProtocol = m.hasButtonByProtocol
              ? m.hasButtonByProtocol
              : action.contact.hasButtonByProtocol;
            m.button = action.contact.button;
            m.isBroadcastList = action.contact.isBroadcastList;
            m.isTemplate =
              action.contact.isTemplate &&
              !action.contact.isCustomerReplyAfterTemplate;
            m.messageStatusType = action.contact.messageStatusType;
            // m.isTemplateSent = action.contact.isTemplateSent //NÃO ESTÁ SENDO USADO
            // m.isCustomerReplyAfterTemplate = action.contact.isCustomerReplyAfterTemplate //NÃO ESTÁ SENDO USADO
          }
          return m;
        }),
      };
    }
    case "NEW_UPDATE_CONTACT_AT_CHAT_LIST": {
      return {
        ...state,
        chatsContacts: state.chatsContacts.map((contact) => {
          if (
            contact.customerId === action.contact.customerId &&
            contact.serviceProtocolId === action.contact.serviceProtocolId
          ) {
            contact.activeMode = false;
          }
          return contact;
        }),
      };
    }
    case "NEW_UPDATE_CHAT_GROUP": {
      return {
        ...state,
        chatsContacts: state.chatsContacts.map((m) => {
          if (m.groupWhatsId === action.group.groupWhatsId) {
            /** Log para tentar debugar o problema reportado no ticket 21406 */
            userSocketLog("NEW_UPDATE_CHAT_GROUP", {
              messageId: action.group.lastMessageId,
              actionGroupId: action.group.groupWhatsId,
              mapGroupId: m.groupWhatsId,
              receivedAction: action,
              stateContacts: state.chatsContacts,
              date: new Date(),
            });
            /** Log para tentar debugar o problema reportado no ticket 21406 */

            m.profileImage = action.group.profileImage;
            m.originGroup = action.group.originGroup;
            m.originGroupId = action.group.originGroupId;
            m.customerName = action.group.customerName;
            m.groupName = action.group.groupName;
            m.lastAttachment = action.group.lastAttachment;
            m.protocol = action.group.protocol;
            m.lastMessage = action.group.lastMessage;
            m.totalUnread = action.increase ? m.totalUnread + 1 : m.totalUnread;
            m.dateLastMessage = action.group.dateLastMessage;
            m.lastMessageId = action.group.lastMessageId;
            m.messageStatusType = action?.group?.messageStatusType ?? null;
          }
          return m;
        }),
      };
    }
    case "NEW_ACTIVE_MODE_CHAT": {
      return {
        ...state,
        chatsContacts: [...state.chatsContacts, { ...action.contact }],
      };
    }
    case "NEW_MARK_CHAT_AS_OPENED": {
      return {
        ...state,
        chatsContacts: state.chatsContacts.map((m) => {
          if (action.chat.isGroup && m.groupWhatsId === action.chat.id) {
            m.opened = true;
            m.totalUnread = 0;
          } else if (
            !action.chat.isGroup &&
            m.customerId === action.chat.id &&
            m.serviceProtocolId === action.chat.protocolId
          ) {
            m.opened = true;
            m.totalUnread = 0;
          }
          return m;
        }),
      };
    }
    case "NEW_MESSAGE_SIDEBAR_DELETE": {
      return {
        ...state,
        chatsContacts: state.chatsContacts.map((m) => {
          if (
            action.message.mustDeleteLastMessage &&
            m.lastMessageId === action.message.lastMessageId
          ) {
            m.lastMessage = "";
          }
          return m;
        }),
      };
    }

    case "NEW_MESSAGE_SIDEBAR_EDITED":
      return {
        ...state,
        chatsContacts: state.chatsContacts.map((m) => {
          if (
            action.message.IsUpdatedSucess &&
            m.lastMessageId === action.message.MessageOmnyveId
          ){
            m.lastMessage = action.message.NewMessageText;
          }
          return m;
        }),
      };

    case "LOADING_CHATS_START":
      return {
        ...state,
        isLoadingChats: true,
      };

    case "CHATS":
      return {
        ...state,
        chatsContacts: [...state.chatsContacts, ...action.chatsContacts],
        statistics: action.statistics,
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        nextPage: action.nextPage,
        isLoadingChats: false,
      };

    case "LOADING_CHATS_END":
      return {
        ...state,
        isLoadingChats: false,
      };

    case "CLEAN_CHAT_MESSAGES":
      let cleanMessages = state.chatsContacts.find((i) =>
        action.activeChat && action.activeChat.groupWhatsId
          ? i.groupWhatsId === action.activeChat.groupWhatsId
          : i.customerId === action.activeChat.customerId
      );
      cleanMessages.messages = [];
      return { ...state };
    case "CHAT_MESSAGES":
      if (
        action.activeChat &&
        action.activeChat.activeMode &&
        !action.activeChat.serviceProtocolId
      ) {
        const newContactObject = state.chatsContacts.map((m) => {
          if (m.customerId === action.activeChat.customerId) {
            m.messages =
              action.direction === "down"
                ? [...m.messages, ...action.messages]
                : [...action.messages, ...m.messages];
            m.Offset = m.Offset + action.messages.length;
            m.noMore = action.messages.length === 0 ? true : false;
            //fetchMessages.noMore = fetchMessages.PerPage > action.messages.length || action.messages.length === 0 ? true : false
            m.LastId =
              m.opened &&
              action.messages &&
              action.messages[0] &&
              action.messages[0].id
                ? action.messages[0].id
                : 0;
          }
          return m;
        });
        return { ...state, chatsContacts: newContactObject };
      } else {
        let fetchMessages = state.chatsContacts.find((i) =>
          action.activeChat && action.activeChat.groupWhatsId
            ? i.groupWhatsId === action.activeChat.groupWhatsId
            : i.customerId === action.activeChat.customerId &&
              i.serviceProtocolId === action.activeChat.serviceProtocolId
        );
        fetchMessages.messages =
          action.direction === "down"
            ? [...fetchMessages.messages, ...action.messages]
            : [...action.messages, ...fetchMessages.messages];
        fetchMessages.Offset = fetchMessages.Offset + action.messages.length;
        fetchMessages.noMore = action.messages.length === 0 ? true : false;
        //fetchMessages.noMore = fetchMessages.PerPage > action.messages.length || action.messages.length === 0 ? true : false
        fetchMessages.LastId =
          fetchMessages.opened &&
          action.messages &&
          action.messages[0] &&
          action.messages[0].id
            ? action.messages[0].id
            : 0;

        return { ...state };
      }
    case "ADD_GROUP_WHATS_PARTICIPANTS":
      let addGroupChatParticipants = state.chatsContacts.find(
        (i) => i.groupWhatsId === action.groupWhats.id
      );
      addGroupChatParticipants.participants = [
        ...action.groupWhats.participants,
        ...addGroupChatParticipants.participants,
      ];
      return { ...state };
    case "ADD_CHAT_MESSAGE":
      //** Implementação de regra/condicional para quando for modo ativo (não há número de protocolo, nessa caso)*/
      const isGroup =
        action.message &&
        action.message[0] &&
        action.message[0].messageMemberGroup &&
        action.message[0].messageMemberGroup.GroupWhatsId
          ? true
          : false;
      // const isActiveMode = action.message && action.message[0] && action.message[0].temporaryMessageId && !action.message[0].serviceProtocolId
      state.chatsContacts.map((item) => {
        if (!item.opened) {
          return item;
        } else if (
          isGroup &&
          item.groupWhatsId ===
            action.message[0].messageMemberGroup.GroupWhatsId
        ) {
          item.messages = [...item.messages, ...action.message];
        } else if (
          !isGroup &&
          item.customerId === action.message[0].CustomerId &&
          item.serviceProtocolId === action.message[0].serviceProtocolId
        ) {
          // console.warn(item)
          item.messages = [...item.messages, ...action.message];
          item.Offset = item.opened ? item.Offset + 1 : 0;
          item.LastId = item.opened ? action.message[0].id : 0;
          item.isBroadcastList = action.message[0].isBroadcastList;
          item.lastMessage = action.message[0].Message;
          item.dateLastMessage = action.message[0].Received;
          item.isTemplate = action.message[0].isTemplate;
        }

        return item;
      });
      return { ...state };
    case "UPDATE_CHAT_MESSAGE":
      /** Atualiza número do protocolo no contato e as informações da mensagem do contato */
      state.chatsContacts.map((item) => {
        const isGroup = action.activeChat && action.activeChat.groupWhatsId;
        if (isGroup) {
        } else {
          if (
            item.customerId === action.message[0].CustomerId &&
            !item.serviceProtocolId
          ) {
            item.protocol = action.response.protocol;
            item.serviceProtocolId = action.response.serviceProtocolId;
            item.isTemplate = action.response.isTemplate;
          }
        }
        item.messages = item.messages.map((m) => {
          if (m.temporaryMessageId === action.message[0].temporaryMessageId) {
            m.id = action.response.id;
            m.delivered = false;
            m.Received = action.response.createdAt;
            m.messageStatusType =
              action.response.messageStatusType > m.messageStatusType
                ? action.response.messageStatusType
                : m.messageStatusType;
            m.protocol = action.response.protocol;
            m.protocolId = action.response.serviceProtocolId;
            m.serviceProtocolId = action.response.serviceProtocolId;
            m.isTemplate = action.response.isTemplate;
          }
          return m;
        });
        return item;
      });
      return { ...state };
    case "REMOVE_TEMPORARY_CHATS":
      let removeTemporaryChats = state.chatsContacts.filter(
        (chat) => chat.customerId !== action.customerId
      );
      return {
        ...state,
        chatsContacts: removeTemporaryChats,
        activeChat: null,
      };
    case "DELIVERED_CHAT_MESSAGE":
      let delivered = state.chatsContacts.find((i) =>
        action.notifiedMessage &&
        action.notifiedMessage.messageMemberGroup &&
        action.notifiedMessage.messageMemberGroup.GroupWhatsId
          ? i.groupWhatsId ===
            action.notifiedMessage.messageMemberGroup.GroupWhatsId
          : i.customerId === action.notifiedMessage.CustomerId &&
            i.serviceProtocolId === action.notifiedMessage.serviceProtocolId
      );
      let deliveredMessage = delivered?.messages
        .map((m) => {
          return m;
        })
        .find(
          (m) =>
            m.id === action.notifiedMessage.id ||
            (m.temporaryMessageId &&
              m.temporaryMessageId ===
                action.notifiedMessage.temporaryMessageId)
        );
      if (typeof deliveredMessage !== "undefined") {
        console.log(
          "------------ ATUALIZA A MENSAGEM ENVIADA PELO AGENTE ------------"
        );
        deliveredMessage.delivered = true;
        deliveredMessage.id = action.notifiedMessage.id;
        deliveredMessage.MessageId = action.notifiedMessage.MessageId;
        deliveredMessage.CustomerId = action.notifiedMessage.customerId;
        deliveredMessage.CustomerName = action.notifiedMessage.CustomerName;
        deliveredMessage.Message = action.notifiedMessage.Message;
        deliveredMessage.origin = action.notifiedMessage.origin;
        deliveredMessage.destination = action.notifiedMessage.destination;
        deliveredMessage.Received = action.notifiedMessage.Received;
        deliveredMessage.ReplyMessageId = action.notifiedMessage.ReplyMessageId;
        deliveredMessage.UserProfile = action.notifiedMessage.UserProfile;
        deliveredMessage.ServiceChannelType =
          action.notifiedMessage.ServiceChannelType;
        deliveredMessage.userSenderId = action.notifiedMessage.userSenderId;
        deliveredMessage.userSender = action.notifiedMessage.userSender;
        deliveredMessage.isSent = action.notifiedMessage.isSent;
        deliveredMessage.messageStatusType =
          action.notifiedMessage.messageStatusType;
        deliveredMessage.serviceProtocolId =
          action.notifiedMessage.serviceProtocolId;
        deliveredMessage.delivered = action.notifiedMessage.delivered;
        deliveredMessage.attachments = action.notifiedMessage.attachments;
        deliveredMessage.replyMessage = action.notifiedMessage.replyMessage;
        deliveredMessage.dividerDay = action.notifiedMessage.dividerDay;
        deliveredMessage.contacts = action.notifiedMessage.contacts;
        deliveredMessage.spacer = action.notifiedMessage.spacer;
        deliveredMessage.lastAgent = action.notifiedMessage.lastAgent;
        deliveredMessage.serviceChannelId =
          action.notifiedMessage.serviceChannelId;
      } else if (delivered?.opened) {
        console.log(
          "------------ ADICIONA UMA NOVA MENSAGEM ENVIADA PELO AGENTE ------------"
        );
        const newDeliveredMessage = {
          delivered: true,
          id: action.notifiedMessage.id,
          MessageId: action.notifiedMessage.MessageId,
          CustomerId: action.notifiedMessage.customerId,
          CustomerName: action.notifiedMessage.CustomerName,
          Message: action.notifiedMessage.Message,
          origin: action.notifiedMessage.origin,
          destination: action.notifiedMessage.destination,
          Received: action.notifiedMessage.Received,
          ReplyMessageId: action.notifiedMessage.ReplyMessageId,
          UserProfile: action.notifiedMessage.UserProfile,
          ServiceChannelType: action.notifiedMessage.ServiceChannelType,
          userSenderId: action.notifiedMessage.userSenderId,
          isSent: action.notifiedMessage.isSent,
          messageStatusType: action.notifiedMessage.messageStatusType,
          serviceProtocolId: action.notifiedMessage.serviceProtocolId,
          delivered: action.notifiedMessage.delivered,
          attachments: action.notifiedMessage.attachments,
          replyMessage: action.notifiedMessage.replyMessage,
          dividerDay: action.notifiedMessage.dividerDay,
          contacts: action.notifiedMessage.contacts,
          spacer: action.notifiedMessage.spacer,
          userSender: action.notifiedMessage.userSender,
          lastAgent: action.notifiedMessage.lastAgent,
          serviceChannelId: action.notifiedMessage.serviceChannelId,
        };
        delivered.messages = [...delivered.messages, newDeliveredMessage];
        delivered.Offset = delivered.opened ? delivered.messages.length : 0;
        delivered.LastId = delivered.opened ? delivered.messages[0].id : 0;
        //addChatMessage.activeMode = false
      }

      //delivered.activeMode = false;
      return { ...state };
    case "RESENDING_CHAT_MESSAGE":
      let resending = state.chatsContacts
        .find((c) => c.messages.some((item) => item.id === action.id))
        .messages.find((m) => m.id === action.id);
      resending.resending = action.retrying;
      resending.messageStatusType = action.messageStatusType;
      return { ...state };
    case "RETRY_GET_ATTACHMENT":
      let retryAttach = state.chatsContacts
        .find((c) => c.messages.some((item) => item.id === action.id))
        .messages.find((m) => m.id === action.id);
      retryAttach.retrying = action.retrying;
      if (action.attachment) {
        retryAttach.attachments = action.attachment;
      }
      return { ...state };

    case "DELETE_CHAT_MESSAGE":
      let deleteMessage = state.chatsContacts
        .find((c) =>
          c.messages.some((item) => item.MessageId === action.message.MessageId)
        )
        .messages.find((m) => m.MessageId === action.message.MessageId);
      if (action.message.type === "deleting") {
        deleteMessage.deleting = true;
      } else if (action.message.type === "deleted") {
        deleteMessage.isDeleted = true;
        deleteMessage.Message = null;
        deleteMessage.attachments = null;
      } else if (action.message.type === "errorDeleting") {
        deleteMessage.errorDeleting = true;
        deleteMessage.deleting = false;
      } else {
        deleteMessage.deleting = false;
        deleteMessage.errorDeleting = false;
      }
      return { ...state };

      case "UPDATE_CHAT_MESSAGE_TO_EDITED":
        let updateMessageToEdited = state.chatsContacts
          .find((c) =>
            c.messages.some((item) => item.MessageId === action.message.MessageId)
          )
          .messages.find((m) => m.MessageId === action.message.MessageId);
        if (action.message.type === "edited") {
          updateMessageToEdited.isUpdated = true;
          updateMessageToEdited.Message = action.message.MessageData.NewMessageText;
        }
        return { ...state };

    case "LOGGED_USER":
      return { ...state, userProfile: action.loggeduser };
    case "SET_SPECIAL":
      let setSpecial = state.chatsContacts.find(
        (i) => i.customerId === action.customerId
      );
      setSpecial.isSpecialCustomer = action.isSpecial;
      return { ...state };
    case "SET_LAST_MESSAGE":
      console.log(action);
      let setLast = state.chatsContacts.find((i) =>
        action.message.messageMemberGroup &&
        action.message.messageMemberGroup.GroupWhatsId
          ? i.groupWhatsId === action.message.messageMemberGroup.GroupWhatsId
          : i.customerId === action.message.CustomerId &&
            i.serviceProtocolId === action.message.serviceProtocolId
      );
      //let setLast = state.chatsContacts.find(i => i.customerId === 500)
      console.log(
        "Seleciona o usuário da lista para adição de uma nova mensagem"
      );
      console.log(setLast);
      if (typeof setLast !== "undefined") {
        console.log("---- ATUALIZA ÚLTIMA MENSAGEM AGENTE ----");
        //console.log(setLast.messages[0].id)
        //ATUALIZA A ULTIMA MENSAGEM DO CHAT
        setLast.lastMessage = action.message.Message;
        if (action.message.CustomerName) {
          setLast.customerName = action.message.CustomerName;
        }
        setLast.lastMessageSender =
          action.message && action.message.messageParticipants
            ? action.message.messageParticipants.name
            : null;
        setLast.dateLastMessage = action.message.Received;
        setLast.serviceChannelType = action.message.ServiceChannelType;
        setLast.serviceProtocolId = action.message.serviceProtocolId;
        setLast.lastAttachment =
          action && action.message && action.message.attachments.length > 0
            ? action.message.attachments[0]
            : null;
        setLast.protocol = action.message.protocol;
        setLast.messageStatusType = action.message.messageStatusType;
        setLast.originGroup = action.message.originGroup;
        setLast.LastId =
          setLast &&
          setLast.opened &&
          setLast.messages &&
          setLast.messages[0] &&
          setLast.messages[0].id;
        setLast.isTemplate = action.message.isTemplate;
        setLast.profileImage = setLast.profileImage
          ? setLast.profileImage
          : action && action.message && action.message.profileImage
          ? `data:image/png;base64, ${action.message.profileImage}`
          : null;

        //if(!action.message.isSent){
        setLast.activeMode = false;
        //}
        return { ...state };
      } else if (
        !action.message.userSenderId ||
        action.message.userSenderId === 9999
      ) {
        //ADICIONA MAIS UMA MENSAGEM NO CHAT
        console.log("---- ADICIONA NOVO CHAT ----");
        console.log(action);
        const chatsContacts = {
          Offset: 0,
          PerPage: 20,
          customerId:
            /*action.message.messageMemberGroup && action.message.messageMemberGroup.GroupWhatsId ? 0 :*/ action
              .message.CustomerId, //ok
          customerName: action.message.CustomerName, //ok
          customerPhone: action.message.origin, //ok
          dateLastMessage: action.message.Received, //ok
          groupName:
            action.message.messageMemberGroup &&
            action.message.messageMemberGroup.GroupName,
          groupWhatsCode:
            action.message.messageMemberGroup &&
            action.message.messageMemberGroup.GroupWhatsCode,
          groupWhatsId:
            action.message.messageMemberGroup &&
            action.message.messageMemberGroup.GroupWhatsId,
          serviceChannelId: action.message.serviceChannelId,
          participants: [],
          lastMessage: action.message.Message, //ok
          lastAttachment:
            action && action.message && action.message.attachments.length > 0
              ? action.message.attachments[0]
              : null,
          profileImage:
            action && action.message && action.message.profileImage
              ? `data:image/png;base64, ${action.message.profileImage}`
              : null,

          messages: [],
          serviceChannelType: action.message.ServiceChannelType, //OK
          serviceProtocolId: action.message.serviceProtocolId, //OK
          isBeta: action.message.isBeta, //OK
          protocol: action.message.protocol, //OK
          contacts: action.message.contacts, //OK
          originGroup: action.message.originGroup,
          originGroupId: action.message.originGroupId,
          //activeMode: false,
          //totalUnread: action.message.totalUnread, //FALTA
          //userPrincipalAgent: action.message.userPrincipalAgent, //FALTA
          isTemplate: action.message.isTemplate,
          LastId: action.message.id,
          isFixed: false,
          forwardedBy: null,
          isFrozenService: false,
          isImportantService: false,
          isSpecialCustomer: false,
          PerPage: 20,
          Offset: 0,
          totalUnread: 1,
          isNew: true,

          /*customerId: ,
            customerName: ,
            dateLastMessage: ,
            forwardedBy: null,
            isFrozenService: false,
            isImportantService: false,
            isSpecialCustomer: false,
            lastMessage: action.message.Message,
            serviceChannelType: action.message.ServiceChannelType,
            serviceProtocolId: action.message.serviceProtocolId,
            PerPage: 20,
            Offset: 0,
            totalUnread: 1,*/
        };
        let oldState = state.chatsContacts;
        let chatList = [...oldState, chatsContacts];
        console.log(JSON.stringify(chatList));

        return { ...state, chatsContacts: chatList };
      }
    case "SET_CONVERSATION_IMPORTANT":
      let setImportant = state.chatsContacts.find(
        (i) => i.serviceProtocolId === action.serviceProtocolId
      );
      setImportant.isImportantService = !setImportant.isImportantService;
      return { ...state };
    case "TOGGLE_CHAT_ATTRIBUTES":
      console.log(action);
      let setAttribute = state.chatsContacts.find((i) =>
        action.chat.customerId > 0
          ? i.customerId === action.chat.customerId &&
            i.serviceProtocolId === action.chat.serviceProtocolId
          : i.groupWhatsId === action.chat.groupWhatsId
      );
      if (action.action === "unread") {
        setAttribute.unread = action.status;
        setAttribute.unreadAt = action.status
          ? moment.utc().utcOffset("-03:00").format()
          : null;
      }
      if (action.action === "pin") {
        setAttribute.isFixed = !setAttribute.isFixed;
        setAttribute.fixedAt = setAttribute.isFixed
          ? moment().utcOffset("-03:00").format()
          : null;
      }
      console.log(setAttribute);

      return { ...state };
    case "LOCK_CHAT_INTERACTION":
      let lockChat = state.chatsContacts.find((i) =>
        action.chat.customerId > 0
          ? i.customerId === action.chat.customerId
          : i.groupWhatsId === action.chat.groupWhatsId
      );
      lockChat.locked = true;
      return { ...state };
    case "FREEZE_CHAT":
      let setFreeze = state.chatsContacts.find(
        (i) => i.customerId === action.customerId
      );
      setFreeze.isFrozenService = !setFreeze.isFrozenService;
      return { ...state };
    case "FORWARD_CHAT":
      let forwardChat = state.chatsContacts.filter((chat) => {
        //return chat.customerId !== 21
        return chat.serviceProtocolId !== action.chat.ServiceProtocolId;
      });
      return { ...state, chatsContacts: forwardChat };
    case "MARK_AS_READ":
      let markAsRead = state.chatsContacts.find((i) =>
        action.activeChat && action.activeChat.groupWhatsId
          ? i.groupWhatsId === action.activeChat.groupWhatsId
          : i.customerId === action.activeChat.customerId &&
            i.serviceProtocolId === action.activeChat.serviceProtocolId
      );
      if (markAsRead) {
        markAsRead.totalUnread = 0;
      }
      return { ...state };
    case "INCREASE_UNREAD":
      let increaseUnread = state.chatsContacts.find((i) =>
        action.message &&
        action.message.messageMemberGroup &&
        action.message.messageMemberGroup.GroupWhatsId
          ? i.groupWhatsId === action.message.messageMemberGroup.GroupWhatsId
          : i.customerId === action.message.CustomerId &&
            i.serviceProtocolId === action.message.serviceProtocolId
      );
      console.log(increaseUnread);
      if (typeof increaseUnread !== "undefined") {
        increaseUnread.totalUnread = increaseUnread.totalUnread + 1;
      }
      return { ...state };
    case "RECEIVE_CHAT":
      //VERIFICA SE O CLIENTE JÁ ESTÁ NA LISTA DE CHATS
      console.log(JSON.stringify(action));

      const selectChatContact = state.chatsContacts.find(
        (c) => c.customerId === action.chat.customerId
      );
      console.log(selectChatContact);
      if (typeof selectChatContact === "undefined") {
        let oldState = state.chatsContacts;
        console.log(oldState);
        console.log(action.chat);
        let chatList = [action.chat, ...oldState];
        console.log(chatList);
        return { ...state, chatsContacts: chatList };
      } else {
        return { ...state };
      }
    case "FORWARD_CHAT_MESSAGE": {
      //VERIFICA SE O CLIENTE JÁ ESTÁ NA LISTA DE CHATS
      console.log(action);
      console.log(state.chatsContacts);

      const selectChatContact = state.chatsContacts.find(
        (c) =>
          c.customerId === action.chat.customerId &&
          c.serviceProtocolId === action.chat.serviceProtocolId
      );
      if (typeof selectChatContact === "undefined") {
        //Adiciona um novo chat
        let oldState = state.chatsContacts;
        let chatList = [action.chat, ...oldState];
        console.log(chatList);
        return { ...state, chatsContacts: chatList };
      } else {
        // Atualiza um chat existente
        let setLast = state.chatsContacts.find(
          (i) =>
            i.customerId === action.chat.customerId &&
            i.serviceProtocolId === action.chat.serviceProtocolId
        );
        setLast.lastMessage = action.chat.lastMessage;
        setLast.lastAttachment = action.chat.lastAttachment;
        setLast.dateLastMessage = action.chat.dateLastMessage;

        return { ...state };
      }
    }

    case "UPDATE_CUSTOMER":
      let updateCustomer = state.chatsContacts.find(
        (i) => i.customerId === action.activeChat.customerId
      );
      updateCustomer.customerName = action.activeChat.customerName;
      updateCustomer.customerCode = action.activeChat.customerCode;
      updateCustomer.customerPhone = action.activeChat.customerPhone;
      updateCustomer.customerEmail = action.activeChat.customerEmail;
      return { ...state };
    case "CLOSE_PROTOCOL":
      let closeProtocol = state.chatsContacts.filter((chat) => {
        return chat.serviceProtocolId !== action.serviceProtocolId;
      });
    return { ...state, chatsContacts: closeProtocol };
    case "VIDEO_METADATA":
      let videoMetadata = state.chatsContacts
        .find((m) =>
          m.messages.some((message) => message.id === action.metadata.messageId)
        )
        .messages.find((m) => m.id === action.metadata.messageId);
      videoMetadata.attachments[0].metadata = action.metadata;
      return { ...state };
    case "REMOVE_CHAT_GROUP":
      let removeChatGroup = state.chatsContacts.filter(
        (chat) => chat.groupWhatsId !== action.id
      );
      return { ...state, chatsContacts: removeChatGroup };
    case "UPDATE_COLLEAGUES_STATUS": {
      return { ...state, colleaguesStatus: action.status };
    }
    default:
      return { ...state };
  }
};

export default newChats;
