import { client } from "../../utils/axios-utils";

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/servicechanneltemplatealias`;

const serviceChannelTemplateAliasService = {
  getServiceChannelTemplateAliasList: (servicechannelid) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/list/${servicechannelid}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },

  postServiceChannelTemplateAlias: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(`${url}`, { ...params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },

  putServiceChannelTemplateAlias: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(`${url}`, { ...params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },

  deleteServiceChannelTemplateAlias: (id) => {
    return new Promise((resolve, reject) => {
      return client()
        .delete(`${url}/list/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
};

export default serviceChannelTemplateAliasService;
