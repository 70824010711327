import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/notification`;

const notificationService =  {
    getNotificationAvailableWebsosocketNotificationFromUser: (params) => {
        console.log(params)
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${url}/available-websocket-notifications-from-user/`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putNotificationWebsocketNotificationAsRead: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${url}/websocket-notification-as-read/`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putNotificationWebsocketNotificationsAsRead: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${url}/websocket-notifications-as-read/`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putNotificationWebsocketNotificationAsDeleted: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${url}/websocket-notification-as-deleted/`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putNotificationWebsocketNotificationsAsDeleted: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${url}/websocket-notifications-as-deleted/`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
    
}

export default  notificationService;    