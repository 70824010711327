import { messagesService } from "../../../../newServices/corporateChat";
import { INTERNAL_CHAT_CONTACTS, ACTIVE_INTERNAL_CHAT } from '../../../../helpers/internalChatConstants';


export const getInternalMessages = (contact) => {
    const params = {
        lastId: contact?.lastId || 0,
        orderBy: 'desc',
        perPage: 10,
        userId: contact.userId
    }

    return messagesService
        .getMessagesByUser(contact.userId, params)
        .then(res => res)
        .catch(error => error)
}

export const addMessagesToContact = (contacts, user, messages) => {

    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if (contact.userId === user.userId) {
                return {
                    ...contact,
                    lastId: messages[messages.length - 1]?.id || 0,
                    firstLoaded: true, //prevent to load messages again after the first load
                    totalUnread: 0,
                    noMoreMessages: messages.length < 10,
                    messages: [
                        ...contact.messages,
                        ...messages
                    ]
                }
            }
            return contact;
        })
    }
}

export const updateActiveChatData = (activeChat, user) => {
    return {
        type: ACTIVE_INTERNAL_CHAT,
        data: {
            ...activeChat,
            user: {
                ...activeChat.user,
                firstLoaded: true
            }
        }
    }
}

export const resetUnreadMessagesCount = (contacts, user) => {
    console.warn('unread')
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if(contact.id === user.id) {
                return {
                    ...contact,
                    totalUnread: 0
                }
            }
            return contact;
        })
    }
};