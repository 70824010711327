const initialState = {
  channels: []
  }
  
  const groupChannelsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_GROUP_CHANNELS":
        let channels = [...state.channels]
        channels = action.channels
        return { ...state, channels }
    case "PUT_GROUP_CHANNEL":
      return { ...state }
    default:
        return state
    }
  }
  
  export default groupChannelsReducer
  