import { client } from '../../utils/axios-utils'

/*if (!domain) {
    throw new Error('Server url not present. Contact the adminstrator!')
}*/
const holidaysService =  {
    getHolidays: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/holidays`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    postHolidays: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/holidays`, params)
            .then(res => {
                resolve(res.data)
                console.log(res.data)
            })
            .catch(error=> {
                reject(error)
                console.log(error)
            })
        })
    },

    putHolidays: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/holidays`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },

    patchHolidaysStatus: (params) => {
        console.log(params)
        return new Promise((resolve, reject) => {
            return  client()
            .patch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/holidays/status`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },

}

export default  holidaysService;