import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { IntlProviderWrapper } from "./utility/context/Internationalization"
import { Layout } from "./utility/context/Layout"
import * as serviceWorker from "./serviceWorker"
import { store } from "./redux/storeConfig/store"
import Spinner from "./components/@vuexy/spinner/Fallback-spinner"
import "./index.scss"
import "./@fake-db"
import userManager, { loadUserFromStorage } from './authServices/is4/userService'
import AuthProvider from './utils/authProvider'
loadUserFromStorage(store)
const LazyApp = lazy(() => import("./App"))
const domain = window.location.hostname

if(domain !== 'localhost' && domain !== 'app.development.omnyve.com.br' && domain !== 'app.staging.omnyve.com.br'){
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  alert = () => {}
}
// configureDatabase()

ReactDOM.render(
  
    <AuthProvider userManager={userManager} store={store}>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <Layout>
            <IntlProviderWrapper>
              <LazyApp />
            </IntlProviderWrapper>
          </Layout>
        </Suspense>
      </Provider>
    </AuthProvider>
  ,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
