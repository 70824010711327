import { UserManager, WebStorageStateStore } from "oidc-client";
import { IDENTITY_CONFIG, METADATA_OIDC } from "./auth-constants"
import { Redirect } from 'react-router-dom'
import jwt from 'jwt-decode'
import { history } from "../history"
import api from '../services/apps/settings/users'

const manager = new UserManager({
    ...IDENTITY_CONFIG,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    metadata: {
        ...METADATA_OIDC
    }
});

/*manager.signinRedirectCallback().then(res => {
    console.log(res);
    const userInfo = res && jwt(res.access_token)
    //OBTEM AS INFORMAÇÕES DO USUÁRIO DA API E SALVA EM UMA SESSION
    api.getUser(userInfo["omnify.user"])
    .then(res => {
        console.log(res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        processDataAsycn(JSON.stringify(res.data)).then((data) => {  
            console.log('Data from processDataAsycn() with async( When promise gets resolved ): ' + data);  
          }).catch((error) => {  
            console.log('Error from processDataAsycn() with async( When promise gets rejected ): ' + error);  
          });
      })
    .catch(err => { console.log(err) })

}).catch(err => {
    console.log(err);
});*/




const processDataAsycn = async (info) => {  
    if(info) {  
      return info;  
    } else {  
      throw new Error('Something went wrong');  
    }  
  };  


const redirect = (role) => {
    console.log(role)
    console.log(localStorage.getItem('userInfo'))

    if(role === 'administrator'){
        //history.push("/settings")
    } else if(role === 'supervisor') {
        //history.push("/monitoring")
    } else if(role === 'agent'){
        //history.push("/chat")
    } else {
        doSignIn()
    }
}

const doSignIn = () => {
    manager.signinRedirect().then(res => {
        console.log(res)
    });
    localStorage.removeItem('userInfo')
};

const doSignInPopUp = () => {
    manager.signinPopup().then(res => {
        console.log(res)
    });
};

/*const getUser = () => {
    manager.getUser().then(user => {
        if (user && !user.expired) {
            // Set the authorization header for axios
            console.log(user)
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
            return user
        }
        return null
    });
};*/

const getUser = () => {
    /*const user = manager.getUser();
    if (!user) {
        //return await manager.signinRedirectCallback();
        return null
    }
    console.log(JSON.stringify(user))
    return user;*/

    return new Promise((resolve, reject) =>{
        try{
            const users = manager.getUser()
            resolve(users)
        }
        catch(e){
            reject(e)
        }
    })

};

const isAuthenticated = () => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`))
    return (!!oidcStorage && !!oidcStorage.access_token)
};

const access_token = () => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`))
    return (oidcStorage && oidcStorage.access_token)

}


const doSignOut = () => {
    /*manager.signoutPopup().then(res => {
        console.log(res)
    });*/

    manager.signoutRedirect().then(res => {
        localStorage.removeItem('userInfo')
    });
};

export {
    doSignIn,
    getUser,
    doSignOut,
    isAuthenticated,
    doSignInPopUp,
    access_token
}