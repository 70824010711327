import { usersService } from '../../../../newServices/main';
import { STORE_USER_INFO, LOGGED_USER_STATUS } from '../../../actions/authentication/types';
import jwt from 'jwt-decode'

export const getUserDetails = ( access_token: string) => {
    const userDetails: any = access_token && jwt(access_token)
    return usersService
      .getUserId(userDetails["omnify.user"], true)
      .then((res) => res)
      .catch((err) => err);
}

export const storeUserInfo = (data: any) => {
    return {
        type: STORE_USER_INFO,
        payload: data
    }
};

export const changeLoggedStatus = (payload: any) => {
    return {
        type: LOGGED_USER_STATUS,
        payload: payload
    }
}
