import React, { useState, useRef, useEffect, useCallback } from "react";
import { MoreVertical } from "react-feather";
import { useSelector } from 'react-redux';
import { useIntl } from "react-intl";
import moment from "moment";

import {
    LOAD_MORE_MESSAGES,
    DELETE_INTERNAL_CHAT_MESSAGE
} from "../../../../../helpers/internalChatConstants";

import { getLocalTime } from "../../../../../utils/dateUtility";

import BeautyScrollbar from "../../../../beautyScrollbar/BeautyScrollbar";
import { ContextMenu, ContextMenuButton } from '../../../../contextMenu';
// import EmojiPicker from "../../../../emojisPicker/EmojisPicker";
import GoToBottom from "../../../../goToBottom/goToBottom";
import {
    Message,
    MessageSpacing,
    MessageText,
    MessageStatus,
    MessageFooter,
    DividerDate
} from '../../../../messages/components';
import NewMessagesOnScrollUp from "../../../../newMessagesOnScrollUp/NewMessagesOnScrollUp";

import useChatDispatchActions from '../../../../../customHooks/useChatDispatchActions';
import usePrevious from "../../../../../customHooks/usePrevious";
import useTabVisibility from "../../../../../customHooks/useTabVisibility";

import useSortedMessages from "../../../utils/useSortedMessages";

import "./body.scss";

const Body = ({
    setMessageText,
    setShowEmojiPicker,
    showEmojiPicker
}) => {

    const intl = useIntl();

    const scrollRef = useRef();
    const { activeChat, contacts } = useSelector(state => state.internalChat);

    const dispatchChatActions = useChatDispatchActions()

    const isTabVisible = useTabVisibility();

    const messages = useSortedMessages(contacts, activeChat);
    const previousMessages = usePrevious(messages);

    const [allowScroll, setAllowScroll] = useState(true);
    const [newMessagesOnScrollUp, setMessagesOnScrollUp] = useState(0);
    const [messageMenu, showMessageMenu] = useState(null);

    const onWheelScroll = (e) => {
        const { bottom } = e.contentInset
        setAllowScroll(bottom < 20)
        setMessagesOnScrollUp(prevState => bottom < 20 ? 0 : prevState)
    }


    const handleScrollToBottom = () => {
        scrollRef.current.scrollToEnd({ behavior: "auto" });
        setAllowScroll(true)
    }

    const handleLoadMoreMessages = (e) => {
        const { bottom } = e.contentInset
        if (bottom > 0) {
            dispatchChatActions(LOAD_MORE_MESSAGES)
        }
    }

    const handleShowMenu = (e, item, type) => {
        showMessageMenu({
            ...e,
            item: item
        })
    }

    const handleCloseMenu = (type) => {
        showMessageMenu(null)
    }

    // const handleGetEmoji = useCallback((emoji) => {
    //     setMessageText(prevState => prevState + emoji)
    // }, [])

    useEffect(() => {
        /** Verifica o recebimento de novas mensagens através da comparação das ids entre o state atual e prévio
         *  Caso o usuário estiver com o navegador ativo e não tenha feito scroll para cima, a tela é rolada para baixo,
         *  caso contrário, é exibido o botão de novas mensagens.
         *  Caso o usuário estiver com o navegador inativo, é exibido o botão de novas mensagens.
         *  A propriedade allowScroll, quando false, impede que a tela seja rolada para baixo, nesse caso,
         *  sempre que novas mensagens forem recebidas, o botão de novas mensagens é exibido.
         */

        const lastPreviousMessage = previousMessages?.[previousMessages?.length - 1] || null
        const lastMessage = messages?.[messages?.length - 1] || null
        const diffMessageCount = messages?.length - previousMessages?.length

        if (isTabVisible) {
            /** Verifica o recebimento de novas mensagens através da comparação das ids entre o state atual e prévio 
             *  se a propriedade allowScroll for true e o navegador visível, a tela é rolada para baixo, caso contrário, o botão de novas mensagens é exibido. 
            */
            if (diffMessageCount === 1 && lastPreviousMessage?.id < lastMessage?.id && !allowScroll) {
                setMessagesOnScrollUp(prevState => prevState + 1)
            } else if (lastMessage?.isSent && allowScroll) {
                setMessagesOnScrollUp(0)
                scrollRef.current.scrollToEnd({ behavior: "smooth" })
            }
        } else {
            /** Caso o usuário estiver com o navegador inativo, é exibido o botão de novas mensagens.
            *   A propriedade allowScroll, quando false, impede que a tela seja rolada para baixo, nesse caso,
            */
            if (diffMessageCount === 1 && lastPreviousMessage?.id < lastMessage?.id && !isTabVisible) {
                setMessagesOnScrollUp(prevState => prevState + 1)
                setAllowScroll(false)
            }
        }
    }, [messages, scrollRef, allowScroll, isTabVisible])

    useEffect(() => {
        /** Ao receber novas mensagens, se for permitido scroll "allowScroll",
         *  a tela é rolada para baixo.
         *  Caso seja uma mensagem enviada, o emoji picker é fechado.
         */
        const lastMessage = messages?.[messages?.length - 1] || null

        if (scrollRef && messages.length > 0 && allowScroll) {
            scrollRef.current.scrollToEnd({ behavior: "smooth" })

            if (lastMessage?.isSent) setShowEmojiPicker(false);
        }
    }, [messages, scrollRef, allowScroll])

    return (
        <>
            <div className="chat-window-body-ui">
                <BeautyScrollbar
                    onWheelScroll={onWheelScroll}
                    ref={scrollRef}
                    keepHeightScrollPosition
                    onReachVerticalStart={handleLoadMoreMessages}
                    className="chat-window-body-messages">
                    {messages.map((message, index) => {
                        const prevMessage = messages[index - 1] ?? null;
                        const nextMessage = messages[index + 1] ?? null;
                        return (
                            <React.Fragment key={index}>
                                <DividerDate
                                    currDate={message.createdAt}
                                    prevDate={prevMessage?.createdAt}
                                />
                                <Message
                                    item={message}
                                    prevMessage={prevMessage}
                                    nextMessage={nextMessage}
                                    isChatbot={false}
                                    isSent={message.isSent}
                                    color={message.isDeleting ? "gray" : message.isSent ? "success" : "light"}
                                    style={{
                                        maxWidth: "100%"
                                    }}
                                >
                                    {message.isDeleted ?
                                        <>
                                            <MessageText>{intl.formatMessage({ id: "Essa mensagem foi apagada" })}</MessageText>
                                            <MessageFooter>
                                                <span>{moment(getLocalTime(message?.createdAt)).format('HH:mm')}</span>
                                                {message.isSent && <MessageStatus status={message.messageStatus} />}
                                            </MessageFooter>
                                        </>
                                        :
                                        <>
                                            <MessageText>{message.messageText}</MessageText>
                                            {message.isSent && message.id &&
                                                <ContextMenuButton
                                                    isVisible={messageMenu?.item?.id === message.id}
                                                    className="message-options-button"
                                                    handleShowMenu={(e) => handleShowMenu(e, message, "message")}
                                                >
                                                    <MoreVertical size={20} />
                                                </ContextMenuButton>
                                            }

                                            <MessageFooter>
                                                {message.isDeleting && <small>{intl.formatMessage({ id: "Apagando..." })}</small>}
                                                <span>{moment(getLocalTime(message?.createdAt)).format('HH:mm')}</span>
                                                {message.isSent && <MessageStatus status={message.messageStatus} />}
                                            </MessageFooter>
                                        </>
                                    }
                                </Message>
                                {index + 1 < messages.length && <MessageSpacing nextMessage={nextMessage} currMessage={message} />}
                            </React.Fragment>
                        )
                    })}
                </BeautyScrollbar>
                {!allowScroll && newMessagesOnScrollUp > 0 && <NewMessagesOnScrollUp onClick={handleScrollToBottom} count={newMessagesOnScrollUp} color="success" />}
                {!allowScroll && <GoToBottom onClick={handleScrollToBottom} />}
                {/* <div className={`message-emoji-picker ${showEmojiPicker ? 'show' : ''}`}>
                    <EmojiPicker
                        handleEmoji={handleGetEmoji}
                        show={showEmojiPicker} />
                </div> */}
            </div>
            <ContextMenu {...messageMenu} handleCloseMenu={() => handleCloseMenu("message")}>
                <ContextMenu.Item onClick={() => dispatchChatActions(DELETE_INTERNAL_CHAT_MESSAGE, messageMenu.item)}>
                    Apagar
                </ContextMenu.Item>
            </ContextMenu>
        </>
    )
};

export default Body;