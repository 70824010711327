import React from 'react'
import { Spinner } from 'reactstrap';
import "./loading.scss"

function Loading({size="sm"}) {
return (
      <div className="loading-ui">
          <Spinner size={size} color="primary" />
      </div>
  )
}
export default React.memo(Loading);
