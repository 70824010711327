import React, { useState, useEffect, useRef } from "react";
import { XCircle, AlertCircle, CheckCircle, X } from "react-feather";
import "./BodyCard.scss";

const icons = {
  success: <CheckCircle />,
  warning: <AlertCircle />,
  danger: <XCircle />,
};

function BodyCard({ children, message, className }) {
  const bodyRef = useRef();
  const headerRef = useRef();
  const footerRef = useRef();
  const [dimensions, setDimensions] = useState({
    windowHeight: null,
    headerHeight: null,
    bodyHeight: null,
    footerHeight: null,
    scroll: false,
  });

  const [bodyMessage, setBodyMessage] = useState(null);
  useEffect(() => {
    if (message) {
      let timer1, timer2, timer3;
      setBodyMessage({ ...message, show: false });
      timer1 = setTimeout(() => {
        setBodyMessage({ ...message, show: true });
      }, 140);

      timer2 = setTimeout(() => {
        setBodyMessage({ ...message, show: false });
        timer3 = setTimeout(() => {
          setBodyMessage(null);
        }, 500);
      }, 5000);
      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
      };
    }
  }, [message]);

  return (
    <div
      className={`body-card-container ${className ? className : ""} ${
        bodyMessage && bodyMessage.show ? bodyMessage.type : ""
      }`}
    >
      {bodyMessage && bodyMessage.message && (
        <div
          className={`body-card-message ${
            bodyMessage && bodyMessage.show ? "show" : ""
          } ${bodyMessage.type ? bodyMessage.type : "default"}`}
        >
          <div className="d-flex w-100">
            <div className="flex-grow-1">
              {bodyMessage.type && icons[bodyMessage.type]}{" "}
              {bodyMessage.message}
            </div>
            <div
              className="body-card-close-button"
              onClick={() => setBodyMessage(false)}
            >
              <X sixe={12} />
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
}

BodyCard.Header = ({ children }) => {
  return <div className="body-card-header text-center">{children}</div>;
};

BodyCard.Content = ({ children, className="" }) => {
  return <div className={`body-card-content ${className}`}>{children}</div>;
};

BodyCard.Footer = ({ children }) => {
  return <div className="body-card-footer">{children}</div>;
};

export default BodyCard;
