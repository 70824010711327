import React from 'react';
import moment from 'moment';
import { getLocalTime } from "../../../utils/dateUtility";

function useSortedMessages(contacts, activeChat) {
  return React.useMemo(() => {
    return contacts.find(contact => contact.id === activeChat?.user?.id)?.messages
      .sort((a, b) => moment(getLocalTime(a.createdAt)) - moment(getLocalTime(b.createdAt)))
      || [];
  }, [contacts, activeChat]);
}

export default useSortedMessages;