import { INTERNAL_CHAT_CONTACTS } from '../../../../helpers/internalChatConstants';


export const resetUnreadMessagesCount = (contacts, user) => {
    console.log(contacts, user)
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if(contact.id === user.id) {
                return {
                    ...contact,
                    totalUnread: 0
                }
            }
            return contact;
        })
    }
};

export const requestResetUnreadMessagesCount = (contacts, user) => {
    return {
        data: true
    }
}