import mock from "../mock"
let data = {
    clients: [
        {
         id: 1,
          image: "assets/img/portrait/small/avatar-s-2.jpg",
          name: "Alyss Lillecrop",
          email: "alillecrop0@twitpic.com",
          date: "May 13, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 1,
            department: 'Financeiro',
            name: 'Fernanda Garcia',
            position: 'Assistente financeiro'
          },
          telephones: [
            {
              id: 1,
              number: +5521900000000,
              status: true,
              source: 'whatsapp'
            },
            {
              id: 2,
              number: +5521911111111,
              status: true,
              source:  'admin'
            }
          ]
        },
        {
         id: 2,
          image: "assets/img/portrait/small/avatar-s-1.jpg",
          name: "Shep Pentlow",
          email: "spentlow1@home.pl",
          date: "June 5, 2019",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: [
            {
              id: 3,
              number: +5521922222222,
              status: true,
              source: 'whatsapp'
            },
            {
              id: 4,
              number: +5521933333333,
              status: true,
              source: 'admin'
            }
          ]
        },
        {
         id: 3,
          image: "assets/img/portrait/small/avatar-s-3.jpg",
          name: "Gasper Morley",
          email: "gmorley2@chronoengine.com",
          date: "December 24, 2019",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 4,
            department: 'Comercial',
            name: 'Bruno Henrique',
            position: 'Assistente comercial'
          },
          telephones: [
            {
              id: 5,
              number: +5521944444444,
              status: true,
              source: 'whatsapp'
            }
          ]
        },
        {
         id: 4,
          image: "assets/img/portrait/small/avatar-s-4.jpg",
          name: "Phaedra Jerrard",
          email: "pjerrard3@blogs.com",
          date: "November 30, 2018",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 7,
            department: 'Logística',
            name: 'Ailton Antunes',
            position: 'Assistente'
          },
          telephones: [
            {
              id: 6,
              number: +5521955555555,
              status: true,
              source: 'whatsapp'
            }
          ]
        },
        {
         id: 5,
          image: "assets/img/portrait/small/avatar-s-5.jpg",
          name: "Conn Plose",
          email: "cplose4@geocities.com",
          date: "April 8, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 11,
            department: 'Relacionamento com o cliente',
            name: 'Juliana Lisboa',
            position: 'Supervisor'
          },
          telephones: [
            {
              id: 7,
              number: +5521966666666,
              status: true,
              source: 'whatsapp'
            }
          ]

        },
        {
         id: 6,
          image: "assets/img/portrait/small/avatar-s-6.jpg",
          name: "Tootsie Brandsma",
          email: "tbrandsma5@theatlantic.com",
          date: "August 12, 2019",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: [
            {
              id: 8,
              number: +5521977777777,
              status: true,
              source: 'whatsapp'
            },
            {
              id: 9,
              number: +5521988888888,
              status: true,
              source: 'admin'
            }
          ]
        },
        {
         id: 7,
          image: "assets/img/portrait/small/avatar-s-8.jpg",
          name: "Sibley Bum",
          email: "sbum6@sourceforge.net",
          date: "October 1, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: [
            {
              id: 9,
              number: +5521999999999,
              status: true,
              source: 'whatsapp'
            }
          ]
        },
        {
         id: 8,
          image: "assets/img/portrait/small/avatar-s-7.jpg",
          name: "Kristoffer Thew",
          email: "kthew7@amazon.com",
          date: "February 28, 2018",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 2,
            department: 'Financeiro',
            name: 'Marcio Braga',
            position: 'Supervisor financeiro'
          },
          telephones: []
          },
        {
         id: 9,
          image: "assets/img/portrait/small/avatar-s-26.jpg",
          name: "Fay Hasard",
          email: "fhasard8@java.com",
          date: "January 29, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 4,
            department: 'Comercial',
            name: 'Bruno Henrique',
            position: 'Assistente comercial'
          },
          telephones: []
        },
        {
         id: 10,
          image: "assets/img/portrait/small/avatar-s-12.jpg",
          name: "Tabby Abercrombie",
          email: "tabercrombie9@statcounter.com",
          date: "April 1, 2019",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 5,
            department: 'Comercial',
            name: 'Patrícia Guimarães',
            position: 'Supervisor comercial'
          },
          telephones: []
        },
        {
         id: 11,
          image: "assets/img/portrait/small/avatar-s-10.jpg",
          name: "	Stella Indruch",
          email: "sindruch1@mayoclinic.com",
          date: "Dec 4, 2019",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: {
            uid: 5,
            department: 'Comercial',
            name: 'Patrícia Guimarães',
            position: 'Supervisor comercial'
          },
          telephones: []
        },
        {
         id: 12,
          image: "assets/img/portrait/small/avatar-s-17.jpg",
          name: "	Aron McNirlin",
          email: "amcnirlin2@samsung.com",
          date: "Jan 4, 2018",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 13,
          image: "assets/img/portrait/small/avatar-s-20.jpg",
          name: "Ange Trenholm",
          email: "atrenholm4@slideshare.net	",
          date: "February 23, 2019",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 14,
          image: "assets/img/portrait/small/avatar-s-14.jpg",
          name: "Caterina Starkie",
          email: "cstarkie5@feedburner.com",
          date: "September 8, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 15,
          image: "assets/img/portrait/small/avatar-s-25.jpg",
          name: "Hugibert McGeagh",
          email: "hmcgeaghf@smh.com.au",
          date: "August 20, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 16,
          image: "assets/img/portrait/small/avatar-s-9.jpg",
          name: "Jaime Maher",
          email: "jmaher1@msu.edu",
          date: "April 7, 2019",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 17,
          image: "assets/img/portrait/small/avatar-s-24.jpg",
          name: "Amalle Pladen",
          email: "jmaher1@msu.edu",
          date: "March 30, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 18,
          image: "assets/img/portrait/small/avatar-s-18.jpg",
          name: "Dorris Ferries",
          email: "dferries7@ucoz.com",
          date: "August 25, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 19,
          image: "assets/img/portrait/small/avatar-s-23.jpg",
          name: "Andy Fettes",
          email: "afettesh@upenn.edu",
          date: "September 30, 2017",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 20,
          image: "assets/img/portrait/small/avatar-s-6.jpg",
          name: "Allene Hughf",
          email: "ahughf0@dropbox.com",
          date: "June 21, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 21,
          image: "assets/img/portrait/small/avatar-s-2.jpg",
          name: "Petra Rheubottom",
          email: "prheubottom0@globo.com",
          date: "July 4, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 22,
          image: "assets/img/portrait/small/avatar-s-1.jpg",
          name: "Ambrosius Olyfant",
          email: "aolyfant1@timesonline.co.uk",
          date: "May 5, 2019",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 23,
          image: "assets/img/portrait/small/avatar-s-3.jpg",
          name: "Letti Trineman",
          email: "ltrineman2@cnbc.com",
          date: "February 15, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 24,
          image: "assets/img/portrait/small/avatar-s-4.jpg",
          name: "Sayer Rodger",
          email: "srodgerb@rakuten.co.jp",
          date: "January 30, 2018",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 25,
          image: "assets/img/portrait/small/avatar-s-5.jpg",
          name: "Skyler Scotcher",
          email: "sscotcher3@soup.io",
          date: "November 3, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 26,
          image: "assets/img/portrait/small/avatar-s-6.jpg",
          name: "Florette Shotbolt",
          email: "fshotbolt7@wiley.com",
          date: "March 12, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 27,
          image: "assets/img/portrait/small/avatar-s-8.jpg",
          name: "Janis Bakhrushkin",
          email: "jbakhrushkina@epa.gov",
          date: "July 10, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
          id: 28,
          image: "assets/img/portrait/small/avatar-s-7.jpg",
          name: "Alric Peinton",
          email: "apeinton0@google.cn",
          date: "February 6, 2017",
          status: false,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 29,
          image: "assets/img/portrait/small/avatar-s-26.jpg",
          name: "Rubie Pitkethly",
          email: "rpitkethlyf@51.la",
          date: "February 20, 2018",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        },
        {
         id: 30,
          image: "assets/img/portrait/small/avatar-s-12.jpg",
          name: "Hortensia Soaper",
          email: "hsoaperh@mapy.cz",
          date: "June 1, 2017",
          status: true,
          clientCode: Math.floor(Math.random() * 99999999999),
          agent: [],
          telephones: []
        }
      ],
}

// GET : Calendar Events
mock.onGet("/api/apps/settings/clients").reply(() => {
  return [200, data.clients]
})

mock.onPut("api/app/settings/clients").reply(request => {
  let reqdata = JSON.parse(request.data)
  const { client } = reqdata
  /*if (data.chats[activeUser.uid]) {
    data.chats[activeUser.uid].msg.push(message)
  } else {
    let newMsg = {
      [contactId]: {
        isPinned: isPinned,
        msg: [message]
      }
    }
    Object.assign(data.chats, newMsg)
  }*/
  return [200, client]
})

// POST : Add new user
mock.onPost("/api/apps/settings/clients").reply(request => {
  // Get task from post data
  let client = JSON.parse(request.data)
  const uid = Math.floor(Math.random() * 99999)

  client.client.id = uid
  client.client.date = new Date()
  client.client.image = ''
  data.client.push(client.client)
  
  return [201, { id: client.id }]
})
