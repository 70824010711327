import React from "react"
import { X } from "react-feather"
import classnames from "classnames"
import BodyCard from "../bodyCard2/BodyCard"
import SmartRendering from "../smartRendering/SmartRendering"
import "./modal.scss"


class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            error: null
        }
    }

    handleOpenModal = () => {
        this.props.toggle()
    }

    componentDidMount = () => {
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.pressedKeyboardKey)
    }

    componentDidUpdate = (prevProps) => {
        const prevShow = prevProps.show
        const currShow = this.props.show
        if (prevShow !== currShow) {
            if (currShow) window.addEventListener('keydown', this.pressedKeyboardKey)
            else window.removeEventListener('keydown', this.pressedKeyboardKey)
        }
        if(this.props.errorMessage !== prevProps.errorMessage){
            this.setState({error: this.props.errorMessage})
        }
    }

    pressedKeyboardKey = (key) => {
        if (key?.code?.toLowerCase() === 'escape' || key.keyCode === 27) {
            this.props.toggle()
        }

        if (key?.code?.toLowerCase() === 'enter' || key.keyCode === 13) {
            this.props.onPressEnter()
        }
    }

    render() {
        const { show, position, size, className, style, color, errorMessage } = this.props
        return (
            <SmartRendering componentWillRender={show}>
                <div className={`modal-ui ${show ? 'show' : ''}  ${position}`} style={style ? style : {}}>
                    <div className={`modal-window-overlay ${show ? 'show' : ''}`} onClick={this.props.toggle ? this.handleOpenModal : null} />
                    <div
                        className={classnames(
                            `modal-ui-window ${color} ${show ? 'show' : ''} ${className ? className : ''}`,
                            {
                                "top": position === "top",
                                "center": position === "center",
                                "bottom": position === "bottom",
                            },
                            {
                                "window-mn": size === "mn",
                                "window-xs": size === "xs",
                                "window-sm": size === "sm",
                                "window-md": size === "md",
                                "window-lg": size === "lg",
                                "window-xl": size === "xl",
                                "window-mx": size === "mx",
                            }
                        )}
                    >
                        <BodyCard>
                            {this.props.children}
                        </BodyCard>
                        {this.state.error &&
                        <div className="modal-error-message">
                            <span>
                                {this.state.error}
                            </span>
                        </div>
                        }
                        {this.props.toggle &&
                            <div className="ct-close" onClick={this.handleOpenModal}>
                                <X />
                            </div>
                        }
                    </div>
                </div>
            </SmartRendering>
        )
    }
}

Modal.Header = ({ children }) => <BodyCard.Header>{children}</BodyCard.Header>

Modal.TopElement = ({ children }) => <div>{children}</div>

Modal.Content = ({ children, onReachVerticalEnd, className="" }) => {
    return (
        <BodyCard.Content
            className={className}
            onReachVerticalEnd={onReachVerticalEnd}>
        {children}
        </BodyCard.Content>

    )
}

Modal.Footer = ({ children }) => <BodyCard.Footer>{children}</BodyCard.Footer>

export default Modal