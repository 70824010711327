import React from "react";
import { useIntl } from "react-intl";
import "./new-messages-on-scroll-up.scss";

const NewMessagesOnScrollUp = React.memo(({ onClick, count, color="primary" }) => {
    const intl = useIntl();
    return (
        count > 0 ?
        <div className={`new-messages-on-scroll-up-ui ui-${color}`} onClick={onClick}>
            <span className="new-messages-on-scroll-up__text">
                {intl.formatMessage(
                    { 
                        id: count === 1 ? "{count} nova mensagem" : "{count} novas mensagens"
                    }, {
                        count: count
                    })}
            </span>
        </div> : 
        null
    )
    }
);

export default NewMessagesOnScrollUp;