import React, { PureComponent } from "react"
import classnames from "classnames"
import Navbar from "./components/navbar/Navbar"
import themeConfig from "../configs/themeConfig"
import { connect } from "react-redux"
import InternalChat from "../newComponents/internalChat/InternalChat"
import ReleaseNotes from "../newComponents/releaseNotes/ReleaseNotes";

class HorizontalLayout extends PureComponent {
  state = {
    width: window.innerWidth,
    sidebarState: false,
    layout: '',
    collapsedContent: false,
    sidebarHidden: false,
    currentLang: "en",
    appOverlay: false,
    customizer: false,
    currRoute: this.props.location.pathname,
    menuOpen: themeConfig.menuOpen
  }

  mounted = false

  updateWidth = () => {
    if (this.mounted) {
      this.setState(prevState => ({
        width: window.innerWidth
      }))
    }
  }

  updateScroll = () => {
    if (this.mounted) {
      this.setState({ scroll: window.pageYOffset })
    }
  }

  handleCustomizer = bool => {
    this.setState({
      customizer: bool
    })
  }

  componentDidMount() {
    this.mounted = true
    if (this.mounted) {
      if (window !== "undefined") {
        window.addEventListener("resize", this.updateWidth, false)
        window.addEventListener("scroll", this.updateScroll, false)
      }
      if (this.props.location.pathname === "/pages/profile") {
        this.setState({
          sidebarState: true,
          collapsedContent: true
        })
      }
      let layout = ''
      return layout === "dark"
        ? document.body.classList.add("dark-layout")
        : layout === "semi-dark"
          ? document.body.classList.add("semi-dark-layout")
          : null
    }
  }

  componentDidUpdate() {
    if (this.mounted) {
      if (this.state.currRoute !== this.props.location.pathname) {
        this.handleRouteChange()
        this.setState({
          currRoute: this.props.location.pathname
        })
      }

      let layout = ''
      if (layout === "dark") {
        document.body.classList.remove("semi-dark-layout")
        document.body.classList.add("dark-layout")
      } else if (layout === "semi-dark") {
        document.body.classList.remove("dark-layout")
        document.body.classList.add("semi-dark-layout")
      } else {
        return document.body.classList.remove("dark-layout", "semi-dark-layout")
      }
    }
  }

  handleRouteChange = () => {
    if (this.props.location.pathname === "/pages/profile") {
      this.setState({
        collapsedContent: true
      })
    } else {
      this.setState({
        sidebarState: false,
        collapsedContent: false
      })
    }
  }

  toggleSidebarMenu = () => {
    this.setState({
      sidebarState: !this.state.sidebarState,
      collapsedContent: !this.state.collapsedContent
    })
  }

  sidebarMenuHover = () => {
    this.setState({
      sidebarState: !this.state.sidebarState
    })
  }

  handleSidebarVisibility = () => {
    if (this.mounted) {
      if (window !== undefined) {
        window.addEventListener("resize", () => {
          if (this.state.sidebarHidden) {
            this.setState({
              sidebarHidden: !this.state.sidebarHidden
            })
          }
        })
      }
      this.setState({
        sidebarHidden: !this.state.sidebarHidden
      })
    }
  }

  handleCurrentLanguage = lang => {
    this.setState({
      currentLang: lang
    })
  }

  handleAppOverlay = value => {
    if (value.length > 0)
      this.setState({
        appOverlay: true
      })
    else if (value.length > 0 || value === "") {
      this.setState({
        appOverlay: false
      })
    }
  }

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false
    })
  }

  componentWillUnmount() {
    this.mounted = false
  }

  render() {
    return (
      <div
        className={classnames(
          `wrapper horizontal-layout theme-primary navbar-floating`,
          {
            "menu-collapsed":
              this.state.collapsedContent === true && this.state.width > 1200
          }
        )}>
        <div
          style={{ marginRight: 60 }}
          className={classnames("app-content content", {
            "show-overlay": this.state.appOverlay === true
          })}
          onClick={this.handleAppOverlayClick}>
          <Navbar
            horizontal
            scrolling={this.state.scroll > 50 ? true : false}
            toggleSidebarMenu={this.toggleSidebarMenu}
            sidebarState={this.state.sidebarState}
            sidebarVisibility={this.handleSidebarVisibility}
            currentLang={this.state.currentLang}
            changeCurrentLang={this.handleCurrentLanguage}
            handleAppOverlay={this.handleAppOverlay}
            appOverlayState={this.state.appOverlay}
          />
          {/*<div className="content-wrapper">{this.props.children}</div>*/}
          {this.props.children}
        </div>

        <div
          className="sidenav-overlay"
          onClick={this.handleSidebarVisibility}
        />
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 60,
          height: '100vh',
          zIndex: 9999
        }}>
          <InternalChat />
          <ReleaseNotes />
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    app: state.customizer
  }
}
export default connect(mapStateToProps, {
})(HorizontalLayout)
