import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import RenderCheckboxAndLabel from "./RenderCheckboxAndLabel";
import { Chevron } from "../../pureHtmlIcons";

// types
import { ValueType, OptionType } from "../interfaces";

interface RenderOptionTypes {
  option: OptionType;
  optionList: OptionType[];
  index: number;
  multiSelect: boolean;
  onChange: (props: {
    name: string;
    option?: ValueType;
    options?: ValueType[];
  }) => void;
  id: string;
  value: ValueType | null;
  name: string;
  handleShowDropdown: () => void;
  setSearch: (search: string) => void;
}

const RenderOption = ({
  option,
  optionList,
  index,
  multiSelect,
  onChange,
  id,
  value,
  name,
  handleShowDropdown,
  setSearch,
}: RenderOptionTypes) => {
  const [collapsed, setColapsed] = useState<number[]>([]);
  const hasOptions = option.options && option.options.length > 0;
  const isArray = Array.isArray(value);
  const selectedRef = useRef<HTMLDivElement | null>(null);

  const toggleCollapse = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemIndex: number
  ) => {
    event.stopPropagation();
    setColapsed((prevState) =>
      prevState.includes(itemIndex)
        ? prevState.filter((f) => f !== itemIndex)
        : [...prevState, itemIndex]
    );
  };

  const handleSingleSelection = useCallback(
    (item: OptionType) => {
      onChange({ name, option: item });
      setSearch("");
      handleShowDropdown();
    },
    [name, onChange, setSearch, handleShowDropdown]
  );

  const handleClick = useCallback(
    (item?: OptionType) => {
      if (!item) {
        item = option;
      }
      if (item.disabled) return;

      const itemOptions = item.options;
      const isGrouped = itemOptions?.length;

      if (multiSelect && isArray) {
        if (isGrouped) {
          const selectedOptionValues = value.map((v: ValueType) => v.value);

          const allSelectedInGroup = itemOptions.every((option) =>
            selectedOptionValues.includes(option.value)
          );

          const newOptions = allSelectedInGroup
            ? value.filter(
                (v: ValueType) => !itemOptions.some((o) => o.value === v.value)
              )
            : [...value, ...itemOptions];

          onChange({ name, options: newOptions });
        } else {
          const isOptionSelected = value.some(
            (f: ValueType) => f.value === item?.value
          );

          const newOptions = isOptionSelected
            ? value.filter((f: ValueType) => f.value !== item?.value)
            : [...value, item];

          onChange({ name, options: newOptions });
          handleShowDropdown();
        }
      } else {
        if (!isGrouped) {
          handleSingleSelection(item);
        }
      }
    },
    [
      name,
      onChange,
      value,
      multiSelect,
      handleShowDropdown,
      option,
      handleSingleSelection,
      isArray,
    ]
  );

  useEffect(() => {
    if (optionList?.length > 0) {
      setColapsed(optionList.map((o: OptionType, index: number) => index));
    }
    // eslint-disable-next-line
  }, [optionList.length]);

  const checked = useMemo(() => {
    if (!isArray) {
      return false;
    }

    if (hasOptions) {
      const selectedOptionValues = value.map((v: ValueType) => v.value);

      const allSelectedInGroup = option.options?.every((option) =>
        selectedOptionValues.includes(option.value)
      );

      return allSelectedInGroup || false;
    }

    return value.some((m) => m.value === option.value) || false;
  }, [option, isArray, hasOptions, value]);

  useEffect(() => {
    selectedRef.current &&
      selectedRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
  }, [value]);

  return (
    <>
      <div
        className={`ui-select-box-dropdown__action ${
          !Object.keys(option).includes("title") &&
          !multiSelect &&
          value?.value === option.value
            ? "selected"
            : ""
        }`}
        ref={value?.value === option.value ? selectedRef : null}
        data-testid="selectbox-option"
        onClick={() => handleClick()}
      >
        <RenderCheckboxAndLabel
          hasCheckbox={multiSelect}
          checked={checked || false}
          label={option.label}
          disabled={option.disabled}
          exibitionHtml={option.exibitionHtml}
          isGroupTitle={!!option.options}
          index={index}
        />

        {hasOptions && (
          <button
            className="dropdown-option__collapse"
            data-testid="option-collapse"
            onClick={(e) => toggleCollapse(e, index)}
          >
            <Chevron direction={collapsed.includes(index) ? "down" : "right"} />
          </button>
        )}
      </div>

      {/** CHILDREN */}
      {hasOptions && collapsed.includes(index) && (
        <div className="dropdown-option__options" data-testid="option-options">
          {option.options &&
            option.options.map((item: OptionType, optionIndex: number) => {
              return (
                <div
                  key={optionIndex}
                  className={`ui-select-box-dropdown__action ${
                    !!multiSelect ? "multiselect" : ""
                  } ${!!item.disabled ? "disabled" : ""} ${
                    !multiSelect && item.value === value?.value
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleClick(item)}
                >
                  <div className="dropdown-option d-flex align-items-center">
                    <RenderCheckboxAndLabel
                      hasCheckbox={multiSelect}
                      checked={
                        isArray
                          ? value.map((m) => m.value).includes(item.value)
                          : value?.value === item.value || false
                      }
                      label={item.label}
                      disabled={item.disabled}
                      index={index}
                      exibitionHtml={item.exibitionHtml}
                      isGroupTitle={false}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {/** \ CHILDREN */}
    </>
  );
};

export default RenderOption;
