import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR } from '../../../helpers/constants';
import { ACTIVE_INTERNAL_CHAT, LOAD_MORE_MESSAGES } from '../../../helpers/internalChatConstants';

import {
    getInternalMessages,
    addMessagesToContact,
} from '../utils/internalChatFunctions/internalMessagesFunc';

import {
    requestReadMessageIfActiveChat
} from '../utils/internalChatFunctions/requestReadMessagesFunc';


import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getContacts = state => state.internalChat.contacts;
const getActiveChat = state => state.internalChat.activeChat;

function* firstMessageRequest(action) {
    const { user } = action.data;
    const contacts = yield select(getContacts);
    const contact = contacts.find(contact => contact.id === user?.id) || null; //GET UPDATED CONTACT DATA

    if(contact?.firstLoaded || typeof contact?.firstLoaded !== 'undefined' || !contact) return;
        try {
            const { data, response } = yield call(getInternalMessages, user);
            if (data) {
                console.log(data)
                // yield put(updateActiveChatData(activeChat, data));
                yield put(addMessagesToContact(contacts, contact, data));
                // yield call(markMessageAsRead, contact)
            } else {
                yield put({
                    type: SAGA_ERROR,
                    origin: errorOrigin.backend,
                    errorType: errorTypes.error,
                    response: response
                });
            }
            
        } catch (e) {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.saga,
                errorType: errorTypes.error,
                response: e.message
            });
        }
   
}

function* markMessageAsRead(user){
    const contacts = yield select(getContacts);
    const activeChat = yield select(getActiveChat);
    if(activeChat?.user?.totalUnread === 0) return
    try {
        const { data, response } = yield call(requestReadMessageIfActiveChat, user);
        if(data){
            // yield put(resetUnreadMessagesCount(contacts, user));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }

    } catch(e){
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }
}

function* loadMoreMessages(action) {
    const activeChat = yield select(getActiveChat);
    const contacts = yield select(getContacts);
    const contact = contacts.find(contact => contact?.userId === activeChat?.user?.userId);
    console.log(contact)
    console.log('passed 2')
    if(contact?.noMoreMessages) return;
    try {

        
        const { data, response } = yield call(getInternalMessages, contact);

        if (data) {
            yield put(addMessagesToContact(contacts, contact, data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    } catch (e) {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }

}

export default function* internalMessagesSaga() {
    yield takeEvery(ACTIVE_INTERNAL_CHAT, firstMessageRequest);
    yield takeEvery(LOAD_MORE_MESSAGES, loadMoreMessages);
}