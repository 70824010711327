import React from "react";
import "./context-menu-button.scss";

export const ContextMenuButton = React.memo(({handleShowMenu, className="", isVisible, children}) => {
    /** Ao clicar no botão, emite um evento com a posição onde houve o clique */
    const handleOptionClick = (e) => {
        const getPositions = e.target.getBoundingClientRect();
        console.log(getPositions)
        handleShowMenu({
            x: e.clientX,
            y: e.clientY
        })
    }   
    
    return (
        <div 
            onClick={handleOptionClick}
            className={`context-menu-button-ui ${className} ${isVisible ? "is-visible" : ""}`}>
            <button>{children}</button>
        </div>
    );
});