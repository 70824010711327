import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/groupservicechannels`;

const groupServiceChannelsService = {
	getGroupServiceChannelsServiceChannel: (id) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/servicechannel/${id}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
};

export default groupServiceChannelsService;
