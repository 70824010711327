
import { INTERNAL_CHAT_CONTACTS, IS_LOADING } from '../../../../helpers/internalChatConstants';
import { internalChatMessageObject } from '../internalChatMessageObject';
import { generateDarkColorFromString } from '../../../../utils/generateColorBasedOnString';
import { messagesService } from '../../../../newServices/corporateChat';
import { normalizeArray } from '../../../../utils/normalizeArray';

export const toggleLoading = (isLoading) => {
    return {
        type: IS_LOADING,
        data: isLoading
    }
}

export const createOrUpdateContactObject = (contacts, { selectedUsers, messages }) => {
    const noAffectedContacts = contacts.filter(contact => !selectedUsers.some(user => user.id === contact.id));
    const createOrUpdateArray = selectedUsers.map(contact => {
        const existingContact = contacts.find(c => c.id === contact.id);
        if (existingContact) {
            return {
                ...existingContact,
                color: generateDarkColorFromString(contact.email),
                dateLastMessage: messages[messages.length - 1].createdAt,
                lastMessage: messages[messages.length - 1].messageText,
                isAnExistingContact: true,
                messages: [
                    ...existingContact.messages,
                    ...messages.map((message, index) => {
                        return {
                            ...internalChatMessageObject(contact, message),
                            index: index
                        }
                    })
                ]
            }
        } else {
            return {
                ...contact,
                totalUnread: 0,
                color: generateDarkColorFromString(contact.email),
                createdAt: new Date(),
                dateLastMessage: messages[messages.length - 1].createdAt,
                lastMessage: messages[messages.length - 1].messageText,
                isAnExistingContact: false,
                messages: []
            }
        }
    })

    return {
        newContacts: createOrUpdateArray.filter(contact => !contact.isAnExistingContact),
        updatedContacts: createOrUpdateArray.filter(contact => contact.isAnExistingContact),
        noAffectedContacts: noAffectedContacts
    }
}

export const createOrUpdateContact = (contacts, requestData) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            const existingContact = requestData.find(c => c.toUserId === contact.userId);
            /** Atualiza o ID da conversa. Esse ID é necessário, para algumas requisições,
             *  como, por exemplo, requisição de finalização de uma conversa.
             */
            if (typeof existingContact !== "undefined" && existingContact.toUserId === contact.userId) {
                contact.id = existingContact.conversationId;
            }
            return contact
        }),
        userStatus: normalizeArray({
            entity: 'byUserId',
            key: 'userId',
            array: contacts.map( user => {
                return {
                  userId: user.userId,
                  lastMessageStatusType: user.lastMessageStatusType,
                  loggedUserStatus: user.loggedUserStatus,
                  pause: user.pause
                } 
            })
        })
    };
}

export const sendMessagesToUsers = (contatcs, messages) => {
    return messagesService.postMessagesSend({
        usersDestination: contatcs.map(contact => contact.id),
        messages: messages.map(message => {
            return {
                order: message.id,
                messageText: message.messageText,
                externalReference: message.externalReference
            }
        })
    })
        .then(res => res)
        .catch(error => error);
}