import React from "react";
import TextInput from "../input/TextInput";
import { Check, X } from "react-feather";

// style
import "./miniform.scss";

const MiniForm = ({ value, onChange, cancel, confirm }) => {
  return (
    <div className="miniform d-flex">
      <TextInput
        className="max-width"
        value={value}
        onChange={onChange}
        testid="miniform-input"
      />

      <button
        className="no-border"
        onClick={cancel}
        data-testid="cancel-button"
      >
        <X size={15} />
      </button>

      <button
        className="no-border"
        onClick={confirm}
        data-testid="confirm-button"
      >
        <Check size={15} />
      </button>
    </div>
  );
};

export default MiniForm;
