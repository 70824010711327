const initialState = {
    routing: [],
}
    
    const groupRoutingReducer = (state = initialState, action) => {
      switch (action.type) {
        case "FETCH_GROUP_ROUTING":
          let routing = [...state.routing]
          routing = action.routing
          return { ...state, routing }
        case "PUT_GROUP_ROUTING":
            return { ...state, routing: action.routing }
        case "PATCH_GROUP_ROUTING":
          return { ...state, routing: action.routing }
      default:
          return state
      }
    }
    
    export default groupRoutingReducer
    