const initialState = {
    limits: [],
}
    
    const groupLimitsReducer = (state = initialState, action) => {
      switch (action.type) {
        case "FETCH_GROUP_LIMITS":
          let limits = [...state.limits]
          limits = action.limits
          return { ...state, limits }
        case "PUT_GROUP_LIMIT":
            return { ...state, limits: action.limits }
            case "UPDATE_GROUP_LIMIT":
            return { ...state }
        case "ADD_GROUP_LIMIT":
            return { ...state }
      default:
          return state
      }
    }
    
    export default groupLimitsReducer
    