import { combineReducers } from "redux";
import settingsReducer from "./settings/";
import channelsReducer from "./settings/channelsReducer";
import clientsReducer from "./settings/clientsReducer";
import chatReducer from "./chat";
import groupsRulesReducer from "./groupsRules/";
import monitoringReducer from "./monitoring/monitoring";
import authReducer from './authentication/authReducer';
import broadcastsReducer from './broadcasts/broadcasts';
import socketReducer from "./socket";
import internalChat from "./internalChat/internalChat";
import releaseNotesReducer from "./releaseNotes/releaseNotesReducer";
import systemConfig from "./systemConfig/systemConfig";
import usersReducer from "./users/usersReducer";
import notificationsReducer from "./notifications/notificationsReducer"

//REDUX CORRETOS
import groupsReducer from "./settings/groupsReducer"

const rootReducer = combineReducers({
  auth: authReducer,
  chatApp: chatReducer,
  groupRules: groupsRulesReducer,
  settings: settingsReducer,
  channels: channelsReducer,
  clients: clientsReducer,
  monitoring: monitoringReducer,
  broadcasts: broadcastsReducer,
  socket: socketReducer,

  //PÁGINA DE CRUD DE GRUPOS
  groups: groupsReducer,
  //CHAT INTERNO
  internalChat: internalChat,
  //RELEASE NOTES
  releaseNotes: releaseNotesReducer,

  //CHAT INTERNO
  internalChat: internalChat,
  systemConfig: systemConfig,

  //USERS
  users: usersReducer,

  //NOTIFICATIONS
  notifications: notificationsReducer
})

export default rootReducer
