import React, { forwardRef, useRef, useState, useImperativeHandle, RefObject } from 'react';
import '../inputs.scss';

interface TextareaProps {
  id: string | undefined;
  type?: string;
  className?: string;
  style?: React.CSSProperties;
  rows?: number;
  autoComplete?: string;
  name?: string;
  placeholder?: string;
  placeholderTop?: boolean;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  errorMessage?: { error: string; color?: string };
  warningMessage?: string;
  fieldDescription?: string;
  fieldIcon?: { icon: React.ReactNode; position: string };
  placeholderStyle?: string;
  clear?: boolean;
  handleClearInput?: () => void;
  hint?: string;
  maxLength?: number;
  minLength?: number;
  dataCy?: string;
  disabled?: boolean;
  size?: string;
  onClick?: (e: React.MouseEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const Textarea = forwardRef(
  (
    {
      id,
      type = 'text',
      className = '',
      style,
      rows = 4,
      autoComplete,
      name,
      placeholder = ' ',
      placeholderTop,
      value,
      onChange = () => {},
      errorMessage,
      fieldDescription,
      fieldIcon,
      placeholderStyle,
      clear,
      handleClearInput = () => {},
      hint,
      maxLength,
      minLength,
      dataCy,
      disabled,
      size,
      onClick = () => {},
      onKeyDown = () => {},
      onKeyUp = () => {},
      ...rest
    }: TextareaProps,
    ref: React.Ref<{ setFocus: () => void; inputRef: RefObject<HTMLTextAreaElement> }>
  ) => {
    const [state, setState] = useState(0);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const setFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
        setState((state) => state + 1);
      }
    };

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
      setFocus,
      inputRef,
    }));

    return (
      <div
        className={`input-form-group ${className} ${
          errorMessage && errorMessage.error ? `is-invalid ${errorMessage.color ? errorMessage.color : ''}` : ''
        } ${disabled ? 'input-disabled' : ''}`}
      >
        <div className={`input-form ${fieldIcon ? `has-icon ${fieldIcon.position}` : ''}`}>
          <textarea
            {...rest}
            rows={rows}
            data-cy={dataCy || undefined}
            className={size || ''}
            maxLength={maxLength || undefined}
            ref={inputRef}
            // type={type}
            id={id}
            autoComplete={autoComplete === 'off' ? 'new-password' : undefined}
            name={name}
            placeholder=" "
            value={value}
            onChange={(e) => onChange(e)}
            onKeyUp={(e) => onKeyUp(e)}
            onKeyDown={(e) => onKeyDown(e)}
            onClick={(e) => onClick(e)}
            disabled={disabled}
            style={{ ...style, height: 70 }}
          />
          {fieldIcon && <div className={`input-field-icon ${fieldIcon.position}`}>{fieldIcon.icon}</div>}
          <label
            className={`${placeholderStyle === 'hide' ? 'hide' : 'animated '} ${placeholderTop ? 'placeholder-top' : ''} truncate`}
            data-cy={id}
            htmlFor={id}
          >
            {placeholder}
          </label>
          {hint && (
            <span className="input-form-hint">
              <div className="input-hint-icon">i</div>
              <div className="input-hint-text">{hint}</div>
            </span>
          )}
        </div>
        {(maxLength && maxLength > 0) || errorMessage || fieldDescription ? (
          <div className="fields-legends">
            <div className="fields-legends-descriptions">
              {errorMessage && errorMessage.error ? (
                <small className={errorMessage.color || ""}>
                  {errorMessage.error}
                </small>
              ) : fieldDescription ? (
                <small>{fieldDescription}</small>
              ) : null}
              {minLength &&
              minLength > 0 &&
              value &&
              value.trim().length < minLength ? (
                <small>{`Mínimo ${minLength} caracteres`}</small>
              ) : maxLength && maxLength > 0 ? (
                <small>{`Máximo ${maxLength} caracteres`}</small>
              ) : null}
            </div>
            {maxLength && maxLength > 0 && (
              <div className="fields-legends-counter">
                {value?.length}/{maxLength}
              </div>
            )}
          </div>
        ) : null}
        {clear && value && value.trim().length > 0 && (
          <div className={`input-form-clear ${hint ? 'has-hint' : ''}`} onClick={handleClearInput}>
            +
          </div>
        )}
      </div>
    );
  }
);

export default Textarea;
