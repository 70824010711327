import React, { ReactNode } from 'react';
import './paragraph.scss';

interface ParagraphProps {
  color?: string;
  children: ReactNode;
  className?: string;
  dark?: boolean;
  light?: boolean;
  fw?: number;
}

const Paragraph: React.FC<ParagraphProps> = ({
  color,
  children,
  className = '',
  dark,
  light,
  fw,
}: ParagraphProps) => {
  const classNameFormatted = `paragraph-ui ui-color ui-${color ? color : 'gray'} ${
    light ? `ui-light` : dark ? `ui-dark` : ''
  } ${fw ? `ui-fw-${fw}` : 'ui-fw-400'} ${className}`;

  return <p className={classNameFormatted}>{children}</p>;
};

export default Paragraph;
