import React, { useState, useEffect, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import { TextInput } from "../forms";

import BeautyScrollbar from "../beautyScrollbar/BeautyScrollbar";

import emojiData from "./emoji.json";

import "./emojis-picker.scss";

const EmojiPicker = ({ show, handleEmoji }) => {
  const [selectedGroup, setSelectedGroup] = useState("Sorrisos e Emoções");
  const [searchTerm, setSearchTerm] = useState("");

  const [emijiCategories, setEmijiCategories] = useState([]);



  const intl = useIntl();
  const refs = useRef([]);

  useEffect(() => {
    setEmijiCategories(emojiData.categories);
  }, []);


  const handleGroup = useCallback(
    (id) => {
      refs && refs.current && refs.current[id] &&
      refs.current[id].scrollIntoView({
        behavior: "smooth",
      });
      setSelectedGroup(id);
      setSearchTerm("");
    },
    [refs]
  );

  const groupIcons = {
    "people": "😄",
    "nature": "🐱",
    "foods": "🍎",
    "places": "🏖️",
    "activity": "⚽",
    "objects": "👓",
    "symbols": "㊗️",
    "flags": "🇧🇷",
  };

  return (
    <div className={`emjis-picker-ui ${show ? "show" : "hide"}`}>
      <div className="emojis-picker-container">
        <div className="search-emoji">
          <TextInput
            type="text"
            placeholderStyle="hide"
            placeholder={intl.formatMessage({ id: "Pesquisar emoji" })}
            value={searchTerm}
            onKeyDown={(e) => { }}
            onChange={(e) => {
              e.preventDefault();
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <ul className="emojis-filters">
          {emijiCategories.map((category, index) =>
            <li
              key={category.id}
              className={`${selectedGroup === category.id && "active"}`}
              onClick={() => handleGroup(category.id)}
            >
              {groupIcons[category.id]}
            </li>
          )}
        </ul>

        <div className="emojis-container">
          <BeautyScrollbar className="emojis-sections">
            {emijiCategories.map((category, index) => {
              const listOfEmojis = category.emojis.map((emoji, index) => emojiData.emojis[emoji])
                .filter(f => {
                  if (searchTerm === "") return f
                  return f.keywords.filter(word => word.includes(searchTerm.toLocaleLowerCase())).length > 0
                });
              return (
                listOfEmojis.length > 0 && 
                <div key={index} ref={(el) => (refs.current[category.id] = el)} className="emojis-groups">
                  <h3>{category.id}</h3>
                  <div className="emojis-list">
                    {listOfEmojis && listOfEmojis.length > 0 && listOfEmojis.map((emoji, index) =>
                      <span
                        data-keywords={emoji.keywords}
                        data-name={emoji.name}
                        key={index}
                        className="emoji"
                        role="img"
                        aria-label={emoji.name}
                        aria-hidden={emoji ? "false" : "true"}
                        onClick={() => handleEmoji(emoji.skins[0].native)}
                      >
                        {emoji.skins[0].native}
                      </span>)}

                  </div>
                </div>
              )
            })}
          </BeautyScrollbar>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EmojiPicker);