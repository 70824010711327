import React, { memo } from "react";
import PropTypes from 'prop-types';
import "./badge.scss";

interface BadgeProps {
  color?: 'primary' | 'gray' | 'secondary' | 'danger' | 'warning' | 'success' | 'info' | 'system' | 'sky-blue' | 'spanish-gray';
  count?: number | null;
  max?: number;
}

const Badge: React.FC<BadgeProps> = ({ color = "danger", count = null, max = 99999 }) => (
  count !== null && count > 0 ? (
    <span className={`badge-ui ui-${color}`}>
      {`${count > max ? `${max}+` : count}`}
    </span>
  ) : null
);

Badge.propTypes = {
  color: PropTypes.oneOf(['primary', 'gray', 'secondary', 'danger', 'warning', 'success', 'info', 'system', 'sky-blue', 'spanish-gray']),
  count: PropTypes.number,
  max: PropTypes.number,
};

Badge.defaultProps = {
  color: 'danger',
  count: null,
};

export default memo(Badge);