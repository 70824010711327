import React from 'react';
import { formatTime } from '../../../../../utils/timeLegend';

const ProgressBar = ({
  progressBarRef,
  audioRef,
  timeProgress,
  duration,
  currentTrack,
  setTimeProgress
}) => {
  // const changeProgressBarValue = (e) => {
  //   console.log('e.target.value', e.target.value)
  //   audioRef.current.currentTime = e.target.value;
  //   progressBarRef.current.currentTime = e.target.value;
  //   // console.log('progressBarRef.current.value', progressBarRef.current.value)
  // };

  const changeProgressBarValue = (e) => {
    const value = parseInt(e.target.value);
    setTimeProgress(value);
    const playbackRate = audioRef.current.playbackRate;
    audioRef.current.load();
    audioRef.current.currentTime = value;
    audioRef.current.playbackRate = playbackRate;
    audioRef.current.play();
    // progressBarRef.current.value = value;
    // progressBarRef.current.style.setProperty(
    //   '--range-progress',
    //   `${(value / currentTrack.duration) * 100}%`
    // );
  }

  return (
    <div className="audio-player-progress">
      <input
        type="range"
        ref={progressBarRef}
        defaultValue="0"
        max={currentTrack?.duration}
        onChange={changeProgressBarValue}
      />
      <div className="progress-bar-times">
        <span className="time current">{formatTime(Math.floor(timeProgress))}</span>
        <span className="time">{formatTime(Math.round(currentTrack.duration))}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
