import React, { ChangeEvent } from "react";
import "./check.scss";

interface CheckboxProps {
  label?: string | null;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  className?: string | null;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  name,
  id,
  className,
  disabled,
}: CheckboxProps) => {
  return (
    <div className={`rc-checkbox-ui ${className || ""}`}>
      <input
        type="checkbox"
        onChange={(e) => onChange(e)}
        checked={checked}
        name={name}
        id={id}
        className="checkbox-input-field"
        disabled={disabled}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

export default Checkbox;
