import { all } from 'redux-saga/effects';
import loadContactListSaga from './internalChatActions/loadContactListSaga';
import terminateInternalChatSaga from './internalChatActions/terminateInternalChatSaga';
import sendInternalChatMessageSaga from './internalChatActions/sendInternalChatMessageSaga';
import startInternalChatsSaga from './internalChatActions/startInternalChatsSaga';
import onActivateChatSaga from './internalChatActions/onActivateChatSaga';
import internalMessagesSaga from './internalChatActions/internalMessagesSaga';
import userTypingEventSaga from './internalChatActions/userTypingEventSaga';
import socketNotificationSaga from './internalChatActions/socketNotificationsSaga'; 
import deleteInternalChatMessageSaga from './internalChatActions/deleteInternalChatMessageSaga';
import groupedUsersSaga from './users/groupedUsersSaga';

import showReleaseToUserSaga from './releaseNotesActions/showReleaseToUserSaga';
import presentReleaseNotesSaga from './releaseNotesActions/presentReleaseNoteSaga';
import releaseNoteUserStatusSaga from './releaseNotesActions/releaseNoteUserStatusSaga';
import notificationsSaga from './notifications/notificationsSaga'
import authSaga from './auth/authSaga'

export default function* rootSaga() {
  yield all([
    //INTERNAL CHAT
    loadContactListSaga(),
    terminateInternalChatSaga(),
    sendInternalChatMessageSaga(),
    startInternalChatsSaga(),
    onActivateChatSaga(),
    internalMessagesSaga(),
    userTypingEventSaga(),
    socketNotificationSaga(),
    deleteInternalChatMessageSaga(),
    //RELEASE NOTES
    showReleaseToUserSaga(),
    presentReleaseNotesSaga(),
    releaseNoteUserStatusSaga(),
    groupedUsersSaga(),
    notificationsSaga(),
    authSaga()
  ])
}