import { client } from '../../../utils/axios-utils'

const api =  {
    resetPassword: (email) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/reset-password`, email)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    changePassword: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/change-password`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
}

export default  api;    