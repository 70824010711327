import { INotification } from '../../../interfaces/global/notifications.interface';
import {
    STORE_NOTIFICATIONS
} from '../../../helpers/constants';

interface INotificationsState {
    notifications: INotification[];
}

const initialState: INotificationsState = {
    notifications: [],
};

export default function notificationsReducer(state: INotificationsState = initialState, action: any): INotificationsState {
    switch (action.type) {
        case STORE_NOTIFICATIONS: {
            return { ...state, notifications: action.payload };
        }
        default:
            return state;
    }
}