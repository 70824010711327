export const customTagReplacer = (text) => {

    text = text.split(/\r?\n|\r|\n/g).map( line => {
        //Verifica em cada quebra de linha
        line = line.split(' ').map( word => {
            //Verifica se é uma url e, nesse caso, renderiza o link
            if(word.startsWith('<a') || word.endsWith('a>')){
                return word
            } else 
            //Caso seja uma tag html, faz o parse
            if(word.startsWith('<') || word.endsWith('>')){
                word = word.replace(/</g, '&#60;').replace(/>/g, '&#62;');
            }
    
            if(word.startsWith('_') && word.endsWith('_')){
                word = `<em>${word.substring(1, word.length-1)}</em>`
            }
            return word
        }).filter( f => f).join(' ')
        return line
    }).filter( f => f).join('<br>')

    let newstr = text
    newstr = newstr.replace(/\*(.*?)\*/g, '<b>$1</b>');
    newstr = newstr.replace(/~(.*?)~/g, '<del>$1</del>');

    return newstr;
}