import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import jwt from 'jwt-decode'
import { connect } from "react-redux"
import { setUserInfo } from "../../../redux/actions/authentication/authActions"
import ChangePassword from '../../../views/pages/authentication/components/changePassword'
import api from '../../../services/apps/settings/users'
import { signoutRedirect } from '../../../authServices/is4/userService'
import { FormattedMessage } from "react-intl"
import Avatar from "../../../newComponents/avatar/Avatar";

import Modal from "../../../newComponents/modal/Modal";
import Button from "../../../newComponents/button/Button";


const UserLocalStorage = JSON.parse(localStorage.getItem('userInfo'))

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
    open: false,
    loggedUser: UserLocalStorage,
    modal: false
  }

  changePasswordModal = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  componentDidMount = async () => {
    //if(!UserLocalStorage){
    const userDetails = this.props && this.props.user && this.props.user.user && jwt(this.props.user.user.access_token)
    api.getUser(userDetails["omnify.user"], true)
      .then(res => {

        this.props.setUserInfo(res.data)

        console.log(res)
        this.setState({ loggedUser: res.data })
        localStorage.setItem('userInfo', JSON.stringify(res.data));
      })
      .catch(err => {
        console.log(err)
      })
    //} else {
    this.setState({ loggedUser: UserLocalStorage })
    //}
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  toggleModal = () => {
    this.setState(prevState => ({ modal: !prevState.modal }))
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    const { userProfile } = this.props.user.user
    const loggedUser = this.state.loggedUser

    return (
      <React.Fragment>
          <UncontrolledDropdown tag="div" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="dropdown-user-link">
              <Avatar
                className="ml-50"
                color="primary" 
                size="md" 
                name={loggedUser && loggedUser.name} />
            </DropdownToggle>
            <DropdownMenu right={true}>
              <DropdownItem
                tag="a"
                className="d-flex align-content-center"
                onClick={() => this.changePasswordModal()}
              >
                <Icon.Lock size={14} className="mr-50" />
                <span className="align-middle"><FormattedMessage id="Alterar senha" /></span>
              </DropdownItem>
              <DropdownItem
                tag="a"
                className="d-flex align-content-center"
                onClick={() => this.toggleModal()}
              >
                <Icon.Power size={14} className="mr-50" />
                <span className="align-middle"><FormattedMessage id="Sair" /></span>
              </DropdownItem>
            </DropdownMenu>

          </UncontrolledDropdown>
        <ChangePassword open={this.state.open} togglePasswordModal={this.changePasswordModal} />

        <Modal
        show={this.state.modal}
        toggle={this.toggleModal}
        position="center"
        color="danger"
      >
        <Modal.Header>
          <FormattedMessage id="Deseja desconectar do sistema" />?
        </Modal.Header>
        <Modal.Content>
          <FormattedMessage id="Ao sair, o sistema te redirecionará de volta para a tela de login" />.
        </Modal.Content>
        <Modal.Footer>
          <div className="d-flex justify-content-between">
            <Button.Cancel
              onClick={this.toggleModal} />

            <Button.Logout
              dark
              onClick={() => signoutRedirect('clickButton')} />
          </div>
        </Modal.Footer>
      </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}
export default connect(mapStateToProps, {
  setUserInfo,
})(NavbarUser)
