import React, { useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";

import "./rich-text-area.scss";

const RichTextArea = ({
  className = "",
  placeholder = "",
  rows = 4,
  isInvalid = false,
  maxLength = 99999,
  value,
  onChange }) => {

  const textareaRef = useRef(null);

  const handleTextTagify = (tag) => {
    const txtarea = textareaRef.current;

    // obtain the index of the first selected character
    const start = txtarea.selectionStart;
    // obtain the index of the last selected character
    const finish = txtarea.selectionEnd;
    //obtain all Text
    const allText = txtarea.value;

    // obtain the selected text
    const sel = allText.substring(start, finish);

    //append te text;
    const newText =
      allText.substring(0, start) +
      tag +
      sel +
      tag +
      allText.substring(finish, allText.length);

    if (sel && sel.length > 0) {
      onChange({ target: { name: "text", value: newText } });
    }
  };

  return (
    <div className={`d-flex ${className}`}>
      <div className="form-label-group w-100 mt-0 mb-0">
        <div className="mini-editor">
          <div onClick={() => handleTextTagify("*")}>
            <b>B</b>
          </div>
          <div onClick={() => handleTextTagify("~")}>
            <del>T</del>
          </div>
          <div onClick={() => handleTextTagify("_")}>
            <em>I</em>
          </div>
        </div>
        <TextareaAutosize
          minRows={rows}
          ref={textareaRef}
          className={`form-control ${isInvalid ? "is-invalid" : ""}`}
          placeholder={placeholder}
          value={value}
          name="text"
          type="textarea"
          id="textAreaElement"
          rows={4}
          required
          maxLength={maxLength}
          onChange={onChange}
        />
        {maxLength && (
          <div
            className={`counter-value float-right ${value.length > maxLength ? "bg-danger" : ""
              }`}
          >
            {`${value.length}/${maxLength}`}
          </div>
        )}
      </div>
    </div>
  )
}

export default RichTextArea;