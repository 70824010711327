import React from 'react';
import { formatTime } from '../../../utils/timeLegend';
import Thumbnail from '../../thumbnail/Thumbnail';
import { ReactComponent as NeutralUser } from '../../../assets/img/svg/gender-neutral-user.svg';
import { ReactComponent as Image } from '../../../assets/img/svg/image.svg';
import { ReactComponent as Microphone } from '../../../assets/img/svg/microphone.svg';
import { ReactComponent as User } from '../../../assets/img/svg/user.svg';
import { ReactComponent as Video } from '../../../assets/img/svg/video.svg';
import { ReactComponent as Brief } from '../../../assets/img/svg/brief.svg';
import './message-reply.scss';

const MessageReplyDescription = ({ item }) => {
    /** A exibição da descrição segue a seguinte órdem de prioridade:
     *  1. Caso exista um anexo, exdibir os dados desse anexo
     *  2. Caso exista um contato, exibir os dados desse contato
     *  3. Caso exista uma mensagem, exibir o texto dessa mensagem
     */
    const { attachments, contacts, messageText } = item
    const contentType =    attachments && attachments.length === 1 ? attachments[0].contentType.split('/')[0] : null
    let text;
    switch(contentType) {
        case 'image':
            text =  <div className="chat-message-reply-attachment-description">
                        <Image />
                        <span>{attachments.length > 1 ? `${attachments.length} imagens` : `imagem`}</span>
                    </div>;
            break;
        case 'video':
            text =  <div className="chat-message-reply-attachment-description">
                        <Video />
                        <span>{attachments.length > 1 ? `${attachments.length} vídeos` : `vídeo`}</span>
                    </div>;
            break;
        case 'audio':
            text =  <div className="chat-message-reply-attachment-description">
                        <Microphone />
                        <span>{attachments.length > 1 ? `${attachments.length} áudios` : formatTime(attachments[0]?.secondsAudio)}</span>
                    </div>;
            break;
        default:
            text =  <div className="chat-message-reply-attachment-description">
                        <Brief />
                        <span>{attachments.length > 1 ? `${attachments.length} anexos` : attachments[0]?.fileName}</span>
                    </div>;
    }

    const MultipleAttaches = attachments && attachments.length > 1
    if(MultipleAttaches) {
        text =  <div className="chat-message-reply-attachment-description">
                    <Brief />
                    <span>{`${attachments.length} anexos`}</span>
                </div>;
    }
    

    text =  contacts && contacts.length > 0 ? (
        <div className="chat-message-reply-attachment-description">
                    <div><NeutralUser /></div>
                    <span>{`${contacts.length === 1 ? contacts[0]?.name : `${contacts.length} contatos`}`}</span>
        </div>
    ) : text;

    text =  (!attachments || attachments?.length === 0) && (!contacts || contacts?.length === 0) && messageText ? 
                <div className="chat-message-reply-attachment-description">
                    <span>{messageText}</span>
                </div> : text

    return  <div className="chat-message-reply-attachment">
                { text }
            </div>;
}


const MessageReplySender = ({ item }) => {
    return  item ? (
        <div className="chat-message-reply-sender">
            {item?.userSender?.userName ? item.userSender.userName : item?.customerName}
        </div>
    ) : null;
}

const MessageReplyIcon = ({ item }) => {

    if(item.attachments.length > 0) {
        const { messageId, storageName, fileName, contentType, secondsAudio } = item.attachments[0]
        return (
            <div className="chat-message-reply-icon">
                <Thumbnail
                    onlyIcon
                    data={{
                        messageId, 
                        storageName, 
                        fileName, 
                        contentType, 
                        secondsAudio
                    }} 
                    itemsHideen={0}
                />
            </div>
        );
    } else if(item?.contacts?.length > 0) {
        return (
            <div className="chat-message-reply-icon">
                <User width={50} height={50} fill="red" />
            </div>
        );
    } else {
        return null
    }

}

export const MessageReply = React.memo(({ item, isSent, className }) => {
    
    return (
        <div className={`chat-message-reply-ui ${isSent ? 'is-sent' : 'is-received'} ${className ?? ''}`}>
            <div className="chat-message-reply-content">
                <MessageReplySender item={item} />
                <MessageReplyDescription item={item} />
            </div>
                
            <MessageReplyIcon item={item} />
        </div>
    );
});