import { UPDATE_INTERNAL_CHAT_MESSAGING_INFO, INTERNAL_CHAT_CONTACTS } from '../../../../helpers/internalChatConstants';
import { messagesService } from '../../../../newServices/corporateChat';

export const addTemporaryMessage = (contacts, message ) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if(contact.userId === message.userReceiver.userId){
                return {
                    ...contact,
                    dateLastMessage: message.createdAt,
                    lastMessage: message.messageText,
                    messages: [
                        ...contact.messages,
                        message
                    ]
                }
            }
            return contact;
        })
    };
};

export const removeMessageFromList = () => {
    return {
        type: UPDATE_INTERNAL_CHAT_MESSAGING_INFO,
        data: ''
    }
}

export const sendMessageRequest = (message, activeChat) => {
    return messagesService.postMessagesSend({
        usersDestination: [activeChat.user.userId],
        messages: [message]
    })
        .then(res => res)
        .catch(error => error);
}

export const updateMessageStatus = (contacts, message) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            
            const messageData = message.find(m => m.toUserId === contact.userId);
            if(typeof messageData !== "undefined" && contact.userId === messageData.toUserId){
                return {
                    ...contact,
                    messages: contact.messages.map(msg => {
                        if(msg.externalReference === messageData.externalReference){
                            return {
                                ...msg,
                                messageStatus: messageData.messageStatus,
                                id: messageData.messageId
                            }
                        }
                        return msg;
                    })
                }
            }
            return contact;
        })
    };
}