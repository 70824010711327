const initialState = {
    times: [],
}
    
    const groupTimesReducer = (state = initialState, action) => {
      switch (action.type) {
        case "FETCH_GROUP_TIMES":
          let times = [...state.times]
          times = action.times
          return { ...state, times }
        case "DELETE_GROUP_TIME":
            return { ...state }
        case "UPDATE_GROUP_TIME":
            return { ...state }
        case "ADD_GROUP_TIMES":
            return { ...state }
      default:
          return state
      }
    }
    
    export default groupTimesReducer
    