// import { BsMusicNoteBeamed } from 'react-icons/bs';
import React from 'react';

const DisplayTrack = ({
  currentTrack,
  audioRef,
  setDuration,
  progressBarRef,
  handleNext,
  setTimeProgress,
  timeProgress
}) => {
  const onLoadedMetadata = () => {
    // const seconds = audioRef.current.duration;
    // const audio = audioRef.current;
    // console.log('seconds', seconds)
    // setDuration(seconds);
    // progressBarRef.current.max = seconds;
    
  };

  function animated(e){
    const value = e.target.currentTime;
    const currentTime = Math.floor(value);
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(currentTime / currentTrack.duration) * 100}%`
    );

  }


  return (
    <div>
      <audio
        src={currentTrack.src}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={handleNext}
        onTimeUpdate={animated}
      />
    </div>
  );
};
export default DisplayTrack;
