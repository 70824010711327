import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Wizard from '../../../wizard/Wizard';
import Button from '../../../button/Button';

import UserList from './stepOne/UserList';
import Message from './stepTwo/Message';
import Confirmation from './stepThree/Confirmation';

import { START_INTERNAL_CHATS } from '../../../../helpers/internalChatConstants';
import useChatDispatchActions from '../../../../customHooks/useChatDispatchActions';

import usePrevious from '../../../../customHooks/usePrevious';

const StartNewInternalChatModal = ({ show, toggle }) => {
    const [currWizardStep, setCurrWizardStep] = useState(0)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [messages, setMessages] = useState([]);

    const { startNewChat } = useSelector(state => state.internalChat.isLoading);
    const { contacts } = useSelector(state => state.internalChat);

    const [showSelectedList, setShowSelectedList] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const intl = useIntl();

    const previousContacts = usePrevious(contacts);

    const dispatchChatActions = useChatDispatchActions();

    const goToStep = (step) => {
        /** A validação ocorre apenas quando o usuário estiver avançcando,
         *  não quando ele estiver voltando para um passo anterior.
         *  Ao voltar, remove a validação da tela que estava anteriormente.
         */
        if (step < currWizardStep) {
            setErrorMessage(null)
            setCurrWizardStep(step)
        } else {
            if (validate()) {
                setCurrWizardStep(step)
            }
        }
    }

    const validate = useCallback((color) => {
        let err = null;
        if (currWizardStep === 0 && selectedUsers.length === 0) err = intl.formatMessage({ id: "Por favor, selecione pelo menos um usuário." });
        if (currWizardStep === 1 && messages.length === 0) err = intl.formatMessage({ id: "Por favor, adicione pelo menos 1 mensagem." });
        setErrorMessage(err);
        return err ? false : true;
    }, [selectedUsers, messages, currWizardStep]);

    const toggleSelectUser = useCallback((user) => {
        const userPreviouslySelected = typeof selectedUsers.find(u => u.id === user.id && u.groupId !== user.groupId) === 'object'
        console.log(user)
        if(userPreviouslySelected){
            alert('Usuário já selecionado em outro grupo')
            return
        }

        setSelectedUsers(prevState => {
            const newState = [...prevState]
            if (newState.includes(user)) {
                const index = newState.indexOf(user)
                newState.splice(index, 1)
            } else {
                newState.push(user)
            }
            return newState
        })

        setErrorMessage(null)
    }, [])

    const handleRemoveUser = (user) => {
        setSelectedUsers(prevState => {
            const newState = [...prevState]
            const index = newState.indexOf(user)
            newState.splice(index, 1)
            return newState
        })
    }

    const handleStartNewInternalChat = ({selectedUsers, messages}) => {
        dispatchChatActions(START_INTERNAL_CHATS, { 
            selectedUsers: selectedUsers.map( user => { 
                                return {
                                    ...user, 
                                    userId: user.id
                                }
                            }), 
                            messages 
                        })
    }

    useEffect(() => {
        if (show && previousContacts && (previousContacts?.length !== contacts?.length)) {
            toggle()
            setSelectedUsers([])
            setMessages([])
            setCurrWizardStep(0)
        }
    }, [contacts, previousContacts, show])

    useEffect(() => {
        if (selectedUsers.length === 0 && showSelectedList) {
            setShowSelectedList(false)
        }
    }, [selectedUsers, showSelectedList])

    return (
        <Wizard
            show={show}
            title="Iniciar nova conversa interna"
            currStep={currWizardStep}
            onStepChange={goToStep}
            // onWizardAbort={toggle}
            errorMessage={errorMessage}
            onClick={goToStep}>
            <Wizard.Step active={currWizardStep === 0} title="Usuários">
                <Wizard.StepContent>
                    <UserList 
                        show={show}
                        selectedUsers={selectedUsers}
                        handleRemoveUser={handleRemoveUser}
                        toggleSelectUser={toggleSelectUser}
                        setErrorMessage={setErrorMessage} />

                </Wizard.StepContent>
            </Wizard.Step>
            <Wizard.Step active={currWizardStep === 1} title="Mensagem">
                <Wizard.StepContent>
                    <Message
                        messages={messages}
                        setMessages={setMessages}
                     />
                </Wizard.StepContent>
            </Wizard.Step>
            <Wizard.Step active={currWizardStep === 2} title="Confirmação">
                <Wizard.StepContent>
                    <Confirmation
                        selectedUsers={selectedUsers}
                        messages={messages}
                        toggle={toggle} 
                    />
                </Wizard.StepContent>
            </Wizard.Step>
            <Wizard.Footer>
                <div className="d-flex justify-content-between">
                    <Button.Cancel
                        disabled={startNewChat}
                        onClick={toggle} />
                    <div className="d-flex">
                        {currWizardStep > 0 &&
                            <Button.Prev
                                disabled={startNewChat}
                                className="mr-50"
                                onClick={() => goToStep(currWizardStep - 1)} />
                        }
                        {currWizardStep === 2 ?
                            <Button.Confirm
                                disabled={startNewChat}
                                loading={startNewChat}
                                dark
                                onClick={() => {handleStartNewInternalChat({selectedUsers, messages})}} />
                            :
                            <Button.Next
                                dark
                                onClick={() => goToStep(currWizardStep + 1)} />
                        }
                    </div>
                </div>
            </Wizard.Footer>
        </Wizard>
    )
}

export default React.memo(StartNewInternalChatModal);