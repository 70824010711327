import React from 'react';
import { X } from "react-feather";
import moment from 'moment-timezone';

function VersionNotification({ data, type, handleClickNotification }) {
    const array = data
    const items = array.reduce((accum, item) => {
      return accum + item.totalItems
    }, 0)
    const lastItem = array.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))[0]
    return <div className="notification-item">
      <div className="notification-icon">
        <img src={require('../../../../assets/img/svg/new-version.svg')} width={45} height={45} alt="New Version" />
      </div>
      <div className="notification-description">
        <h2>Atualização de versão</h2>
        <p>{lastItem.text}</p>
        <small>{moment.utc(lastItem.createdAt).local().fromNow()}</small>
      </div>
      <div 
        className="notification-delete" 
        onClick={(e) => handleClickNotification(e, type, array)}>
        <X />
      </div>
    </div>

}

export default VersionNotification