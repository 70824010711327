import mock from "../mock"
let data = {
    channels: [
        {
            id: 1,
            name: "Facebook CT",
            welcomeMessage: "Seja bem vindo(a). Como podemos ajudá-lo(a)?",
            type: "facebook",
            title: "Oficial CT Voicer",
            date: new Date(),
            chatbot: true,
            status: true,
        },
        {
            id: 2,
            name: "WhatsApp CT Comercial",
            welcomeMessage: null,
            type: "whatsapp",
            title: "4121039300",
            date: new Date(),
            chatbot: true,
            status: true,
        },
        {
            id: 3,
            name: "WhatsApp CT Suporte",
            welcomeMessage: null,
            type: "whatsapp",
            title: "4121039301",
            date: new Date(),
            chatbot: true,
            status: true,
        },
        {
            id: 4,
            name: "Facebook Antigo CT",
            welcomeMessage: "Seja bem vindo(a). Como podemos ajudá-lo(a)?",
            type: "facebook",
            title: "Oficial CT Voicer Old",
            date: "February 20, 2018",
            chatbot: false,
            status: false,
        },
        {
            id: 5,
            name: "WhatsApp CT Suporte Antigo",
            welcomeMessage: null,
            type: "whatsapp",
            title: "4121039301",
            date: "March 08, 2017",
            chatbot: false,
            status: false,
        }
      ],
}

// GET : Calendar Events
mock.onGet("/api/apps/settings/channels").reply(() => {
  return [200, data.channels]
})

mock.onPut("api/app/settings/channels").reply(request => {
  let reqdata = JSON.parse(request.data)
  const { channel } = reqdata
  /*if (data.chats[activeUser.uid]) {
    data.chats[activeUser.uid].msg.push(message)
  } else {
    let newMsg = {
      [contactId]: {
        isPinned: isPinned,
        msg: [message]
      }
    }
    Object.assign(data.chats, newMsg)
  }*/
  return [200, channel]
})

// POST : Add new user
mock.onPost("/api/apps/settings/channels").reply(request => {
  // Get task from post data
  let channel = JSON.parse(request.data)
  const uid = Math.floor(Math.random() * 99999)

  channel.channel.id = uid
  channel.channel.date = new Date()
  data.channels.push(channel.channel)
  
  return [201, { id: channel.id }]
})
