import React, { useState, useEffect, useRef } from 'react'
import {
  JsonHubProtocol,
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType
} from '@microsoft/signalr';
import { access_token } from "./utils/auth"
import PageVisibility from 'react-page-visibility';
import { useDispatch } from 'react-redux';
import { socketEvent } from './redux/actions/socket/socket';
import { internalChatActions } from './redux/actions/internalChat/internalChat';
import { camelCaseToPascalCase } from './utils/pascalCaseToCamelCase';



let visibility = true
function MainSocket({ children, event, visibilityChange }) {
  const [connection, setConnection] = useState(null);

  const dispatch = useDispatch()


  useEffect(() => {
    // console.log(connection)
    const token = access_token();
    const options = {
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true,
      accessTokenFactory: () => token
    };

    const conn = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL_SOCKET_DEV}/messageHub`, options)
      .withAutomaticReconnect()
      // .withHubProtocol(new JsonHubProtocol())
      // .configureLogging(LogLevel.Information)
      .build();

    conn.onclose(async (error) => {
      console.log('lost signalR connection')
    });

    conn.onreconnecting(error => {
      console.log('reconnecting to signalR')
    });

    conn.onreconnected(res => {
      console.log(conn)
      console.log(`Reconnected to signalR.`)
    })

    setConnection(conn)
    // 
  }, [])

  useEffect(() => {
    if (connection) {
      connection.start().then((res) => {
        // setConnection(conn)

        // connection.invoke('getConnectionId')
        // .then((connectionId) => {
        //   console.log(connectionId)
        // }).catch(err => console.log(err));


        connection.on('NotifyFromNotificationHub', message => {
          dispatch(socketEvent({
            event: 'NotifyFromNotificationHub',
            message: JSON.parse(message),
            visibility: visibility
          }))
        })

        connection.on('NotifyUpdateLoggedUserStatus', message => {
          dispatch(socketEvent({
            event: 'NotifyUpdateLoggedUserStatus',
            message: JSON.parse(message),
            visibility: visibility
          }))
          dispatch(internalChatActions('NOTIFY_UPDATE_LOGGED_USER_STATUS', camelCaseToPascalCase(JSON.parse(message))))
        })

        connection.on('NotifyMessageSupervisor', message => {
          dispatch(socketEvent({
            event: 'NotifyMessageSupervisor',
            message: JSON.parse(message),
            visibility: visibility
          }))
        });

        //NOTIFICAÇÃO DE RECEBIMENTO DE UMA TRANSFERÊNCIA
        connection.on('NotifyTransferRemove', message => {
          dispatch(socketEvent({
            event: 'NotifyTransferRemove',
            message: JSON.parse(message),
            visibility: visibility
          }))
        });

        //NOTIFICAÇÃO DE ENVIO DE CHAT PARA OUTRO AGENTE
        connection.on('NotifyTransferAdd', message => {
          dispatch(socketEvent({
            event: 'NotifyTransferAdd',
            message: JSON.parse(message),
            visibility: visibility
          }))
        });

        //NOTIFICAÇÃO DE ENCERRAMENTO DE CHAT
        connection.on('NotifyClosedProtocol', message => {
          dispatch(socketEvent({
            event: 'NotifyClosedProtocol',
            message: JSON.parse(message),
            visibility: visibility
          }))
        });


        //NOTIFICAÇÃO DE MENSAGEM APAGADA
        connection.on('NotifyMessageDeletedSupervisor', message => {
          dispatch(socketEvent({
            event: 'NotifyMessageDeletedSupervisor',
            message: JSON.parse(message),
            visibility: visibility
          }))
        });

        //NOTIFICAÇÃO DE MENSAGEM APAGADA
        connection.on('NotifyLogoutCommand', message => {
          console.log(message)
        });

        // NOTIFICAÇÃO DE MENSAGEM EDITADA
        connection.on('NotifyTextMessageUpdatedSupervisor', message => {
          dispatch(socketEvent({
            event: 'NotifyTextMessageUpdatedSupervisor',
            message: JSON.parse(message),
            visibility: visibility,
            type: 'edited'
          }))
        });

        connection.on('CorporateNotifyUserTypingMessage', (notification) => {
          console.log('CorporateNotifyUserTypingMessage', notification)
          // Despachar a ação para receber a notificação com os dados recebidos
          dispatch(internalChatActions('CORPORATE_NOTIFY_USER_TYPING_MESSAGE', JSON.parse(notification)))
        });

        connection.on('CorporateNotifyMessage', (notification) => {
          console.log('CorporateNotifyMessage', notification)
          // Despachar a ação para receber a notificação com os dados recebidos
          dispatch(internalChatActions('CORPORATE_NOTIFY_MESSAGE', JSON.parse(notification)))

        });

        connection.on('CorporateNotifyMessageDeleted', (notification) => {
          console.log('CorporateNotifyMessageDeleted', notification)
          // Despachar a ação para receber a notificação com os dados recebidos
          dispatch(internalChatActions('CORPORATE_NOTIFY_MESSAGE_DELETE', JSON.parse(notification)))

        });

        connection.on('CorporateNotifyFinishedConversation', (notification) => {
          console.log('CorporateNotifyFinishedConversation', notification)
          // Despachar a ação para receber a notificação com os dados recebidos
          dispatch(internalChatActions('CORPORATE_NOTIFY_FINISHED_CONVERSATION', JSON.parse(notification)))

        });

        connection.on('CorporateNotifyMessageWasRead', (notification) => {
          console.log('CorporateNotifyMessageWasRead', notification)
          // Despachar a ação para receber a notificação com os dados recebidos
          dispatch(internalChatActions('CORPORATE_NOTIFY_MESSAGE_WAS_READ', JSON.parse(notification)))

        });



      }).catch(err => console.log(err))

    }
  }, [connection])

  function handleVisibilityChange(isVisible) {
    visibility = isVisible
    visibilityChange(isVisible)
  }


  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <div>{children}</div>
    </PageVisibility>
  )
}

export default MainSocket;