import React from 'react';
import { connect } from 'react-redux';

const checkPermissions = (userPermissions, allowedPermissions) => {
   const permissions =  userPermissions && userPermissions.accessProfiles && userPermissions.accessProfiles.map( m => m.code)
   const userRole = userPermissions && userPermissions.role
  if (allowedPermissions.length === 0 || userRole === 1) {
    return true;
  }

  return permissions && permissions.some(permission =>
    allowedPermissions.includes(permission)
  );
};

const AccessControl = ({
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(userPermissions, allowedPermissions);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  permissions: [],
  renderNoAccess: () => null,
};

export default connect(state => ({
    userPermissions: state.auth.userData,
  }))(AccessControl);