const initialState = {
    groups: [],
}
    
    const groupsReducer = (state = initialState, action) => {
      switch (action.type) {
        case "FETCH_GROUPS":
          console.log(action.groups.data)
          let groups = [...state.groups]
          groups = action.groups.data
          return { ...state, groups }
        case "PUT_GROUP":
            return { ...state, groups: action.groups }
            case "UPDATE_GROUP":
            return { ...state }
        case "ADD_GROUP":
          return [...state, [action.group] ]
      default:
          return state
      }
    }
    
    export default groupsReducer