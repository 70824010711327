import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/groups`;

const groupsService = {
	getGroups: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(url, { params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	postGroups: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	putGroups: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	patchGroups: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.patch(`${url}/status`, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getGroupsNumberLicenses: () => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/number-licenses`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	putGroupsNumberLicences: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/number-licenses`, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
};

export default groupsService;
