import React from 'react';
import moment from 'moment';
import './message.scss';

export const Message = React.memo(({ 
    children, 
    item, 
    prevMessage,
    nextMessage,
    isSent,
    isChatbot=false,
    color="primary",
    style={}
}) => {

    const { createdAt, id, isDeleted } = item;
    const nextIsSent = nextMessage?.userSender ? true : false;
    const prevIsSent = prevMessage?.userSender ? true : false;
    const roundStart = prevIsSent !== isSent || moment(createdAt).format('HH:mm') !== moment(prevMessage?.createdAt).format('HH:mm')
    const roundEnd = nextIsSent !== isSent || moment(createdAt).format('HH:mm') !== moment(nextMessage?.createdAt).format('HH:mm')

    const messageTemplate = item?.attachments?.length > 0 && item?.attachments?.length <= 3 ? 'attach-single-column' :
                            item?.attachments?.length > 3 ? 'attach-single-column' :
                                item?.contacts?.length > 0 ? 'attach-contacts' :
                                    item?.replyMessage ? 'message-reply' :
                                        'message-pure-text';
                    

    return <div
        id={id}
        data-date={createdAt}
        style={style}
        className={`chat-messages-item-ui ${messageTemplate} ${isSent || isChatbot ? "sent" : "received"} ${roundStart ? 'round-start' : ''} ${roundEnd ? 'round-end' : ''} ${isChatbot ? 'chatbot' : ''} ${isDeleted ? 'deleted' : ''} ${color}`}>
        <div className="chat-messages-item">
            <div>
                {children}
            </div>
        </div>
    </div>

});
