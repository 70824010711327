import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/webhookplatform`;

const webhookServices = {
	putWebhookPlatformChangeBrzoWebhook: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/change-brzo-webhook`, { ...params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	putWebhookPlatform360DialogWebhook: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/change-360dialog-webhook`, { ...params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
};

export default webhookServices;
