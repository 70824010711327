import React from "react";
import { ReactComponent as DoubleCheck } from "../assets/message-status/double-check.svg";
import { ReactComponent as SingleCheck } from "../assets/message-status/single-check.svg";
import { ReactComponent as Clock } from "../assets/message-status/clock.svg";
import { ReactComponent as Failed } from "../assets/message-status/failed.svg";
import "./message-status.scss";

/** Legenda de status das mensagens
 *  - null: Mensagem não foi enviada ao telefone do destinatário ainda
 *  - 0: Mensagem enviada aos servidores do Omnyve
 *  - 1: Em fila para envio ao destinatário
 *  - 2: Enviada ao destinatátio
 *  - 3: Falha na entrega ao destinatário
 *  - 4: Recebida pelo destinatário
 *  - 5: Lida pelo destinatário
 */

/** Ícone de status das mensagens
 *  - null: relógio cinza (rederizado na tela)
 *  - 0: relógio azul (enviado ao servidor do Omnyve)
 *  - 1: Check simples cinza (recebido 200 do servidor do Omnyve)
 *  - 2: Check simples cinza (mensagem enviada ao destinatário)
 *  - 3: Falha na entrega ao destinatário
 *  - 4: Check duplo cinza (mensagem entregue no dispositivo do usuário)
 *  - 5: Check duplo azul (mensagem foi lida pelo usuário)
 */

export const MessageStatus = React.memo(({status}) => {

    switch (status) {
        case null:
            return (
                <div className="message-status-ui rendered" data-status={status}>
                    <Clock width={13} height={10} />
                </div>
            )
        case 0:
            return (
                <div className="message-status-ui sent-to-server" data-status={status}>
                    <Clock width={15} height={12} />
                </div>
            )
        case 1:
            return (
                <div className="message-status-ui queued-to-send-to-user" data-status={status}>
                    <SingleCheck width={13} height={10} />
                </div>
            )
        case 2:
            return (
                <div className="message-status-ui sent-to-user" data-status={status}>
                    <SingleCheck width={13} height={10} />
                </div>
            )
        case 3:
            return (
                <div className="message-status-ui delivery-failed" data-status={status}>
                    <Failed width={13} height={13} />
                </div>
            )
        case 4:
            return (
                <div className="message-status-ui delivered-to-user" data-status={status}>
                    <DoubleCheck width={13} height={10} />
                </div>
            )
        case 5:
            return (
                <div className="message-status-ui read-by-user">
                    <DoubleCheck width={13} height={10} />
                </div>
            )
        default:
            return null;
    }

});