import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR, STORE_USER } from '../../../helpers/constants';
import { IReleaseVersion } from '../../../interfaces/releaseNotes/releaseVersions.interface';

import {
    STORE_RELEASE_NOTES_VERSIONS,
    RELEASE_NOTE_CHANGE_PRESENTATION
} from '../../../helpers/releaseNotesConstants';

import {
    getReleaseNoteVersions,
    listOfReleaseNotesVersions,
    getReleaseVersionUserState,
    setUserCurrentReleaseVersion
} from '../utils/releaseNotesFunctions/showReleaseNotesToUserFunc';

import {
    toggleReleaseNotesModals
} from '../utils/releaseNotesFunctions/releaseNodesModalsFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

function* loadReleaseNotes(action: any) {
    try {
        const { data, response } = yield call(getReleaseNoteVersions);
        if (data) {
            yield put(listOfReleaseNotesVersions(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    
    } 
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

const getReleaseNotesVersions = (state: { releaseNotes: { releaseVersions: any; }; }) => state.releaseNotes.releaseVersions;
const getAccessToken = (state: any) => state.auth.user.access_token;

function* checkReleaseVersionUserState() {
    try {
        const releaseVersions: IReleaseVersion[] = yield select(getReleaseNotesVersions);
        const lastVersion: IReleaseVersion = releaseVersions[0];
        const accessToken: string = yield select(getAccessToken);
        const { data, response } = yield call(getReleaseVersionUserState, lastVersion, accessToken);
        
        if (data) {
            yield put(setUserCurrentReleaseVersion(data));

            yield put(toggleReleaseNotesModals({
                welcome: true,
                presentation: false
            }));
        }
        else if(response){
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* handleChangeReleaseNotePresentation(action: any) {
    try {
        const { payload } = action;
        yield put(setUserCurrentReleaseVersion(action.data));
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

export default function* showReleaseToUserSaga() {
    yield takeEvery(STORE_USER, loadReleaseNotes);
    yield takeEvery(STORE_RELEASE_NOTES_VERSIONS, checkReleaseVersionUserState);
    yield takeEvery(RELEASE_NOTE_CHANGE_PRESENTATION, handleChangeReleaseNotePresentation);
}
