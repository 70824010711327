import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/servicechannelauthenticationintegration`;

const serviceChannelAuthenticationIntegrationService = {
	getServiceChannelAuthenticationIntegrationService: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(url, {params})
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	postServiceChannelAuthenticationIntegrationServiceGeneratekeystoservicechannel: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/generatekeystoservicechannel`, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	patchServiceChannelAuthenticationIntegrationServiceTemplalteCredentials: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.patch(`${url}/templalte-credentials`, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	}
};

export default serviceChannelAuthenticationIntegrationService;
