import { getGmt0 } from '../../../utils/dateUtility';
import moment from 'moment';

export const internalChatMessageObject = (contact, message) => {
    return {
        createdAt: getGmt0(moment()),
        userReceiver: {
            userId: contact.userId,
            name: contact.name,
        },
        isSent: true,
        messageText: message.messageText,
        externalReference: message.externalReference,
        messageStatus: null
    }
}
