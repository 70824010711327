import React from "react"
import {
  Alert,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  Spinner
} from "reactstrap"
import "../../../../assets/scss/pages/authentication.scss"
import api from "../../../../services/pages/authentication/authentication"
import { X } from "react-feather"
import { FormattedMessage, injectIntl } from "react-intl"

import Modal from "../../../../newComponents/modal/Modal";
import Button from "../../../../newComponents/button/Button";
import { TextInput } from "../../../../newComponents/forms";

//import { errorHandle } from '../../../utils/error-handle'


class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      alertMessage: null,
      recoverySuccess: false,
      request: false,
      oldpass: '',
      newpass: '',
      confirm: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }


  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (name === 'newpass') {
      this.strength(value)
    }
  }

  strength = (value) => {

    const sequential = /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+/ig
    let points = 100
    if (value.search(/[A-Z]/) < 0) {
      console.log("Your password needs an upper case letter")
      this.setState({ uppercase: false })
      points = points - 20
    } else {
      this.setState({ uppercase: true })
    }
    if (value.search(/[0-9]/) < 0) {
      console.log("Your password needs a number")
      this.setState({ number: false })
      points = points - 20
    } else {
      this.setState({ number: true })
    }
    if (value.length < 8) {
      this.setState({ characters: false })
      points = points - 20
      console.log("Your password needs a minimum of 8 characters")
    } else {
      this.setState({ characters: true })
    }
    if (value.search(/[a-z]/) < 0) {
      console.log("Your password needs a lower case letter")
      points = points - 20
    }
    if (value.search(sequential) > 0) {
      console.log("have sequential")
      points = points - 20
    }
    this.setState({
      strength: points,
      color: points <= 20 ? 'bg-danger' : points <= 60 ? 'bg-warning' : points > 60 ? 'bg-success' : 'bg-default'
    })
  }

  changePassword = (e) => {

    const { uppercase, number, characters, newpass, confirm, oldpass } = this.state
    const { intl } = this.props
    let erros = ''
    if (!uppercase) {
      erros = erros + `<li>${intl.formatMessage({ id: "Pelo menos 1 letra maiúscula" })}</li>`
    }
    if (!number) {
      erros = erros + `<li>${intl.formatMessage({ id: "Pelo menos 1 número" })}</li>`
    }
    if (!characters) {
      erros = erros + `<li>${intl.formatMessage({ id: "Pelo menos 8 caracteres" })}</li>`
    }
    if (newpass !== confirm) {
      erros = erros + `<li>${intl.formatMessage({ id: "A senha e a confirmação estão diferentes" })}</li>`
    }
    if (!newpass) {
      erros = erros + `<li>${intl.formatMessage({ id: "Informe uma nova senha" })}</li>`
    }

    if (!oldpass) {
      erros = erros + `<li>${intl.formatMessage({ id: "Informe a sua senha atual" })}</li>`
    }
    if (erros) {
      this.setState({
        alertColor: 'danger',
        alertMessage: erros,
        request: false
      })
    } else {
      this.setState({ request: true })

      api.changePassword({ oldPassword: oldpass, newPassword: newpass })
        .then(res => {
          this.setState({ recoverySuccess: res.data, request: false })
        })
        .catch(err => {
          const error = err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]
          this.setState({
            alertColor: 'danger',
            alertMessage: error,
            request: false
          })
        })
    }
    /*if(regexp.test(this.state.value)){
      api.resetPassword({ email: this.state.value })
      .then(res => {
        this.setState({recoverySuccess: res.data, request: false})
      })
      .catch(err => { 
        const error = err &&  err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]
        this.setState({
          alertColor: 'danger',
          alertMessage: error,
          request: false
        })
      })
    } else {
      console.log('invalido')
      this.setState({
        alertColor: 'danger',
        alertMessage: 'Digite um endereço de e-mail válido',
        request: false
      })
    }*/


  }

  render() {
    const { value, strength, characters, uppercase, number, newpass, oldpass, color, showModal } = this.state
    const { open, intl } = this.props
    return (
      <Modal
        position="center"
        show={open}
        toggle={this.props.togglePasswordModal}
      >
        <Modal.Header>{intl.formatMessage({ id: "Alterar senha" })}</Modal.Header>
        <Modal.Content>

          {this.state.alertMessage &&
            <Alert color={this.state.alertColor} className="mb-2">
              <small dangerouslySetInnerHTML={{
                __html: this.state.alertMessage
              }} />

            </Alert>
          }
          {!this.state.recoverySuccess ?
            <Form>
              <TextInput
                type="text"
                name="oldpass"
                className="mb-50"
                placeholder="Senha atual"
                value={this.state.oldpass}
                onChange={this.handleInputChange}
                errorMessage={null}
              />
              <TextInput
                type="password"
                name="newpass"
                className="mb-1"
                placeholder="Nova senha"
                value={this.state.newpass}
                onChange={this.handleInputChange}
                errorMessage={null}
              />
              <div className="password-strength">
                <div className="header">
                  <h5><FormattedMessage id="Força da senha" /></h5>
                  <div className="strength">
                    <span className={`point ${newpass && newpass.length > 0 && strength >= 20 && color}`} />
                    <span className={`point ${newpass && newpass.length > 0 && strength >= 40 && color}`} />
                    <span className={`point ${newpass && newpass.length > 0 && strength >= 60 && color}`} />
                    <span className={`point ${newpass && newpass.length > 0 && strength >= 80 && color}`} />
                    <span className={`point ${newpass && newpass.length > 0 && strength > 80 && color}`} />
                  </div>
                </div>
                <ul>
                  <li className={!newpass ? '' : newpass && newpass.length > 0 && !characters ? 'danger' : 'success'}><FormattedMessage id="Pelo menos 8 caracteres" /></li>
                  <li className={!newpass ? '' : newpass && newpass.length > 0 && !uppercase ? 'danger' : 'success'}><FormattedMessage id="Pelo menos 1 letra maiúscula" /></li>
                  <li className={!newpass ? '' : newpass && newpass.length > 0 && !number ? 'danger' : 'success'}><FormattedMessage id="Pelo menos 1 número" /></li>
                </ul>
              </div>
              
              <TextInput
                type="password"
                name="confirm"
                className="mt-1"
                placeholder="Confirme a nova senha"
                value={this.state.confirm}
                onChange={this.handleInputChange}
                errorMessage={null}
              />

            </Form>
            :
            <Alert color="success" className="mb-2">
              <FormattedMessage id="Sua senha foi alterada com sucesso e você deverá usá-la no seu próximo acesso" />.
            </Alert>
          }


        </Modal.Content>
        <Modal.Footer>
          <div className="d-flex justify-content-between">
            <Button.Cancel
              disabled={this.state.request}
              onClick={this.props.togglePasswordModal} />

            <Button.Confirm
              disabled={this.state.request}
              loading={this.state.request}
              dark
              onClick={e => {
                e.preventDefault()
                !this.state.request && this.changePassword()
              }
              } />
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default injectIntl(React.memo(ChangePassword))
