import React from 'react';
import { ReactComponent as MessagesIcon } from "../../../../assets/img/svg/messages-icon.svg";
import { X } from "react-feather";
import moment from 'moment-timezone';

function MonitoringNotification({ data, type, handleClickNotification }) {
    const array = data
    const items = array.reduce((accum, item) => {
      return accum + item.totalItems
    }, 0)
    const lastDatetime = array.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))[0]
    return <div className="notification-item cursor-pointer" onClick={e => handleClickNotification(e, 1, array)}>
      <div className="notification-icon">
        <MessagesIcon />
      </div>
      <div className="notification-description">
        <h2>Possíveis problemas com a fila de atendimento</h2>
        <p><b>{items === 1 ? '1 conversa' : `${items} conversas`}</b> aguardando interação dos agentes</p>
        <small>{moment.utc(lastDatetime.createdAt).local().fromNow()}</small>
      </div>
      <div className="notification-delete">
        <X />
      </div>
    </div>
}

export default MonitoringNotification