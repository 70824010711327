const initialState = {
    users: [],
    filteredUsers: [],
    //sidebar: false,
    selectedUser: null,
    userProfile: []
  }
  
  const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SETTINGS_FETCH_USERS":
        let users = [...state.users]
        users = action.users
        return { ...state, users }
      case "LOGGED_USER":
        return { ...state, userProfile: action.loggeduser }
  
      case "SETTINGS_UPDATE_STATUS":
        state.users.find(
          u => u.id === action.id
        ).status = !state.users.find(i => i.id === action.id).status
        return { ...state }
        
      case "SETTINGS_UPDATE_USER":
        /*let userToUpdate = action.user
        let updatedState = { ...state.users, [userToUpdate.id]: { ...userToUpdate } }
        return { ...state, users: updatedState }*/
        let userUpdate = state.users.find(i => i.id === action.activeUser.id)
        userUpdate.name = action.activeUser.name
        userUpdate.status = action.activeUser.status
        return { ...state }


    case "SETTINGS_SEARCH_USERS":
      let filteredUsers = state.users.filter(val => {
         if (action.query) {
          return (
            (val.name.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.email.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.group.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.level.toLowerCase().includes(action.query.term.toLowerCase())) &&
            (action.query.actives && val.status === true || 
            action.query.inactives && val.status === false)
          )
        } else {
          return state.users
        }
      })
      return { ...state, filteredUsers }
    case "SETTINGS_ADD_USER":
      console.log(action)
      return { ...state }

      /*console.log(action.user)
      let userToUpdate = action.user,
      extractedUser = state.users.map(user => {
        if (user.id === userToUpdate.id) {
          console.log('replace')
          return userToUpdate
        }
        console.log('all')
        return user
      })
      console.log(extractedUser)
    return { ...state, users: extractedUser }*/
    /*case "SETTINGS_UPDATE_STATUS":
      let changeStatus = state.users.find(user => user.id === action.id)
      changeStatus.status = changeStatus.status === 'active' ? 'inactive' : 'active'
      
    return { ...state }*/

      /*case "ADD_EVENT":
        state.events.push(action.event)
        return { ...state }
      case "REMOVE_EVENT":
        let index = state.events.findIndex(item => item.id === action.id)
        let updatedData = [...state.events]
        updatedData.splice(index, 1)
        return { ...state, events: updatedData }
      case "UPDATE_EVENT":
        let updatedEvents = state.events.map(event => {
          if (event.id === action.event.id) {
            return action.event
          }
          return event
        })
        return { ...state, events: updatedEvents }
      case "CHANGE_STATE":
        let statusUpdate = state.events.find(i => i.id === action.id)
        statusUpdate.status = statusUpdate.status ? false : true
        return { ...state }
  
      case "UPDATE_DRAG":
        let eventToDrag = action.event,
          extractedEvent = state.events.map(event => {
            if (event.id === eventToDrag.id) {
              return eventToDrag
            }
            return event
          })
        return { ...state, events: extractedEvent }
      case "EVENT_RESIZE":
        let eventToResize = action.event,
          resizeEvent = state.events.map(event => {
            if (event.id === eventToResize.id) {
              return eventToResize
            }
            return event
          })
        return { ...state, events: resizeEvent }
      case "HANDLE_SIDEBAR":
        return { ...state, sidebar: action.status }
      case "HANDLE_SELECTED_EVENT":
        return { ...state, selectedEvent: action.event }*/
      default:
        return state
    }
  }
  
  export default settingsReducer
  