import {
    SAGA_ERROR,
} from '../../../helpers/constants';

import {
    INTERNAL_CHAT_CONTACTS,
    ACTIVE_INTERNAL_CHAT,
    UPDATE_INTERNAL_CHAT_MESSAGING_INFO,
    IS_LOADING,
    UPDATE_INTERNAL_CHAT_CONTACT_STATUS
} from '../../../helpers/internalChatConstants'

const initialState = {
    activeChat: {
        user: null,
        position: {
          x: 0,
          y: 0
        }
    },
    contacts: [],
    userStatus: {},
    messaging: {},
    isLoading: {
        startNewChat: false
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INTERNAL_CHAT_CONTACTS: {
            console.log(action)
            return { 
                ...state, 
                contacts: action.contacts, 
                userStatus: action?.userStatus ? action.userStatus : state.userStatus 
            }
        }
        case UPDATE_INTERNAL_CHAT_CONTACT_STATUS: {
            return { 
                ...state, 
                userStatus: { 
                    ...state.userStatus, 
                    byUserId: {
                        ...state.userStatus.byUserId,
                        [action.data.userId]: action.data
                    }
                } 
            }
        }
        case ACTIVE_INTERNAL_CHAT: {
            return { ...state, activeChat: action.data }
        }
        case UPDATE_INTERNAL_CHAT_MESSAGING_INFO: {
            if(state.activeChat?.user?.id){
                return { 
                    ...state, 
                    messaging: {
                        ...state.messaging,
                        [state.activeChat?.user?.id]: {
                            ...state.messaging[state.activeChat?.user?.id],
                            messageText: action.data
                        }
                    } 
                };
            } else {
                return state;
            }
        }
        case IS_LOADING: {
            return { ...state, isLoading: { ...state.isLoading, ...action.data } }
        }
        case SAGA_ERROR: {
            return { ...state, error: action.response};
        }
        default:
            return state
    }
}