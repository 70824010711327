import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/overflow`;

const overflowService = {
	getOverflowServiceChannel: (id) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/servicechannel/${id}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	postOverflow: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	putOverflow: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
};

export default overflowService;
