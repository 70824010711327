import { client } from "../../utils/axios-utils";

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/qualifiergroupconfiguration`;

const qualifierGroupConfigurationService = {
  getQualifierGroupConfigurationByGroupId: (groupId) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/by-groupid/${groupId}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  postQualifierGroupConfiguration: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(`${url}/list`, [...params])
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
  putQualifierGroupConfiguration: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(`${url}/list`, [...params])
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
  deleteQualifierGroupConfiguration: (ids) => {
    return new Promise((resolve, reject) => {
      return client()
        .delete(`${url}/list`, [...ids])
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
};

export default qualifierGroupConfigurationService;
