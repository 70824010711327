import { call, put, takeEvery } from 'redux-saga/effects';

import { USER_DATA, SAGA_ERROR } from '../../../helpers/constants';

import {
    getInternalContactList,
    getListOfInternalChatContacts
} from '../utils/internalChatFunctions/loadContactListFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

function* loadInternalChatContacts(action) {
    try {
        const { data, response } = yield call(getInternalContactList);
        if (data) {
            yield put(getListOfInternalChatContacts(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    } catch (e) {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }
}

export default function* loadContactListSaga() {
    yield takeEvery('STORE_USER', loadInternalChatContacts);
}