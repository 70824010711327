import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_CORPORATECHAT_API_URL}/${process.env.REACT_APP_API_URI}/conversations`;

const conversationsService = {
	getConversationsLoggeduser: () => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/loggeduser`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	putConversationsTypingConversationId: ({conversationId, isTyping}) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/typing/${conversationId}/${isTyping}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	postConversationsEnd: (conversationId) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/end/${conversationId}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	}
};

export default conversationsService;
