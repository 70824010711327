import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/releaseversionattachments`;

const releaseVersionAttachmentsService =  {
    postReleaseVersionAttachments: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getReleaseVersionAttachments: (storageName) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${url}/${storageName.storageName}`, )
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
}
export default  releaseVersionAttachmentsService;