import mock from "../mock"
let data = {
    routing: [
        {
            id: "1",
            routing: 'client',
            priority: 0,
            status: true
        },
        {
          id: "2",
          routing: 'agent',
          priority: 1,
          status: true
        },
    ]
}

// GET : Calendar Events
mock.onGet("/api/apps/group-rules/routing").reply(() => {
  return [200, data.routing]
})

mock.onPut("/api/apps/group-rules/routing").reply(request => {
    let { params } = JSON.parse(request.data)
    let reordered = []
    params.map((g,i) => {
        let routing = data.routing.find(r => r.routing === g.routing)
        routing.priority = data.routing.find(r => r.routing === g.routing).priority === 1 ? 0 : 1
        reordered.push(routing)
    })
  return [200, reordered]
})

mock.onPatch("/api/apps/group-rules/routing").reply(request => {

  let { id } = JSON.parse(request.data)

  const change = data.routing.find(
    c => c.id === id
  ).status = !data.routing.find(c => c.id === id).status
  console.log(data.routing)
  return [201, data.routing]
})
