import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { validateUrl } from "../../utils/validation";
import CloseButton from "../closeButton/CloseButton";
import Button from "../button/Button";
import BeautyScrollbar from "../beautyScrollbar/BeautyScrollbar";
import Modal from "../modal/Modal";
import { Checkbox } from "../forms";
import moment from "moment";

import useChatDispatchActions from "../../customHooks/useChatDispatchActions";

import {
  SHOW_RELEASE_NOTE,
  RELEASE_NOTES_PAGE_NAVIGATION,
  TOGGLE_RELEASE_NOTES_MODALS,
  UPDATE_RELEASE_NOTE_USER_STATUS,
  RELEASE_NOTE_CHANGE_PRESENTATION,
} from "../../helpers/releaseNotesConstants";

import "./release-notes.scss";

function ReleaseNotes() {
  const history = useHistory();
  const [showVersionList, setShowVersionList] = useState(false);
  const [checkedDontShowAnymore, setCheckedDontShowAnymore] = useState(false);

  const dispatchActions = useChatDispatchActions();

  const {
    topic,
    modals,
    currentRelease,
    // showVersionList,
    releaseVersions,
    releaseVersionsPages,
  } = useSelector((state) => state.releaseNotes);

  useEffect(() => {
    if (modals.presentation) {
      document.addEventListener("click", goToExternalAddress);
    } else {
      document.removeEventListener("click", goToExternalAddress);
    }
  }, [modals.presentation]);

  const goToPage = (e, urlData) => {
    e.preventDefault();
    const { url, isUrlExternal } = urlData;
    console.log(urlData);
    if (isUrlExternal) {
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  const toggleShowVersionList = () => {
    setShowVersionList(!showVersionList);
  };

  const goToExternalAddress = (e) => {
    e.preventDefault();
    let target = e.target;
    while (target && target.tagName !== "A") {
      target = target.parentNode;
      if (!target) return;
    }
    if (validateUrl(target.getAttribute("href"))) {
      window.open(target.href, "_blank").focus();
    }
  };

  const toggleDontShowAnymore = (e) => {
    const { checked } = e.target;
    setCheckedDontShowAnymore(checked);
  };

  const handleClosePresentation = () => {
    dispatchActions(TOGGLE_RELEASE_NOTES_MODALS, {
      ...modals,
      presentation: false,
    });
  };

  const handleCloseWelcome = () => {
    dispatchActions(TOGGLE_RELEASE_NOTES_MODALS, {
      ...modals,
      welcome: false,
    });
  };

  useEffect(() => {
    setShowVersionList(false);
  }, [currentRelease]);

  const { loading, pages } = releaseVersionsPages;
  const currentPage = pages[topic];

  const pageDescription = () => {
    const inputString = currentPage?.description;

    // Use a regular expression to find and replace the figure with the iframe
    const replacedString = inputString.replace(
      /<figure class="media"><oembed url="https:\/\/www\.youtube\.com\/watch\?v=([A-Za-z0-9_-]+)(?:&[^"]+)?"><\/oembed><\/figure>/g,
      '<iframe width="350" height="215" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>'
    );

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: replacedString,
        }}
      />
    );
  };

  const modalContent = (
    <>
      {modals.welcome && <Modal
        show={modals.welcome}
        toggle={handleCloseWelcome}
        size="sm"
        position="center"
        onPressEnter={() => {}}
        removeShadowOnClick
      >
        <Modal.Header>
          Descubra as novidades da versão {currentRelease?.versionNumber}
        </Modal.Header>
        <Modal.Content>
          Olá, no último dia{" "}
          {moment(currentRelease?.createdAt).format("DD/MM/YYYY")} lançamos a
          versão {currentRelease?.versionNumber}. Gostaria de conhecer as
          funcionalidades dessa versão?
          <Checkbox
            id={`checkbox`}
            name={`checkbox}`}
            label="Não mostrar novamente"
            className="mt-1"
            checked={checkedDontShowAnymore}
            onChange={toggleDontShowAnymore}
          />
        </Modal.Content>
        <Modal.Footer>
          <div className="d-flex justify-content-between">
            <Button.Default
              className="mr-50"
              disabled={false}
              onClick={() =>
                dispatchActions(UPDATE_RELEASE_NOTE_USER_STATUS, "remindme")
              }
            >
              Lembrar depois
            </Button.Default>
            {checkedDontShowAnymore ? (
              <Button.Primary
                dark
                onClick={() =>
                  dispatchActions(UPDATE_RELEASE_NOTE_USER_STATUS, "dismiss")
                }
                disabled={false}
              >
                Não ver novamente
              </Button.Primary>
            ) : (
              <Button.Success
                dark
                onClick={() => dispatchActions(SHOW_RELEASE_NOTE)}
                disabled={false}
              >
                Ver novidades
              </Button.Success>
            )}
          </div>
        </Modal.Footer>
      </Modal>}
      {modals.presentation && <div className={`help-center-ui ${modals.presentation ? "show" : ""}`}>
        <div className="help-center-header">
          <h5 className="cursor-pointer" onClick={toggleShowVersionList}>
            Novidades da versão {currentRelease?.versionNumber}
          </h5>
          <CloseButton onClick={handleClosePresentation} />
        </div>
        {!loading && currentPage?.title && (
          <>
            <div className="help-center-body">
              <div className="help-center-title">{currentPage?.title}</div>
              <BeautyScrollbar className="help-center-content">
                {pageDescription()}
              </BeautyScrollbar>
              <div className="show-related-page">
                <a
                  href="#"
                  onClick={(e) => goToPage(e, currentPage)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver página relacionada
                </a>
              </div>
            </div>
            <div className="help-center-footer">
              {topic !== 0 ? (
                <Button.Prev
                  onClick={() =>
                    dispatchActions(RELEASE_NOTES_PAGE_NAVIGATION, topic - 1)
                  }
                  disabled={false}
                />
              ) : (
                <div className="button-reserved-space"></div>
              )}
              <ul className="help-center-topics">
                {pages.map((key, index) => (
                  <li
                    key={index}
                    className={index === topic ? "active" : ""}
                    // onClick={() => showTopic(pages[key])}
                  >
                    {}
                  </li>
                ))}
              </ul>
              {topic !== pages.length - 1 ? (
                <Button.Next
                  dark
                  onClick={() =>
                    dispatchActions(RELEASE_NOTES_PAGE_NAVIGATION, topic + 1)
                  }
                  disabled={false}
                />
              ) : (
                <div className="button-reserved-space d-flex justify-content-end">
                  <Button.Primary
                    dark
                    onClick={() =>
                      dispatchActions(
                        UPDATE_RELEASE_NOTE_USER_STATUS,
                        "dismiss"
                      )
                    }
                    disabled={false}
                  >
                    Concluir
                  </Button.Primary>
                </div>
              )}
            </div>
          </>
        )}

        <div
          className={`help-center-version-list ${
            showVersionList ? "show" : ""
          }`}
        >
          <ul>
            {releaseVersions.map((item, index) => (
              <li
                key={index}
                className={item?.id === currentRelease?.id ? "active" : ""}
                onClick={() =>
                  dispatchActions(RELEASE_NOTE_CHANGE_PRESENTATION, item)
                }
              >
                <span>Versão {item.versionNumber}</span>{" "}
                <small>{moment(item.createdAt).format("DD/MM/YYYY")}</small>
              </li>
            ))}
          </ul>
        </div>
      </div>}
    </>
  );

  // return modalContent;

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById("modal-root")
  );
}

export default ReleaseNotes;
