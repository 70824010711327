import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ReactComponent as High } from '../assets/high.svg';
import { ReactComponent as Low } from '../assets/low.svg';
import { ReactComponent as Mute } from '../assets/mute.svg';
import { ReactComponent as None } from '../assets/none.svg';
import { ReactComponent as NoSound } from '../assets/no-sound.svg';

const Controls = ({
  audioRef,
  progressBarRef,
  duration,
  setTimeProgress,
  currentTrack,
  tracks,
  trackIndex,
  setTrackIndex,
  setCurrentTrack,
  handleNext,
}) => {
  // const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(60);
  const [muteVolume, setMuteVolume] = useState(false);
  const [speed, setSpeed] = useState(1);
  // const togglePlayPause = () => {
  //   setIsPlaying((prev) => !prev);
  // };

  const playAnimationRef = useRef();

  // const repeat = useCallback(() => {
  //   const currentTime = Math.floor(audioRef.current.currentTime);
  //   setTimeProgress(currentTime);
  //   progressBarRef.current.value = currentTime;
  //   progressBarRef.current.style.setProperty(
  //     '--range-progress',
  //     `${(currentTime / currentTrack.duration) * 100}%`
  //   );
  //   // playAnimationRef.current = requestAnimationFrame(repeat);
  // }, [audioRef, progressBarRef, setTimeProgress]);

  // useEffect(() => {
  //   console.log(audioRef.current.currentTime)
  // }, [audioRef?.current?.currentTime]);

  // useEffect(() => {
  //   if (isPlaying) {
  //     audioRef.current.play();
  //   } else {
  //     audioRef.current.pause();
  //   }
  //   console.log(audioRef)
  //   // playAnimationRef.current = requestAnimationFrame(repeat);
  // }, [isPlaying, audioRef]);


  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
    
  }, [volume, audioRef, muteVolume]);

  useEffect(() => { 
    audioRef.current.playbackRate = speed;
  }, [speed])


  const handleChangeSpeed = () => {
    setSpeed((prev) => {
      return prev == 2 ? .5 : prev + .5
    });
  }

  return (
    <div className="controls-wrapper">
      <div className="controls">
        {/* <button onClick={handlePrevious}>
          IoPlaySkipBackSharp
        </button>
        <button onClick={skipBackward}>
          IoPlayBackSharp
        </button> */}

        {/* <button onClick={togglePlayPause}>
          {isPlaying ? "Pause" : "Play"}
        </button> */}
        {/* <button onClick={skipForward}>
          IoPlayForwardSharp
        </button>
        <button onClick={handleNext}>
          IoPlaySkipForwardSharp
        </button> */}
      </div>
      <div className="volume">
        <div className="volume-control">
        <button onClick={() => setVolume((prev) => prev > 0 ? 0 : 60)}>
          {volume == 0 ? (
            <Mute width={10} />
          ) : volume < 5 ? (
            <NoSound width={10} />
          ) : volume < 40 ? (
            <Low width={8} />
          ) : (
            <High width={10} />
          )}
        </button>
        <input
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={(e) => setVolume(e.target.value)}
          style={{
            background: `linear-gradient(to right, #28C76F ${volume}%, #ccc ${volume}%)`,
          }}
        />
        </div>
        <div className="volume-label">{volume > 0 ? `${volume}%` : 'Mudo'}</div>
        
      </div>
      <div className="speed-control" onClick={handleChangeSpeed}>{speed}x</div>
    </div>
  );
};

export default Controls;
