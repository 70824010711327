import React, { useEffect, useRef, useState, ReactNode, ReactElement, FC } from 'react';

import BeautyScrollbar from '../beautyScrollbar/BeautyScrollbar';
import Overlay from '../overlay/Overlay';
import Loading from '../loading/Loading';

import './contact-list.scss';
import { Chevron } from '../pureHtmlIcons';

interface ContactListProps {
    children: ReactNode;
}

interface ContactListSectionProps {
    children: ReactNode;
    collapse?: boolean;
    onClick?: () => void;
    title?: string;
}

interface ContentProps {
    loading?: boolean;
    className?: string | null;
    style?: React.CSSProperties;
    scrollDown?: () => void;
    disableScrollX?: boolean;
    children: ReactNode;
}

const ContactList: FC<ContactListProps> & {
    Header: FC<ContactListSectionProps>;
    Content: FC<ContentProps>;
    Footer: FC<ContactListSectionProps>;
} = ({ children }) => {
    const [headerHeight, setHeaderHeight] = useState<number>(0);
    const [footerColapsed, setFooterColapsed] = useState<boolean>(false);

    const headerRef = useRef<HTMLDivElement | null>(null);

    const header = React.Children.map(children, (child) => {
        if ((child as ReactElement)?.type === ContactList.Header) {
            return React.cloneElement(child as ReactElement);
        }
        return null;
    });

    const content = React.Children.map(children, (child) => {
        if ((child as ReactElement)?.type === ContactList.Content) {
            return React.cloneElement(child as ReactElement);
        }
        return null;
    });

    const footer = React.Children.map(children, (child) => {
        if ((child as ReactElement)?.type === ContactList.Footer) {
            return React.cloneElement(child as ReactElement);
        }
        return null;
    });

    const footerOnClick = footer && footer[0] && (footer[0] as ReactElement).props?.onClick || null;
    const footerTitle = footer && footer[0] && (footer[0] as ReactElement).props?.title || null;
    const isFooterCollapsable = footer && footer[0] && (footer[0] as ReactElement).props?.collapse || false;

    const { loading = false, className = null, style = {}, scrollDown = () => {}, disableScrollX = false } =
        content && content[0] && (content[0] as ReactElement).props || {};

    const onToggleColapse = () => {
        if (footerOnClick) {
            footerOnClick();
            setFooterColapsed(!footerColapsed);
        }
    };

    useEffect(() => {
        setHeaderHeight(headerRef?.current?.offsetHeight || 0);
        const footerCollapsedProp =
            footer && typeof footer && footer[0] && (footer[0] as ReactElement).props?.collapsed !== undefined
                ? (footer[0] as ReactElement).props?.collapsed
                : false;
        setFooterColapsed(footerCollapsedProp);
    }, []);

    useEffect(() => {
        if (footerColapsed && footer && footer.length === 0) {
            setFooterColapsed(false);
        }
    }, [footer]);

    return (
        <div className="contact-search-ui">
            <div className="contact-search-container">
                <div className="contact-search-main-content">
                    <Overlay show={footerColapsed} onClick={onToggleColapse} />
                    {header && header.length > 0 && (
                        <div ref={headerRef} className="contact-search-header">
                            {header}
                        </div>
                    )}
                    <div className="contact-search-body" style={{ maxHeight: `calc(100% - ${headerHeight}px)` }}>
                        {loading && (
                            <div className="p-3 d-flex justify-content-center position-relrative">
                                <Loading />
                            </div>
                        )}
                        {content}
                    </div>
                </div>
            </div>
            {footer && footer.length > 0 && (
                <div className="contact-search-footer">
                    {footerTitle && (
                        <div className="contact-search-footer-header" onClick={onToggleColapse}>
                            <span>{footerTitle}</span>
                            {isFooterCollapsable && <Chevron direction={footerColapsed ? 'up' : 'down'} />}
                        </div>
                    )}
                    <BeautyScrollbar className={`contact-search-footer-body ${footerColapsed || !footerTitle ? 'show' : ''}`}>
                        {footer}
                    </BeautyScrollbar>
                </div>
            )}
        </div>
    );
};

ContactList.Header = ({ children }: ContactListSectionProps) => children;
ContactList.Content = ({ loading = false, className = null, style = {}, scrollDown = () => {}, disableScrollX = false, children }: ContentProps) =>
    children;
ContactList.Footer = ({ children }: ContactListSectionProps) => children;

export default ContactList;
