import { messagesService } from '../../../../newServices/corporateChat';

export const requestReadMessageIfActiveChat = (notification) => {
    return messagesService.putMessagesSetAsRead(notification.id)
        .then(res => res)
        .catch(err => err)
}

export const requestReadAllMessagesifActiveChat = ({id}) => {
    return messagesService.putMessagesConversationIdSetAsRead(id)
        .then(res => res)
        .catch(err => err)
}
