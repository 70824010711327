import React from "react";
import Avatar from "../../../newComponents/avatar/Avatar";
import { Heading } from "../../../newComponents/typography";

import "./message-contacts.scss";

/** Componente de exibição dos contatos anexados à conversas */

const colors = ["primary", "warning", "success"]
export const MessageContacts = React.memo(({contacts, metadata, onClick}) => {
    return contacts ? (
        <>
            <div className="message-contacts-ui">
                <div className="message-contacts-header" onClick={() => onClick('show', contacts)}>
                    <div className="message-contacts-avatar-container">
                        {contacts.slice(0,3).map((avatar, index) =>
                            <div 
                                key={`avatar_${avatar.number}`}  
                                className="message-contacts-avatar-item"
                                style={{marginLeft: index > 0 ? -30 : 0, zIndex: 3 - index}}
                                >
                                <Avatar size="lg" name={avatar.name} color={colors[index]} />
                            </div>
                        )}
                    </div>
                    <div className="message-contacts-info">
                        <div className="message-contacts-info-name">
                            <Heading 
                                color="gray"
                                className="mb-0"
                                type="h6" 
                                fw={500}
                                >
                                    {contacts.slice(0,3).map((contact) => contact.name).join(", ")}
                                </Heading>
                        </div>
                    </div>
                </div>
                {metadata}
                <div className="message-contacts-footer">
                    {contacts.length === 1 ? 
                        <div className="message-contacts-footer-item" onClick={() => onClick('active-mode', contacts[0])}>Enviar mensagem</div> : 
                        <div className="message-contacts-footer-item" onClick={() => onClick('show', contacts)}>Ver todos</div>
                    }
                </div>
            </div>
        </>
    ) : null;
})