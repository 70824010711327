import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR } from '../../../helpers/constants';
import { IReleaseVersion } from '../../../interfaces/releaseNotes/releaseVersions.interface';

import {
    SHOW_RELEASE_NOTE,
    RELEASE_NOTE_CHANGE_PRESENTATION
} from '../../../helpers/releaseNotesConstants';

import {
    getReleaseVersionPages,
    listOfReleaseNotesPages
} from '../utils/releaseNotesFunctions/presentReleaseNotesFunc';

import {
    toggleReleaseNotesModals
} from '../utils/releaseNotesFunctions/releaseNodesModalsFunc';

import {
    setUserCurrentReleaseVersion
} from '../utils/releaseNotesFunctions/showReleaseNotesToUserFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getCurrentRelease = (state: { releaseNotes: { currentRelease: IReleaseVersion; }; }) => state.releaseNotes.currentRelease;

function* showReleaseNote( action: any ) {
    if(action?.data?.id){
        yield call(showReleaseNoteManually, action)
    } else {
        yield call(showReleaseNoteAutomatcally)
    }
}

function* showReleaseNoteManually(action: any) {
    try {

        yield put(setUserCurrentReleaseVersion(action.data));

        yield put(toggleReleaseNotesModals({
            welcome: false,
            presentation: true
        }));
        yield call(pages, action.data)
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* showReleaseNoteAutomatcally() {
    try {    
        const currentRelease: IReleaseVersion = yield select(getCurrentRelease);
        yield put(toggleReleaseNotesModals({
            welcome: false,
            presentation: true
        }));
        yield call(pages, currentRelease)
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* handleChangeReleaseNotePresentation(action: any){
    try {
        yield call(pages, action.data)
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

function* pages( releaseNoteVersion: IReleaseVersion){
    try {

        const { data, response } = yield call(getReleaseVersionPages, releaseNoteVersion);
        if(data){
            yield put(listOfReleaseNotesPages(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }    
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

export default function* showReleaseToUserSaga() {
    yield takeEvery(SHOW_RELEASE_NOTE, showReleaseNote);
    yield takeEvery(RELEASE_NOTE_CHANGE_PRESENTATION, handleChangeReleaseNotePresentation);
}
