import React, { ReactNode } from "react";
import Checkbox from "../check/Check";

interface CheckboxAndLabelProps {
  checked: boolean;
  label: string;
  disabled: boolean;
  index: number;
  isGroupTitle?: boolean;
  hasCheckbox?: boolean;
  exibitionHtml?: ReactNode;
}

const RenderCheckboxAndLabel = ({
  isGroupTitle = false,
  checked,
  label,
  disabled,
  index,
  hasCheckbox,
  exibitionHtml,
}: CheckboxAndLabelProps) => {
  return (
    <div className="dropdown-option d-flex align-items-center">
      {hasCheckbox && (
        <Checkbox
          id={`checkbox-${index}`}
          name={`checkbox-${index}`}
          checked={checked}
          disabled={disabled}
          label={null}
          className={null}
          onChange={() => {}}
        />
      )}
      {exibitionHtml ? (
        exibitionHtml
      ) : isGroupTitle ? (
        <strong>{label}</strong>
      ) : (
        label
      )}
    </div>
  );
};

export default RenderCheckboxAndLabel;
