import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import "./scrollbar.scss";

interface ScrollEvents {
  contentInset: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
  contentOffset: {
    x: number;
    y: number;
  };
  contentSize: {
    width: number;
    height: number;
  };
  layoutMeasurement: {
    width: number;
    height: number;
  };
  zoomScale: number;
}

interface ScrollbarProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  onScroll?: (event: ScrollEvents) => void;
  disableScrollX?: boolean;
  onReachVerticalEnd?: (event: ScrollEvents) => void;
  onReachVerticalStart?: (event: ScrollEvents) => void;
  onReachHorizontalEnd?: (event: ScrollEvents) => void;
  onReachHorizontalStart?: (event: ScrollEvents) => void;
  keepHeightScrollPosition?: boolean;
  maxHeight?: string;
  loading?: boolean;
}

interface CustomRef {
  scrollToEnd(animation: boolean): void;
  scrollToStart(): void;
  scrollTo(): void;
  scrollToIndex(): void;
}

const Scrollbar = forwardRef<CustomRef, ScrollbarProps>(
  (
    {
      children,
      className,
      style,
      id,
      onScroll,
      disableScrollX = false,
      onReachVerticalEnd,
      onReachVerticalStart,
      onReachHorizontalEnd,
      onReachHorizontalStart,
      keepHeightScrollPosition,
      maxHeight,
      loading,
    }: ScrollbarProps,
    ref
  ) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const scrollContentRef = useRef<HTMLDivElement | null>(null);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const [preventDoubleScrollListener, setPreventDoubleScrollListener] =
      useState(false);
    const [contentHeightChange, setContentHeightChange] = useState({
      prevHeight: 0,
      currentHeight: 0,
    });
    const [preventMultipleCalls, setPreventMultipleCalls] = useState(false);

    useImperativeHandle(ref, () => ({
      ...messagesEndRef.current,
      scrollToEnd(animation: boolean) {
        messagesEndRef.current?.scrollIntoView({
          behavior: animation ? "smooth" : "instant",
        });
      },
      scrollToStart() {
        console.log("scrollToStart");
      },
      scrollTo() {
        console.log("scrollTo");
      },
      scrollToIndex() {
        console.log("scrollToIndex");
      },
    }));

    const handleScroll = useCallback((event: Event) => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
        scrollLeft,
        scrollWidth,
        clientWidth,
      } = event.target as HTMLElement;
      const scrollEvents = {
        contentInset: {
          top: scrollTop,
          left: scrollLeft,
          bottom: scrollHeight - scrollTop - clientHeight,
          right: scrollWidth - scrollLeft - clientWidth,
        },
        contentOffset: {
          x: scrollLeft,
          y: scrollTop,
        },
        contentSize: {
          width: scrollWidth,
          height: scrollHeight,
        },
        layoutMeasurement: {
          width: clientWidth,
          height: clientHeight,
        },
        zoomScale: 1,
      };

      if (scrollTop === 0 && onReachVerticalStart) {
        onReachVerticalStart(scrollEvents);
      } else if (
        !preventMultipleCalls &&
        scrollTop + clientHeight + 1 >= scrollHeight &&
        onReachVerticalEnd
      ) {
        /** Foi adicionado +5 pois, em algumas resoluções, há diferença de 1 a 2px */
        onReachVerticalEnd(scrollEvents);
        setTimeout(() => {
          setPreventMultipleCalls(false);
        }, 300);
      }
      if (scrollLeft === 0 && onReachHorizontalStart) {
        onReachHorizontalStart(scrollEvents);
      } else if (
        scrollLeft + clientWidth >= scrollWidth &&
        onReachHorizontalEnd
      ) {
        onReachHorizontalEnd(scrollEvents);
      }
      if (onScroll) {
        onScroll(scrollEvents);
      }
    }, []);

    useEffect(() => {
      if (scrollRef.current && !preventDoubleScrollListener) {
        scrollRef.current.addEventListener("scroll", handleScroll);
        setPreventDoubleScrollListener(true);
        return () => window.removeEventListener("scroll", handleScroll);
      }
    }, [preventDoubleScrollListener, handleScroll]);

    useEffect(() => {
      const element = scrollContentRef?.current;

      if (!element) return;

      const observer = new ResizeObserver(() => {
        if (scrollRef?.current) {
          const { scrollHeight } = scrollRef?.current;
          setContentHeightChange((prev) => {
            return {
              prevHeight: prev.currentHeight,
              currentHeight: scrollHeight,
            };
          });
        }
      });

      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }, []);

    useEffect(() => {
      if (keepHeightScrollPosition && scrollRef.current) {
        const { prevHeight } = contentHeightChange;
        const { scrollHeight, scrollTop } = scrollRef.current as HTMLDivElement;
        console.log(scrollHeight, prevHeight, scrollTop);
        if (scrollTop === 0) {
          scrollRef.current.scrollTo(0, scrollHeight - prevHeight);
        }
      }
    }, [contentHeightChange, keepHeightScrollPosition]);

    return (
      <div
        className={`scrollbar-ui ${className ? className : ""}`}
        id={id || undefined}
        style={{ ...style, maxHeight: maxHeight }}
        ref={scrollRef}
        // onScroll={onScroll}
      >
        <div ref={scrollContentRef} className="scrollbar-ui-content">
          {children}
        </div>
        <div ref={messagesEndRef} />
        {/* {JSON.stringify(loading)} */}
      </div>
    );
  }
);

export default Scrollbar;
