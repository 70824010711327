const initialState = {
    channels: [],
    filteredChannels: [],
  }
  
  const channelsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "CHANNELS_FETCH_CHANNELS":
        let channels = [...state.channels]
        channels = action.channels
        return { ...state, channels }

      case "CHANNELS_UPDATE_STATUS":
        state.channels.find(
          c => c.id === action.id
        ).status = !state.channels.find(c => c.id === action.id).status
        return { ...state }
        
      case "CHANNELS_UPDATE_CHANNEL":
        let channelUpdate = state.channels.find(c => c.id === action.activeChannel.id)
        channelUpdate.name = action.activeChannel.name
        channelUpdate.status = action.activeChannel.status
        return { ...state }


    case "CHANNELS_SEARCH_CHANNELS":
      let filteredChannels = state.channels.filter(val => {
         if (action.query) {
          return (
            (val.name.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.type.toLowerCase().includes(action.query.term.toLowerCase()) ||
            val.title.toLowerCase().includes(action.query.term.toLowerCase())) &&
            (action.query.actives && val.status === true || 
            action.query.inactives && val.status === false)
          )
        } else {
          return state.channels
        }
      })
      return { ...state, filteredChannels }
    case "CHANNELS_ADD_CHANNEL":
      return { ...state }
      default:
        return state
    }
  }
  
  export default channelsReducer
  