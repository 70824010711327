import { client } from '../../../utils/axios-utils'

/*if (!domain) {
    throw new Error('Server url not present. Contact the adminstrator!')
}*/
const api =  {
    fetchUsers: (params) => {
        const formated = params ? Object.entries(params).map((key, index) => {
            const value = key[1]
            return typeof value === 'object' ? value.map( t => `${key[0]}=${t}`).join('&') : `${key[0]}=${value}`
        }): [];
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users?${formated.join('&')}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    fetchUsersAll: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/customers`, {params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getGroupUsers: (params) => {
        const GroupIds = params && params.length > 0 && params.map( m => `GroupIds=${m}`).join('&')
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/agents/groups?${GroupIds}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getUser: (id, refreshCache) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/${id}/${refreshCache}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    createUser: (params) => {
        console.log(params)
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },

    updateUser: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },

    changeUserStatus: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .patch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/status`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    filterUsers: (params) => {
        const {ActiveRows, InactiveRows, Filter} = params
        console.log(params)
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users?ActiveRows=${ActiveRows}&InactiveRows=${InactiveRows}${Filter ? `&Filter=${Filter}` : ''}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    userPause: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/pause`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    userLogon: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/logon`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    userLogout: (origin) => {
        console.log(origin)
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/logout?signalr=${origin === 'clickButton' ? false : true}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    fetchOnlineUsers: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/agents/online`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    bulkDisconnectUsers: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/logout-users`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    callerconfiguration: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/callerconfiguration`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    startCall: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/startcall`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    endCall: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/endcall`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    fetchExtensions: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/callchannelextension/`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    fetchUserClients: (id) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/customers/agent/${id}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    discardUserClients: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .patch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/customers/discarding/agent/`, { ...params })
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    agentPause: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users/agents/pause`, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },


}

export default  api;