


import React from "react";
import PropTypes from 'prop-types';
import "./chevron.scss";

const Chevron = ({onClick = () => {}, direction="up", color="default"}) =>
    <div 
        className={`chevron-icon-ui ${direction} ${color}`} 
        onClick={onClick}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24">
            <path d="M16.594 8.578l1.406 1.406-6 6-6-6 1.406-1.406 4.594 4.594z"/>
        </svg>
    </div>

Chevron.propTypes = {
    onClick: PropTypes.func,
    direction: PropTypes.oneOf(["up", "down", "left", "right"])
}

export default Chevron;