import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/releaseversionuserstates`;

const releaseVersionUserStatesService =  {
    getReleaseVersionsUserStates: (releaseVersionId) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${url}/${releaseVersionId}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    postReleaseVersionsUserStates: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putReleaseVersionsUserStates: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
}
export default  releaseVersionUserStatesService;