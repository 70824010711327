import { call, put, takeEvery, select, delay } from 'redux-saga/effects';

import { USER_DATA, SAGA_ERROR } from '../../../helpers/constants';
import { TERMINATE_INTERNAL_CHAT, ACTIVE_INTERNAL_CHAT, REMOVE_CONTACT_FROM_LIST } from '../../../helpers/internalChatConstants';

import {
    toggleLoading,
    terminateActiveChat,
    removeContactFromList,
    removeActiveChat
} from '../utils/internalChatFunctions/terminateInternalChatFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getContacts = state => state.internalChat.contacts;
const getActiveChat = state => state.internalChat.activeChat;

function* terminateInternalChat(action) {

    const contacts = yield select(getContacts);
    const activeChat = yield select(getActiveChat);
    try {
        yield put(toggleLoading(activeChat, action.data));
        const { data, response } = yield call(terminateActiveChat, activeChat);
        if (data) {
            yield put({ type: ACTIVE_INTERNAL_CHAT, data: {
                user: null,
                position: {
                  x: 0,
                  y: 0
                }
            } });
            yield put(removeContactFromList(contacts, activeChat));
            yield put(removeActiveChat());
        } else {
            yield put(toggleLoading(activeChat, action.data));
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    } catch (e) {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }
}

function* handleRemoveContactFromList(action) {
    const contacts = yield select(getContacts);
    const activeChat = yield select(getActiveChat);
    
    try {
            yield put({ type: ACTIVE_INTERNAL_CHAT, data: {
                user: null,
                position: {
                  x: 0,
                  y: 0
                }
            } });
            yield put(removeContactFromList(contacts, activeChat));
            yield put(removeActiveChat());
    } catch (e) {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }

}



export default function* terminateInternalChatSaga() {
    yield takeEvery(TERMINATE_INTERNAL_CHAT, terminateInternalChat);
    yield takeEvery(REMOVE_CONTACT_FROM_LIST, handleRemoveContactFromList);
}