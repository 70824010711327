import mock from "../mock"
let data = {
    outMessages: [
        {
          id: 1,
          content: 'Mensagem fora do horário de atendimento.',
        }
    ]
}

// GET : Calendar Events
mock.onGet("/api/apps/group-rules/out-messages").reply(() => {
  return [200, data.outMessages]
})

mock.onPut("/api/apps/group-rules/out-messages").reply(request => {
    let { params } = JSON.parse(request.data)
    const messageLength = data.outMessages.length
    if(messageLength){
        //ATUALIZA MENSAGEM EXISTENTE
        let outMessages = data.outMessages.find(m => m.id === 1)
        outMessages.content = params
    } else {
        //ADICIONA UMA NOVA MENSAGEM
        const uid = Math.floor(Math.random() * 99999)
        data.outMessages.push({
            id: uid,
            content: params,
          })
  
    }
  return [200, data.outMessages]
})