import { pagination } from '../../../utils/pagination'
import moment from 'moment'
const initialState = {
  users: [],
  filteredUsers: [],
  chats: [],
  filteredChats: [],
  activeContact: null,
  activeChat: null,
  messages: [],
  loading: false,
  scrollDown: true,
  selectedContacts: [],
  groups: [],
  loading: false
}

const monitoring = (state = initialState, action) => {
  switch (action.type) {
    case "MONITORING_GROUPS":
      return { ...state, groups: action.groups }
    case "MONITORING_CHANGE_GROUP":
      return {
        ...state, groups: state.groups.map(m => {
          if (m.id === action.group.id) {
            return { ...m, ...action.group }
          }
          return m
        })
      }
    case "MONITORING_USERS":
      return {
        ...state,
        groups: state.groups.map(m => {
          if (m.id === action.groupId) {
            //m.users = action.users
            m.loading = false
          }
          return m
        }),
        chats: [...state.chats, ...action.users]
      }
    case "MONITORING_NOTIFY_USER_STATUS":
      //ALTERA O STATUS DO USUÁRIO AO RECEBER NOTIFICAÇÃO
      return {
        ...state,
        chats: state.chats.map(chat => {
          if (chat.id === action.status.userId) {
            chat.pause = action.status.pause
            chat.loggedUserStatus = action.status.loggedUserStatus
          }
          return chat
        })
      }
    case "MONITORING_LOADMING_CHATS": {
      return { ...state, loading: !state.loading }
    }
    case "MONITORING_UNLOAD_USER_LIST":
      return { ...state, users: [] }
    case "MONITORING_CLEAN_UP": {
      return { ...state, ...initialState }
    }
    case "CHAT_STRUCTURE":
      return { ...state, chats: [...state.chats, ...action.chats] }
    case "ACTIVE_CONTACT":
      return { ...state, activeContact: action.contact }
    case "ACTIVE_CHAT":
      return { ...state, activeChat: action.chat }
    case "MONITORING_CHATS":
      let userChats = state.chats.find(c => c.user === action.user)
      userChats.contacts = action.contacts
      userChats.checkChats = !userChats.checkChats
      return { ...state }
    case "MONITORING_LOADING":
      return { ...state, loading: action.loading, scrollDown: action.scrollDown }



    case "LAST_MESSAGE":
      console.log(action)
      let receivedContacts = state.chats.find(c => c.contacts.some(item => (action.notifiedMessage && action.notifiedMessage.MessageMemberGroup && action.notifiedMessage.MessageMemberGroup.GroupWhatsId ?
        item.groupWhatsId === action.notifiedMessage.MessageMemberGroup.GroupWhatsId :
        item.customerId === action.notifiedMessage.CustomerId && item.serviceProtocolId === action.notifiedMessage.ServiceProtocolId && (action.notifiedMessage.AgentId || action.notifiedMessage.UserSenderId))))

      let addOwnChat = state.chats.find(f => f.user === (action.notifiedMessage.AgentId || action.notifiedMessage.UserSenderId))
      const isGroupMessage = action.notifiedMessage && action.notifiedMessage.GroupWhatsId ? true : false
      const checkChatExistis = isGroupMessage ? state.chats.find(c => c.contacts.some(item => item.groupWhatsId === action.notifiedMessage.GroupWhatsId))
        : state.chats.find(c => c.contacts.some(item => item.customerId === action.notifiedMessage.CustomerId && item.serviceProtocolId === action.notifiedMessage.ServiceProtocolId && item.agentId === (action.notifiedMessage.AgentId || action.notifiedMessage.UserSenderId)))

      if (typeof receivedContacts !== 'undefined' && typeof checkChatExistis !== 'undefined') {
        console.log('ATUALIZA OU REATIVA CONTATO NA LISTA')
        console.log(receivedContacts)
        const lastMessageAttach = action && action.notifiedMessage && action.notifiedMessage.Attachments && action.notifiedMessage.Attachments.length > 0 ? action.notifiedMessage.Attachments[0] : null
        //ATUALIZA A MENSAGEM
        const userIndex = state.chats.findIndex(i => i.user === receivedContacts.user)
        const messageIndex = receivedContacts.contacts.findIndex(i =>
        (action.notifiedMessage && action.notifiedMessage.MessageMemberGroup && action.notifiedMessage.MessageMemberGroup.GroupWhatsId ?
          i.groupWhatsId === action.notifiedMessage.MessageMemberGroup.GroupWhatsId :
          (i.customerId === action.notifiedMessage.CustomerId && i.serviceProtocolId === action.notifiedMessage.ServiceProtocolId)
        ))
        let userUpdate = state.chats[userIndex].contacts[messageIndex]

        if (action.notifiedMessage.Id !== userUpdate.lastMessageId && (!state.activeChat || (state.activeChat && (action.notifiedMessage && action.notifiedMessage.MessageMemberGroup && action.notifiedMessage.MessageMemberGroup.GroupWhatsId ? state.activeChat.groupWhatsId !== action.notifiedMessage.MessageMemberGroup.GroupWhatsId : state.activeChat.customerId !== action.notifiedMessage.CustomerId)))) {
          console.warn('AUMENTA A CONTAGEM')
          //userUpdate.unseen = userUpdate.unseen + 1
          userUpdate.unseen = 0 //FOI REMOVIDA A CONTAGEM NA TELA DO SUPERVISOR
        }

        //ÚLTIMA MENSAGEM RECEBIDA
        userUpdate.lastMessageId = action.notifiedMessage.Id
        userUpdate.lastMessage = action.notifiedMessage.MessageText
        userUpdate.dateLastMessage = action.notifiedMessage.CreatedAt
        userUpdate.originGroup = action.notifiedMessage.OriginGroup
        userUpdate.lastAttachment = lastMessageAttach ? {
          contentType: lastMessageAttach.ContentType,
          defaultUrl: lastMessageAttach.DefaultUrl,
          fileName: lastMessageAttach.FileName,
          heigth: lastMessageAttach.Heigth,
          messageBroadcastListId: lastMessageAttach.MessageBroadcastListId,
          messageId: lastMessageAttach.MessageId,
          secondsAudio: lastMessageAttach.SecondsAudio,
          sizeBytes: lastMessageAttach.SizeBytes,
          storageName: lastMessageAttach.StorageName,
          width: lastMessageAttach.Width,
        } : null
        userUpdate.customerName = action.notifiedMessage.CustomerName
        userUpdate.terminated = false
        userUpdate.isBroadcastList = action.notifiedMessage.IsBroadcastList
        userUpdate.profileImage = action.notifiedMessage && action.notifiedMessage.ProfileImage ? `data:image/png;base64, ${action.notifiedMessage.ProfileImage}` : null
        //ALTERA A CONTAGEM DE MENSAGENS
        console.log(userUpdate.lastMessageId)
        console.log(action.notifiedMessage.Id)
        if ((state.activeContact && state.activeContact.id !== receivedContacts.user) && action.notifiedMessage.Id !== userUpdate.lastMessageId) {
          console.warn('AUMENTA A CONTAGEM')
          //let unseenUpdate = state.chats[userIndex]
          //unseenUpdate.unseen = unseenUpdate.unseen + 1
        }
        console.log(lastMessageAttach)
        console.log(userUpdate)
      } else if (typeof addOwnChat !== 'undefined' && typeof checkChatExistis === 'undefined') {
        console.log('ADICIONA CONTATO NA LISTA')
        //ADICIONA UMA NOVA MENSAGEM NA LISTA
        const newItem = {
          origin: action.notifiedMessage.Origin,
          destination: action.notifiedMessage.Destination,
          messageStatusType: action.notifiedMessage.MessageStatusType,
          customerCode: action.notifiedMessage.CustomerCode,
          customerEmail: action.notifiedMessage.CustomerEmail,
          customerId: action.notifiedMessage.CustomerId,
          customerName: action.notifiedMessage.CustomerName,
          customerPhone: typeof action.notifiedMessage.OriginNumber !== 'undefined' ? action.notifiedMessage.OriginNumber : action.notifiedMessage.Destination,
          dateLastMessage: action.notifiedMessage.CreatedAt,
          groupName: action.notifiedMessage.MessageMemberGroup && action.notifiedMessage.MessageMemberGroup.GroupName,
          originGroup: action.notifiedMessage.OriginGroup,
          groupWhatsCode: action.notifiedMessage.MessageMemberGroup && action.notifiedMessage.MessageMemberGroup.GroupWhatsCode,
          groupWhatsId: action.notifiedMessage.MessageMemberGroup && action.notifiedMessage.MessageMemberGroup.GroupWhatsId,
          forwardedBy: null,
          freezedAt: action.notifiedMessage.FreezedAt,
          isFrozenService: action.notifiedMessage.IsFrozenService,
          isImportantService: action.notifiedMessage.IsImportantService,
          isSpecialCustomer: action.notifiedMessage.IsSpecialCustomer,
          lastMessage: action.notifiedMessage.MessageText,
          protocol: action.notifiedMessage.Protocol,
          serviceChannelType: action.notifiedMessage.ServiceChannelType,
          serviceProtocolId: action.notifiedMessage.ServiceProtocolId,
          serviceChannelId: action.notifiedMessage.ServiceChannelId,
          agentId: action.notifiedMessage.AgentId,
          profileImage: action && action.notifiedMessage && action.notifiedMessage.ProfileImage ? `data:image/png;base64, ${action.notifiedMessage.ProfileImage}` : null,
          totalUnread: 0,
          unseen: 1,
          userPrincipalAgent: null
        }

        // VERIFICA SE O PROTOCOLO JÁ EXISTE NA LISTA DE CONTATOS
        const protocolIndex = addOwnChat.contacts.findIndex(contact => contact.protocol === newItem.protocol);
        if (protocolIndex !== -1) {
          // SE EXISTIR É SUBSTITUIDO O CONTATO EXISTENTE ATUALIZANDO O "customerId"
          if (newItem.customerId){
            addOwnChat.contacts[protocolIndex] = newItem;
          }
        } else {
          // SE NÃO EXISTIR É ADICIONADNO O NOVO CONTATO A LISTA
          addOwnChat.contacts = [...addOwnChat.contacts, newItem];
          
          // ATUALIZA O CHATS ATIVOS DO AGENTE
          state.chats.filter(chat => chat.id === action.notifiedMessage.AgentId).forEach(contact => contact.openChatsByAgent++);
        }
      }

      //ADICIONA MAIS UMA MENSAGEM NA LISTA DE MENSAGENS DO USUÁRIO
      //let addNewMessage = state.messages.find(i => i.customerId === action.notifiedMessage.CustomerId)
      let addNewMessage = state.messages.find(i => (action.notifiedMessage && action.notifiedMessage.MessageMemberGroup && action.notifiedMessage.MessageMemberGroup.GroupWhatsId ?
        i.groupWhatsId === action.notifiedMessage.MessageMemberGroup.GroupWhatsId :
        (i.customerId === action.notifiedMessage.CustomerId && i.serviceProtocolId === action.notifiedMessage.ServiceProtocolId)))
      let messageExistis = addNewMessage && addNewMessage.messages && addNewMessage.messages.find(f => f.id === action.notifiedMessage.Id)
      console.log(state.chats)
      console.log(state.messages)
      console.log(addNewMessage)
      console.log(action)
      console.log(messageExistis)
      if (typeof messageExistis === 'undefined' && typeof addNewMessage !== 'undefined') {
        console.log('add new message')
        const msg = action.notifiedMessage
        const attachments = msg.Attachments && msg.Attachments.length > 0 ?
          msg.Attachments.map(a => {
            return {
              messageId: a.MessageId,
              fileName: a.FileName,
              storageName: a.StorageName,
              contentType: a.ContentType,
              sizeBytes: a.SizeBytes,
              width: a.Width,
              heigth: a.Heigth,
              secondsAudio: a.SecondsAudio
            }
          }) : []
        const lastMessage = addNewMessage.messages && addNewMessage.messages.length > 0 && addNewMessage.messages.slice(-2, -1).pop()
        const userSender = msg && msg.UserSender ? {
          userId: msg.UserSender.UserId,
          userName: msg.UserSender.UserName,
          userGroup: msg.UserSender.UserGroup
        } : null
        console.warn(msg)
        console.log(userSender)

        const notifiedMessage = {
          userSender: userSender,
          id: msg.Id,
          origin: msg.Origin,
          destination: msg.Destination,
          MessageId: msg.MessageId,
          CustomerId: msg.CustomerId,
          CustomerName: msg.CustomerName,
          Message: msg.MessageText,
          OriginNumber: msg.OriginNumber,
          DestinationNumber: msg.DestinationNumber,
          Received: moment.utc(msg.CreatedAt).utcOffset("-03:00"),
          ReplyMessageId: null,
          UserProfile: null,
          ServiceChannelType: msg.ServiceChannelType,
          profileImage: msg && msg.ProfileImage ? `data:image/png;base64, ${msg.ProfileImage}` : null,
          userSenderId: msg.UserSenderId,
          isSent: msg.UserSenderId ? true : false,
          isUpdated: msg.IsUpdated,
          oldMessageText: msg.OldMessageText !== null ? msg.OldMessageText : '',
          protocol: msg.Protocol,
          serviceProtocolUserId: msg.ServiceProtocolUserId,
          protocolFinishedAt: msg.ProtocolFinishedAt,
          serviceProtocolId: msg.ServiceProtocolId,
          messageStatusType: msg.MessageStatusType,
          messageMemberGroup: msg.MessageMemberGroup,
          messageParticipants: {
            name: msg.MessageMemberGroup ? msg.MessageMemberGroup.Name : null,
            number: msg.MessageMemberGroup ? msg.MessageMemberGroup.Number : null,
            color: msg.MessageMemberGroup ? msg.MessageMemberGroup.Color : null,
            isAdmin: msg.MessageMemberGroup ? msg.MessageMemberGroup.IsAdmin : null,
          },
          delivered: true,
          attachments,
          replyMessage: msg && msg.ReplyMessage && msg.ReplyMessage.Id && {
            id: msg.ReplyMessage.Id,
            messageText: msg.ReplyMessage.MessageText,
            messageId: msg.ReplyMessage.Id,
            createdAt: msg.ReplyMessage.CreatedAt,
            attachments: msg.ReplyMessage.Attachments ? msg.ReplyMessage.Attachments.map(a => {
              return {
                messageId: a.MessageId,
                contentType: a.ContentType,
                fileName: a.FileName,
                storageName: a.StorageName,
                sizeBytes: a.SizeBytes,
                width: a.Width,
                heigth: a.Heigth
              }
            }) : null,
            messageParticipants: msg.ReplyMessage.MessageParticipants ? {
              color: msg.ReplyMessage.MessageParticipants.Color,
              id: msg.ReplyMessage.MessageParticipants.Id,
              isAdmin: msg.ReplyMessage.MessageParticipants.IsAdmin,
              isSuperAdmin: msg.ReplyMessage.MessageParticipants.IsSuperAdmin,
              name: msg.ReplyMessage.MessageParticipants.Name,
              number: msg.ReplyMessage.MessageParticipants.Number,
            } : null
          },
          dividerDay: lastMessage && moment(lastMessage.Received).format('YYYYMMDD') !== moment(msg.createdAt).format('YYYYMMDD') ? msg.createdAt : null,
          spacer: 'small',
          lastAgent: lastMessage && JSON.stringify(lastMessage.userSender) !== JSON.stringify(userSender) ? { agent: lastMessage.userSender, createdAt: lastMessage.createdAt, ServiceChannelType: lastMessage.serviceChannelType } : null,
          isBroadcastList: msg.IsBroadcastList,
          relatedUserId: msg.RelatedUserId,
          relatedUserName: msg.RelatedUserName
        }
        //const messageExistis = addNewMessage.messages.find(f => f.id === msg.Id)
        //if(typeof messageExistis === 'undefined'){
        addNewMessage.messages = [...addNewMessage.messages, notifiedMessage]
        //}

      } else if (typeof messageExistis !== 'undefined') {
        console.log('update message')
        messageExistis.messageStatusType = action.notifiedMessage.MessageStatusType > messageExistis.messageStatusType ? action.notifiedMessage.MessageStatusType : messageExistis.messageStatusType
      }


      return { ...state, scrollDown: action.scrollDown }

    /*case "ADD_NEW_USER":
      return { ...state, users: [...state.users, action.user], chats: [...state.chats, action.data] }*/
    case "UPDATE_USER_UNSEEN":
      if (state.activeContact) {
        let userInitialUnseen = state.chats.find(f => f.user === action.contact.id)
        userInitialUnseen.unseen = 0
      }
      return { ...state }
    case "UPDATE_CHAT_UNSEEN":
      if (state.activeChat) {
        const userContacts = state.chats.find(c => c.contacts.some(item => item.customerId === action.chat.customerId))
        if (typeof userContacts !== 'undefined') {
          const userUnseenIndex = state.chats.findIndex(i => i.user === userContacts.user)
          const contactUnseenIndex = userContacts.contacts.findIndex(i => i.customerId === action.chat.customerId)

          let chatUnseenUpdate = state.chats[userUnseenIndex].contacts[contactUnseenIndex]
          chatUnseenUpdate.unseen = 0

        }

      }
      return { ...state }
    case "MONITORING_FILTER_USERS":
      let filteredUsers = state.users.filter(val => {
        if (action.value) {
          return (
            val.name.toLowerCase().includes(action.value.toLowerCase()) ||
            val.email.toLowerCase().includes(action.value.toLowerCase())
          )
        } else {
          return state.users
        }
      })
      return { ...state, filteredUsers }

    case "MONITORING_FILTER_CHATS":

      let filteredChats = state.chats.find(f => f.user === state.activeContact.id).contacts
      filteredChats = filteredChats.filter(val => {
        if (action.value) {
          console.log(val)
          return (
            val.customerName ? (val.customerName.toLowerCase().includes(action.value.toLowerCase()) || val.customerPhone.toLowerCase().includes(action.value.toLowerCase())) :
              val.groupName.toLowerCase().includes(action.value.toLowerCase())
          )
        } else {
          return val
        }
      })
      return { ...state, filteredChats }

    case "MONITORING_LOAD_MESSAGES":
      return { ...state, messages: [...action.messages, ...state.messages] }
    case "MONITORING_LOAD_OLD_MESSAGES":
      let fetchMessages = state.messages.find(i => i.customerId === action.customerId)
      fetchMessages.messages = [...action.messages, ...fetchMessages.messages]
      fetchMessages.Offset = fetchMessages.Offset + action.messages.length
      fetchMessages.noMore = pagination > action.messages.length || action.messages.length === 0 ? true : false
      fetchMessages.LastId = action.messages && action.messages.length > 0 ? action.messages[0].id : fetchMessages.LastId
      return { ...state }
    case "LINK_CLIENT":
      const linkContacts = state.chats.find(c => c.contacts.some(item => item.customerId === action.params.activeChat.customerId))
      const userLinkIndex = state.chats.findIndex(i => i.user === linkContacts.user)
      const contacLinkIndex = linkContacts.contacts.findIndex(i => i.customerId === action.params.activeChat.customerId)

      let chatLinkUpdate = state.chats[userLinkIndex].contacts[contacLinkIndex]
      chatLinkUpdate.userPrincipalAgent = action.params.action === 'link' && action.params.activeContact.id ? {
        userId: action.params.activeContact.id,
        userName: action.params.activeContact.name,
        userGroup: action.params.activeContact.groupName
      } : null
      return { ...state }
    case "MONITORING_REMOVE_CHAT":
      const allContactsRmv = state.chats.find(c => c.contacts.some(item => item.customerId === action.chat.CustomerId))
      if (typeof allContactsRmv !== 'undefined') {
        const contactIndexRmv = state.chats.findIndex(i => i.user === allContactsRmv.user)
        const newContactList = allContactsRmv.contacts.filter(i => i.customerId !== action.chat.CustomerId)
        let removedContactList = state.chats[contactIndexRmv]
        removedContactList.contacts = newContactList

        //ATUALIZA A QUANTIDADE DE CHATS ATIVOS        
        state.chats.filter(i => i.id === allContactsRmv.id).forEach(contact => {
          if(contact.openChatsByAgent > 0){
            contact.openChatsByAgent--;
          }
        });
      }

      if (state.activeChat && state.activeChat.customerId === action.chat.CustomerId) {
        console.log('remove active chat')
        let activeChat = state.activeChat
        activeChat = null
      }
      return { ...state, activeChat: state.activeChat && state.activeChat.customerId === action.chat.CustomerId ? null : state.activeChat }
    case "MONITORING_ADD_CHAT":
      const allContactsAdd = state.chats.find(f => f.user === action.chat.AgentId)
      if (typeof allContactsAdd !== 'undefined') {
        const addNewChat = [{
          customerCode: action.chat.CustomerCode,
          customerEmail: action.chat.CustomerEmail,
          customerId: action.chat.CustomerId,
          customerName: action.chat.CustomerName,
          customerPhone: action.chat.OriginNumber,
          dateLastMessage: action.chat.CreatedAt,
          forwardedBy: null,
          freezedAt: action.chat.FreezedAt,
          isFrozenService: action.chat.IsFrozenService,
          isImportantService: action.chat.IsImportantService,
          isSpecialCustomer: action.chat.IsSpecialCustomer,
          lastMessage: action.chat.MessageText,
          protocol: action.chat.Protocol,
          serviceChannelType: action.chat.ServiceChannelType,
          serviceProtocolId: action.chat.ServiceProtocolId,
          agentId: action.chat.AgentId,
          profileImage: action.chat && action.chat.ProfileImage ? `data:image/png;base64, ${action.chat.ProfileImage}` : null,
          totalUnread: 0,
          unseen: 0,
          userPrincipalAgent: action.chat.UserPrincipalAgent
        }]

        const contactIndexAdd = state.chats.findIndex(i => i.user === allContactsAdd.user)
        
        let addedContactList = state.chats[contactIndexAdd]
        addedContactList.contacts = [...addedContactList.contacts, ...addNewChat.filter(f => !addedContactList.contacts.map(m => m.protocol).includes(f.protocol))].sort((a, b) => moment(a) - moment(b))
        
        //ATUALIZA A QUANTIDADE DE CHATS ATIVOS  
        state.chats.filter(i => i.id === allContactsAdd.id).forEach(contact => contact.openChatsByAgent++);
      }
      return { ...state, activeChat: null }

    case "NOTIFY_TERMINATE_CHAT":
      const notifyContacts = state.chats.find(c => c.contacts.some(item => item.customerId === action.chat.CustomerId))
      console.log(notifyContacts)
      console.log(action)
      if (typeof notifyContacts !== 'undefined') {
        const userNotifyIndex = state.chats.findIndex(i => i.user === notifyContacts.user)
        const contacNotifyIndex = notifyContacts.contacts.findIndex(i => i.customerId === action.chat.CustomerId)
        let chatNotifyUpdate = state.chats[userNotifyIndex].contacts[contacNotifyIndex]
        chatNotifyUpdate.terminated = true
      }
      return { ...state }

    case "MONITORING_TERMINATE_CHAT":
      //REMOVE DA LISTA DE CONTATOS
      console.log(action)
      const terminateContacts = state.chats.find(c => c.contacts.some(item => item.customerId === action.chat.customerId && item.agentId === (action.chat.agentId || action.chat.userSenderId)))
      const userTerminateIndex = state.chats.findIndex(i => i.user === terminateContacts.user)
      const newTerminateContactList = terminateContacts.contacts.filter(i => i.customerId !== action.chat.customerId)
      let terminateContactList = state.chats[userTerminateIndex]
      terminateContactList.contacts = newTerminateContactList

      //ATUALIZA A QUANTIDADE DE CHATS ATIVOS        
      state.chats.filter(i => i.id === terminateContacts.id).forEach(contact => {
        if(contact.openChatsByAgent > 0){
          contact.openChatsByAgent--;
        }
      });

      return { ...state, activeChat: null }

      return { ...state }
    case "EXPAND_CHAT_MESSAGE":
      let openMessage = state.messages.find(i => c => c.messages.some(item => item.id === action.message.id)).messages.find(m => m.id === action.message.id)
      openMessage.opened = (typeof openMessage.opened === 'undefined' || !openMessage.opened) ? true : false;
      return { ...state }

    case "RETRY_LOAD_ATTACHMENT":
      console.log(action)
      let retryAttach = state.messages.find(i => c => c.messages.some(item => item.id === action.id)).messages.find(m => m.id === action.id)
      retryAttach.retrying = action.retrying
      if (action.attachment) {
        retryAttach.attachments = action.attachment
      }
      return { ...state }
    case "DELETE_CHAT_MESSAGE_SUPERVISOR":
      const deleteMessage = state.messages.map(contact => {
        contact.messages = contact.messages.map(message => {
          if (message.MessageId === action.message.MessageId) {
            message.isDeleted = true;
          }
          return message;
        })
        return contact;
      })
      return { ...state, messages: deleteMessage }
    case "EDITED_CHAT_MESSAGE_SUPERVISOR":
      const updateMessage = state.messages.map(contact => {
        contact.messages = contact.messages.map(message => {
          if (message.MessageId === action.message.MessageId) {
            message.oldMessageText = action.message.message.OldMessageText;
            message.Message = action.message.message.NewMessageText;
            message.isUpdated = true;
          }
          return message;
        })
        return contact;
      })
      return { ...state, messages: updateMessage }
    case "MONITORING_SELECTED_CONTACTS":
      return { ...state, selectedContacts: action.contacts }

    default:
      return { ...state }
  }
}

export default monitoring
