import React, { ReactNode, HTMLAttributes } from 'react';
import './small.scss';

interface SmallProps extends HTMLAttributes<HTMLSpanElement> {
  color?: string;
  light?: boolean;
  dark?: boolean;
  fw?: number;
  children: ReactNode;
}

function Small({ children, color, light, dark, className, fw, ...props }: SmallProps) {
  return (
    <small
      className={`small-ui ui-color ${color ? `ui-${color}` : 'gray'} ${
        light ? `ui-light` : dark ? `ui-dark` : ''
      } ${className ? className : ''} ${fw ? `ui-fw-${fw}` : 'ui-fw-400'}`}
      {...props}
    >
      {children}
    </small>
  );
}

export default Small;