import React from 'react';
import * as Icon from "react-feather";
import { Spinner } from "reactstrap";
import Tooltip from '../../newComponents/tooltip/Tooltip';
import PropTypes from 'prop-types';
import "./button.scss";

const Buttom = ({ children }) => {
    let subComponentList = Object.keys(Buttom);
    console.log(children)
    let subComponents = subComponentList ? subComponentList.map((key) => {
        return React.Children.map(children, (child) =>
            child.type && child.type.name === key ? child : null
        );
    }) : null;

    return (
        <>
            <button className='buttom-ui'>
                {children}
            </button>
        </>
    );
};

const button = ({ children }) => {
    let subComponentList = Object.keys(button);
    let subComponents = subComponentList ? subComponentList.map((key) => {
        return React.Children.map(children, (child) =>
            child.type && child.type.name === key ? child : null
        );
    }) : null;

    return (
        <>
            <button className='button-ui'>
                {children}
            </button>
        </>
    );
};

let showToolTip = false

const Add = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui add ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.PlusCircle size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Adicionar</span></></button>;
button.Add = Add;
Add.displayName = "Button.Add";

const Plus = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui add ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Plus size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.Plus = Plus;
Plus.displayName = "Button.Plus";

const Save = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui save ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Save size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Salvar</span></></button>;
button.Save = Save;
Save.displayName = "Button.Save";


const Confirm = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui confirm ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.CheckCircle size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Confirmar</span></></button>;
button.Confirm = Confirm;
Confirm.displayName = "Button.Confirm";

const Archive = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui confirm ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Archive size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Arquivar</span></></button>;
button.Archive = Archive;
Archive.displayName = "Button.Archive";

const Unarchive = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui undo ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Archive size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Desarquivar</span></></button>;
button.Unarchive = Unarchive;
Unarchive.displayName = "Button.Unarchive";

const Undoarchive = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui undo ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.RotateCcw size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Desfazer</span></></button>;
button.Undoarchive = Undoarchive;
Undoarchive.displayName = "Button.Undoarchive";

const Next = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui add ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Próximo</span></><Icon.ChevronRight size={16} className="button-icon right" /></button>;
button.Next = Next;
Next.displayName = "Button.Next";

const Prev = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui cancel ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.ChevronLeft size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Voltar</span></></button>;
button.Prev = Prev;
Prev.displayName = "Button.Prev";

const Folder = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui confirm ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Archive size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.Folder = Folder;
Folder.displayName = "Button.Folder";

const Copy = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui info ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Copy size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.Copy = Copy;
Copy.displayName = "Button.Copy";

const View = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-sky-blue ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Eye size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.View = View;
View.displayName = "Button.View";

const Delete = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui delete ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.XCircle size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Apagar</span></></button>;
button.Delete = Delete;
Delete.displayName = "Button.Delete";

const Logout = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui delete ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.LogOut size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Sair</span></></button>;
button.Logout = Logout;
Logout.displayName = "Button.Logout";

const Cancel = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui cancel ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.SkipBack size={16} className="button-icon" /><>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>Cancelar</span></></button>;
button.Cancel = Cancel;
Cancel.displayName = "Button.Cancel";

const Undo = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui undo ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.RotateCcw size={16} />{props.loading && <Spinner size='sm' />}</button>;
button.Undo = Undo;
Undo.displayName = "Button.Undo";

const Edit = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui edit ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Edit2 size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.Edit = Edit;
Edit.displayName = "Button.Edit";

const X = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui exclude ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.X size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.X = X;
X.displayName = "Button.X";

const Check = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-success ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Check size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.Check = Check;
Check.displayName = "Button.Check";

const Agree = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-success ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Check size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.Agree = Agree;
Agree.displayName = "Button.Agree";

const Share = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-sky-blue ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<Icon.Share size={16} /><>{props.loading && <Spinner size='sm' />}</></button>;
button.Share = Share;
Share.displayName = "Button.Share";

const Primary = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-primary ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.Primary = Primary;
Primary.displayName = "Button.Primary";

const Secondary = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-secondary ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.Secondary = Secondary;
Secondary.displayName = "Button.Secondary";

const Default = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-default ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.Default = Default;
Default.displayName = "Button.Default";

const Success = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-success ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.Success = Success;
Success.displayName = "Button.Success";

const System = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-system ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.System = System;
System.displayName = "Button.System";

const Info = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-info ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.Info = Info;
Info.displayName = "Button.Info";

const Warning = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-warning ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.Warning = Warning;
Warning.displayName = "Button.Warning";

const Danger = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-danger ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.Danger = Danger;
Danger.displayName = "Button.Danger";

const SpanishGray = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-spanish-gray ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.SpanishGray = SpanishGray;
SpanishGray.displayName = "Button.SpanishGray";

const SkyBlue = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-sky-blue ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}<>{props.loading && <Spinner size='sm' />}<span className={`default-button-ui-text ${props.loading ? 'processing' : ''}`}>{props.children}</span></></button>;
button.SkyBlue = SkyBlue;
SkyBlue.displayName = "Button.SkyBlue";

const Search = (props) => <button data-testid={props["data-testid"]} onClick={props.onClick} id={props.id} data-cy={props.dataCy ? props.dataCy : 'none'} className={`button-ui button-primary ${props.dark ? 'dark-button' : ''} ${props.block ? 'button-block' : ''} ${props.size ? props.size : ''} ${props.className ? props.className : ''}`} disabled={props.loading || props.disabled}>{props.tooltip && props.legend && <Tooltip position={props.legendPosition} label={props.legend} color={props.legendColor} />}{props.loading && <Spinner size='sm' />}<Icon.Search size={16} className="button-icon m-0" style={{ opacity: props.loading ? 0 : 1 }} /></button>;
button.Search = Search;
Search.displayName = "Button.Search";


Buttom.propTypes = {
    id: PropTypes.string,
    dataCy: PropTypes.string,
    onClick: PropTypes.func,
    dark: PropTypes.bool,
    block: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'gray', 'success', 'system', 'info', 'warning', 'danger', 'spanish-gray', 'sky-blue']),
    className: PropTypes.string,
    legend: PropTypes.string,
    legendPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    legendColor: PropTypes.oneOf(['primary', 'secondary', 'gray', 'success', 'system', 'info', 'warning', 'danger', 'spanish-gray', 'sky-blue']),
};
  
Buttom.defaultProps = {
    id: '',
    dataCy: '',
    color: 'primary',
    onClick: () => {},
    dark: false,
    block: false,
    size: 'sm',
    loading: false,
    disabled: false,
    className: '',
    legend: 'Legend',
    legendPosition: 'top',
    legendColor: 'primary',
};

export default button;