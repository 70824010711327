import React, { useEffect, useRef, useState } from 'react';
import { calculateElementPosition } from '../../utils/calculateElementPosition';
import "./context-menu.scss";

export const ContextMenu = React.memo((props) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const { x, y, container, item, ...rest } = props;
    const menuRef = useRef(null);

    useEffect(() => {
        if (item) {
            /** Caso o menu esteja sendo exibido, calcula sua posição*/
            const { left, top } = calculateElementPosition({
                containerWidth: menuRef.current?.clientWidth,
                containerHeight: menuRef.current?.clientHeight,
                x,
                y
            })
            setPosition({ left, top })

            /** Caso o menu esteja sendo exibido e haja clique externo
             *  emite evento para fechar o menu
             */
            const handleClickOutside = (e) => {
                if (menuRef.current && !menuRef.current.contains(e.target)) {

                    props.handleCloseMenu();
                }
            }

            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        } else {
            /** Caso o menu não esteja sendo exibido, reseta a posição */
            setPosition({ top: 0, left: 0 })
        }
    }, [menuRef, item]);

    /** Obtem os cálculos para posicionar o menu corretamente na tela */
    const { left, top } = position


    return (
        <div
            ref={menuRef}
            className={`message-options-menu-ui ${item && (top > 0 || left > 0) ? 'is-open' : ''}`}
            style={{
                top: top,
                left: left,
            }}>
            <ul onClick={() => props.handleCloseMenu()}>
                {props.children}
            </ul>
        </div>
    );
});

ContextMenu.Item = ({ children, onClick, visible }) => {
    return visible === false ? null : <li onClick={onClick}>{children}</li>;
}