import { call, put, takeEvery, select, delay } from 'redux-saga/effects';

import { USER_DATA, SAGA_ERROR } from '../../../helpers/constants';
import { START_INTERNAL_CHATS  } from '../../../helpers/internalChatConstants';

import {
    createOrUpdateContact,
    toggleLoading,
    createOrUpdateContactObject,
    sendMessagesToUsers
} from '../utils/internalChatFunctions/startInternalChatsFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getContacts = state => state.internalChat.contacts;

function* handleCreateNewChats(action) {

    const contacts = yield select(getContacts);
    try {
        /** CREATE OR UPDATE CONTACTS */

        yield put(toggleLoading({ startNewChat: true }))
        const { newContacts, updatedContacts, noAffectedContacts } = createOrUpdateContactObject(contacts, action.data);

        console.log(newContacts, updatedContacts)
        
        const { data, response } = yield call(sendMessagesToUsers, [...newContacts, ...updatedContacts], action.data.messages);
        
        if (data) {
            yield put(createOrUpdateContact([...newContacts, ...noAffectedContacts], data));
            yield put(toggleLoading({ startNewChat: false }))
        } else {
            yield put(toggleLoading({ startNewChat: false }))
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    } catch (e) {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });
    }
}

export default function* startInternalChatsSaga() {
    yield takeEvery(START_INTERNAL_CHATS, handleCreateNewChats);
}