import { combineReducers } from "redux"
import groupChannels from "./channels"
import groupMessages from "./messages"
import groupScripts from "./chatScripts"
import groupTimes from "./workTimes"
import groupLimits from "./timeLimits"
import groupOutMessages from "./outMessages"
import groupRouting from "./groupRouting"

const groupsRulesReducer = combineReducers({
    groupChannels,
    groupMessages,
    groupScripts,
    groupTimes,
    groupLimits,
    groupOutMessages,
    groupRouting
})

export default groupsRulesReducer