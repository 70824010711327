import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_CORPORATECHAT_API_URL}/${process.env.REACT_APP_API_URI}/messages`;

const messagesService = {
	postMessagesSend: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/send`, { ...params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	getMessagesByUser: (messageId, params) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/byuser/${messageId}`, { params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	deleteMessages: (messageId) => {
		return new Promise((resolve, reject) => {
			return client()
				.delete(`${url}/${messageId}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});

	},
	putMessagesSetAsRead: (messageId) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/setasread/${messageId}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});

	},
	putMessagesConversationIdSetAsRead: (conversationId) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/${conversationId}/setallread/`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});

	}
};

export default messagesService;
