import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR } from '../../../helpers/constants';
import { DELETE_INTERNAL_CHAT_MESSAGE } from '../../../helpers/internalChatConstants';

import {
    isDeletingMessage,
    requestDeleteInternalChatMessage,
    removeMessageFromContact
} from '../utils/internalChatFunctions/deleteInternalChatFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getContacts = state => state.internalChat.contacts;

function* sendInternalChatMessage(action) {
    
    try {
        const contacts = yield select(getContacts);
        
        /** Altrera o status da mensagem para isDeleting para entregar um feedback ao usuário */
        yield put(isDeletingMessage(contacts, action.data));

        /** Faz a requisição para apagar a mensagem */
        const { data, response } = yield call(requestDeleteInternalChatMessage, action.data.id);
        
        if (data) {

            /** É preciso pegar a lista de contatos atualizada*/
            const contacts = yield select(getContacts);
            yield put(isDeletingMessage(contacts, action.data));
            yield put(removeMessageFromContact(contacts, action.data));
            
        } else {
            yield put(isDeletingMessage(contacts, action.data));
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }

    } catch (e) {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: e.message
        });

    }
}

export default function* sendInternalChatMessageSaga() {
    yield takeEvery(DELETE_INTERNAL_CHAT_MESSAGE, sendInternalChatMessage);
}