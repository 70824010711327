import React, { useRef, useState } from 'react';
import { tracksData } from '../data/tracks';
// import components
import DisplayTrack from './DisplayTrack';
import Controls from './Controls';
import ProgressBar from './ProgressBar';
import TogglePlay from './TogglePlay';

import './audio-player.scss'
import './customize-progress-bar.css'


const AudioPlayer = ({ attachUrl, audioTime }) => {
  const test = tracksData[0]
  const tracks = [{
    title: 'Trinix ft Rushawn – Its a beautiful day',
    src: attachUrl,
    author: 'Trinix ft Rushawn',
    thumbnail: null,
    duration: audioTime,
  }]
  // states
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(
    tracks[trackIndex]
  );
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  // reference
  let audioRef = useRef();
  const progressBarRef = useRef();

  const handleNext = () => {
    if (trackIndex >= tracks.length - 1) {
      setTrackIndex(0);
      setCurrentTrack(tracks[0]);
    } else {
      setTrackIndex((prev) => prev + 1);
      setCurrentTrack(tracks[trackIndex + 1]);
    }
  };

  // const changeProgressBarValue = (e) => {
  //   const value = parseInt(e.target.value);
  //   console.log(value)
  //   console.log(`${(value / audioTime) * 100}%`)
  //   audioRef.current.currentTime = value;
  //   progressBarRef.current.value = value;
  //   progressBarRef.current.style.setProperty(
  //     '--range-progress',
  //     `${(value / audioTime) * 100}%`
  //   );
  // }

  return (
      <div className="audio-player-ui">
        <TogglePlay {...{
              currentTrack,
              audioRef,
              setDuration,
              progressBarRef,
              handleNext,
              setTimeProgress,
              timeProgress
            }} />
        <div className="inner">
          <DisplayTrack
            src={attachUrl}
            {...{
              setTimeProgress,
              currentTrack,
              audioRef,
              setDuration,
              progressBarRef,
              handleNext,
            }}
          />
           <ProgressBar
            {...{ currentTrack, progressBarRef, audioRef, timeProgress, duration, setTimeProgress }}
          />
          <Controls
            {...{
              currentTrack,
              audioRef,
              progressBarRef,
              duration,
              setTimeProgress,
              tracks,
              trackIndex,
              setTrackIndex,
              setCurrentTrack,
              handleNext,
            }}
          />
         
        </div>
      </div>
  );
};
export default AudioPlayer;
