import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./beauty-scrollbar.scss";

// Define the props interface
interface BeautyScrollbarProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  id?: string | undefined | null;
  onScroll?: (scrollEvents: ScrollEventData) => void;
  onWheelScroll?: (scrollEvents: ScrollEventData) => void;
  disableScrollX?: boolean;
  onReachVerticalEnd?: (scrollEvents: ScrollEventData) => void;
  onReachVerticalStart?: (scrollEvents: ScrollEventData) => void;
  onReachHorizontalEnd?: (scrollEvents: ScrollEventData) => void;
  onReachHorizontalStart?: (scrollEvents: ScrollEventData) => void;
  keepHeightScrollPosition?: boolean;
}

// Define the scroll event data structure
interface ScrollEventData {
  contentInset: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };
  contentOffset: {
    x: number;
    y: number;
  };
  contentSize: {
    width: number;
    height: number;
  };
  layoutMeasurement: {
    width: number;
    height: number;
  };
  zoomScale: number;
}

// Define the imperative methods exposed through useImperativeHandle
export interface BeautyScrollbarRef {
  scrollToEnd: (animation: boolean) => void;
  scrollToStart: () => void;
  scrollTo: () => void;
  scrollToIndex: () => void;
}

const BeautyScrollbar = forwardRef<BeautyScrollbarRef, BeautyScrollbarProps>(
  (
    {
      children,
      className,
      style,
      id,
      onScroll,
      onWheelScroll,
      disableScrollX = false,
      onReachVerticalEnd,
      onReachVerticalStart,
      onReachHorizontalEnd,
      onReachHorizontalStart,
      keepHeightScrollPosition,
    }: BeautyScrollbarProps,
    ref
  ) => {
    const scrollRef = useRef<any>(null);
    const scrollContentRef = useRef<HTMLDivElement>(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [preventDoubleScrollListener, setPreventDoubleScrollListener] =
      useState(false);
    const [contentHeightChange, setContentHeightChange] = useState({
      prevHeight: 0,
      currentHeight: 0,
    });
    const [preventMultipleCalls, setPreventMultipleCalls] = useState(false);
    const wheelScrollTimeout = useRef<any>(null);

    useImperativeHandle(ref, () => ({
      scrollToEnd(animation: boolean) {
        messagesEndRef.current!.scrollIntoView(animation);
      },
      scrollToStart() {
        console.log("scrollToStart");
      },
      scrollTo() {
        console.log("scrollTo");
      },
      scrollToIndex() {
        console.log("scrollToIndex");
      },
    }));

    useEffect(() => {
      const scrollEventsData = () => {
        const {
          scrollTop,
          scrollHeight,
          clientHeight,
          scrollLeft,
          scrollWidth,
          clientWidth,
        } = scrollRef.current;
        return {
          contentInset: {
            top: scrollTop,
            left: scrollLeft,
            bottom: scrollHeight - scrollTop - clientHeight,
            right: scrollWidth - scrollLeft - clientWidth,
          },
          contentOffset: {
            x: scrollLeft,
            y: scrollTop,
          },
          contentSize: {
            width: scrollWidth,
            height: scrollHeight,
          },
          layoutMeasurement: {
            width: clientWidth,
            height: clientHeight,
          },
          zoomScale: 1,
        };
      };
      const handleScroll = () => {
        const {
          scrollTop,
          scrollHeight,
          clientHeight,
          scrollLeft,
          scrollWidth,
          clientWidth,
        } = scrollRef.current;

        const scrollEvents = scrollEventsData();
        // console.log(scrollEvents)

        if (scrollTop === 0 && onReachVerticalStart) {
          onReachVerticalStart(scrollEvents);
        } else if (
          !preventMultipleCalls &&
          scrollTop + clientHeight + 1 >= scrollHeight &&
          onReachVerticalEnd
        ) {
          /** Foi adicionado +5 pois, em algumas resoluções, há diferença de 1 a 2px */
          onReachVerticalEnd(scrollEvents);
          setTimeout(() => {
            setPreventMultipleCalls(false);
          }, 300);
        }
        if (scrollLeft === 0 && onReachHorizontalStart) {
          onReachHorizontalStart(scrollEvents);
        } else if (
          scrollLeft + clientWidth >= scrollWidth &&
          onReachHorizontalEnd
        ) {
          onReachHorizontalEnd(scrollEvents);
        }

        if (onScroll) {
          onScroll(scrollEvents);
        }
      };

      const onMouseWheel = (event: WheelEvent) => {
        if (event.type === "mousewheel" && onWheelScroll) {
          if (wheelScrollTimeout.current !== null) {
            clearTimeout(wheelScrollTimeout.current);
          }
          wheelScrollTimeout.current = setTimeout(() => {
            const scrollEvents = scrollEventsData();
            onWheelScroll(scrollEvents);
          }, 250); // Adjust the timeout duration as needed
        }
      };

      if (scrollRef.current && !preventDoubleScrollListener) {
        scrollRef.current.addEventListener("scroll", handleScroll);
        scrollRef.current.addEventListener("wheel", onMouseWheel);

        setPreventDoubleScrollListener(true);
        return () => {
          window.removeEventListener("scroll", handleScroll);
          window.removeEventListener("wheel", onMouseWheel);
        };
      }
    }, [scrollRef.current, preventDoubleScrollListener]);

    useEffect(() => {
      const element = scrollContentRef?.current;

      if (!element) return;

      const observer = new ResizeObserver(() => {
        const { scrollHeight } = scrollRef.current;
        setContentHeightChange((prev) => {
          return {
            prevHeight: prev.currentHeight,
            currentHeight: scrollHeight,
          };
        });
      });

      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }, []);

    useEffect(() => {
      if (keepHeightScrollPosition) {
        const { prevHeight } = contentHeightChange;
        const { scrollHeight, scrollTop } = scrollRef.current;

        if (scrollRef.current && scrollTop === 0) {
          scrollRef.current.scrollTo(0, scrollHeight - prevHeight);
        }
      }
    }, [contentHeightChange, keepHeightScrollPosition]);

    return (
      <div
        className={`scrollbar-ui ${className ? className : ""} ${
          disableScrollX ? "no-scroll" : ""
        }`}
        id={id || undefined}
        style={style}
        ref={scrollRef}
      >
        <div ref={scrollContentRef} className="scrollbar-ui-content">
          {children}
        </div>
        <div ref={messagesEndRef} />
      </div>
    );
  }
);

export default BeautyScrollbar;
