import api from '../services/apps/settings/users'

const status =  {
    logon: () => {
        return new Promise((resolve, reject) => {
            return api.userLogon()
                .then(res => resolve(true))
                .catch(error=> reject(false))
        })
    },
    logout: (origin) => {
        return new Promise((resolve, reject) => {
            return api.userLogout(origin)
                .then(res => resolve(true))
                .catch(error=> reject(false))
        })
    },
    pause: (param) => {
        return new Promise((resolve, reject) => {
            return api.userPause(param)
                .then(res => resolve(res))
                .catch(error=> reject(false))
        })
    },
}

export default status