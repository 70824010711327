import React from 'react'
import "./radio.scss"

function Radio({onChange, label, id, color, name, className, checked, value}) {
return (
    <div className={`ui-radio ${className ? className : ''}`}>
        <input type="radio" className={`${color ? `radio-${color}` : ''}`} value={value} checked={checked} id={id} name={name} onChange={ e => onChange(e)} />
        <label htmlFor={id}>{label}</label>
    </div>
  )
}
export default Radio
