import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR, STORE_USER } from '../../../helpers/constants';
import {
    getUserDetails,
    storeUserInfo,
    changeLoggedStatus
} from '../utils/authFunctions/authFunc';
import { NOTIFY_UPDATE_LOGGED_USER_STATUS } from '../../../helpers/constants';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';


const getUserData = (state: any) => state.auth.userData;

function* storeUserData(action: any) {
    try {
        const { data, response } = yield call(getUserDetails, action?.payload?.access_token);
        if (data) {
            yield put(storeUserInfo(data));
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    
    } 
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

type UserData = {
    id: number;
};

function* changeUserStatus(action: any) {
    const userData: UserData = yield select(getUserData);
        try {
            if(userData.id === action.payload.userId)  yield put(changeLoggedStatus(action.payload));           
          } catch (e) {
              const error = e as Error;
            yield put({
              type: SAGA_ERROR,
              origin: errorOrigin.saga,
              errorType: errorTypes.error,
              response: error
            });
          }      
  }

export default function* authSaga() {
    //** Essa função estava impactando o Loggeduser no chat do agente, impossibilidando que a lista de contatos fosse carregada */
    // yield takeEvery(STORE_USER, storeUserData);

    yield takeEvery(NOTIFY_UPDATE_LOGGED_USER_STATUS, changeUserStatus);

}