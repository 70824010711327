const initialState = {
    scripts: [],
    filteredScripts: []
}
    
    const groupScriptsReducer = (state = initialState, action) => {
      switch (action.type) {
        case "FETCH_GROUP_SCRIPTS":
          let scripts = [...state.scripts]
          scripts = action.scripts
          return { ...state, scripts }
        case "PUT_GROUP_SCRIPTS":
            return { ...state }
        case "UPDATE_GROUP_SCRIPTS":
            return { ...state }
        case "ADD_GROUP_SCRIPTS":
            return { ...state }
        case "SEARCH_GROUP_SCRIPTS":
            console.log(action.term)
            let filteredScripts = state.scripts.filter(val => {
                if (action.term) {
                 return (
                   ( val.content.toLowerCase().includes(action.term.toLowerCase()) )
                 )
               } else {
                 return state.scripts
               }
             })
             return { ...state, filteredScripts }
      default:
          return state
      }
    }
    
    export default groupScriptsReducer
    