import React, { } from 'react';
import moment from 'moment'

const diffDays = (local, date, type) => {
    const today = moment.utc(local,'YYYY-MM-DD H:mm')
    const next = moment.utc(date,'YYYY-MM-DD H:mm')
    const diffDay = next.diff(today, type)
    return diffDay
  }

  const localDatetime = () => {
    const date = moment.utc().format('YYYY-MM-DD HH:mm');
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
    return local
  }

  const getLocalTime = (date) => {
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss.SSS');
    return local
  }

  const getGmt0 = (date) => {
    // combine date and time strings and create a Moment.js object
    const dateTime = moment(date, 'YYYY-MM-DD HH:mm:ss.SSS');
  
    // convert to GMT 0 and return as string
    return dateTime.utc().format('YYYY-MM-DD HH:mm:ss.SSS');
  }

  const calendarDate = (date) => {
    return moment(getLocalTime(date)).calendar(null, {
        lastDay: '[Ontem às] HH:mm',
        sameDay: '[Hoje às] HH:mm',
        nextDay: '[Amanhã]',
        // lastWeek: 'dddd',
        // nextWeek: 'DD/MM',
        sameElse: '[em ]DD/MM/YY [às] HH:mm'
    })
  }

  export {
    diffDays,
    localDatetime,
    getLocalTime,
    getGmt0,
    calendarDate
  }