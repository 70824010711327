const initialState = {
    messages: [],
    filteredMessages: []
}
    
    const groupMessagesReducer = (state = initialState, action) => {
      switch (action.type) {
        case "FETCH_GROUP_MESSAGES":
          let messages = [...state.messages]
          messages = action.messages
          return { ...state, messages }
        case "PUT_GROUP_MESSAGE":
            return { ...state }
        case "UPDATE_GROUP_MESSAGE":
            return { ...state }
        case "ADD_GROUP_MESSAGE":
            return { ...state }
        case "SEARCH_GROUP_MESSAGES":
            console.log(action.term)
            let filteredMessages = state.messages.filter(val => {
                if (action.term) {
                 return (
                   ( val.slug.toLowerCase().includes(action.term.toLowerCase()) ||
                    val.message.toLowerCase().includes(action.term.toLowerCase()) )
                 )
               } else {
                 return state.messages
               }
             })
             return { ...state, filteredMessages }
      default:
          return state
      }
    }
    
    export default groupMessagesReducer
    