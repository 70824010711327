import React from "react";
import AudioPlayer from "../messages/components/audio-player/components/AudioPlayer";
import { ReactComponent as AudioWave } from '../../assets/img/svg/audio-wave.svg';
import { ReactComponent as Video } from '../../assets/img/svg/video.svg';

import "./thumbnail.scss";

const fileFormatIcons = [
    "7Z",
    "AAC",
    "AEP",
    "AI",
    "AVI",
    "AZW",
    "BAT",
    "BIN",
    "BMP",
    "CDR",
    "CSS-01",
    "CSS-02",
    "CSV",
    "DAT",
    "DJVU",
    "DLL",
    "DMG",
    "DOC",
    "DOCX",
    "EML",
    "EPS",
    "EPUB",
    "EXE",
    "FLA",
    "FLAC",
    "GIF",
    "HTML",
    "ICO",
    "INDD",
    "INI",
    "ISO",
    "JAR",
    "JPEG",
    "JPG",
    "JS",
    "KEY",
    "MIDI",
    "MKV",
    "MOBI",
    "MOV",
    "MP3",
    "MP4",
    "NFO",
    "NUMBERS",
    "OGG",
    "OTF",
    "PAGES",
    "PDF",
    "PHP",
    "PNG",
    "PPT",
    "PPTX",
    "PSD",
    "RAR",
    "RAW",
    "RTF",
    "SQL",
    "SUB",
    "SVG",
    "SWF",
    "SYS",
    "TTF",
    "TXT",
    "URL",
    "WAV",
    "WMV",
    "XLS",
    "XLSX",
    "XML",
    "ZIP"
]

const Thumbnail = ({ data, itemsHideen, onlyIcon }) => {
    const { messageId, storageName, fileName, contentType, secondsAudio } = data;
    const urlProperty = null;
    const attachUrl = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/messageattachments/${urlProperty ? `${urlProperty}/` : ''}${messageId}/download/${storageName}`;
    const fileExtension = fileName.split('.').pop()
    const fileType = contentType.split('/')[0]
    const specificIcon = fileFormatIcons.find(f => f.toLowerCase() === fileExtension)

    /** Arquivos de imagens, vídeos, áudios, renderizam uma tag html específica.
     *  Os demais formatos de aruqivos, renderizam um ícone trazido da lista fileFormatIcons.
     *  Caso o formato não esteja na lista, renderiza um ícone genérico.
     */
    let icon
    switch (fileType) {
        case 'image':
            icon = <div className="thumbmail-img">
                <img className={contentType === "image/webp" ? "sticker" : "thumbnail"} src={attachUrl} />
            </div>
            break;
        case 'video':
            icon =  onlyIcon ?   <div className="thumbmail-icon video">
                                    <Video />
                                </div> : 
                                <div className="video-icon" key={attachUrl}>
                                    <video controls>
                                        <source src={attachUrl} type={contentType} />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
            break;
        case 'audio':
            icon = onlyIcon ?   <div className="thumbmail-icon audio-wave">
                                    <AudioWave />
                                </div> : 
                                <AudioPlayer attachUrl={attachUrl} audioTime={secondsAudio} />
            break;
        default:
            icon =  <div className="thumbmail-icon">
                        <img className="icon" src={
                            typeof specificIcon !== 'undefined' ?
                                require('../../assets/img/svg/mimetype/' + specificIcon + '.svg') :
                                require('../../assets/img/svg/mimetype/Default.svg')
                        } />
                    </div>
            break;
    }

    return (
        <div className="thumbnail-ui">
            {icon}
            {itemsHideen > 0 && <div className="thumbnail-hiddem-items">+{itemsHideen}</div>}
        </div>
    );
}

export default Thumbnail;