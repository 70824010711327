import React from "react";
import "./x-circle.scss";

const XCircle = ({onClick = () => {}}) =>
    <div className="arrow" onClick={onClick}>
        <svg className="xmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <line className="xmark__line" x1="15" y1="15" x2="37" y2="37" />
            <line className="xmark__line" x1="15" y1="37" x2="37" y2="15" />
        </svg>
    </div>

export default XCircle;