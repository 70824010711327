export const lightenColor = (color, percentage) => {
    // If color is an array [r, g, b]
    if (Array.isArray(color)) {
      const [r, g, b] = color;
      const amount = Math.round(2.55 * percentage);
      const lightenedR = Math.min(r + amount, 255);
      const lightenedG = Math.min(g + amount, 255);
      const lightenedB = Math.min(b + amount, 255);
      return `rgb(${lightenedR}, ${lightenedG}, ${lightenedB})`;
    }
  
  }
  
 export const generateDarkColorFromString = (str="default") => {
    let value = 0;
    for (let i = 0; i < str?.length; i++) {
      value += str.charCodeAt(i);
    }
  
    const goldenRatio = 0.618033988749895; // Proporção áurea
    const hue = (value * goldenRatio) % 1; // Valor de matiz entre 0 e 1
    const saturation = 0.25 + (value % 8) * 0.1; // Valor de saturação entre 0.25 e 0.95
    const lightness = 0 + (value % 8) * 0.1; // Valor de luminosidade entre 0.25 e 0.95
  
    const rgb = hslToRgb(hue, saturation, lightness);
    const [r, g, b] = rgb.map(Math.round);
  
    // return `rgb(${r}, ${g}, ${b})`;
    return {
      dark: `rgb(${r}, ${g}, ${b})`,
      medium: lightenColor([r, g, b], 25),
      light: lightenColor([r, g, b], 50)
    }
  
  }
  
  // Função auxiliar para converter HSL para RGB
  const hslToRgb =(h, s, l) => {
    let r, g, b;
  
    if (s === 0) {
      r = g = b = l; // Tons de cinza
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
  
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
  
      r = hueToRgb(p, q, h + 1 / 3);
      g = hueToRgb(p, q, h);
      b = hueToRgb(p, q, h - 1 / 3);
    }
  
    return [r * 255, g * 255, b * 255];
  }
  