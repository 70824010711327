import { useEffect, useRef, useState } from 'react';

const useAlternateBrowserTitle = (originalTitle) => {
    const [newTitle, setNewTitle] = useState(originalTitle);
    const intervalRef = useRef(null);

    const handleResetTitle = () => {
        clearInterval(intervalRef.current);
        setNewTitle(originalTitle);

    };

    const handleChangeTitle = (title) => {
        handleResetTitle();
        const toggleTitle = () => {
            setNewTitle((prevIsAlternate) => prevIsAlternate === title ? originalTitle : title);
        };

        intervalRef.current = setInterval(toggleTitle, 1000);

        return () => {
            clearInterval(intervalRef.current);
        };

    }

    useEffect(() => {
        document.title = newTitle;
    }, [newTitle]);

    return {
        handleResetTitle,
        handleChangeTitle
    }
};

export default useAlternateBrowserTitle;