import mock from "../mock"
import moment from "moment"

// Contact
let data = {
  contacts: [
    {
      uid: 1,
      displayName: "Felecia Rower",
      about:
        "Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing",
      photoURL: null,
      status: "offline",
      source: "facebook",
      phoneNumber: "+5521999999999",
      email: 'felecia@gmail.com',
      favorite: true,
      lastMessage: '2020-06-03 07:35:42',
      unread: true,
      forwardFrom: {
        uid: 5,
        department: 'Comercial',
        name: 'Patrícia Guimarães',
        position: 'Supervisor comercial'  
      },
      agent: {
        uid: 5,
        department: 'Comercial',
        name: 'Patrícia Guimarães',
        position: 'Supervisor comercial'
      },
      lastAgent: {
        uid: 5,
        department: 'Comercial',
        name: 'Patrícia Guimarães',
        position: 'Supervisor comercial'
      }
    },
    {
      uid: 2,
      displayName: "Adalberto Granzin",
      about:
        "Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.",
      photoURL: null,
      status: "do not disturb",
      source: "whatsapp",
      phoneNumber: "+5521999999999",
      email: 'adalberto@gmail.com',
      favorite: false,
      lastMessage: '2020-06-03 08:50:42',
      forwardFrom: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
       },
       agent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      },
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      },
       wait: {
        begin: 'Fri May 29 2020 12:49:03 GMT-0300 (Horário Padrão de Brasília)',
        end: null,
        state: true
       }
    },
    {
      uid: 3,
      displayName: "Joaquina Weisenborn",
      about:
        "Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.",
      photoURL: null,
      status: "do not disturb",
      source: "whatsapp",
      phoneNumber: "+5521999999999",
      email: 'joaquina@gmail.com',
      favorite: false,
      lastMessage: '2020-05-18 08:50:42',
      unread: true,
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      },

    },
    {
      uid: 4,
      displayName: "Verla Morgano",
      about:
        "Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.",
      photoURL: require("../../assets/img/portrait/small/avatar-s-4.jpg"),
      status: "online",
      source: "facebook",
      phoneNumber: "+5521999999999",
      email: 'verla@gmail.com',
      favorite: true,
      lastMessage: '2020-05-18 08:45:42',
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      },
    },
    {
      uid: 5,
      displayName: "Margot Henschke",
      about:
        "Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing",
      photoURL: require("../../assets/img/portrait/small/avatar-s-5.jpg"),
      status: "do not disturb",
      source: "facebook",
      phoneNumber: "+5521999999999",
      email: 'margot@gmail.com',
      favorite: false,
      lastMessage: '2020-05-18 08:40:42',
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      }
    },
    {
      uid: 6,
      displayName: "Sal Piggee",
      about:
        "Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.",
      photoURL: null,
      status: "online",
      source: "facebook",
      phoneNumber: "+5521999999999",
      email: 'sal@gmail.com',
      favorite: true,
      lastMessage: '2020-05-18 08:35:42',
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      }
    },
    {
      uid: 7,
      displayName: "Miguel Guelff",
      about:
        "Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.",
      photoURL: null,
      status: "online",
      source: "whatsapp",
      phoneNumber: "+5521999999999",
      email: 'miguel@gmail.com',
      favorite: false,
      lastMessage: '2020-05-17 08:45:42',
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      }
    },
    {
      uid: 8,
      displayName: "Mauro Elenbaas",
      about:
        "Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.",
      photoURL: require("../../assets/img/portrait/small/avatar-s-8.jpg"),
      status: "away",
      source: "whatsapp",
      phoneNumber: "+5521999999999",
      email: 'mauro@gmail.com',
      favorite: false,
      lastMessage: '2020-05-17 08:45:42',
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      }
    },
    {
      uid: 9,
      displayName: "Bridgett Omohundro",
      about:
        "Gummies gummi bears I love candy icing apple pie I love marzipan bear claw. I love tart biscuit I love candy canes pudding chupa chups liquorice croissant.",
      photoURL: require("../../assets/img/portrait/small/avatar-s-9.jpg"),
      status: "offline",
      source: "facebook",
      phoneNumber: "+5521999999999",
      email: 'bridgett@gmail.com',
      favorite: false,
      lastMessage: '2020-05-16 08:45:42',
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      }
    },
    {
      uid: 10,
      displayName: "Zenia Jacobs",
      about:
        "Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing",
      photoURL: null,
      status: "away",
      source: "facebook",
      phoneNumber: "+5521999999999",
      email: 'zenia@gmail.com',
      favorite: false,
      lastMessage: '2020-05-15 08:45:42',
      lastAgent: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      }
    }
  ],
  chats: {
    1: {
      isPinned: false,
      source: 'facebook',
      operator: {
        uid: 5,
        department: 'Comercial',
        name: 'Patrícia Guimarães',
        position: 'Supervisor comercial'  
      },
      scripts: [
        {
          id: 2,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        },
        {
          id: 6,
          realized: true
        }
        
      ],
      msg: [
        {
          id: 1,
          textContent: "Hi",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 2,
          textContent: "Hello. How can I help You?",
          time: "Mon Aug 22 2020 07:45:15 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 3,
          textContent:
            "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has?",
          time: "Mon Aug 22 2020 07:46:10 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true,
          answerFrom: {
            id: 2,
            textContent: "Hello. How can I help You?",
            time: "Mon Aug 22 2020 07:45:15 GMT+0000 (GMT)",
            isSent: false,
            isSeen: true
          },
          attachedFile: {
            base64: "data:application/zip;base64,JVBERi0xLjQKJcOkw7zDts",
            fileName: "FileZilla_3.48.1_macosx-x86.app.tar.bz2",
            fileSize: 1035800,
          }

        },
        {
          id: 4,
          textContent:
            "We need to check if we can provide you such information.",
          time: "Mon Aug 22 2020 07:45:15 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true,
          answerFrom: {
            id: 3,
            isSeen: true,
            isSent: true,
            textContent: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has?",
            time: "Mon Aug 22 2020 07:46:10 GMT+0000 (GMT)"
          },
          attachedFile: {
            base64: "data:application/vnd.ms-excel;base64,JVBERi0xLjQKJcOkw7zDts",
            fileName: "Contrato Jonathan Rufino.pdf",
            fileSize: 51714,
          }
        },
        {
          id: 5,
          textContent: "I will inform you as I get update on this.",
          time: "Mon Aug 22 2020 07:46:15 GMT+0000 (GMT)",
          isSent: false,
          isSeen: false,
          attachedFile: {
            base64: "data:application/pdf;base64,JVBERi0xLjQKJcOkw7zDts",
            fileName: "Contrato Jonathan Rufino.pdf",
            fileSize: 51714,
          }
        },
        {
          id: 6,
          textContent: "If it takes long you can mail me at my mail address",
          time: "Mon Aug 22 2020 07:46:20 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        }
      ]
    },
    2: {
      isPinned: true,
      source: 'facebook',
      operator: {
        uid: 2,
        department: 'Financeiro',
        name: 'Marcio Braga',
        position: 'Supervisor financeiro'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 7,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 8,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 9,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 10,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 11,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 12,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 13,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 14,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 15,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 16,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    3: {
      isPinned: true,
      source: 'whatsapp',
      operator: {
        uid: 6,
        department: 'Comercial',
        name: 'Alberto Neto',
        position: 'Gerente comercial'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 17,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 18,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 19,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 20,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 21,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 22,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 23,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 24,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 25,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 26,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    4: {
      isPinned: true,
      source: 'whatsapp',
      operator: {
        uid: 12,
        department: 'Relacionamento com o cliente',
        name: 'Jorge José',
        position: 'Gerente'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 27,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 28,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 29,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 30,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 31,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 32,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 33,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 34,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 35,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 36,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    5: {
      isPinned: true,
      source: 'whatsapp',
      operator: {
        uid: 6,
        department: 'Comercial',
        name: 'Alberto Neto',
        position: 'Gerente comercial'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 37,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 38,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 39,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 40,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 41,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 42,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 43,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 44,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 45,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 46,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    6: {
      isPinned: true,
      source: 'whatsapp',
      operator: {
        uid: 6,
        department: 'Comercial',
        name: 'Alberto Neto',
        position: 'Gerente comercial'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 47,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 48,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 49,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 50,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 51,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 52,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 53,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 54,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 55,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 56,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    7: {
      isPinned: true,
      source: 'facebook',
      operator: {
        uid: 6,
        department: 'Comercial',
        name: 'Alberto Neto',
        position: 'Gerente comercial'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 57,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 58,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 59,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 60,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 61,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 62,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true,
        },
        {
          id: 63,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 64,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 65,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 66,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    8: {
      isPinned: true,
      source: 'whatsapp',
      operator: {
        uid: 6,
        department: 'Comercial',
        name: 'Alberto Neto',
        position: 'Gerente comercial'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 67,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 68,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 69,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 70,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 71,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 72,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 73,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 74,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 75,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 76,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    9: {
      isPinned: true,
      source: 'whatsapp',
      operator: {
        uid: 6,
        department: 'Comercial',
        name: 'Alberto Neto',
        position: 'Gerente comercial'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 77,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 78,
          textContent:
            "Hey John, I am looking for the best admin template. Could you please help me to find it out?",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 79,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 80,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 81,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 82,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 83,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 84,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 85,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 86,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },
    10: {
      isPinned: true,
      source: 'facebook',
      operator: {
        uid: 6,
        department: 'Comercial',
        name: 'Alberto Neto',
        position: 'Gerente comercial'
      },
      scripts: [
        {
          id: 1,
          realized: true
        },
        {
          id: 3,
          realized: true
        },
        {
          id: 4,
          realized: true
        }
      ],
      msg: [
        {
          id: 87,
          textContent: "How can we help? We're here for you!",
          time: "Mon Aug 21 2020 07:45:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: false
        },
        {
          id: 88,
          textContent: "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It hasto make a type specimen book. It has dd type specimen book. It",
          time: "Mon Aug 21 2020 07:45:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: false
        },
        {
          id: 89,
          textContent: "It should be Bootstrap 4 compatible.",
          time: "Mon Aug 21 2020 07:45:55 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 90,
          textContent: "Absolutely!",
          time: "Mon Aug 21 2020 07:46:00 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 91,
          textContent:
            "Modern admin is the responsive bootstrap 4 admin template.!",
          time: "Mon Aug 21 2020 07:46:05 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 92,
          textContent: "Looks clean and fresh UI.",
          time: "Mon Aug 21 2020 07:46:23 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 93,
          textContent: "It's perfect for my next project.",
          time: "Mon Aug 21 2020 07:46:33 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 94,
          textContent: "How can I purchase it?",
          time: "Mon Aug 21 2020 07:46:43 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        },
        {
          id: 95,
          textContent: "Thanks, from ThemeForest.",
          time: "Mon Aug 21 2020 07:46:53 GMT+0000 (GMT)",
          isSent: true,
          isSeen: true
        },
        {
          id: 96,
          textContent: "I will purchase it for sure 👍.",
          time: "Mon Aug 21 2020 07:47:00 GMT+0000 (GMT)",
          isSent: false,
          isSeen: true
        }
      ]
    },

  },
  readyMessages: [
    {
      id: 1,
      slug: '/obrigado',
      message: 'Agradecemos o seu contato e permanecemos à disposição caso tenha alguma nova questão.'
    },
    {
      id: 2,
      slug: '/documentos',
      message: 'Para abrir a sua solicitação, precisaremos do número do seu CPF, Endereco completo com o CEP e o número do telefone.'
    },
    {
      id: 3,
      slug: '/endereço',
      message: 'Qual é o seu endereço completo com o CEP?'
    },
    {
      id: 4,
      slug: '/telefone',
      message: 'Qual é o número do seu telefone com o DDD?'
    },
    {
      id: 5,
      slug: '/email',
      message: 'Qual é o seu endereço de email?',
    }
  ],
  users: [
    {
      uid: 1,
      department: 'Financeiro',
      name: 'Fernanda Garcia',
      position: 'Assistente financeiro',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 2,
      department: 'Financeiro',
      name: 'Marcio Braga',
      position: 'Supervisor financeiro',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 3,
      department: 'Financeiro',
      name: 'Amégia Faria',
      position: 'Gerente financeiro',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 4,
      department: 'Comercial',
      name: 'Bruno Henrique',
      position: 'Assistente comercial',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 5,
      department: 'Comercial',
      name: 'Patrícia Guimarães',
      position: 'Supervisor comercial',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 6,
      department: 'Comercial',
      name: 'Alberto Neto',
      position: 'Gerente comercial',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 7,
      department: 'Logística',
      name: 'Ailton Antunes',
      position: 'Assistente',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 8,
      department: 'Logística',
      name: 'Miguel Filho',
      position: 'Supervisor',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 9,
      department: 'Logística',
      name: 'Maurício Filho',
      position: 'Gerente',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 10,
      department: 'Relacionamento com o cliente',
      name: 'Fabiana Braga',
      position: 'Assistente',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 11,
      department: 'Relacionamento com o cliente',
      name: 'Juliana Lisboa',
      position: 'Supervisor',
      stats: {
        chats: 5,
        messages: 148
      }
    },
    {
      uid: 12,
      department: 'Relacionamento com o cliente',
      name: 'Jorge José',
      position: 'Gerente',
      stats: {
        chats: 5,
        messages: 148
      }
    },
  ],
  scripts: [
    {
      id: 1,
      content: 'Solicitar o endereço completo do cliente',
      realized: false
    },
    {
      id: 2,
      content: 'Informar o prazo de compensação do boleto de 3 dias',
      realized: false
    },
    {
      id: 3,
      content: 'Procurar receber contas em atraso via cartão de crédito.',
      realized: false
    },
    {
      id: 4,
      content: 'Inormar número de protocolo ao cliente.',
      realized: false
    },
    {
      id: 5,
      content: 'Informar que o número do protocolo será enviado por email ou sms.',
      realized: false
    },
    {
      id: 6,
      content: 'Informar que será enviado um link para responder uma pesquisa de satisfação e solicitar a gentileza do cliente responder.',
      realized: false
    },

  ]
}

// GET : CHATS, CONTACTS, USERS  AND READ MESSAGES
mock.onGet("/api/app/chat/chats").reply(() => {
  return [200, data]
})

// GET : CHAT LIST
mock.onGet("/api/app/chat/chat-contacts").reply(request => {
  const chatContactsArray = data.contacts.filter(contact => {
    if (data.chats[contact.uid]) return data.chats[contact.uid]
    else return null
  })

  return [200, chatContactsArray]
})

// GET : CHAT SEARCH
mock.onGet("/api/app/chat/search-chats").reply(request => {
  const startDate = request.daterange[0]
  const endDate = request.daterange[1] ? request.daterange[1] : request.daterange[0] 
  const term = request.term

  const searchResults = data.contacts.filter(f => {
    const itemData = `${f.displayName.toUpperCase()} ${f.phoneNumber.toUpperCase()}  ${f.email.toUpperCase()}`;
    return itemData
        .toLowerCase()
        .includes(term.toLowerCase())
    })
  return [200, searchResults]
})

// GET : GET HISTORY
mock.onGet("/api/app/chat/get-history").reply(request => {
  console.log(request.chat)
  const uid = request.chat.uid;
  
  return [200, data.chats[uid]]
})

// GET : CHAT USERS
mock.onGet("/api/app/chat/chat-users").reply(request => {
  const chatUsersArray = data.users.filter(users => {
    if (data.chats[users.uid]) return data.chats[users.uid]
    else return null
  })

  return [200, chatUsersArray]
})


// POST : UPDATE USER
mock.onPut("api/app/chat/update-user").reply(request => {
  let reqdata = JSON.parse(request.data)
  const { activeUser } = reqdata
  /*if (data.chats[activeUser.uid]) {
    data.chats[activeUser.uid].msg.push(message)
  } else {
    let newMsg = {
      [contactId]: {
        isPinned: isPinned,
        msg: [message]
      }
    }
    Object.assign(data.chats, newMsg)
  }*/
  return [200, activeUser]
})

// POST : SEND MESSAGE
mock.onPost("api/app/chat/send-message").reply(request => {
  let reqdata = JSON.parse(request.data)
  const { contactId, message, isPinned } = reqdata
  if (data.chats[contactId]) {
    data.chats[contactId].msg.push(message)
  } else {
    let newMsg = {
      [contactId]: {
        isPinned: isPinned,
        msg: [message]
      }
    }
    Object.assign(data.chats, newMsg)
  }
  return [200]
})


mock.onPost("/api/apps/chat/mark-all-seen/").reply(request => {
  let contactId = JSON.parse(request.data).contactId

  // Get chat data
  let marked = data.chats[contactId]

  marked !== undefined &&
    marked.msg.forEach(msg => {
      msg.isSeen = true
    })

  return [200]
})
// POST : TOGGLE PINNED
/*mock.onPost("/api/apps/chat/set-pinned/").reply(request => {
  let { contactId, value } = JSON.parse(request.data)
  data.chats[contactId].isPinned = data.chats[contactId].isPinned = value
  return [200]
})*/

// POST : TOGGLE PINNED
mock.onPost("/api/apps/chat/set-favorite/").reply(request => {
  let { contactId } = JSON.parse(request.data)
  //data.chats[contactId].isPinned = data.chats[contactId].isPinned = value
  return [200, contactId]
})

// POST : TOGGLE PINNED
mock.onPut("/api/apps/chat/mark-as-unread/").reply(request => {
  let { contactId } = JSON.parse(request.data)
  console.log(contactId)
  return [200, contactId]
})

mock.onPost("/api/apps/chat/link-client/").reply(request => {
  let { action, activeContact, activeUser } = JSON.parse(request.data)
  let userToUpdate = data.contacts.find(f => f.uid === activeUser.uid);
  if(action == 'relink' || action == 'link'){
    userToUpdate.agent = activeContact
  } else {
    userToUpdate.agent = []
  }
  return [200, {action, userToUpdate}]
})



// POST : TOGGLE PINNED
mock.onPut("/api/apps/chat/update-fields/").reply(request => {
  let reqdata = JSON.parse(request.data)
  const { activeUser } = reqdata
  //data.chats[contactId].isPinned = data.chats[contactId].isPinned = value
  return [200, activeUser]
})


