import { releaseVersionsService, releaseVersionUserStatesService } from '../../../../newServices/main';
import { IReleaseVersion } from '../../../../interfaces/releaseNotes/releaseVersions.interface';
import { STORE_RELEASE_NOTES_VERSIONS, SET_CURRENT_RELEASE_VERSION  } from '../../../../helpers/releaseNotesConstants';

import moment from 'moment';
import jwt from 'jwt-decode'

import { IUserData } from '../../../../interfaces/auth/userData.interface';

const HOURS_TO_SHOW_AGAIN = 24;

export const getReleaseNoteVersions = () => {

    const params = {
      PageNumber: 1,
      PageSize: 20,
      IsPublished: true,
      FilterByUserRole: true
    }

    return releaseVersionsService
            .getReleaseVersionsByPagination(params)
            .then((res) => res)
            .catch((err) => err)
}



export const listOfReleaseNotesVersions = (data: IReleaseVersion[]) => {
    return {
        type: STORE_RELEASE_NOTES_VERSIONS,
        payload: data.sort((a, b) => b.id - a.id)
    }
};

export const getReleaseVersionUserState = (lastVersion: IReleaseVersion, accessToken: string) => {

    const userInfo: any = accessToken && jwt(accessToken)
    const role = parseInt(userInfo["omnify.user"]);

    const { totalAdminPages, totalAgentPages } = lastVersion;
    
    if(role < 3 && totalAdminPages === 0 || role === 3 && totalAgentPages === 0) return null;

    return releaseVersionUserStatesService.
      getReleaseVersionsUserStates(lastVersion.id)
      .then((res) => {
        const { data } = res;
        
        const diff = data?.seeLaterCheckedAt ? moment().diff(moment(data.seeLaterCheckedAt).startOf('day'), 'hours') : null;

        if (data?.isSeeLater && diff && diff < HOURS_TO_SHOW_AGAIN || data?.isSkipped) return;


        if (data || !data) {
            return {
                data: {
                    ...lastVersion,
                    userVersionData: data
                }
            };
        } else {
            return {
                data: null
            }
        }

      })
      .catch((err) => err)
  }

export const setUserCurrentReleaseVersion = (releaseVersion: IReleaseVersion) => {
    return {
        type: SET_CURRENT_RELEASE_VERSION,
        payload: releaseVersion
    }
};
