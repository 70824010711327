import {
  USER_SIGNED_OUT,
  STORE_USER_ERROR,
  USER_EXPIRED,
  STORE_USER,
  STORE_USER_INFO,
  LOADING_USER,
  EXPIRING_TOKEN,
  EXPIRED_TOKEN,
  USER_STATUS,
  USER_DATA,
  LOGGED_USER_STATUS
} from '../../actions/authentication/types'

const initialState = {
  user: null,
  isLoadingUser: false,
  userData: null,
  loggedUserStatus: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case STORE_USER:
      console.log('Reducer STORE_USER')
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload
      }
    case STORE_USER_INFO: {
      return {
        ...state,
        userData: action.payload,
        loggedUserStatus: {
          ...action.payload.loggedUserStatus,
          loggedUserStatus: action.payload.loggedUserStatus,
          pause: action.payload.pause
        }
      }
    }
    case LOADING_USER:
      console.log('Reducer LOADING_USER')
      return {
        ...state,
        isLoadingUser: true
      }
    case USER_EXPIRED:
      console.log('Reducer USER_EXPIRED')
    case EXPIRING_TOKEN:
      console.log('Reducer EXPIRING_TOKEN')
      return {...state}
    case EXPIRED_TOKEN:
      console.log('Reducer EXPIRED_TOKEN')
      return {
        ...state,
        user: null,
        isLoadingUser: false
      }
    case STORE_USER_ERROR:
      console.log('Reducer STORE_USER_ERROR')
    case USER_SIGNED_OUT:
      console.log('Reducer USER_SIGNED_OUT')
      return {
        ...state,
        user: null,
        isLoadingUser: false
      }
      case LOGGED_USER_STATUS:
        return { 
          ...state, 
          loggedUserStatus: {
          ...state.loggedUserStatus,
          ...action.payload
        }  
      }
    default:
      return state
  }
}