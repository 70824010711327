
import React from "react";
import { formatPhoneNumber, formatPhoneNumberIntl} from 'react-phone-number-input';

function phoneMask(value ) {
  if(value && value.length > 0){
    return !value.startsWith('+') ? formatPhoneNumberIntl(`+${value}`) : formatPhoneNumberIntl(value)
  } else {
    return ''
  }
}

const mphone = r => {
  // var r = `${r}`.replace(/\D/g, "");
  // r = r.replace(/^0/, "");
  if(r.length === 14){
    r = r.replace(/^(\d{3})(\d{2})(\d{5})(\d{4}).*/, "($1 $2) $3-$4");
  } else 
  if(r.length === 13){
    r = r.replace(/^(\d{3})(\d{2})(\d{4})(\d{4}).*/, "($1 $2) $3-$4");
  } else if (r.length === 12) {
    r = r.replace(/^(\d{3})(\d{4})(\d{5}).*/, "($1) $2-$3");
  } else if (r.length === 11) {
    r = r.replace(/^(\d{3})(\d{4})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length === 10) {
    r = r.replace(/^(\d{3})(\d{4})(\d{3})/, "($1) $2-$3");
  } else if (r.length === 9) {
    r = r.replace(/^(\d{3})(\d{4})(\d{2})/, "($1) $2-$3");
  } else if (r.length === 8) {
    r = r.replace(/^(\d{3})(\d{4})(\d{1})/, "($1) $2-$3");
  }  else if (r.length === 7) {
    r = r.replace(/^(\d{3})(\d{4})/, "($1) $2");
  }  else if (r.length === 6) {
    r = r.replace(/^(\d{3})(\d{3})/, "($1) $2");
  }  else if (r.length === 5) {
    r = r.replace(/^(\d{3})(\d{2})/, "($1) $2");
  }  else if (r.length === 4) {
    r = r.replace(/^(\d{3})(\d{1})/, "($1) $2");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "$1$2");
  } else {
    r = r.replace(/^(\d*)/, "$1");
  }
  return r;

}

export {
  phoneMask, mphone
}
