import React, { ReactNode } from 'react';
import './divider.scss';

interface DividerProps {
  className?: string;
  children?: ReactNode;
}

const Divider: React.FC<DividerProps> = ({ className, children }) => {
  return (
    <div className={`ui-division-title ${className ? className : ''} ${!children ? 'pure-line' : ''}`}>
      <span>{children}</span>
    </div>
  );
};

export default Divider;
