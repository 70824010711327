import { client } from '../../utils/axios-utils'

/*if (!domain) {
    throw new Error('Server url not present. Contact the adminstrator!')
}*/
const groupshiftsService =  {

    postGroupshifts: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/groupshifts`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putGroupshifts: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/groupshifts`, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getGroupshiftsShift: (id) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/groupshifts/shift/${id}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
}

export default  groupshiftsService;