import React from "react";

const Clearer = (handleClear, optionIsArray, size, disabled) => {
  return (
    <div className={`remove-selected ${size}`}>
      <div
        className={`input-form-clear danger ${disabled ? "disabled" : ""}`}
        onClick={() => handleClear(optionIsArray)}
        data-testid="clear-button"
      >
        +
      </div>
    </div>
  );
};

export default Clearer;
