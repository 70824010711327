import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/users`;

const usersService = {
	getUsers: (params) => {
		const formated = params
			? Object.entries(params).map((key, index) => {
					const value = key[1];
					return typeof value === 'object'
						? value.map((t) => `${key[0]}=${t}`).join('&')
						: `${key[0]}=${value}`;
			  })
			: [];
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}?${formated.join('&')}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	getUserId: (id, refreshCache) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${url}/${id}/${refreshCache}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
	postUsersAddusershift: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/addusershift`, { ...params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	putUsersUpdateusershift: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(`${url}/updateusershift`, { ...params })
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	getUsersAgentsShift: (id) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/agents/shift/${id}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	getUsersAgentsGroups: (params) => {
		const GroupIds =
			params &&
			params.length > 0 &&
			params.map((m) => `GroupIds=${m}`).join('&');
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/agents/groups?${GroupIds}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	getUsersAgentsLogged: () => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/agents/logged`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	postUsersLogoutUsers: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(`${url}/logout-users`, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
	getAgentsGroupAll: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${url}/agents/group/all`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
	getAgentsCorporateAll: () => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(`${url}/agents/corporate/all`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    }
};

export default usersService;
