const initialState = {
    windowHierarchy: null,
    showNotifications: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case 'WINDOW_HIERARCHY': {
            return { ...state, windowHierarchy: action.window }
        }
        case 'TOGGLE_SHOW_NOTIFICATIONS': {
            return { ...state, showNotifications: !state.showNotifications }
        }
        default:
            return state
    }
}