import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/callchannelextension`;

const channelExtensionService = {
	postCallChannelExtension: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	putCallChannelExtension: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getCallChannelExtension: (id) => {
		return new Promise((resolve, reject) => {
			return client()
				.get(`${url}/${id}`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
};

export default channelExtensionService;
