import {
    GROUPED_USERS
} from '../../../helpers/constants'

const initialState = {
    groupedUsers: {
        groups: [], 
        isLoading: false,
        requestWasMade: true
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GROUPED_USERS: {
            return { ...state, groupedUsers: action.data }
        }
        default:
            return state
    }
}