import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR } from '../../../helpers/constants';
import { ACTIVE_INTERNAL_CHAT,  } from '../../../helpers/internalChatConstants';

import {
    requestReadAllMessagesifActiveChat
  } from '../utils/internalChatFunctions/requestReadMessagesFunc';

import { resetUnreadMessagesCount } from '../utils/internalChatFunctions/onActivateChatFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';

const getContacts = state => state.internalChat.contacts;

function* handleActiveChat(action) {
    const { user } = action.data;
    const contacts = yield select(getContacts);
    const contact = contacts.find(contact => contact?.id === user?.id) || null; //GET UPDATED CONTACT DATA

    if(contact?.totalUnread === 0 || !contact) return;
    const {data, response} = yield call(requestReadAllMessagesifActiveChat, contact);
    if(data){
        try {
            yield put(resetUnreadMessagesCount(contacts, contact));
        } catch(e) {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.saga,
                errorType: errorTypes.error,
                response: e.message
            });
    
        }
    } else {
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.backend,
            errorType: errorTypes.error,
            response: response
        });
    }
}

export default function* onActivateChatSaga() {
    yield takeEvery(ACTIVE_INTERNAL_CHAT, handleActiveChat);
}