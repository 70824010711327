import { client } from "../../utils/axios-utils";

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/qualifiers`;

const qualifiersService = {
  getQualifiersById: (id) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  getQualifiersByPagination: () => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/by-pagination`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  getQualifiersTreeView: (id) => {
    return new Promise((resolve, reject) => {
      return client()
        .get(`${url}/tree-view/${id}`)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  },
  postQualifiers: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .post(`${url}`, { ...params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
  putQualifiers: (params) => {
    return new Promise((resolve, reject) => {
      return client()
        .put(`${url}`, { ...params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
  deleteQualifiers: (id) => {
    return new Promise((resolve, reject) => {
      return client()
        .delete(`${url}?qualifierId=${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  },
};

export default qualifiersService;
