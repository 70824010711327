import { call, takeEvery } from 'redux-saga/effects';
import { IS_THE_USER_TYPING,  } from '../../../helpers/internalChatConstants';

import {
    postIsTheUserTypingEvent,
} from '../utils/internalChatFunctions/userTypingEventFunc';

function* sendIsTheUserTypingEvent(action) {
    try {
        const { data, response } = yield call(postIsTheUserTypingEvent, action.data);
    } catch (e) {

    }
}

export default function* userTypingEventSaga() {
    yield takeEvery(IS_THE_USER_TYPING, sendIsTheUserTypingEvent);
}