import React, { useState } from 'react';
import { ReactComponent as ReportsIcon } from "../../../../assets/img/svg/transfer-icon.svg";
import { X } from "react-feather";
import moment from 'moment-timezone';
import classnames from 'classnames';

function TransferNotification({ data, type, handleClearNotifications, handleDeleteNotification }) {
    const [docked, setDocked] = useState(true)
    const array = data
    function toggleDocked(){
        setDocked(!docked)
    }
    return <div className="notification-group">
        {(!docked && array.length > 3) &&
            <div className="notification-group-header" onClick={toggleDocked}>
                <h2>Transferências</h2>
                <span>Mostrar menos</span>
            </div>
        }
        {(docked && array.length > 3) && <div className="notification-clear-all" onClick={() => handleClearNotifications(type, data)}><X size={12} /> <span>Limpar tudo</span></div>}
        <div className="notification-group-body">
        {array.sort((a, b) => b.id - a.id).map( (item, index) => 
        <div key={`notify-${item.id}`} 
            className={`notification-item cursor-pointer ${classnames({
                grouped: docked && array.length > 0,
                docked: docked && array.length > 0 && index > 0 && index < 3,
                queued: docked && array.length > 0 && index > 2
              })}`} 
              style={{
                // bottom: docked && `-${index === 1 ? 25 : index === 2 ? 40 : 0 }px`,
                marginTop: index > 0 && index < 3 && docked && array.length > 3 && `-49%`,
                marginBottom: index === 2 && docked && 0,
                zIndex: array.length - index,
                transform: docked &&  array.length > 3 && `scale(${1 - (index * .05)})`
            }}
              onClick={() => array.length > 3 ? toggleDocked() : {}}>
            <div className="notification-icon">
                <ReportsIcon />
            </div>
            <div className="notification-description">
                <h2>Solicitação de transferência</h2>
                <p>{item.text}</p>
                <small>{moment.utc(item.createdAt).local().fromNow()}</small>
            </div>
            {(!docked || array.length <= 3) &&
                <div className="notification-delete" onClick={ event => {
                    event.stopPropagation();
                    handleDeleteNotification(type, item.id)
                }}>
                    <X />
                </div>
            }
        </div>
    )}
        </div>
        
    </div>
}

export default TransferNotification