import { releaseVersionPagesService } from '../../../../newServices/main';
import { IReleaseVersion } from '../../../../interfaces/releaseNotes/releaseVersions.interface';
import { IReleaseVersionPage }  from '../../../../interfaces/releaseNotes/releaseVersionPages.interface';
import { STORE_RELEASE_NOTE_PAGES  } from '../../../../helpers/releaseNotesConstants';
import { IUserData } from '../../../../interfaces/auth/userData.interface';

export const getReleaseVersionPages = ( currentRelease: IReleaseVersion): IReleaseVersionPage | object => {
    return releaseVersionPagesService
      .getReleaseVersionPages({
        releaseVersionId: currentRelease.id
      })
      .then((res) => res)
      .catch((err) => err);
}

export const listOfReleaseNotesPages = (data: IReleaseVersionPage[]) => {
    return {
        type: STORE_RELEASE_NOTE_PAGES,
        payload: {
            loading: false,
            pages: data
        }
    }
};
