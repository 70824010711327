import React from "react";
import "./accordion.scss";
import { ChevronDown } from "react-feather";
import { Spinner } from "reactstrap";

function Accordion({ children, className }) {
  return <div className={`accordion-ui ${className ? className : ''}`}>{children}</div>;
}

Accordion.Group = ({ children, className }) => {
    const multipleItems = children.length > 1;
    return <div className={`accordion-group ${multipleItems ? 'multiple' : 'single'} ${className ? className : ''}`}>{children}</div>;
}

Accordion.Item = ({ children, id, title, subtitle, icon, color, opened, toggleAccordion, loading, disabled, maxHeight, className }) => {
    return  <div className={`accordion-item ${opened ? 'opened': ''} ${className ? className : ''}`}>
                <div className={`accordion-item-header ${disabled ? 'disabled' : ''}`} onClick={() => disabled ? {} : toggleAccordion(id)}>
                    {icon &&
                        <div className="accordion-item-icon">
                            {icon}
                        </div>
                    }
                    <div className="accordion-item-title">
                        <h4>{loading ? <Spinner size="sm" /> : title ? title : 'Insert a title'}</h4>
                        {subtitle && <small>{subtitle}</small>}
                    </div>
                    <div className="accordion-item-chevron">
                        <span><ChevronDown size={15} /></span>
                    </div>
                </div>
                <div className={`accordion-item-content-container ${maxHeight > 0 ? 'content-height' : ''}`} style={{maxHeight: opened && maxHeight ? maxHeight : 'auto'}}>
                    <div className={`accordion-item-content`}>
                        {children}
                    </div>
                </div>
            </div>
}

export default Accordion;