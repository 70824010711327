import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/companies`;

const companiesService = {
	patchCompaniesNumberLicenses: () => {
		return new Promise((resolve, reject) => {
			return client()
				.patch(`${url}/number-licenses`)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	getCompanies: () => {
		return new Promise((resolve, reject) => {
			return client()
				.get(url)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	postCompanies: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.post(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},

	putCompanies: (params) => {
		return new Promise((resolve, reject) => {
			return client()
				.put(url, params)
				.then((res) => resolve(res.data))
				.catch((error) => reject(error));
		});
	},
};

export default companiesService;
