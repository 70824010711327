import React from 'react';
import Thumbnail from '../../thumbnail/Thumbnail';
import './message-attachments.scss';

export const MessageAttachments = React.memo(({attachments}) => {
    /** Campanhas e listas de transmissão possuem um endereço específico para acesso aos anexos */
    // urlProperty: "campaign"
    // urlProperty: "broadcastlist"
    if(attachments) {
        const attachmentTemplate =  attachments?.length > 0 && attachments?.length <= 3  ? 'attach-single-column' :
                                    attachments?.length > 3 ? 'attach-double-column' :
                                    'attach-single-column';

        return  <div className={`message-attachments-ui ${attachmentTemplate}`}>
                    {attachments.slice(0,4).map((attachment, index) => 
                        <Thumbnail 
                            key={`attachment-${attachment.id}`} 
                            data={attachment} 
                            itemsHideen={attachments.length > 4 && index === 3 ? attachments.length - 4 : 0}
                        />
                    )}
                </div>
    } else {
        return null;
    }
});