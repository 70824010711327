import { ACTIVE_INTERNAL_CHAT, INTERNAL_CHAT_CONTACTS } from '../../../../helpers/internalChatConstants';
import { conversationsService } from '../../../../newServices/corporateChat';


export const toggleLoading = (activeChat) => {
    return {
        type: ACTIVE_INTERNAL_CHAT,
        data: {
            ...activeChat,
            isLoading: !activeChat.isLoading
        }
    };
};

export const terminateActiveChat = (activeChat) => {
    return conversationsService.
        postConversationsEnd(activeChat.user.id)
        .then(res => res)
        .catch(err => err);
}

export const removeContactFromList = (contacts, activeChat) => {
    const newContacts = contacts.filter(contact => contact.id !== activeChat.user.id);
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: newContacts
    }
}

export const removeActiveChat = () => {
    return {
        type: ACTIVE_INTERNAL_CHAT,
        data: {
            user: null,
            position: {
                x: 0,
                y: 0
            }
        }
    }
}
