import React, { ReactNode, HTMLAttributes } from 'react';
import './heading.scss';

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: string;
  dark?: boolean;
  light?: boolean;
  fw?: number;
  children: ReactNode;
}

const elements = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

function Heading({ type = 'h1', color, dark, light, fw, children, ...props }: HeadingProps) {
  let className = props.className ? `${props.className} heading-ui` : 'heading-ui';
  className = `ui-color ui-${color ? color : 'gray'} ${light ? `ui-light` : dark ? `ui-dark` : ''} ${fw ? `ui-fw-${fw}` : 'ui-fw-400'} ${className}`;
  return React.createElement(
    elements[type] || elements.h1, 
    {...props, className: className}, 
    children
  );
}

export default React.memo(Heading);