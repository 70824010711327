export const STORE_USER = 'STORE_USER'
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT'
export const USER_EXPIRED = 'USER_EXPIRED'
export const STORE_USER_ERROR = 'STORE_USER_ERROR'
export const LOADING_USER = 'LOADING_USER'
export const EXPIRING_TOKEN = 'EXPIRING_TOKEN'
export const EXPIRED_TOKEN = 'EXPIRED_TOKEN'
export const STORE_USER_INFO = 'USER_DATA'
export const USER_STATUS = 'USER_STATUS'
export const LOGGED_USER_STATUS = 'LOGGED_USER_STATUS'

