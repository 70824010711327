import { call, put, takeEvery, select } from 'redux-saga/effects';

import { SAGA_ERROR } from '../../../helpers/constants';
import { IReleaseVersion } from '../../../interfaces/releaseNotes/releaseVersions.interface';

import {
    UPDATE_RELEASE_NOTE_USER_STATUS
} from '../../../helpers/releaseNotesConstants';

import {
    createOrUpdateReleaseNoteStates
} from '../utils/releaseNotesFunctions/releaseNoteUserStatusFunc';

import {
    toggleReleaseNotesModals
} from '../utils/releaseNotesFunctions/releaseNodesModalsFunc';

import { errorTypes, errorOrigin } from '../utils/toastActionCreator';


const getCurrentRelease = (state: { releaseNotes: { currentRelease: IReleaseVersion; }; }) => state.releaseNotes.currentRelease;

function* updateReleaseNoteUserStatus( action: any ) {
    try {
        const currentRelease: IReleaseVersion = yield select(getCurrentRelease);

        const updateType = action.data;
        const { data, response } = yield call(createOrUpdateReleaseNoteStates, currentRelease, updateType);
        if(data){
            yield put(toggleReleaseNotesModals({
                welcome: false,
                presentation: false
            }));    
        } else {
            yield put({
                type: SAGA_ERROR,
                origin: errorOrigin.backend,
                errorType: errorTypes.error,
                response: response
            });
        }
    }
    catch (e) {
        const error = e as Error;
        yield put({
            type: SAGA_ERROR,
            origin: errorOrigin.saga,
            errorType: errorTypes.error,
            response: error
        });
    }
}

export default function* releaseNoteUserStatusSaga() {
    yield takeEvery(UPDATE_RELEASE_NOTE_USER_STATUS, updateReleaseNoteUserStatus);
}
