import React from 'react';
import { Paragraph } from '../../../../typography';
import { useIntl } from 'react-intl';

const Confirmation = React.memo(({ selectedUsers, messages }) => {
    const intl = useIntl();
    return (
        <div className="p-1">
            <Paragraph>{ intl.formatMessage({ 
                id: "Ao enviar {messages}, você iniciará um chat {users}." 
                }, {
                    messages: messages.length === 1 ? 'essa mensagem' : `essas mensagens`,
                    users: selectedUsers.length === 1 ? 'o usuário selecionado' : `com cada um dos usuários selecionados`
                })}</Paragraph>
            <Paragraph>{ intl.formatMessage({ 
                id: "Confirma o envio de {messages} para {users}?" 
            }, {
                messages: messages.length === 1 ? '1 mensagem' : `${messages.length} mensagens`,
                users: selectedUsers.length === 1 ? '1 usuário' : `${selectedUsers.length} usuários`
            })}</Paragraph>
        </div>
    )
});

export default Confirmation;