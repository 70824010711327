import { INTERNAL_CHAT_CONTACTS, ACTIVE_INTERNAL_CHAT, UPDATE_INTERNAL_CHAT_CONTACT_STATUS, CREATE_NEW_INTERNAL_CHAT } from '../../../../helpers/internalChatConstants';
import { generateDarkColorFromString } from '../../../../utils/generateColorBasedOnString';
import { messagesService } from '../../../../newServices/corporateChat';
import { stat } from 'fs';

export const updateUserIsTyping = (contacts, notification) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if(contact.userId === notification.userFromId){
                return {
                    ...contact,
                    isTyping: notification.isTyping
                }
            }
            return contact;
        })
    }
}

export const addNewMessageToContact = (contacts, activeChat, notification) => {
    /** Caso a mensagem esteja sendo recebida por um chat ativo
     *  - Adiciona uma nova mensagem ao contato
     *  - Não incrementa o total de mensagens não lidas
     *  Caso contrário
     *  - Nâo adiciona uma nova mensagem ao contato, pois ao ativar o contato as mensagens serão carregadas
     *  - Incrementa o total de mensagens não lidas
     */

    const messageIsBeenDeliveredToActiveChat = activeChat?.userId === notification.userSender.id;
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if(contact.userId === notification.userSender.id){
                console.warn(contact.firstLoaded)
                console.warn(notification)
                return {
                    ...contact,
                    lastMessage:    notification.messageText,
                    dateLastMessage: notification.createdAt,
                    totalUnread:    messageIsBeenDeliveredToActiveChat ? contact.totalUnread : contact.totalUnread + 1,
                    isFinished: false,
                    messages:       contact.firstLoaded ? 
                                    [...contact.messages, {...notification, isSent: false }] :
                                    contact.messages
                                
                }
            }
            return contact;
        })
    }
}

export const updateExistinContact = (contacts, notification) => {
    return typeof contacts.find(contact => contact.userId === notification.userSender.id) !== 'undefined'
}


export const craetaNewContact = (contacts, status, notification) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: [
            ...contacts,
            {
                id: notification.conversationId,
                userId: notification.userSender.id,
                name: notification.userSender.name,
                lastMessage: notification.messageText,
                dateLastMessage: notification.createdAt,
                lastMessageStatusType: null,
                email: notification.userSender?.email,
                loggedUserStatus: notification.userSender?.loggedUserStatus,
                roleName: notification.userSender?.userRole,
                pause: notification.userSender?.pause,
                color: generateDarkColorFromString(notification.userSender.email),
                totalUnread: 1, //ver com tarcis,
                messages: []
            }
        ],
        userStatus: {
            byUserId: {
                ...status.byUserId,
                [notification.userSender.id]: {
                    userId: notification.userSender.id,
                    lastMessageStatusType: null,
                    loggedUserStatus: notification.userSender?.loggedUserStatus,
                    pause: notification.userSender?.pause
                }
            }
        }
    }
}

export const updateActiveChatUserStatus = (activeChat, notification) => {
    return {
        type: ACTIVE_INTERNAL_CHAT,
        data: {
            ...activeChat,
            user: {
                ...activeChat.user,
                loggedUserStatus: notification.loggedUserStatus,
                pause: notification.pause
            }
        }
    }
}

export const updateContactStatus = (status) => {
    console.log(status)
    return {
        type: UPDATE_INTERNAL_CHAT_CONTACT_STATUS,
        data: status
    }
}

export const deleteMessageFromContact = (contacts, notification) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if(contact.userId === notification.userFromId){
                return {
                    ...contact,
                    messages: contact.messages.map( message => {
                        if(message.id === notification.messageId){
                            return {
                                ...message,
                                isDeleted: true
                            }
                        }
                        return message;
                    })
                }
            }
            return contact;
        })
    }
}

export const removeContactFromList = (contacts, notification) => {
    const newContacts = contacts.map(contact => {
        if(contact.userId === notification.userFromId){
            return {
                ...contact,
                isFinished: true
            }
        }
        return contact;
    })

    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: newContacts
    }
}

export const changeMessageStatus = (contacts, notification) => {
    return {
        type: INTERNAL_CHAT_CONTACTS,
        contacts: contacts.map(contact => {
            if(contact.userId === notification.userFromId){
                return {
                    ...contact,
                    messages: contact.messages.map( message => {
                        if(message.id === notification.messageId){
                            return {
                                ...message,
                                messageStatus: notification.messageStatus,
                            }
                        }
                        return message;
                    })
                }
            }
            return contact;
        })
    }
}