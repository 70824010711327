export const linkify = (text) => {
    const re = /(\(.*?)?\b((?:https?|ftp|file):\/\/[-a-z0-9+&@#\/%?=~_()|!:,.;]*[-a-z0-9+&@#\/%=~_()|])/ig;
    return text.replace(re, (match, lParens, url) => {
        let rParens = '';
        lParens = lParens || '';
        const lParenCounter = /\(/g;
        while (lParenCounter.exec(lParens)) {
            let m;
            if (m = /(.*)(\.\).*)/.exec(url) ||
                    /(.*)(\).*)/.exec(url)) {
                url = m[1];
                rParens = m[2] + rParens;
            }
        }
        return lParens + "<a href='" + url + "' target='_blank'>" + url + "</a>" + rParens;
    });
}