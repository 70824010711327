import mock from "../mock"
let data = {
    scripts: [
        {
          id: 1,
          content: 'Solicitar o endereço completo do cliente',
          status: true
        },
        {
          id: 2,
          content: 'Informar o prazo de compensação do boleto de 3 dias',
          status: true
        },
        {
          id: 3,
          content: 'Procurar receber contas em atraso via cartão de crédito.',
          status: true
        },
        {
          id: 4,
          content: 'Inormar número de protocolo ao cliente.',
          status: true
        },
        {
          id: 5,
          content: 'Informar que o número do protocolo será enviado por email ou sms.',
          status: true
        },
        {
          id: 6,
          content: 'Informar que será enviado um link para responder uma pesquisa de satisfação e solicitar a gentileza do cliente responder.',
          status: true
        },
    ]
}

// GET : Calendar Events
mock.onGet("/api/apps/group-rules/chat-scripts").reply(() => {
  return [200, data.scripts]
})
// POST : Add new user
mock.onPatch("/api/apps/group-rules/chat-scripts").reply(request => {

  let { id } = JSON.parse(request.data)

  const change = data.scripts.find(
    s => s.id === id
  ).status = !data.scripts.find(s => s.id === id).status
  return [201, data.scripts]
})

mock.onPost("/api/apps/group-rules/chat-scripts").reply(request => {
  
  let { params } = JSON.parse(request.data)
  const uid = Math.floor(Math.random() * 99999)
    console.log( params )
  params.id = uid
  data.scripts.unshift(params)
  return [200, data.scripts]
})

mock.onPut("/api/apps/group-rules/chat-scripts").reply(request => {
  let { params } = JSON.parse(request.data)
    let script = data.scripts.find(m => m.id === params.id)
    script.content = params.content
  return [200, data.scripts]
})

