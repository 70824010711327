import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import './avatar.scss';

interface AvatarProps {
  name: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'gray'
    | 'system'
    | 'info'
    | 'warning'
    | 'danger'
    | 'sky-blue'
    | 'spanish-gray'
    | 'default';
  dark?: boolean;
  icon?: string;
  style?: React.CSSProperties;
  squared?: boolean;
  status?: ReactNode;
  testid?: string;
}

const Avatar: FC<AvatarProps> = ({
  name,
  size="sm",
  color,
  dark,
  icon,
  style = {},
  squared,
  status,
  testid="avatar-ui",
}) => {
  const fullname = name ? name.split(' ') : ['no', 'name'];
  const userInitials =
    fullname.length === 1
      ? name.slice(0, 2)
      : fullname.shift()!.charAt(0) + fullname.pop()!.charAt(0);

  return (
    <div
      data-testid={testid}
      style={{ ...style }}
      className={classnames(
        `avatar-ui`,
        {
          'avatar-primary': color === 'primary',
          'avatar-secondary': color === 'secondary',
          'avatar-success': color === 'success',
          'avatar-gray': color === 'gray',
          'avatar-system': color === 'system',
          'avatar-info': color === 'info',
          'avatar-warning': color === 'warning',
          'avatar-danger': color === 'danger',
          'avatar-sky-blue': color === 'sky-blue',
          'avatar-spanish-gray': color === 'spanish-gray',
        },
        { dark: dark },
        { squared: squared },
        {
          'avatar-xs': size === 'xs',
          'avatar-sm': size === 'sm',
          'avatar-md': size === 'md',
          'avatar-lg': size === 'lg',
          'avatar-xl': size === 'xl',
        },
      )}
    >
      {icon ? <img src={icon} alt={name} /> : userInitials}
      {status && <div className="avatar-status">{status}</div>}
    </div>
  );
};

export default React.memo(Avatar);
