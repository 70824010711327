import { conversationsService } from "../../../../newServices/corporateChat";


export const postIsTheUserTypingEvent = ({user, typing}) => {
    return conversationsService
            .putConversationsTypingConversationId({
                conversationId: user?.id,
                isTyping: typing
            })
            .then(res => res)
            .catch(error => error)
}