import React from 'react';
import moment from 'moment';

import 'moment/locale/pt-br';

import { calendarDate, getLocalTime } from '../../../utils/dateUtility';

import Divider from '../../divider/Divider';

import './message-dividers.scss';

/** Os componentes abaixo são responsáveis por exibir os divisores do chat,
 *  melhorando a experiência do usuário.
 */

const MessageDivider = ({type, children}) => <div className={`divider-ui ${type}`}>
    {children}
</div>

export const DividerDate = React.memo(({ currDate, prevDate }) => {
    const changedDay = !moment(currDate).isSame(prevDate, 'day')
    const date = moment(getLocalTime(currDate)).calendar(null, {
        lastDay: '[Ontem]',
        sameDay: '[Hoje] HH:mm',
        nextDay: '[Amanhã]',
        lastWeek: 'dddd',
        nextWeek: 'DD/MM',
        sameElse: 'DD/MM/YY'
    })
    return changedDay ? <div className="chat-messages-divider-date">
        {<Divider className="mt-2 mb-1" color="primary">{date}</Divider>}
    </div> : null
});


export const StartAgentDivider = React.memo(({prevMessage, currMessage, noIsGroup}) => {
    /** O divider de start, deverá comparar a troca de agentes respeitando as seguintes prioridades
     *  Caso exista a propriedade relatedUserId, usar essa propriedade
     *  Caso não exista, utilizar userSender.userId, porém, apenas em mensagens enviadas pelo agente
     *  pois essa propridade não existe em mensagens enviadas pelo cliente.
     *  À partir do dia 29/03/2023, foi adicionada a propriedade relatedUserId em todas as mensagens, assim
     *  mensagens anteriores a essa data, poderá apresentar problemas nesse divider.
     */

    const startNewAgent =   currMessage && currMessage?.reltedUserId && prevMessage && prevMessage?.relatedUserId && (currMessage?.relatedUserId !== prevMessage?.relatedUserId) ||
                            (
                                (!currMessage?.relatedUserId && !prevMessage?.relatedUserId) && 
                                currMessage?.userSender?.userId && prevMessage?.userSender?.userId && 
                                (currMessage?.userSender?.userId !== prevMessage?.userSender?.userId)
                            ) ? true : false;

    if (startNewAgent && noIsGroup) {
        const userName = currMessage?.relatedUserName ? currMessage?.relatedUserName : currMessage.userSender.userName;
        const createdAt = currMessage.Received;
        const date = moment(getLocalTime(createdAt)).calendar(null, {
            lastDay: '[Ontem às] HH:mm',
            sameDay: '[Hoje às] HH:mm',
            nextDay: '[Amanhã]',
            sameElse: '[em ]DD/MM/YY [às] HH:mm'
        })
        return  <MessageDivider type="default">
                    {userName} iniciou o atendimento {date}
                </MessageDivider>
    } else {
        return null
    }
});

export const EndAgentDivider = React.memo(({currMessage, nextMessage, noIsGroup}) => {
    const endAgentConversation =   currMessage?.reltedUserId && nextMessage?.relatedUserId && (currMessage?.relatedUserId !== nextMessage?.relatedUserId) ||
                            (
                                (!currMessage?.relatedUserId && !nextMessage?.relatedUserId) && 
                                currMessage?.userSender?.userId && nextMessage?.userSender?.userId && 
                                (currMessage?.userSender?.userId !== nextMessage?.userSender?.userId)
                            ) ? true : false;
    

    if (endAgentConversation && noIsGroup) {
        const userName = currMessage?.relatedUserName ? currMessage?.relatedUserName : currMessage.userSender.userName;
        const createdAt = currMessage.Received;
        const date = calendarDate(createdAt)
        return <Divider className="mb-3">{userName} finalizou ou transferiu o atendimento {date}</Divider>
    } else {
        return null
    }
    return null;
});

export const ProtocolDivider = React.memo(({prevMessage, currMessage}) => {

    if((prevMessage?.protocol && currMessage?.protocol && prevMessage?.protocol !== currMessage?.protocol)) {
        const date = calendarDate(currMessage.createdAt)
        const person = currMessage.userName ? `com ${currMessage.userName}` : '';

        return  <MessageDivider type="protocol">
                    <b>Protocolo:</b> {currMessage.protocol} {date} {person}
                </MessageDivider>

    } else {
        return null
    }
});

export const ClosingProtocolDivider = React.memo(({currMessage, nextMessage}) => {

    if(nextMessage && nextMessage?.protocol !== currMessage?.protocol && currMessage?.closingProtocolAuthorType) {
    const { closingProtocolAuthorType, closingProtocolAuthorTypeName, closingProtocolUserId, closingProtocolUserName, createdAt } = currMessage;
        const finished = calendarDate(createdAt);

        switch (closingProtocolAuthorType) {
            case 1:
                return  <MessageDivider type="closing-protocol">
                            Protocolo encerrado pelo agente {closingProtocolUserName} {finished}
                        </MessageDivider>
                
            case 2:
                return  <MessageDivider type="closing-protocol">
                            Protocolo encerrado pelo supervisor {closingProtocolUserName} {finished}
                        </MessageDivider>
                
            case 3:
                return  <MessageDivider type="closing-protocol">
                            Protocolo encerrado pelo bot {finished}
                        </MessageDivider>
            case 4:
                return  <MessageDivider type="closing-protocol">
                            Protocolo encerrado por limite de tempo {finished}
                        </MessageDivider> 
            default:
                return null;
        }
    } else {
        return null
    }
});

export const StartConversationDivider = React.memo(({currDate, index, canRequestOldMessages}) => {
    const date = calendarDate(currDate)
    return index === 0 && !canRequestOldMessages ? 
                        <MessageDivider type="first-interaction">
                            Início da conversa {date}
                        </MessageDivider>  : 
                        null
});
