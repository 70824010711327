import React from "react";
import { useIntl } from 'react-intl';

import Button from "../../../button/Button";
import { Paragraph } from "../../../typography";
import Modal from "../../../modal/Modal";


const DeleteModalConfirmation = ({ show, toggle, activeChat, handleConfirm}) =>{
    const intl = useIntl();
    return (
    <Modal
          position="center"
          show={show}
          toggle={toggle}>
          <Modal.Header>{intl.formatMessage({ id: "Finalizar conversa" })}</Modal.Header>
          <Modal.Content>
            <Paragraph color="gray" light>{
              intl.formatMessage({ id: 'Ao confirmar, o seu chat com {name} será removido das conversas recentes, mas você poderá iniciar um novo chat a qualquer momento.' },
                {
                  name: <b>{activeChat.user?.name}</b>
                })}</Paragraph>
            <Paragraph color="gray" light>{intl.formatMessage({ id: 'Confirma o encerramento dessa conversa?' })}</Paragraph>

          </Modal.Content>
          <Modal.Footer>
            <div className="d-flex justify-content-between">
              <Button.Cancel
                disabled={activeChat?.isLoading}
                onClick={toggle} />
              <Button.Confirm 
                disabled={activeChat?.isLoading}
                loading={activeChat?.isLoading}
                dark onClick={handleConfirm} />
            </div>
          </Modal.Footer>
        </Modal>
    )
};

export default React.memo(DeleteModalConfirmation);
