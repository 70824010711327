const initialState = {
  socketEvent: null
}

const socket = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SOCKET_EVENT":
      return { ...state, socketEvent: action.socketEvent }
    default:
      return { ...state }
  }
}

export default socket
