export const INTERNAL_CHAT_CONTACTS = 'INTERNAL_CHAT_CONTACTS';
export const ACTIVE_INTERNAL_CHAT = 'ACTIVE_INTERNAL_CHAT';
export const TERMINATE_INTERNAL_CHAT = 'TERMINATE_INTERNAL_CHAT';
export const UPDATE_INTERNAL_CHAT_MESSAGING_INFO = 'UPDATE_INTERNAL_CHAT_MESSAGING_INFO';
export const SEND_INTERNAL_CHAT_MESSAGE = 'SEND_INTERNAL_CHAT_MESSAGE';
export const RECEIVE_INTERNAL_CHAT_MESSAGE = 'RECEIVE_INTERNAL_CHAT_MESSAGE';
export const START_INTERNAL_CHATS = 'START_INTERNAL_CHATS';
export const IS_LOADING = 'IS_LOADING';
export const IS_THE_USER_TYPING = 'IS_THE_USER_TYPING';
export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const DELETE_INTERNAL_CHAT_MESSAGE = 'DELETE_INTERNAL_CHAT_MESSAGE';
export const CORPORATE_NOTIFY_USER_TYPING_MESSAGE = 'CORPORATE_NOTIFY_USER_TYPING_MESSAGE';
export const CORPORATE_NOTIFY_MESSAGE = 'CORPORATE_NOTIFY_MESSAGE';
export const CORPORATE_NOTIFY_MESSAGE_DELETE = 'CORPORATE_NOTIFY_MESSAGE_DELETE';
export const CORPORATE_NOTIFY_FINISHED_CONVERSATION = 'CORPORATE_NOTIFY_FINISHED_CONVERSATION';
export const REMOVE_CONTACT_FROM_LIST = 'REMOVE_CONTACT_FROM_LIST';
export const CORPORATE_NOTIFY_MESSAGE_WAS_READ = 'CORPORATE_NOTIFY_MESSAGE_WAS_READ';
export const UPDATE_INTERNAL_CHAT_CONTACT_STATUS = 'UPDATE_INTERNAL_CHAT_CONTACT_STATUS';
export const CREATE_NEW_INTERNAL_CHAT = 'CREATE_NEW_INTERNAL_CHAT';