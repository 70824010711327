import mock from "../mock"
let data = {
    timelimits: [
        {
          id: 1,
          type: 'end_chat',
          time: '00:20',
        },
        {
            id: 2,
            type: 'transfer_chat',
            time: '00:40',
        }
    ]
}

// GET : Calendar Events
mock.onGet("/api/apps/group-rules/time-limits").reply(() => {
  return [200, data.timelimits]
})

mock.onPut("/api/apps/group-rules/time-limits").reply(request => {
    let { params } = JSON.parse(request.data)
    
    params.map((g,i) => {
      if(g.id){
        //ATUALIZA CONFIGURAÇÃO EXISTENTE
        let timelimit = data.timelimits.find(m => m.id === g.id)
        timelimit.time = g.time
      } else {
        //ADICIONA UM NOVO ITEM DE CONFIGURAÇÃO
        const uid = Math.floor(Math.random() * 99999)
        data.timelimits.push({
          id: uid,
          type: g.type,
          time: g.time,
        })
      }
    })
  return [200, data.timelimits]
})

