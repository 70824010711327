import { client } from '../../utils/axios-utils';

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_URI}/blacklist`;

const blacklistService =  {
    deleteBlacklist: (id) => {
        return new Promise((resolve, reject) => {
            return  client()
            .delete(`${url}/${id}`)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    getBlacklist: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .get(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    postBlacklist: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .post(url, params)
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },
    putBlacklist: (params) => {
        return new Promise((resolve, reject) => {
            return  client()
            .put(url, {...params})
            .then(res => resolve(res.data))
            .catch(error=> reject(error))
        })
    },

}
export default  blacklistService;